import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, IconNames } from 'components/icon';

import { sizes } from 'constants/styles';
import { TagType } from 'constants/ui';

import { tagTypeProp } from 'helpers/propTypes';

/**
 * Renders the close icon section for closable tags.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Function} props.onClick
 * @param {string} props.type
 * @return {StatelessComponent}
 */
const TagClose = ({ className, onClick, type }) => (
  <button
    aria-label="tag-close-icon-button"
    className={clsx('tag-close', className, type.toLowerCase())}
    onClick={onClick}
    type="button"
  >
    <Icon className="tag-close-icon" icon={IconNames.CROSS} size={sizes.iconSizes.MEDIUM} />
  </button>
);

TagClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: tagTypeProp,
};

TagClose.defaultProps = {
  className: undefined,
  onClick: undefined,
  type: TagType.DEFAULT,
};

export default TagClose;
