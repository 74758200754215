import _reduce from 'lodash/reduce';
import { createSelector } from 'reselect';

import { convertToUpperCase } from 'helpers/stringHelpers';
import { allKeys, allValues, uniqueArray } from 'helpers/utility';

import { idSelector, optionsSelector, propsSelector } from 'selectors/globalSelectors';

export const getState = (state) => state.funding;

/**
 * Selects the fundingAccounts object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingAccountsSelector = createSelector([getState], (fundingState) => fundingState.fundingAccounts);

/**
 * Selects fundingAccountAddressManual object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingAccountAddressManualSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingAccountAddressManual,
);

/**
 * Selects the fundingAccountBankManual object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingAccountBankManualSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingAccountBankManual,
);

/**
 * Selects the fundingAccountBankToken object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingAccountBankTokenSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingAccountBankToken,
);

/**
 * Selects the fundingInfoAddresses object
 * @param {ReduxState} state
 */
export const fundingInfoAddressesSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingInfoAddresses,
);

/**
 * Selects the funding info bank accounts object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingInfoBankAccountsSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingInfoBankAccounts,
);

/**
 * Selects the fundingSources object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingSourcesSelector = createSelector([getState], (fundingState) => fundingState.fundingSources);

/**
 * Selects the fundingAccounts byId object
 * @function
 * @param {ReduxState} state
 * @return {ByIdCollection}
 */
export const fundingAccountsByIdSelector = createSelector(
  [fundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.byId,
);

/**
 * Selects the fundingAccounts isFetching
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountsIsFetchingSelector = createSelector(
  [fundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.isFetching,
);

/**
 * Selects errors for fundingAccountAddressManual
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountAddressManualErrorsSelector = createSelector(
  [fundingAccountAddressManualSelector],
  (fundingAccountAddressManual) => fundingAccountAddressManual.errors,
);

/**
 * Selects isSubmitting from fundingAccountAddressManual
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountAddressManualIsSubmittingSelector = createSelector(
  [fundingAccountAddressManualSelector],
  (fundingAccountAddressManual) => fundingAccountAddressManual.isSubmitting,
);

/**
 * Selects the fundingAccountAddressManual lastSubmitted
 * @function
 * @param {ReduxState} state
 * @return {Date|null}
 */
export const fundingAccountAddressManualLastSubmittedSelector = createSelector(
  [fundingAccountAddressManualSelector],
  (fundingAccountAddressManual) => fundingAccountAddressManual.lastSubmitted,
);

/**
 * Selects the fundingAccountBankToken isSubmitting
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountBankTokenIsSubmittingSelector = createSelector(
  [fundingAccountBankTokenSelector],
  (fundingAccountBankToken) => fundingAccountBankToken.isSubmitting,
);

/**
 * Selects the fundingAccountBankToken lastSubmitted
 * @function
 * @param {ReduxState} state
 * @return {Date|null}
 */
export const fundingAccountBankTokenLastSubmittedSelector = createSelector(
  [fundingAccountBankTokenSelector],
  (fundingAccountBankToken) => fundingAccountBankToken.lastSubmitted,
);

/**
 * Selects the fundingAccountBankManual isSubmitting
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountBankManualIsSubmittingSelector = createSelector(
  [fundingAccountBankManualSelector],
  (fundingAccountBankManual) => fundingAccountBankManual.isSubmitting,
);

/**
 * Selects the fundingAccountBankManual isSubmitting
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountBankManualErrorsSelector = createSelector(
  [fundingAccountBankManualSelector],
  (fundingAccountBankManual) => fundingAccountBankManual.errors,
);

/**
 * Selects the fundingAccountBankManual lastSubmitted
 * @function
 * @param {ReduxState} state
 * @return {Date|null}
 */
export const fundingAccountBankManualLastSubmittedSelector = createSelector(
  [fundingAccountBankManualSelector],
  (fundingAccountBankManual) => fundingAccountBankManual.lastSubmitted,
);

/**
 * Selects the fundingSources byId object
 * @function
 * @param {ReduxState} state
 * @return {ByIdCollection}
 */
export const fundingSourcesByIdSelector = createSelector(
  [fundingSourcesSelector],
  (fundingSources) => fundingSources.byId,
);

/**
 * Selects all the fundingAccounts from the state and preps them for React select
 * @function
 * @param {ReduxState} state
 * @return {Object[]}
 */
export const fundingAccountsAllValuesSelector = createSelector([fundingAccountsByIdSelector], (fundingAccounts) =>
  Object.values(fundingAccounts),
);

/**
 * Selects fundingAccount by a singular fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {string} fundingAccountId
 * @return {ObjectMaybe}
 */
export const fundingAccountForIdSelector = createSelector(
  [fundingAccountsByIdSelector, idSelector],
  (fundingAccounts, fundingAccountId) => fundingAccounts[fundingAccountId],
);

/**
 * Selects fundingAccount by a singular fundingAccountId, and returns its company property.
 * @function
 * @param {ReduxState} state
 * @param {string} fundingAccountId
 * @return {StringMaybe}
 */
export const fundingAccountForIdCompanySelector = createSelector([fundingAccountForIdSelector], (fundingAccount) => {
  if (fundingAccount) {
    return fundingAccount.company;
  }

  return undefined;
});

/**
 * Selects all funding accounts which have fundingSource property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {FundingAccount[]}
 */
export const fundingAccountsWithFundingSourceSelector = createSelector(
  [fundingAccountsAllValuesSelector],
  (fundingAccounts) => fundingAccounts?.filter((fundingAccount) => Array.isArray(fundingAccount.fundingSources)),
);

/**
 * Selects fundingAccount by a singular fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {string} fundingAccountId
 * @return {ObjectMaybe}
 */
export const fundingSourceForIdSelector = createSelector(
  [fundingSourcesByIdSelector, idSelector],
  (fundingSources, fundingSourceId) => fundingSources[fundingSourceId],
);

/**
 * Selects all the funding sources from the state
 * @function
 * @param {ReduxState} state
 * @return {Object[]}
 */
export const fundingSourcesAllValuesSelector = createSelector([fundingSourcesByIdSelector], (fundingSources) =>
  Object.values(fundingSources),
);

/**
 * Selects the current funding account id
 * @function
 * @param {ReduxState} state
 * @return {string|null}
 */
export const fundingAccountsLastSubmittedIdSelector = createSelector(
  [getState],
  (funding) => funding.fundingAccounts.lastSubmittedId,
);

/**
 * Selects the last converted funding account id
 * @function
 * @param {ReduxState} state
 * @return {string|null}
 */
export const fundingAccountsLastConvertedIdSelector = createSelector(
  [getState],
  (funding) => funding.fundingAccounts.lastConvertedId,
);

/**
 * Selects last verified funding source id
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {FundingSource.id|null}
 */
export const fundingSourcesLastVerifiedIdSelector = createSelector(
  [getState],
  (funding) => funding.fundingSources.lastVerifiedFundingSource,
);

/**
 * Selects the current funding account object
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountsLastSubmittedSelector = createSelector(
  [fundingAccountsByIdSelector, fundingAccountsLastSubmittedIdSelector],
  (fundingAccounts, fundingAccountId) => fundingAccounts[fundingAccountId],
);

/**
 * Selects the last converted funding account object
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountsLastConvertedSelector = createSelector(
  [fundingAccountsByIdSelector, fundingAccountsLastConvertedIdSelector],
  (fundingAccounts, fundingAccountId) => fundingAccounts[fundingAccountId],
);

/**
 * Selects the funding account from last verified funding source id
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {FundingAccount}
 */
export const fundingAccountsLastVerifiedSelector = createSelector(
  [fundingAccountsWithFundingSourceSelector, fundingSourcesLastVerifiedIdSelector],
  (fundingAccounts = [], fundingSourceId) =>
    fundingAccounts.find((fundingAccount) => fundingAccount.fundingSources.includes(fundingSourceId)),
);

/**
 * Selects last submitted or last verified funding account
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {FundingAccount}
 */
export const fundingAccountsLastUpdatedSelector = createSelector(
  [fundingAccountsLastConvertedSelector, fundingAccountsLastSubmittedSelector, fundingAccountsLastVerifiedSelector],
  (lastConvertedFundingAccount, lastSubmittedFundingAccount, lastVerifiedFundingAccount) =>
    lastConvertedFundingAccount || lastSubmittedFundingAccount || lastVerifiedFundingAccount,
);

/**
 * Selects the funding info bank accounts byId
 * @function
 * @param {ReduxState} state
 * @return {ByIdCollection}
 */
export const fundingInfoBankAccountsByIdSelector = createSelector(
  [fundingInfoBankAccountsSelector],
  (fundingInfoBankAccounts) => fundingInfoBankAccounts.byId,
);

/**
 * Selects funding info bank account by a singular fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {string} fundingInfoBankAccountId
 * @return {ObjectMaybe}
 */
export const fundingInfoBankAccountForIdSelector = createSelector(
  [fundingInfoBankAccountsByIdSelector, idSelector],
  (fundingInfoBankAccounts, fundingInfoBankAccountId) => fundingInfoBankAccounts[fundingInfoBankAccountId],
);

/**
 * Selects the funding info addresses byId
 * @function
 * @param {ReduxState} state
 * @return {ByIdCollection}
 */
export const fundingInfoAddressesByIdSelector = createSelector(
  [fundingInfoAddressesSelector],
  (fundingInfoAddresses) => fundingInfoAddresses.byId,
);

/**
 * Selects funding info addresses by a singular fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {[]} options
 * @return {ObjectMaybe}
 */
export const fundingInfoAddressForAccountAddressSelector = createSelector(
  [fundingInfoAddressesByIdSelector, optionsSelector],
  (fundingInfoAddresses, options) => {
    const [fundingAccount] = options;

    if (!fundingAccount) {
      return undefined;
    }

    return fundingInfoAddresses[fundingAccount.address];
  },
);

/**
 * Selects the fundingAccounts isConverting
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingAccountsIsConvertingSelector = createSelector(
  [fundingAccountsSelector],
  (fundingAccounts) => fundingAccounts.isConverting,
);

/**
 * Selects funding info address by a fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {string} fundingAccountId
 * @return {ObjectMaybe}
 */
export const fundingInfoAddressForFundingAccountIdSelector = createSelector(
  [fundingAccountForIdSelector, fundingInfoAddressesByIdSelector],
  (fundingAccount, fundingInfoAddress) => {
    if (!fundingAccount || !fundingAccount.address) {
      return null;
    }

    return fundingInfoAddress[fundingAccount.address];
  },
);

/**
 * Selects funding info bank by a fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {string} fundingAccountId
 * @return {ObjectMaybe}
 */
export const fundingInfoBankAccountForFundingAccountIdSelector = createSelector(
  [fundingAccountForIdSelector, fundingInfoBankAccountsByIdSelector],
  (fundingAccount, fundingInfoBankAccount) => {
    if (!fundingAccount || !fundingAccount.bank) {
      return null;
    }

    return fundingInfoBankAccount[fundingAccount.bank];
  },
);

/**
 * Selects funding info bank by a fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {Object} props
 * @return {ObjectMaybe}
 */
export const fundingAccountFromPropsSelector = createSelector([propsSelector], (props) => props.fundingAccount);

/**
 * Selects funding info bank by a fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {Object} props
 * @return {StringMaybe}
 */
export const fundingAccountIdFromFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector],
  (fundingAccount) => (fundingAccount ? fundingAccount.id : undefined),
);

/**
 * Selects funding info bank by a fundingAccountId
 * @function
 * @param {ReduxState} state
 * @param {Object} props
 * @return {StringMaybe}
 */
export const fundingAccountCreatorIdFromFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector],
  (fundingAccount) => (fundingAccount ? fundingAccount.creator : undefined),
);

/**
 * Selects fundingSources from fundingAccount prop
 * @function
 * @param {ReduxState} state
 * @param {Object} props
 * @return {StringMaybe}
 */
export const fundingSourceIdsFromFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector],
  (fundingAccount) => fundingAccount?.fundingSources || [],
);

/**
 * Selects funding info bank by a fundingAccountId, when fundingAccount is in props
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @return {FundingInfoBankAccount|undefined}
 */
export const fundingInfoBankAccountForFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector, fundingInfoBankAccountsByIdSelector],
  (fundingAccount, fundingInfoBankAccounts) => {
    if (!fundingAccount || !fundingAccount.bank) {
      return undefined;
    }

    return fundingInfoBankAccounts[fundingAccount.bank];
  },
);

/**
 * Selects funding info address by fundingAccount.address, when fundingAccount is in props
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @returns {FundingInfoAddress|undefined}
 */
export const fundingInfoAddressForFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector, fundingInfoAddressesByIdSelector],
  (fundingAccount, fundingInfoAddresses) => fundingInfoAddresses[fundingAccount?.address],
);

/**
 * Selects the fundingInfoInternational prop from the funding state
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingInfoInternationalSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingInfoInternational,
);

/**
 * Selects the fundingInfoInternational byId map from state
 * @function
 * @param {ReduxState} state
 * @return {ByIdCollection}
 */
export const fundingInfoInternationalByIdSelector = createSelector(
  [fundingInfoInternationalSelector],
  (fundingInfoInternational) => fundingInfoInternational.byId,
);

// DEV-4800 @adamjaffeback - This is the START of the old beneficiary requirements selectors

/**
 * Selects fundingInfoInternational from a given fundingAccount in the props.
 * @type {StandardSelector}
 * @function
 * @param {ReduxState} state
 * @param {Object} props
 * @return {FundingInfoInternational}
 */
export const fundingInfoInternationalForFundingAccountPropSelector = createSelector(
  [fundingAccountFromPropsSelector, fundingInfoInternationalByIdSelector],
  (fundingAccount, fundingInfoInternationalById) => fundingInfoInternationalById[fundingAccount?.international],
);

/**
 * Selects the fundingSupportedCountries object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingSupportedCountriesSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingSupportedCountries,
);

/**
 * Selects the fundingSupportedCountries allCountryCodes array
 * @function
 * @param {ReduxState} state
 * @return {String[]}
 */
export const fundingSupportedCountriesAllCountryCodesSelector = createSelector(
  [fundingSupportedCountriesSelector],
  (supportedCountries) => supportedCountries.allCountryCodes,
);

/**
 * Selects the fundingSupportedCountries by Valid CountryCode object except empty country code
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingSupportedCountriesByCountryCode = createSelector(
  [fundingSupportedCountriesSelector],
  (supportedCountries) =>
    _reduce(
      supportedCountries.byCountryCode,
      (validSupportedCountries, countryCode) => {
        if (countryCode?.id.length > 0) {
          return { ...validSupportedCountries, [countryCode.id]: countryCode };
        }

        return validSupportedCountries;
      },
      {},
    ),
);

/**
 * Selects the fundingSupportedCountries all byCountryCode object
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingSupportedCountriesByCountryCodeWithEmptyUS = createSelector(
  [fundingSupportedCountriesSelector],
  (supportedCountries) => supportedCountries.byCountryCode,
);

/**
 * Returns a list of all the Routable supported currencies in lowercase
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @return {String[]}
 */
export const fundingSupportedCountriesPaymentOptionsCurrencyList = createSelector(
  [fundingSupportedCountriesByCountryCode],
  (supportedCountriesByCountryCode) => {
    // Get array of all supported currencies
    const allCurrencies = allValues(supportedCountriesByCountryCode).reduce(
      (arr, { paymentOptions }) => [...arr, ...allKeys(paymentOptions)],
      [],
    );

    // Remove duplicates and convert to uppercase
    return uniqueArray(allCurrencies).map(convertToUpperCase);
  },
);

/**
 * Selects international bank validation data
 * @function
 * @param {ReduxState} state
 * @return {Array}
 */
export const internationalBankValidationData = createSelector(
  [getState, idSelector],
  (fundingState, fieldName) => fundingState.fundingInfoInternationalBankValidator.data?.[fieldName] || {},
);
