import { HELP_DOCS_URL } from 'constants/platform';
const buildHelpDocsURL = (section, page) => `${HELP_DOCS_URL}/hc/${section}/${page}`;
const helpDocs = {
    APPROVING_PAYABLES_AND_RECEIVABLES: buildHelpDocsURL('articles', '21511736497165'),
    ACCOUNT_AND_ROUTING_NUMBERS: buildHelpDocsURL('articles', '21511851815949'),
    ADDENDA_RECORDS: buildHelpDocsURL('articles', '21511804223501'),
    ADDING_BILLS_TO_MY_INBOX: buildHelpDocsURL('articles', '21513045463437'),
    AUTOMATIC_BATCH_PAYMENTS: buildHelpDocsURL('articles', '21509701710477'),
    BALANCE_TRANSACTIONS_ABOUT: buildHelpDocsURL('articles', '21516320894605'),
    BALANCE_TRANSACTIONS_WHAT_DOES_NEGATIVE_MEAN: buildHelpDocsURL('article', '21516469936781'),
    BUSINESS_VERIFICATION_DETAILS: buildHelpDocsURL('articles', '21516644470157'),
    CANCELING_A_PAYABLE_OR_RECEIVABLE: buildHelpDocsURL('articles', '21512880766349'),
    CANNOT_REPLY_TO_ITEM_NO_ACTIVE_CONTACTS: buildHelpDocsURL('articles', '21511921954189'),
    CHECK_FORMATTING: buildHelpDocsURL('articles', '21513900076941'),
    CONTACT_DOUBLE_VERIFICATION: buildHelpDocsURL('articles', '4004206-what-is-contact-double-verification'),
    CONTACT_TYPES: buildHelpDocsURL('articles', '21511902911501'),
    CORRECTING_BULK_FILE_ERRORS: buildHelpDocsURL('articles', '21510857834765'),
    CREATE_AND_DONT_SEND_PAYABLE: buildHelpDocsURL('articles', '21512086913293'),
    CREATE_AND_DONT_SEND_RECEIVABLE: buildHelpDocsURL('articles', '21512636412429'),
    CUSTOMIZE_BUSINESS_BRANDING: buildHelpDocsURL('articles', '21513893624077'),
    DASHBOARD_ONE_TIME_VERIFICATION_FOR_NEW_CUSTOMER: buildHelpDocsURL('articles', '21514103798413'),
    EXTERNAL_CUSTOMER_VERIFY_CUSTOMER_OVER_5000: buildHelpDocsURL('articles', '21514120358669'),
    INTEGRATING_ACCOUNTING_SOFTWARE: buildHelpDocsURL('articles', '21516224117901'),
    ITEM_EDIT_SUPPORT_HINT: buildHelpDocsURL('articles', '21510827360141'),
    EDITING_CONTACTS: buildHelpDocsURL('articles', '21511981996813'),
    ORACLE_NETSUITE_SYNCING_VENDORS_AND_CUSTOMERS: buildHelpDocsURL('articles', '21511532309773'),
    PAYABLE_CONFIGURATIONS: buildHelpDocsURL('articles', '21510280504717'),
    PO_MATCHING_AUTOMATIC_LINKING: buildHelpDocsURL('articles', '31732972814093'),
    SAGE_INTACCT_COMPANY_MATCHING_ALERT: buildHelpDocsURL('articles', '21510720838413'),
    SAGE_INTACCT_INTEGRATING_SOFTWARE: buildHelpDocsURL('articles', '21510703142669'),
    SELF_MANAGED_COMPANIES: buildHelpDocsURL('articles', '21511849274637'),
    SENDING_XB_PAYMENTS_TO_INTERNATIONAL_VENDORS: buildHelpDocsURL('articles', '21509594694925'),
    SETTING_UP_COMPANY_SS4: buildHelpDocsURL('setting-up-your-account/verifying-your-business-account', 'what-is-form-ss-4'),
    SETTING_UP_BULK_TEMPLATE: buildHelpDocsURL('articles', '21511368562829'),
    SETTING_UP_BULK_INTERNATIONAL_TEMPLATE: buildHelpDocsURL('articles', '21510350765581'),
    SSO_SIGN_IN_WITH_GOOGLE: buildHelpDocsURL('articles', '21509769727117'),
    SUPPORTED_FILE_TYPES: buildHelpDocsURL('articles', '21516508450317'),
    SYNC_XB_PAYMENTS_TO_LEDGER: buildHelpDocsURL('articles', '21512936826637'),
    TAX_CONSENT_LEARN_MORE: buildHelpDocsURL('articles', '3584763-receiving-a-1099-electronically-vs-by-mail'),
    TAX_DOCS_COLLECTION: buildHelpDocsURL('collections', '2063610-end-of-year-tax-filing'),
    TAX_WHY_IS_INFO_ALREADY_ENTERED: buildHelpDocsURL('articles', '21516264677005'),
    TRANSFER_LIMITS_ACH: buildHelpDocsURL('articles', '21512012629389'),
    TRANSFER_LIMITS_CHECK: buildHelpDocsURL('articles', '21516454786829'),
    TRANSFER_LIMITS_BANK_TRANSFER: buildHelpDocsURL('articles', '21512012629389'),
    QBO_SYNCING_CLEARING_ACCOUNTS: buildHelpDocsURL('articles', '21516614621965'),
    QBO_WHY_AM_I_SEEING_MATCH_CUSTOMER_OR_VENDOR_ALERT: buildHelpDocsURL('articles', '21514176320525'),
    UNDERSTANDING_ROUTABLE_FEES: buildHelpDocsURL('articles', '21511588791309'),
    UNDERSTANDING_USER_ROLES: buildHelpDocsURL('articles', '21511459326221'),
    WHY_WAS_MY_PAYMENT_METHOD_PREVIOUSLY_ADDED: buildHelpDocsURL('articles', '21516537554189'),
    XERO_SYNCING_CLEARING_ACCOUNTS: buildHelpDocsURL('articles', '21516639920909'),
    XERO_SYNCING_VENDORS_AND_CUSTOMERS: buildHelpDocsURL('articles', '21512982980365'),
    PARTNER_COUNTRY_CURRENCY_HINT: buildHelpDocsURL('articles', '21509803855757'),
    PARTNER_UNIQUE_ID: buildHelpDocsURL('articles', '21509704814477'),
};
export default helpDocs;
