import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Panel = ({ children, className }) => <div className={clsx('panel', className)}>{children}</div>;

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Panel;
