import clsx from 'clsx';
import React from 'react';
import { Field } from 'redux-form';
import { loginHandler } from 'actions/routines/auth';
import { ButtonV2, FormControl, FormFieldLabel, IconNames, ReduxFormFieldRenderInput, Form } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';
import { emailValidator, requiredValidator } from 'helpers/fieldValidation';
import InvalidLoginCredentialsHint from '../InvalidLoginCredentialsHint';
import {} from './EmailPasswordForm.types';
import './EmailPasswordForm.scss';
const EmailPasswordForm = ({ isSubmitting, handleSubmit, loginBtnText = 'Log in', usernameFieldLabel, onClearLoginErrorState, errors, }) => {
    React.useEffect(() => {
        onClearLoginErrorState();
    }, [onClearLoginErrorState]);
    return (React.createElement(Form, { className: "form email-password-form", id: formNamesAuthV2.LOGIN, onSubmit: handleSubmit(loginHandler) },
        usernameFieldLabel && React.createElement(FormFieldLabel, { className: "username-field-label" }, usernameFieldLabel),
        React.createElement(FormControl, null,
            React.createElement(Field, { className: clsx(formStyles.field.xl.full, 'input-height--xl'), component: ReduxFormFieldRenderInput, dataFullStory: true, name: "form.username", placeholder: "Email", type: "email", validate: [requiredValidator, emailValidator] })),
        React.createElement(FormControl, null,
            React.createElement(Field, { className: clsx(formStyles.field.xl.full, 'input-height--xl'), component: ReduxFormFieldRenderInput, dataFullStory: true, name: "form.password", placeholder: "Password", type: "password", validate: requiredValidator })),
        React.createElement(InvalidLoginCredentialsHint, { errors: Boolean(errors) }),
        React.createElement("div", { className: "margin-top--m-large" },
            React.createElement(ButtonV2, { htmlFor: formNamesAuthV2.LOGIN, id: "auth_login_btn", isLoading: isSubmitting, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, type: "submit" }, loginBtnText))));
};
export default EmailPasswordForm;
