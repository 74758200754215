import clsx from 'clsx';
import React from 'react';
import { ButtonV2, IconNames } from 'components';
import { ButtonSize } from 'constants/button';
import { googleLetterLogoMap } from 'constants/images';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import './LoginWithGoogleButton.scss';
const LoginWithGoogleButton = ({ mode = 'light', className, ...btnProps }) => (React.createElement(ButtonV2, { className: clsx('login-with-google-btn', className), intent: mode === 'light' ? Intent.NEUTRAL : Intent.PRIMARY, rightIconClassName: "google-btn--right-icon", rightIconName: IconNames.ARROW_RIGHT, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, ...btnProps },
    React.createElement("img", { alt: "Google logo", className: "google-btn--logo", src: googleLetterLogoMap[mode] }),
    "Login with Google"));
export default LoginWithGoogleButton;
