import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './Label.scss';

const Label = ({ children, className }) => <div className={clsx('label', className)}>{children}</div>;

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = {
  className: '',
};

export default Label;
