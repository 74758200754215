import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import { inputHelpers, internationalPhoneInputHelpers } from 'components/form/helpers';
import { InputPlaceholder } from 'components/input';
import * as commonProps from 'components/input/commonProps';

import { flattenNestedErrors, getFieldErrors } from 'helpers/errors';
import { isUndef, noop } from 'helpers/utility';

import { useDebouncedField } from 'hooks';

import { InternationalPhoneInputField, InternationalPhoneInputLabel } from './components';

import 'react-phone-number-input/style.css';
import './InternationalPhoneInput.scss';

/**
 * Input which formats phone numbers correctly based on country code or user country selection from a dropdown.
 * Uses 'react-phone-number-input' package which utilizes Google's libphonenumber metadata for formatting, validation,
 * etc.
 * @param {Object} props
 * @returns {FunctionComponent}
 */
const InternationalPhoneInput = (props) => {
  const {
    debounceDelay,
    defaultCountry,
    errors,
    hasErrors,
    isDebounced,
    isRequired,
    name,
    onChange,
    onValuesChange,
    placeholder,
    showLabel: overrideShowLabel,
    updateFieldMeta,
    value,
  } = props;

  /**
   * The label in this component is not a direct sibling to the input. We normally show/hide/focus styles with
   * pseudo-classes in forms.scss using the sibling selector. These component state flags help us apply our own classes
   * to handle what is normally done by pseudo-classes in forms.scss.
   */
  const [isFocused, changeIsFocused] = React.useState(false);
  const phoneInputRef = React.useRef({ value: value.number });
  const [showLabel, shouldShowLabel] = React.useState(overrideShowLabel || Boolean(value?.number));

  const derivedShowErrors = Boolean(hasErrors && !isFocused);

  const classOptions = {
    hasErrors: derivedShowErrors || getFieldErrors(errors, name),
  };
  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, classOptions);

  const flattenedErrors = flattenNestedErrors(errors);

  const derivedShowLabel = isUndef(overrideShowLabel) ? showLabel : overrideShowLabel;

  const focusHandleParams = {
    changeIsFocused,
    shouldShowLabel: noop,
    updateFieldMeta,
  };

  const {
    // this would normally always be the callback to use, whether debouncing or not. however,
    // this component has a special callback in the general case, so this time, we only want
    // to call this specific onChange function when debouncing updates.
    onChange: onDebouncedChange,
    // this is always the value to use, whether or not we're debouncing
    value: currentValue,
  } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input: { onChange, value },
  });

  React.useEffect(() => {
    shouldShowLabel(Boolean(currentValue.number));
  }, [overrideShowLabel, currentValue.number]);

  const handleBlur = internationalPhoneInputHelpers.createHandleFocus({
    ...focusHandleParams,
    toggleIsFocusedTo: false,
  });

  const handleFocus = internationalPhoneInputHelpers.createHandleFocus({
    ...focusHandleParams,
    toggleIsFocusedTo: true,
  });

  const handleChange = internationalPhoneInputHelpers.handleChange({
    reduxFormKey: name,
    shouldShowLabel,
    onValuesChange,
    isDebounced,
    onDebouncedChange,
  });

  const handleCountryChange = internationalPhoneInputHelpers.handleCountryChange(name, onValuesChange);

  return (
    <div className={clsx(widthClasses)}>
      <div className={clsx('input-container', otherClasses)}>
        <InternationalPhoneInputLabel {...props} isFocused={isFocused} showLabel={derivedShowLabel} />
        <InternationalPhoneInputField
          {...props}
          defaultCountry={defaultCountry}
          onBlur={handleBlur}
          onChange={(phone) => handleChange(phone, isFocused, currentValue)}
          onCountryChange={(country) => handleCountryChange(country)}
          onFocus={handleFocus}
          ref={phoneInputRef}
          showLabel={derivedShowLabel}
        />
        <InputPlaceholder
          className={clsx({ showLabel: derivedShowLabel })}
          isRequired={isRequired}
          placeholder={placeholder}
          value={phoneInputRef.current?.value}
        />
      </div>

      {derivedShowErrors && <FormFieldErrors errors={flattenedErrors} fieldName={name} />}
    </div>
  );
};

InternationalPhoneInput.propTypes = {
  ...commonProps.propTypes,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      country: PropTypes.string,
      number: PropTypes.string,
    }),
  ]),
  showLabel: PropTypes.bool,
  updateFieldMeta: PropTypes.func.isRequired,
};

InternationalPhoneInput.defaultProps = {
  ...commonProps.defaultProps,
  label: undefined,
  inputClassName: '',
  showLabel: undefined,
  value: '',
};

export default InternationalPhoneInput;
