import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Convenience component for modal footer's right content className.
 * @param {Object} props
 * @param {*} [props.children]
 * @param {string} [props.className]
 * @param {DOMProps} domFriendlyRest
 * @returns {StatelessComponent}
 */
const ModalFooterContentRight = ({ children, className, ...domFriendlyRest }) => (
  <div {...domFriendlyRest} className={clsx('content--right', className)}>
    {children}
  </div>
);

ModalFooterContentRight.propTypes = {
  children: PropTypes.node,
};

ModalFooterContentRight.defaultProps = {
  children: undefined,
};

export default ModalFooterContentRight;
