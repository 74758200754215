import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import BrandLogo from '../brand/BrandLogo';

import './BrandNav.scss';

const BrandNav = ({ children, disableLink }) => (
  <div className="brand--nav">
    <div className={clsx('nav--content-left', { 'disable-pointer-events': disableLink })}>
      <BrandLogo />
    </div>

    {children && <div className="nav--content-right">{children}</div>}
  </div>
);

BrandNav.propTypes = {
  children: PropTypes.node,
  disableLink: PropTypes.bool,
};

BrandNav.defaultProps = {
  children: null,
  disableLink: false,
};

export default BrandNav;
