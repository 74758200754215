import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { staticImagesFullPaths } from 'constants/assets';

import './ErrorCard.scss';

const ErrorCard = ({ children, header, imagePath, reason, noBorder }) => (
  <div className="error-card--container">
    <div className={clsx('error-card--body', { 'no-border': noBorder })}>
      <img
        alt="Error illustration"
        className="error-card--img"
        src={`${process.env.REACT_APP_STATIC_BASE_PATH}${imagePath}`}
      />

      <h2 className="error-card--header">{header}</h2>

      <p className="error-card--reason">{reason}</p>

      {children}
    </div>
  </div>
);

ErrorCard.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  imagePath: PropTypes.string,
  reason: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
};

ErrorCard.defaultProps = {
  children: undefined,
  header: undefined,
  imagePath: staticImagesFullPaths.ERROR_GENERIC,
  noBorder: undefined,
};

export default ErrorCard;
