import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { radioDefaultProps, radioPropTypes } from 'components/commonProps';

import RadioTitle from '../RadioTitle';

import '../Radio.scss';

/**
 * Component for input of type 'radio'.
 * Includes RadioTitle as well, for showing text next to the input.
 * @param {Object} props
 * @param {String} props.id
 * @param {Object} props.input
 * @param {boolean} [props.isRequired]
 * @returns {StatelessComponent}
 */
const RadioInput = ({ dataTestId, id, input, isDisabled, isRequired, optionText, subText, tooltipProps }) => (
  <label className={clsx('radio--label', { 'no-subtext': !subText })} htmlFor={id}>
    <input {...input} data-testid={dataTestId} disabled={isDisabled} id={id} required={isRequired} type="radio" />
    <span className={clsx('primary radio--indicator', { disabled: !!isDisabled })} />
    <RadioTitle isChecked={input.checked} optionText={optionText} subText={subText} tooltipProps={tooltipProps} />
  </label>
);

RadioInput.propTypes = {
  ...radioPropTypes,
  dataTestId: PropTypes.string,
  isDisabled: PropTypes.bool,
  optionText: PropTypes.string,
  tooltipProps: PropTypes.shape(),
  subText: PropTypes.string,
};

RadioInput.defaultProps = {
  ...radioDefaultProps,
  dataTestId: undefined,
  isDisabled: undefined,
  optionText: undefined,
  tooltipProps: undefined,
};

export default RadioInput;
