import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ToggleAllButton } from 'components/buttonTypes';

import './InteractionList.scss';

/**
 * Display an InteractionBar with a list of typically actionable elements below. Used for payment methods, contacts, etc.
 * @param {Object} props
 * @param {String} [props.className]
 * @param {number} props.displayMax - Max items to render before "show all" button (if set to -1, all items will show)
 * @param {Node} props.interactionBar
 * @param {Node} props.isShowingAll - Override to display all items regardless of displayMax value
 * @param {Function} props.keyExtractor
 * @param {Node[]} props.listItems - An array of components
 * @return {FunctionComponent}
 */
const InteractionList = ({
  displayMax,
  className,
  interactionBar,
  keyExtractor,
  listItems,
  isShowingAll: overrideIsShowingAll,
}) => {
  const [isShowingAll, setIsShowingAll] = React.useState(overrideIsShowingAll);

  const itemCount = listItems?.length ?? 0;
  const hasItems = itemCount > 0;

  const hasDisplayMax = displayMax > -1;
  const displayCount = !isShowingAll && hasDisplayMax ? displayMax : itemCount;

  const shouldDisplayToggleAll = itemCount > displayMax;

  const items = listItems.slice(0, displayCount);

  return (
    <div className={clsx('interaction-list', className, { 'has-items': hasItems })}>
      {interactionBar}

      {hasItems && (
        <ul className="interaction-list-items">
          {items.map((listItem, idx) => {
            const key = keyExtractor(listItem, idx);

            return (
              <li className="interaction-list-item" key={key}>
                {listItem}
              </li>
            );
          })}
        </ul>
      )}

      {shouldDisplayToggleAll && (
        <ToggleAllButton
          className="margin-top--m"
          onClick={() => setIsShowingAll(!isShowingAll)}
          shouldDisplayFewer={isShowingAll}
        />
      )}
    </div>
  );
};

InteractionList.propTypes = {
  className: PropTypes.string,
  displayMax: PropTypes.number,
  interactionBar: PropTypes.node.isRequired,
  isShowingAll: PropTypes.bool,
  keyExtractor: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.node).isRequired,
};

InteractionList.defaultProps = {
  className: undefined,
  displayMax: -1,
  isShowingAll: undefined,
  keyExtractor: (obj) => obj.key,
};

export default InteractionList;
