import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Option component for react-select that displays a label and a verboseLabel (description/info).
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {boolean} props.isDisabled
 * @return {StatelessComponent}
 */
const VerboseLabel = ({ children, dataFullStory, isDisabled }) => (
  <span
    className={clsx('base-text', 'verbose-option--label', { disabled: !!isDisabled })}
    data-fullstory={dataFullStory}
  >
    <span className="truncate">{children}</span>
  </span>
);

VerboseLabel.propTypes = {
  // passed to react-select Option component
  children: PropTypes.node,
  dataFullStory: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

VerboseLabel.defaultProps = {
  children: undefined,
  dataFullStory: undefined,
  isDisabled: undefined,
};

export default VerboseLabel;
