import { DateFormats } from 'constants/date';
import { DASHBOARD, ITEM, PAYMENTS, PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_TABS } from 'constants/routes';
import { TransactionStatus } from 'constants/transactions';

import {
  getBillingDataForBillingCode,
  isBillingCodeAccountsPayable,
  isBillingCodePayableBalanceTransfer,
  isBillingCodeReceivableBalanceTransfer,
} from 'helpers/billing';
import { formatDateString } from 'helpers/date';
import { getFundingAccountMask } from 'helpers/funding';
import { getBillOrInvoiceText, isKindPayable } from 'helpers/items';
import { getJoinedPath } from 'helpers/routeHelpers';
import { capitalize } from 'helpers/stringHelpers';
import { transactionHelpers } from 'helpers/transactions';

import {
  BalanceTransactionBankAccountCell,
  BalanceTransactionCreatedCell,
  BalanceTransactionCurrencyCell,
  BalanceTransactionReferenceCell,
  BalanceTransactionStatusCell,
} from './components';

/**
 * Returns the date string to display for the given transaction's status.
 * @param {Transaction} transaction
 * @param {TransactionStatus} statusToUse
 * @return {string}
 */
export const getStatusDisplayDate = (transaction, statusToUse) => {
  if (!transactionHelpers.doesTransactionHaveDateStatusChange(transaction)) {
    return undefined;
  }

  const { dateExpected, dateStatusChange } = transaction;

  const dateExpectedStr = formatDateString(dateExpected, DateFormats.SHORT_MONTH_SHORT_DATE_FULL_YEAR);
  const dateStatusChangeStr = formatDateString(dateStatusChange, DateFormats.SHORT_MONTH_SHORT_DATE_FULL_YEAR);

  switch (statusToUse) {
    case TransactionStatus.FAILED:
    case TransactionStatus.ISSUE:
    case TransactionStatus.CANCELED:
      return `On ${dateStatusChangeStr}`;

    case TransactionStatus.INITIATED:
    case TransactionStatus.PENDING:
      return `Expected ${dateExpectedStr}`;

    case TransactionStatus.PROCESSED:
      return `Transferred ${dateStatusChangeStr}`;

    default:
      return dateStatusChangeStr;
  }
};

/**
 * Returns the text to display for the given transaction's reference
 * (basically a title plus the transaction id).
 * @param {BillingCodeData} billingData
 * @param {Transaction} transaction
 * @param {Object} [items]
 * @return {string|*}
 */
export const getTransactionTitle = (billingData, transaction, items) => {
  const { billingCode, id, item: itemId } = transaction;

  if (isBillingCodePayableBalanceTransfer(billingCode)) {
    return `Balance: Money in`;
  }

  if (isBillingCodeReceivableBalanceTransfer(billingCode)) {
    return `Balance: Money out`;
  }

  const item = items ? items[itemId] : undefined;

  if (!billingData && !item) {
    return id;
  }

  if (item) {
    const { invoiceNumber, reference } = item;
    const billOrInvoiceText = getBillOrInvoiceText(item);
    return `${capitalize(billOrInvoiceText)}: ${reference || invoiceNumber}`;
  }

  return billingData.paymentDeliveryOptionExternalName;
};

/**
 * Returns the url for the linked item, unless this is a balance
 * transfer transaction.
 * @param {BillingCodeData} billingData
 * @param {Transaction} transaction
 * @return {StringMaybe}
 */
export const getItemLink = (billingData, transaction) => {
  const { isBalance, item } = transaction;

  if (isBalance) {
    return undefined;
  }

  const tab = isKindPayable(billingData?.paymentKind) ? PAYMENTS_LIST_TABS.PAYABLES : PAYMENTS_LIST_TABS.RECEIVABLES;

  return getJoinedPath(DASHBOARD, PAYMENTS, tab, PAYMENTS_LIST_FILTERS.ALL, item, ITEM);
};

/**
 * Returns the class names to use for the amount text.
 * @param {boolean} [isStatusFailed=false]
 * @param {boolean} [isAnyNegativeStep=false]
 * @return {string}
 */
export const getCurrencyAmountClassNames = (isStatusFailed = false, isAnyNegativeStep = false) => {
  if (isStatusFailed && !isAnyNegativeStep) {
    return 'text-decoration--line-through font-color--main-jordan';
  }

  return undefined;
};

/**
 * Returns the amount as it should be displayed in balance transactions table.
 * If the transaction resolved in such a way that it immediately or eventually deducted from balance,
 * this will be a negative number. If money into balance, will be positive.
 * @param {string|number} amount
 * @param {string} billingCode
 * @return {number}
 */
export const getDisplayAmount = ({ amount, isToBalance }) => {
  const parsedAmount = parseFloat(amount);

  // if this isn't money deposited into balance, we'll show it as negative
  if (!isToBalance) {
    return parsedAmount * -1;
  }

  return parsedAmount;
};

/**
 * Returns data to use for the transaction amount cell.
 * @param {Object} options
 * @param {string|number} options.amount
 * @param {Object} options.balanceFundingAccount
 * @param {string} options.billingCode
 * @param {string} options.destinationFundingAccount
 * @param {boolean} options.isDestinationNegative
 * @param {boolean} options.isOriginationNegative
 * @param {boolean} options.isStatusFailed
 * @return {Object}
 */
export const getTransactionAmountCellData = (options) => {
  const {
    amount,
    balanceFundingAccount,
    billingCode,
    destinationFundingAccount,
    isDestinationNegative,
    isOriginationNegative,
    isStatusFailed,
  } = options;

  const isAnyNegativeStep = isDestinationNegative || isOriginationNegative;

  const displayAmount = getDisplayAmount({
    amount,
    billingCode,
    isToBalance: Boolean(balanceFundingAccount && balanceFundingAccount.id === destinationFundingAccount),
  });
  const className = getCurrencyAmountClassNames(isStatusFailed, isAnyNegativeStep);
  const description =
    isStatusFailed && !isAnyNegativeStep
      ? 'This transaction failed to process and did not debit from your account.'
      : undefined;

  return {
    amount: displayAmount,
    className,
    description,
  };
};

/**
 * Returns data to use for the transaction bank account cell.
 * @param {Object} options
 * @param {BillingCodeData} options.billingData
 * @param {string} options.destinationFundingAccount
 * @param {Object} options.fundingAccountsById
 * @param {Object} options.fundingInfoBankAccountsById
 * @param {boolean} options.isBalance
 * @param {boolean} options.isStatusFailed
 * @param {string} options.originationFundingAccount
 * @return {Object}
 */
export const getTransactionAmountBankAccountCellData = (options) => {
  const {
    billingData,
    destinationFundingAccount,
    fundingAccountsById,
    fundingInfoBankAccountsById,
    isBalance,
    isStatusFailed,
    originationFundingAccount,
  } = options;

  let fundingAccountId;
  let accountName;
  let accountNumber;

  const isPayable = billingData && isBillingCodeAccountsPayable(billingData.code);
  const isSuccessPayable = isPayable && !isStatusFailed;
  const isMoneyIntoBalance = isPayable && isBalance;

  if (billingData && !billingData.requireBalance) {
    if (isMoneyIntoBalance || isSuccessPayable) {
      // money into balance
      fundingAccountId = originationFundingAccount;
    } else {
      // money out of balance
      fundingAccountId = destinationFundingAccount;
    }
  }

  const fundingAccount = fundingAccountsById[fundingAccountId];
  const bankAccount = fundingAccount ? fundingInfoBankAccountsById[fundingAccount.bank] : undefined;

  if (bankAccount) {
    accountName = bankAccount.institutionName;
    accountNumber = getFundingAccountMask(bankAccount.accountNumber);
  } else {
    // funding account id, and therefor the bank account, will not be set here
    // if the transaction is for an item payment (and not for a balance transfer).
    // in this case, the relevant account is the balance account, as item payment
    // transactions only originate with that.
    accountName = 'Balance account';
  }

  return {
    name: accountName,
    number: accountNumber,
  };
};

/**
 * Returns data to use for the transaction reference cell.
 * @param {Transaction} transaction
 * @param {BillingCodeData} billingData
 * @param {Object} items
 * @return {Object}
 */
export const getTransactionReferenceCellData = (transaction, billingData, items) => ({
  id: transaction.id,
  item: getItemLink(billingData, transaction),
  title: getTransactionTitle(billingData, transaction, items),
});

/**
 * Returns data to use for the transaction status cell.
 * @param {Transaction} transaction
 * @return {Object}
 */
export const getTransactionStatusData = (transaction) => {
  // status information and text
  const status = transactionHelpers.getTransactionStatusToUse(transaction);

  return {
    date: getStatusDisplayDate(transaction, status),
    status,
  };
};

/**
 * Returns data to use for the transaction created cell.
 * @param {Transaction} transaction
 * @param {BillingCodeData} billingData
 * @return {Object}
 */
export const getTransactionCreatedData = (transaction, billingData) => {
  const { created } = transaction;

  const date = formatDateString(created, DateFormats.SHORT_MONTH_SHORT_DATE_FULL_YEAR);
  let title;

  if (billingData) {
    if (billingData.paymentDeliveryOptionExternalName) {
      title = billingData.paymentDeliveryOptionExternalName;
    } else if (isBillingCodePayableBalanceTransfer(billingData.code)) {
      title = 'Deposit';
    } else if (isBillingCodeReceivableBalanceTransfer(billingData.code)) {
      title = 'Withdrawal';
    }
  }

  return {
    date: `On ${date}`,
    title,
  };
};

/**
 * Formats the billing code data for the TransferLimitsTable.
 * @param {Object} params
 * @param {Object} params.billingDataByCode
 * @param {Object} params.fundingAccountsById
 * @param {Object} params.fundingInfoBankAccountsById
 * @param {Array<Transaction>} params.transactions
 * @return {Array<Object>}
 */
export const getFormattedTableData = ({
  balanceFundingAccount,
  billingDataByCode,
  fundingAccountsById,
  fundingInfoBankAccountsById,
  items,
  transactions,
}) =>
  transactions.map((transaction) => {
    const { amount, billingCode, destinationFundingAccount, isBalance, originationFundingAccount, transactionStep } =
      transaction;

    // is the transaction status explicitly failed?
    const isStatusFailed = transactionHelpers.isTransactionFailed(transaction);
    // the transaction step will inform our css class names
    const isDestinationNegative = transactionHelpers.isTransactionStepDestinationNegative(transactionStep);
    const isOriginationNegative = transactionHelpers.isTransactionStepOriginationNegative(transactionStep);
    // data for the transaction's billing code
    const billingData = getBillingDataForBillingCode(billingDataByCode, billingCode);

    // data for the amount cell
    const amountData = getTransactionAmountCellData({
      amount,
      balanceFundingAccount,
      billingCode,
      destinationFundingAccount,
      isDestinationNegative,
      isOriginationNegative,
      isStatusFailed,
    });

    // data for the date cell
    const dateData = getTransactionCreatedData(transaction, billingData);

    // data for the account cell
    const bankAccountData = getTransactionAmountBankAccountCellData({
      billingData,
      destinationFundingAccount,
      fundingAccountsById,
      fundingInfoBankAccountsById,
      isBalance,
      isStatusFailed,
      originationFundingAccount,
    });

    // data for the reference cell
    const referenceData = getTransactionReferenceCellData(transaction, billingData, items);

    // data for the status cell
    const statusData = getTransactionStatusData(transaction);

    return {
      amount: amountData,
      date: dateData,
      bankAccount: bankAccountData,
      reference: referenceData,
      status: statusData,
    };
  });

/**
 * Returns the configuration to use for TransferLimitsTable.
 * @return {Object}
 */
export const getTableDataConfig = () => ({
  reference: {
    baseClassName: 'table-row--column',
    displayName: 'Transfer reference',
    width: 4,
    obj: BalanceTransactionReferenceCell,
    props: {
      id: 'reference.id',
      title: 'reference.title',
      item: 'reference.item',
    },
  },
  bankAccount: {
    baseClassName: 'table-row--column',
    displayName: 'Account',
    width: 2,
    obj: BalanceTransactionBankAccountCell,
    props: {
      accountName: 'bankAccount.name',
      accountNumber: 'bankAccount.number',
    },
  },
  status: {
    baseClassName: 'table-row--column',
    displayName: 'Transfer status',
    width: 2,
    obj: BalanceTransactionStatusCell,
    props: {
      status: 'status.status',
      date: 'status.date',
    },
  },
  date: {
    baseClassName: 'table-row--column',
    displayName: 'Payment method',
    width: 1,
    obj: BalanceTransactionCreatedCell,
    props: {
      date: 'date.date',
      title: 'date.title',
    },
  },
  amount: {
    baseClassName: 'table-row--column',
    displayName: 'Amount',
    width: 1,
    obj: BalanceTransactionCurrencyCell,
    props: {
      className: 'amount.className',
      description: 'amount.description',
      text: 'amount.amount',
    },
  },
});
