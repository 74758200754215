import React from 'react';
import BaseHint from 'components/hintTypes/BaseHint';
import { RoutableFeesLink } from 'components/linkTypes';
import { Intent } from 'constants/ui';
import { getFundingProviderMemoTransactionFeeText } from 'helpers/fundingProviderMemo';
import helpDocs from 'helpers/helpDocs';
import {} from './FundingProviderMemoTransactionCostHint.types';
const FundingProviderMemoTransactionCostHint = ({ billingData }) => (React.createElement(BaseHint, { intent: Intent.INFO, title: React.createElement(React.Fragment, null,
        `${getFundingProviderMemoTransactionFeeText(billingData)} `,
        React.createElement(RoutableFeesLink, { className: "primary bold", href: helpDocs.ADDENDA_RECORDS })), titleProps: { marginBottom: false } }));
export default FundingProviderMemoTransactionCostHint;
