import { ItemApprovalTypes } from 'constants/item';

import { uniqueArray } from 'helpers/utility';

/**
 * On Item Edit , we want to prefill each level with:
 * - any approver currently on the config for the level
 * - by default/on create item, 'any' levels only pre-fill using the first approver
 *   on item edit however to make it easier for users, we also  if an approver was previously selected (ie. is in state.approvals for this level)
 * @param {object} props
 * @param {Object} props.allApprovals state.approvals
 * @param {ApprovalSelectConfig} props.levelConfig
 * @returns {ApprovalSelectConfig}
 */
export const getSelectedApproversForItemEdit = ({ levelConfig, allApprovals }) => {
  let selectedApprovers = [];

  const { levelType, approvers } = levelConfig;

  if (levelType === ItemApprovalTypes.ANY) {
    // "ANY" levels are enabled by default so RCTM can select approvers
    // On Create Item
    // - if there is multiple approver for this level, we leave the level empty so RCTMs can select approvers
    // - of there is only 1 approver for this level, we pre-fill the level to make it easier for users
    // on Item Edit, we also want to pre-fill using the sames rules, but also add approvers that were previously
    // selected for this level so RCTM don't need to re-select approvers each time they edit
    if (approvers.length > 1) {
      approvers.forEach((approver) => {
        // at this point, allApprovals will contain approvals for this item
        // In ItemEdit.js we clear state.approvals.byId and fetch the item with latest approvals
        const approverWasPreviouslySelectedForThisLevel = allApprovals.find(
          (approval) => approval.levelId === levelConfig.id && approval.membership === approver.id,
        );

        if (approverWasPreviouslySelectedForThisLevel) {
          selectedApprovers.push(approver);
        }
      });
    } else {
      // if there only 1 qualified approver in the approvers array, let's pre-fill the level with that approver
      selectedApprovers = approvers;
    }
  } else if (levelType === ItemApprovalTypes.ALL) {
    // "ALL" levels are disabled and prefilled using every approver for that level
    selectedApprovers = approvers;
  }

  return uniqueArray(selectedApprovers);
};
