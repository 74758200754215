import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import BaseOption from '../BaseOption';

import { VerboseLabel } from './components';

/**
 * Option component for react-select that displays a label and a verboseLabel (description/info).
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Object} props.data
 * @param {Object} props.selectProps
 * @param {string} props.data.verboseLabel
 * @param {string} props.label
 * @return {StatelessComponent}
 */
const VerboseOption = (props) => {
  const { className, data, dataFullStory, isDisabled, selectProps } = props;
  const { verboseLabel } = data;

  return (
    <BaseOption
      stackContentOnMobile={false}
      {...props}
      className={clsx('verbose-option', className)}
      dataFullStory={dataFullStory || selectProps?.dataFullStory}
    >
      {!!verboseLabel && (
        <VerboseLabel dataFullStory={dataFullStory || selectProps?.dataFullStory} isDisabled={isDisabled}>
          {verboseLabel}
        </VerboseLabel>
      )}
    </BaseOption>
  );
};

VerboseOption.propTypes = {
  // passed to react-select Option component
  className: PropTypes.string,
  dataFullStory: PropTypes.bool,
  data: PropTypes.shape({
    // react-select's indicator of whether this is the "create" option
    __isNew__: PropTypes.bool,
    verboseLabel: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

VerboseOption.defaultProps = {
  dataFullStory: undefined,
  className: undefined,
  isDisabled: undefined,
};

export default VerboseOption;
