import PropTypes from 'prop-types';
import React from 'react';

import { assembleLedgerDetailsListItems } from 'helpers/ui';
import { hasZeroLength } from 'helpers/utility';

import DetailsListCard from '../DetailsListCard';

/**
 * DetailsListCard which shows details for ledger-connected dashboard users. Differentiates fields based on the
 * LedgerContactType.
 * @param {Object} props
 * @param {String} [props.className]
 * @param {string} props.fullLedgerName
 * @param {LedgerSettings} props.ledgerSettings
 * @param {Partnership} props.partnership
 * @returns {StatelessComponent}
 */
const CompanyLedgerInfoCard = ({ className, fullLedgerName, ledgerSettings, partnership }) => {
  const detailsListItems = assembleLedgerDetailsListItems(ledgerSettings, partnership);

  // not matched as a vendor or customer
  if (hasZeroLength(detailsListItems)) {
    return null;
  }

  return (
    <DetailsListCard className={className} dataFullStory detailsListItems={detailsListItems} title={fullLedgerName} />
  );
};

CompanyLedgerInfoCard.propTypes = {
  fullLedgerName: PropTypes.string.isRequired,
  ledgerSettings: PropTypes.shape({}).isRequired,
  partnership: PropTypes.shape({}).isRequired,
};

export default CompanyLedgerInfoCard;
