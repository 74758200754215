import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { flexDefaults, flexProps } from 'components/layout/flexProps';
import { getGridColClass, getUtilityClassesConfig } from 'components/layout/helpers';

import { breakpointClassPrefixes, breakpointNames } from 'constants/mediaQuery';

const Block = (props) => {
  const { breakpointProps, cols, children, className, isColumn, isGrid, style, ...rest } = props;

  let breakpointClassConfig = {};

  if (breakpointProps) {
    const breakpoints = Object.keys(breakpointProps);

    breakpoints.forEach((breakpoint) => {
      const { cols: breakpointCols, ...breakPropsRest } = breakpointProps[breakpoint];
      const prefix = breakpointClassPrefixes[breakpoint];
      const breakpointGridColClass = getGridColClass(breakpointCols);
      const breakpointColClass = `${prefix}--${breakpointGridColClass}`;

      breakpointClassConfig = {
        ...breakpointClassConfig,
        ...getUtilityClassesConfig(breakPropsRest, prefix),
        [breakpointColClass]: !!breakpointGridColClass,
      };
    });
  }

  const colClass = getGridColClass(cols);
  const baseConfig = getUtilityClassesConfig(rest, breakpointNames.allMedia);

  return (
    <div
      className={clsx(colClass, className, {
        ...baseConfig,
        ...breakpointClassConfig,
        grid: isGrid,
        col: isColumn && isGrid,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

Block.propTypes = {
  ...flexProps,
  breakpointProps: PropTypes.objectOf(
    PropTypes.shape({
      ...flexProps,
      cols: PropTypes.number,
    }),
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  cols: PropTypes.number,
  isColumn: PropTypes.bool,
  isGrid: PropTypes.bool,
  style: PropTypes.shape(),
};

Block.defaultProps = {
  ...flexDefaults,
  breakpointProps: undefined,
  children: undefined,
  className: undefined,
  cols: undefined,
  isColumn: false,
  isGrid: true,
  style: {},
};

export default Block;
