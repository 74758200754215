import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import { InputLabel, InputPlaceholder } from 'components/input';

import { getFieldErrors } from 'helpers/errors';
import { inputNameOrNameProp } from 'helpers/propTypes';

import * as inputHelpers from './helpers/inputs';

import './Textarea.scss';

/**
 * Textarea component used in forms.
 * @param {Object} props
 * @return {StatelessComponent}
 */
const TextareaField = (props) => {
  const {
    children,
    cols,
    errors,
    input,
    isDisabled,
    isRequired,
    maxLength,
    meta,
    name,
    onBlur,
    onInputChange,
    placeholder,
    required,
    rows,
    style,
    value,
  } = props;

  let currentValue;
  let fieldErrors;
  let hasErrors;
  let inputName;
  let handleOnBlur;
  let handleOnChange;

  if (input) {
    // This is a redux-form input
    inputName = input.name;
    currentValue = input.value;
    fieldErrors = (meta.touched || meta.submitFailed) && meta.error;
    hasErrors = !!fieldErrors;
    handleOnChange = input.onChange;
    handleOnBlur = input.onBlur;
  } else {
    // This is not a redux-form input
    inputName = name;
    currentValue = value;
    fieldErrors = errors;
    hasErrors = getFieldErrors(errors, inputName);
    handleOnChange = onInputChange;
    handleOnBlur = onBlur;
  }

  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, { hasErrors });

  return (
    <div className={clsx(widthClasses)}>
      <div className={clsx('input-container', 'h-auto', otherClasses)}>
        {children}

        <InputLabel name={name} placeholder={placeholder} value={currentValue} />

        <textarea
          className="mt-1 h-full"
          cols={cols}
          disabled={isDisabled}
          id={inputName}
          maxLength={maxLength}
          name={inputName}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          required={required}
          rows={rows}
          style={style}
          value={currentValue || ''}
        />
        <InputPlaceholder isRequired={isRequired} placeholder={placeholder} value={currentValue} />
      </div>

      <FormFieldErrors errors={fieldErrors} fieldName={inputName} />
    </div>
  );
};

TextareaField.propTypes = {
  children: PropTypes.node,
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.shape(),
  input: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meta: PropTypes.shape(),
  name: inputNameOrNameProp,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  value: PropTypes.string,
};

TextareaField.defaultProps = {
  children: undefined,
  cols: 1,
  errors: undefined,
  input: undefined,
  isDisabled: undefined,
  isRequired: undefined,
  maxLength: undefined,
  meta: {},
  name: undefined,
  onBlur: undefined,
  onInputChange: undefined,
  required: undefined,
  rows: 5,
  style: {},
  value: '',
};

export default TextareaField;
