import React from 'react';
import {} from './ExternalComponents.types';
const ExternalComponents = ({ className, components, renderExternalComponent }) => {
    if (!components?.length) {
        return null;
    }
    return (React.createElement("div", { className: className }, components.map((componentName) => {
        const ComponentEl = renderExternalComponent(componentName);
        if (!ComponentEl) {
            return null;
        }
        return (React.createElement("div", { className: "external-components--component-wrapper", key: componentName }, ComponentEl));
    })));
};
export default ExternalComponents;
