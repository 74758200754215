import { SubmissionError } from 'redux-form';
import { all, call, put, select } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { externalOnboardingChangeStep, externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { pushHistory } from 'actions/navigation';
import {
  fetchExternalPartnershipRequestRoutine,
  submitPartnerCompanyContactInfoRoutine,
  submitPartnerCompanyGeneralInfoRoutine,
  submitTermsOfServiceRoutine,
} from 'actions/routines/external';

import { NOT_FOUND } from 'constants/routes';

import {
  getExternalPartnershipRequestCompanyPayload,
  getExternalPartnershipRequestMembershipPayload,
} from 'data/submitTransformers/partnershipRequest';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getJoinedPath } from 'helpers/routeHelpers';
import { sagaWatcher } from 'helpers/saga';

import { initialStepIndexSelector } from 'queries/currentWorkflowStepSelector';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserIdSelector } from 'selectors/currentUserSelectors';
import { partnershipRequestFromLocationSelector } from 'selectors/partnershipRequestSelectors';

import { FetchService } from 'services';

import * as api from './api';

export function* fetchExternalPartnerRequestDetails() {
  let errorData = {};

  yield put(fetchExternalPartnershipRequestRoutine.request());

  try {
    const response = yield call(api.fetchPartnershipRequestDetails);

    if (response.ok) {
      yield put(fetchExternalPartnershipRequestRoutine.success(response.data));

      // Set initial step to be displayed by ExternalWorkflowWrapper
      const defaultActiveStepIndex = yield select(initialStepIndexSelector);
      yield put(externalOnboardingChangeStep(defaultActiveStepIndex));

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const { status } = errorData.options;

  // In this case, we deal with 401 and 403 responses differently than in other
  // parts of the app, so we need to check if error is either Unauthorized or
  // Forbidden.
  const isErrorUnauthorizedOrForbidden =
    FetchService.isResponseForbidden({ status }) || FetchService.isResponseNotAuthorized({ status });

  if (isErrorUnauthorizedOrForbidden) {
    // If it is Unauthorized or Forbidden error, we want to trigger failure on the routine
    // and dispatch pushHistory action to redirect the user to the Not Found page.
    yield put(fetchExternalPartnershipRequestRoutine.failure(errorData));
    yield put(pushHistory(getJoinedPath(NOT_FOUND)));
  } else {
    // For every other error type, handle things the same way we do normally.
    yield put(handleRequestErrors(fetchExternalPartnershipRequestRoutine.failure, errorData));
  }
}

export function* updateExternalPartnershipContactInfo({ payload }) {
  let errorData = {};

  yield put(submitPartnerCompanyContactInfoRoutine.request());

  // Get partnershipRequest and current user Id from the state
  const partnershipRequest = yield select(partnershipRequestFromLocationSelector);
  const userId = yield select(currentUserIdSelector);

  try {
    // Make API request
    const response = yield call(api.patchPartnershipRequestContactInfo, {
      partnershipRequest,
      payload: getExternalPartnershipRequestMembershipPayload({
        partnershipRequest,
        payload,
        userId,
      }),
    });

    if (response.ok) {
      yield put(submitPartnerCompanyContactInfoRoutine.success(response.data));
      yield put(externalOnboardingIncrementStep());
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(submitPartnerCompanyContactInfoRoutine.failure, errorData));
}

/**
 * Will submit data to create Company. Used in the external accept partnership flow.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* updateExternalPartnershipCompanyInfo({ payload }) {
  const { company, formUI, partner } = payload.values;
  let errorData = {};

  yield put(submitPartnerCompanyGeneralInfoRoutine.request());

  const partnershipRequest = yield select(partnershipRequestFromLocationSelector);
  const currentCompany = yield select(currentCompanySelector);
  const partnership = yield select(partnershipFromQuerySelector);

  try {
    const response = yield call(api.patchPartnershipRequestCompanyInfo, {
      partnershipRequest,
      payload: getExternalPartnershipRequestCompanyPayload({
        companyInfoId: partnershipRequest?.companyInfo,
        company,
        currentCompany,
        formUI,
        partnership,
        partner,
      }),
    });

    if (response.ok) {
      yield all([
        put(submitPartnerCompanyGeneralInfoRoutine.success(response.data)),
        put(externalOnboardingIncrementStep()),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  // Create field-level error if error data includes "fields" property
  if (errorData?.fields) {
    yield put(
      submitPartnerCompanyGeneralInfoRoutine.failure(
        // On the UI, we set all of the mailingAddress fields under the company namespace.
        // Since this is only true on the UI (and not on the BE), the error fields returned are
        // not under that namespace, and we need to add it.
        new SubmissionError({ company: errorData.fields }),
      ),
    );
    return;
  }

  // Create general errors payload if there are no field-level errors
  yield put(handleRequestErrors(submitPartnerCompanyGeneralInfoRoutine.failure, errorData));
}

/**
 * Will submit Terms Of Agreement external accept partnership flow.
 * @return {IterableIterator<*>}
 */
export function* submitExternalTermsOfService() {
  let errorData;

  yield put(submitTermsOfServiceRoutine.request());

  // Get partnershipRequest from the state
  const partnershipRequest = yield select(partnershipRequestFromLocationSelector);

  try {
    const response = yield call(api.patchPartnershipTOS, {
      partnershipRequest,
    });

    if (response.ok) {
      yield all([put(submitTermsOfServiceRoutine.success(response.data)), put(externalOnboardingIncrementStep())]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(submitTermsOfServiceRoutine.failure, errorData));
}

/**
 * Root external saga
 * @return {IterableIterator<*>}
 */
export default function* externalSagas() {
  yield sagaWatcher([
    {
      type: fetchExternalPartnershipRequestRoutine.TRIGGER,
      saga: fetchExternalPartnerRequestDetails,
    },
    {
      type: submitPartnerCompanyContactInfoRoutine.TRIGGER,
      saga: updateExternalPartnershipContactInfo,
    },
    {
      type: submitPartnerCompanyGeneralInfoRoutine.TRIGGER,
      saga: updateExternalPartnershipCompanyInfo,
    },
    {
      type: submitTermsOfServiceRoutine.TRIGGER,
      saga: submitExternalTermsOfService,
    },
  ]);
}
