import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Text from 'components/text/Text';

import { TextColor, TextSize } from 'constants/styles/typography';

import { oneOfValuesFromObject } from 'helpers/propTypes';

/**
 * A piece of text which kind of looks like a link, but is disabled.
 *
 * @param {ComponentProps} props
 * @param {AnyComponent} props.children
 * @param {String} [props.className]
 * @param {Object} [props.textProps={}]
 * @param {string} props.cursorClassName
 * @returns {StatelessComponent}
 */
const DisabledTextLinkNewInternalPage = ({ children, className, cursorClassName, TextComponent, textProps, size }) => (
  <TextComponent
    className={clsx(className, cursorClassName)}
    color={TextColor.GREY_MED}
    size={size || TextSize.LEVEL_200}
    {...textProps}
  >
    {children}
  </TextComponent>
);

DisabledTextLinkNewInternalPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  cursorClassName: PropTypes.string,
  textProps: PropTypes.shape(),
  size: oneOfValuesFromObject(TextSize),
  TextComponent: PropTypes.elementType,
};

DisabledTextLinkNewInternalPage.defaultProps = {
  className: undefined,
  cursorClassName: 'cursor--default',
  textProps: {},
  size: undefined,
  TextComponent: Text.Regular,
};

export default DisabledTextLinkNewInternalPage;
