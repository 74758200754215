import clsx from 'clsx';
import React from 'react';
import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';
import {} from './RoutableFeesLink.types';
const RoutableFeesLink = ({ className, href, isInternationalPayment }) => (React.createElement(React.Fragment, null,
    "Learn more about",
    React.createElement(WhiteSpace, null),
    React.createElement(LinkNewPage, { className: clsx(className), href: href }, isInternationalPayment ? 'international payment fees' : 'transaction fees'),
    "."));
export default RoutableFeesLink;
