import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidgetHeader, EntityWidgetHeaderSubtitle } from 'components/entityWidget';

import { BankAccountViewType } from 'constants/ui';

import {
  getFundingAccountDisplayStatus,
  getFundingAccountDisplayStatusText,
  getFundingAccountTabStatus,
} from 'helpers/funding';
import { isBankAccountViewTypePartnerAchAny, isBankAccountViewTypePartnerAddressAny } from 'helpers/ui';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { BankAccountHeaderContentRight, BankAccountHeaderTitle, BankAccountInfoStatus } from './components';
import { shouldShowBankAccountHeaderContentRight } from './helpers';

const BankAccountHeader = ({
  contentLeft,
  contentRight,
  fundingAccount,
  fundingInfoAddress,
  isCollapsible,
  onClick,
  menu,
  partnership,
  partnershipFundingAccount,
  viewType,
}) => {
  const isPartnerAddressView = isBankAccountViewTypePartnerAddressAny(viewType);
  const isPartnerACHView = isBankAccountViewTypePartnerAchAny(viewType);
  const status = getFundingAccountTabStatus(
    fundingAccount,
    isPartnerAddressView || isPartnerACHView,
    partnershipFundingAccount,
  );
  const displayStatus = getFundingAccountDisplayStatus(fundingAccount, {
    partnershipFundingAccount,
    viewType,
  });
  const statusText = getFundingAccountDisplayStatusText(fundingAccount, {
    viewType,
    partnership,
    partnershipFundingAccount,
  });

  const shouldShowContentRight = shouldShowBankAccountHeaderContentRight({
    viewType,
    partnershipFundingAccount,
  });

  return (
    <EntityWidgetHeader
      className={clsx('bank-account--header', { 'bank-account--address-header': isPartnerAddressView })}
      contentLeft={contentLeft}
      contentRight={
        contentRight ||
        (shouldShowContentRight && (
          <BankAccountHeaderContentRight
            menu={menu}
            partnership={partnership}
            partnershipFundingAccount={partnershipFundingAccount}
            viewType={viewType}
          />
        ))
      }
      isCollapsible={isCollapsible}
      onClick={onClick}
      subtitle={
        <EntityWidgetHeaderSubtitle>
          <BankAccountInfoStatus displayStatus={displayStatus} displayText={statusText} status={status} />
        </EntityWidgetHeaderSubtitle>
      }
      title={
        <BankAccountHeaderTitle
          fundingAccount={fundingAccount}
          fundingInfoAddress={fundingInfoAddress}
          viewType={viewType}
        />
      }
    />
  );
};

BankAccountHeader.propTypes = {
  contentLeft: PropTypes.node,
  contentRight: PropTypes.node,
  fundingAccount: PropTypes.shape(),
  fundingInfoAddress: PropTypes.shape(),
  isCollapsible: PropTypes.bool,
  onClick: PropTypes.func,
  menu: PropTypes.func,
  partnership: PropTypes.shape(),
  partnershipFundingAccount: PropTypes.shape(),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountHeader.defaultProps = {
  contentLeft: undefined,
  contentRight: undefined,
  fundingAccount: {},
  fundingInfoAddress: {},
  isCollapsible: true,
  menu: undefined,
  onClick: undefined,
  partnership: undefined,
  partnershipFundingAccount: undefined,
  viewType: BankAccountViewType.FULL,
};

export default BankAccountHeader;
