import clsx from 'clsx';
import React from 'react';
import { SafeAndSecureBadge } from 'components/badges';
import { BrandLogo } from 'components/brand';
import { TermsOfServiceLink, HelpDocsLink, LinkNewPage, PrivacyPolicyLink } from 'components/link';
import { LOGO } from 'global/images/platform';
import helpDocs from 'helpers/helpDocs';
import {} from './BrandWrapperV2.types';
import './BrandV2.scss';
const BrandWrapperV2 = ({ children, className, helpDocsLink, showFooter = true, showLogo }) => {
    let helpLink = React.createElement(HelpDocsLink, null);
    if (helpDocs[helpDocsLink]) {
        helpLink = (React.createElement(LinkNewPage, { className: "primary", href: helpDocs[helpDocsLink] }, "Help Docs"));
    }
    return (React.createElement("div", { className: clsx('brandedV2--container', className, { 'has--footer': !!showFooter }) },
        showLogo && (React.createElement("div", { className: "brandedV2--logo-container" },
            React.createElement(BrandLogo, { imageSrc: LOGO.NAVY }))),
        children,
        showFooter && (React.createElement("div", { className: "brandedV2--footer-container" },
            React.createElement("div", { className: "brandedV2--footer" },
                React.createElement("div", { className: "brandedV2--footer__nav-container" },
                    React.createElement("ul", { className: "brandedV2--footer__nav-links" },
                        React.createElement("li", null,
                            React.createElement(SafeAndSecureBadge, { includeLink: true })),
                        React.createElement("li", null,
                            React.createElement(TermsOfServiceLink, null)),
                        React.createElement("li", null,
                            React.createElement(PrivacyPolicyLink, null)),
                        React.createElement("li", null, helpLink))))))));
};
export default BrandWrapperV2;
