import _get from 'lodash/get';

import { inviteTeamMemberFormFields } from 'constants/formFields';
import { formNamesItem, formNamesPartnership } from 'constants/forms';

import * as atomicUtils from 'helpers/utility/atomic';
import { isValidEmail } from 'helpers/validation';

export const valueOrEmptyString = (value) => atomicUtils.valueOrDefault(value, '');
export const valueOrFalse = (value) => atomicUtils.valueOrDefault(value, false);
export const valueOrUndefined = (value) => atomicUtils.valueOrDefault(value, undefined);

/**
 * Given a form name, returns whether it is the create item form.
 * @param {string} form - Form name
 * @return {boolean}
 */
export const isFormCreateItem = (form) => form === formNamesItem.CREATE_ITEM;

/**
 * Given a form name, returns whether it is the create partnership form.
 * @param {string} form - Form name
 * @return {boolean}
 */
export const isFormCreatePartnership = (form) => form === formNamesPartnership.CREATE;

/**
 * Given a form name, returns whether it has a nested form to create
 * partnership members.
 * @param {string} form - Form name
 * @return {boolean}
 */
export const isFormWithNestedCreatePartnershipMemberForm = (form) =>
  isFormCreateItem(form) || isFormCreatePartnership(form);

/**
 * Given a single input string, parses it to get a "best guess" about what type of
 * input was entered (is it an email, or a name?). If the input passes email regex
 * test, we'll assume email; otherwise, we'll assume it's a name.
 * @param {string} [input='']
 * @return {{ firstName: StringMaybe, lastName: StringMaybe, email: StringMaybe }}
 */
export const parseFormInputStringForBestGuessContactFields = (input = '') => {
  const parsedFields = {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
  };

  const [firstValue, ...rest] = input.split(' ');

  if (isValidEmail(firstValue)) {
    // if the input passes a regex test for email address, assume
    // an email address was entered
    parsedFields.email = firstValue;
  } else {
    // otherwise, we either have a name, or no input at all;
    // return trimmed strings if present, otherwise undefined
    parsedFields.firstName = valueOrUndefined(firstValue?.trim());
    parsedFields.lastName = valueOrUndefined(rest.join(' ').trim());
  }

  return parsedFields;
};

/**
 * Redux-form configuration function that, given params including the type of validation trigger,
 * returns whether async validation should be performed.
 * @param {Object} params
 * @param {ReduxFormValidationTrigger} params.trigger
 * @param {boolean} params.syncValidationPasses
 * @return {boolean}
 */
export const getFormShouldAsyncValidate = ({ pristine, syncValidationPasses, trigger }) => {
  const isInitialFormOpen = atomicUtils.every([trigger === 'submit', !!pristine]);

  return atomicUtils.every([
    syncValidationPasses,
    atomicUtils.any([trigger === 'blur', trigger === 'change', !!isInitialFormOpen]),
  ]);
};

/**
 * For use when cancelling a form submit programmatically. Appends the isCancelled flag to any data provided.
 * The resulting object can be returned by any onSubmit handler, and redux-form will provide it as the first
 * argument to an onSubmitSuccess or onSubmitFailure callback.
 * @param {Object} [data={}]
 * @returns {Object}
 */
export const getCancelledFormSubmitResult = (data = {}) => ({
  ...data,
  isCancelled: true,
});

/**
 * Generic helper to get use when creating a form to show redux-form where the warnings are.
 * @param {Object} values
 * @returns {Object}
 */
export const getFormWarnings = (values) => values?.meta?.warnings ?? {};

/**
 * Tells redux-form whether or not the form should be in a warning state. Depends on whether or not the email validation
 * resulted in a warning.
 * @param {Object} values
 * @returns {boolean} - true if there is an email warning
 */
export const getInviteTeamMemberFormShouldWarn = ({ values }) =>
  Boolean(_get(values, inviteTeamMemberFormFields.META_WARNINGS_EMAIL_WARNING_TYPE));

/**
 * Determine if we should disable the button based on the form's validity and whether or not we find any values at the
 * fieldPath, which could be the path to warnings or errors.
 * @param {Object} options
 * @param {string} options.fieldPath
 * @param {Object} options.formValues
 * @param {boolean} [options.invalid]
 * @returns {boolean}
 */
export const shouldSubmitButtonBeDisabledDueToField = ({ fieldPath, formValues, invalid }) => {
  if (invalid) {
    return true;
  }

  return !!_get(formValues, fieldPath);
};

/**
 * Function that provides control for sync error valadation in redux-form.
 *
 * This is needed for since redux-form does not validate when a field is dynamically shown.
 * There is an open issue in github. https://github.com/redux-form/redux-form/issues/3276
 * @param {Object} opts
 * @param {Object} opts.fieldValidatorKeys
 * @param {Boolean} opts.initialRender
 * @param {Object} opts.lastFieldValidatorKeys
 * @param {Object} opts.nextProps
 * @param {Object} opts.props
 * @param {Object} opts.structure
 * @param {Object} opts.values
 * @returns {Boolean}
 */
export const shouldRunErrorValidation = ({
  fieldValidatorKeys,
  initialRender,
  lastFieldValidatorKeys,
  nextProps,
  props,
  structure,
  values,
}) => {
  if (initialRender) {
    return true;
  }

  return (
    props.registeredFields !== nextProps.registeredFields ||
    !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys) ||
    !structure.deepEqual(values, nextProps.values)
  );
};

/**
 * Check to see if the initial form values match the current values.
 * @param {Object} initialValues
 * @param {Object|null} initialValues.form
 * @param {Object} formValues
 * @param {Object|null} formValues.form
 * @returns {boolean} true if the current form values have *not* changed from the initial form values.
 */
export const areFormValuesPristine = atomicUtils.isEqual;
