import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const TableCellText = ({ children, className, style }) => (
  <div className={clsx('bold font-color--greyXDark font-size--regular', className)} style={style}>
    {children}
  </div>
);

TableCellText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

TableCellText.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default TableCellText;
