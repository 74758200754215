import { PaymentDeliveryMethodType } from '@routable/shared';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { change, initialize, stopSubmit, untouch } from 'redux-form';

import { FundingErrors } from 'constants/error';
import { createItemFormFields } from 'constants/formFields';
import { ItemApprovalTypes } from 'constants/item';

import { bankRoutingNumberValidator } from 'helpers/fieldValidation';
import {
  getValidFundingAccountsWithSourceAllowedByPaymentDeliveryOption,
  isFundingAccountInCollection,
} from 'helpers/funding';
import { isItemKindPayable, isItemKindReceivable } from 'helpers/items';
import {
  getFallbackDeliveryOptionForPaymentMethod,
  isPaymentDeliveryMethodAny,
  isPaymentDeliveryMethodInternational,
  isPaymentDeliveryOptionAvailable,
  isPaymentMethodAvailableACH,
  isPaymentMethodAvailableCheck,
  isPaymentMethodAvailableInternational,
} from 'helpers/paymentMethods';
import {
  checkRoutingNumber,
  clearBankNameFromRoutingNumber,
  getBankInstitutionNameFromRouting,
  updateBankNameFromRoutingNumber,
} from 'helpers/routingNumbers';
import { firstValue, hasLength, isArray } from 'helpers/utility';

import { getAcceptedPaymentMethodsByCountryCode } from 'modules/createPartnerCompany/helpers/paymentMethods';

import { partnerPaymentOptionsSelector, selectedInvoicesCountryCodeSelector } from 'queries/createItemFormSelectors';
import { applicableBillingDataByCodeSelector } from 'queries/fundingCompoundSelectors';

import {
  currentCompanyPayablePaymentDeliveryMethodsSelector,
  currentCompanySettingsIsInternationalPaymentsEnabledSelector,
} from 'selectors/currentCompanySelectors';
import { fundingAccountsByIdSelector, fundingSourcesByIdSelector } from 'selectors/fundingSelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Returns the default international delivery option
 * @param {Item} item
 * @returns {null|*}
 */
const getDefaultInternationalDeliveryOption = (partnerPaymentOptions) => {
  const paymentOption = firstValue(partnerPaymentOptions);
  if (paymentOption && isPaymentDeliveryMethodInternational(paymentOption.method)) {
    return firstValue(paymentOption.options);
  }

  return null;
};

/**
 * Returns the best fit delivery option after the delivery method value
 * has changed. If modifying an existing item, this will return its most
 * recently saved delivery option, if that option is allowed in combination
 * with the current delivery method selection. If said option is not allowed,
 * returns a default for the method (or null if 'any').
 * @param {Object} params
 * @param {BillingCodeDataByCode} params.billingDataByCode
 * @param {StringMaybe} params.defaultPaymentDeliveryOption
 * @param {ItemPaymentDeliveryMethod} params.deliveryMethod
 * @param {ItemKind} params.itemKind
 * @param {PartnerPaymentOption} params.partnerPaymentOptions
 * @return {null|*}
 */
export const getBestFitDeliveryOption = (params) => {
  const { billingDataByCode, defaultPaymentDeliveryOption, deliveryMethod, itemKind, partnerPaymentOptions } = params;

  // if selecting 'any' method, just need to clear out the delivery option
  if (isPaymentDeliveryMethodAny(deliveryMethod)) {
    return null;
  }

  if (isPaymentDeliveryMethodInternational(deliveryMethod) && partnerPaymentOptions) {
    return getDefaultInternationalDeliveryOption(partnerPaymentOptions);
  }

  const isDefaultDeliveryOptionAllowed = isPaymentDeliveryOptionAvailable(
    [deliveryMethod],
    defaultPaymentDeliveryOption,
    billingDataByCode,
    itemKind,
  );

  if (isDefaultDeliveryOptionAllowed) {
    return defaultPaymentDeliveryOption;
  }

  return getFallbackDeliveryOptionForPaymentMethod({
    billingDataByCode,
    itemKind,
    paymentMethod: deliveryMethod,
  });
};

/**
 * Returns the best fit funding account's id after the delivery method and/or
 * delivery option has just changed. If modifying an existing item, this will return
 * its most recently saved funding account id, if that account is allowed in combination
 * with the current delivery method/option. If said account is not allowed,
 * returns the first allowed account found, or `null` if none are allowed.
 * @param {Object} params
 * @param {BillingCodeData} params.billingDataByCode
 * @param {StringMaybe} params.deliveryOption
 * @param {Collection} params.fundingAccountsById
 * @param {Collection} params.fundingSourcesById
 * @param {StringMaybe} params.defaultFundingAccountId
 * @param {ItemKind} params.itemKind
 * @return {StringMaybe}
 */
export const getBestFitFundingAccountId = (params) => {
  const {
    billingDataByCode,
    defaultFundingAccountId,
    deliveryOption,
    fundingAccountsById,
    fundingSourcesById,
    itemKind,
  } = params;

  const allowedFundingAccounts = getValidFundingAccountsWithSourceAllowedByPaymentDeliveryOption(
    deliveryOption,
    billingDataByCode,
    fundingAccountsById,
    fundingSourcesById,
    0, // this is the default
    itemKind,
  );

  const defaultFundingAccountIsAllowed = isFundingAccountInCollection({
    fundingAccountId: defaultFundingAccountId,
    fundingAccounts: allowedFundingAccounts,
  });

  if (defaultFundingAccountIsAllowed) {
    return defaultFundingAccountId;
  }

  if (allowedFundingAccounts.length > 0) {
    return allowedFundingAccounts[0].id;
  }

  // no applicable funding account found for this delivery option
  return null;
};

/**
 * Returns currently selected delivery method and option, and whether
 * these values have just changed.
 * Utility function to keep things easier to read.
 * @param {ReduxFormValues} values
 * @param {ReduxFormValues|undefined} previousValues
 * @param {Object} params
 * @return {Object}
 */
export const getFormPaymentChangeIndicators = (values, previousValues, params) => {
  const { fieldNames } = params;

  const currentFundingAccountId = _get(values, fieldNames.fundingAccountId);
  const currentDeliveryOption = _get(values, fieldNames.paymentDeliveryOption);
  const currentDeliveryMethod = _get(values, fieldNames.paymentDeliveryMethod);
  const currentCountryCodePartner = _get(values, fieldNames.countryCodePartner);
  const currentCurrencyCodeReceiver = _get(values, fieldNames.currencyCodeReceiver);

  const prevDeliveryMethod = _get(previousValues, fieldNames.paymentDeliveryMethod);
  const prevDeliveryOption = _get(previousValues, fieldNames.paymentDeliveryOption);
  const prevCountryCodePartner = _get(previousValues, fieldNames.countryCodePartner);
  const prevCurrencyCodeReceiver = _get(previousValues, fieldNames.currencyCodeReceiver);

  const didDeliveryMethodChange = currentDeliveryMethod && currentDeliveryMethod !== prevDeliveryMethod;
  const didDeliveryOptionChange = currentDeliveryOption && currentDeliveryOption !== prevDeliveryOption;
  const didPartnerChange = currentCountryCodePartner && currentCountryCodePartner !== prevCountryCodePartner;
  const didCurrencyCodeReceiverChange =
    currentCurrencyCodeReceiver && currentCurrencyCodeReceiver !== prevCurrencyCodeReceiver;

  return {
    currentDeliveryMethod,
    currentDeliveryOption,
    currentFundingAccountId,
    didCurrencyCodeReceiverChange,
    didDeliveryMethodChange,
    didDeliveryOptionChange,
    didPartnerChange,
  };
};

/**
 * Returns values that need to be acquired via selectors.
 * Utility function to keep things easier to read.
 * @param {ReduxState} state
 * @return {Object}
 */
export const getFormPaymentChangeSelectorValues = (state) => {
  const billingDataByCode = applicableBillingDataByCodeSelector(state);
  const fundingAccountsById = fundingAccountsByIdSelector(state);
  const fundingSourcesById = fundingSourcesByIdSelector(state);

  return {
    billingDataByCode,
    fundingAccountsById,
    fundingSourcesById,
  };
};

/**
 * Updates the paymentDeliveryOption for receivables so that the createItem form (used in Item Edit flow)
 * pre-fills as expected
 * @param {Function} dispatch
 * @param {Item} item
 * @param {State} state
 * @param {string} itemKind
 * @param {StringMaybe} defaultPaymentDeliveryOption
 * @param {string[]} defaultPaymentMethods
 * @param {ItemPaymentDeliveryMethod} currentDeliveryMethod
 * @param {string} formName
 * @param {object} fieldNames
 */
export const handleItemEditChangesOnMount = ({
  dispatch,
  item,
  state,
  itemKind,
  defaultPaymentDeliveryOption,
  defaultPaymentMethods,
  currentDeliveryMethod,
  formName,
  fieldNames,
}) => {
  // when item edit is firt loaded, we want to ensure paymentDeliveryOption prefills with the value initially selected on the item
  // this should work automatically for payables
  // for receivables, however, we need to set the field so that it can default to ACH/standard

  if (isItemKindReceivable(item)) {
    const { billingDataByCode } = getFormPaymentChangeSelectorValues(state);

    const nextDeliveryOption = getBestFitDeliveryOption({
      billingDataByCode,
      itemKind,
      defaultPaymentDeliveryOption,
      defaultPaymentMethods,
      deliveryMethod: currentDeliveryMethod,
    });

    dispatch(change(formName, fieldNames.paymentDeliveryOption, nextDeliveryOption));
  }
};

/**
 * Determines whether to select a new delivery option and default funding account,
 * based on changes to the item's paymentDeliveryMethod.
 * e.g. If we change to ACH, we should select a default ACH delivery option, and
 * clear the selected funding account.
 * >>> Important: This function is called in 3 flows:
 *  - create item
 *  - item edit
 *  - send/finalize existing item
 * If you change this function, ensure the fields prefill & behave as expected in all flows
 * @param {ReduxFormValues} values
 * @param {ReduxFormValues} previousValues
 * @param {Dispatch} dispatch
 * @param {Object} options
 * @param {Object.<string, string>} options.fieldNames
 * @param {String} options.formName
 * @param {StringMaybe} [options.defaultFundingAccountId]
 * @param {ItemKind} options.itemKind
 * @param {string[]} [options.defaultPaymentMethods=[]]
 * @return {StringMaybe} [options.defaultPaymentDeliveryOption]
 */
export const onFormPaymentDetailsChange = (values, previousValues, dispatch, options) => {
  const {
    defaultFundingAccountId,
    defaultPaymentDeliveryOption,
    defaultPaymentMethods = [],
    fieldNames,
    formName,
    itemKind,
  } = options;

  const state = store.getState();

  const {
    currentDeliveryMethod,
    currentDeliveryOption,
    currentFundingAccountId,
    didCurrencyCodeReceiverChange,
    didDeliveryMethodChange,
    didDeliveryOptionChange,
    didPartnerChange,
  } = getFormPaymentChangeIndicators(values, previousValues, options);
  const { billingDataByCode, fundingAccountsById, fundingSourcesById } = getFormPaymentChangeSelectorValues(state);

  const prevDeliveryMethod = _get(previousValues, fieldNames.paymentDeliveryMethod);
  const prevDeliveryOption = _get(previousValues, fieldNames.paymentDeliveryOption);

  const {
    ui: { isItemEdit },
    item = {},
  } = values;

  if (didPartnerChange) {
    const updateActions = [];
    const partnerPaymentOptions = partnerPaymentOptionsSelector(state);
    const companySettingsPaymentMethods = currentCompanyPayablePaymentDeliveryMethodsSelector(state);
    const partnerPaymentMethodsFilteredByCompanySettings = partnerPaymentOptions?.filter((paymentMethod) =>
      companySettingsPaymentMethods.includes(paymentMethod.method),
    );
    const isInternationalPaymentsEnabled = currentCompanySettingsIsInternationalPaymentsEnabledSelector(state);

    if (isInternationalPaymentsEnabled && hasLength(partnerPaymentOptions) && isItemKindPayable(item)) {
      const paymentMethodOptions = [
        ItemApprovalTypes.ANY,
        ...partnerPaymentMethodsFilteredByCompanySettings.map((paymentMethod) => paymentMethod.method),
      ];
      const paymentDeliveryMethodsAccepted = {
        [PaymentDeliveryMethodType.ACH]: isPaymentMethodAvailableACH(paymentMethodOptions),
        [PaymentDeliveryMethodType.CHECK]: isPaymentMethodAvailableCheck(paymentMethodOptions),
        [PaymentDeliveryMethodType.INTERNATIONAL]: isPaymentMethodAvailableInternational(paymentMethodOptions),
      };
      updateActions.push(change(formName, fieldNames.paymentMethodOptions, paymentMethodOptions));
      if (!isItemEdit) {
        updateActions.push(change(formName, fieldNames.paymentDeliveryMethodsAccepted, paymentDeliveryMethodsAccepted));
      }
      dispatch(updateActions);
    }
  }

  if (isItemEdit && !prevDeliveryMethod && !prevDeliveryOption) {
    // when item edit first loads, prevDeliveryMethod and prevDeliveryOption will be falsy
    handleItemEditChangesOnMount({
      dispatch,
      item,
      state,
      itemKind,
      defaultPaymentDeliveryOption,
      defaultPaymentMethods,
      currentDeliveryMethod,
      formName,
      fieldNames,
    });
  } else if (didDeliveryMethodChange || didDeliveryOptionChange || didCurrencyCodeReceiverChange) {
    /**
     * Please refer to
     *  - usePaymentDetailsEffects for changes related to paymentDeliveryOption
     *  - usePurposeCode hook from CreateItemPurposeCode.hooks for effects related to UI.isPurposeCodeRequired
     */

    const updateActions = [];

    // default this to the most recent form value of paymentDeliveryOption--
    // it's important this is set here, and that we don't use something like
    // `nextDeliveryOption || currentDeliveryOption` later, because if nextDeliveryOption
    // is set to null between now and then, we don't want a default value anymore
    const nextDeliveryOption = currentDeliveryOption;

    if (didDeliveryMethodChange || didCurrencyCodeReceiverChange) {
      const partnerAccount = _get(values, fieldNames.partnerReceivableAccount);
      const isDeliveryMethodAny = isPaymentDeliveryMethodAny(currentDeliveryMethod);

      // if changing the delivery method to 'any', we also need to
      // clear the partner receivable account value by setting it to `null`
      if (partnerAccount && isDeliveryMethodAny) {
        updateActions.push(change(formName, fieldNames.partnerReceivableAccount, null));
      }
    }

    // nextFundingAccountId is either a string, or null to clear it
    const nextFundingAccountId = getBestFitFundingAccountId({
      billingDataByCode,
      defaultFundingAccountId,
      deliveryOption: nextDeliveryOption,
      fundingAccountsById,
      fundingSourcesById,
      itemKind,
    });

    if (nextFundingAccountId !== currentFundingAccountId) {
      updateActions.push(change(formName, fieldNames.fundingAccountId, nextFundingAccountId));
    }

    dispatch(updateActions);
  }
};

/**
 * Handler to update the form per a routing number change (redux-form)
 * @param values
 * @param previousValues
 * @param dispatch
 * @param formName
 * @param paths
 * @return {Promise<void>}
 */
export const onFormRoutingNumberChange = async (values, previousValues, dispatch, formName, paths) => {
  const { bankInstitutionPath, routingNumberPath } = paths;
  const bankInstitutionName = _get(values, bankInstitutionPath);
  const bankRoutingNumber = _get(values, routingNumberPath);
  const previousBankRoutingNumber = _get(previousValues, routingNumberPath);

  if (bankRoutingNumber === previousBankRoutingNumber) {
    // Don't re-fetch if the routing number has not changed
    return;
  }

  // Note: using checkRoutingNumber (even though it's in the validator as well for when FE validation is off
  // Note: the validator returns undefined if valid, hence the NOT is what we want
  const isValid = checkRoutingNumber(bankRoutingNumber) && !bankRoutingNumberValidator(bankRoutingNumber);

  if (!isValid) {
    if (bankInstitutionName) {
      clearBankNameFromRoutingNumber(formName, bankInstitutionPath, dispatch);
    }
    return;
  }

  if (bankInstitutionName) {
    // Ensure this doesn't happen twice
    return;
  }

  const updatedBankInstitutionName = await getBankInstitutionNameFromRouting(bankRoutingNumber);

  if (!updatedBankInstitutionName) {
    const errorObj = {};
    _set(errorObj, routingNumberPath, [FundingErrors.INVALID_ROUTING_NUMBER]);
    dispatch(stopSubmit(formName, errorObj));
    return;
  }

  updateBankNameFromRoutingNumber(updatedBankInstitutionName, formName, bankInstitutionPath, dispatch);
};

/**
 * After a successful submit, we sometimes want to reset the form fields
 * so that none of them are marked as dirty, which can be accomplished
 * by calling initialize on the whole form, but with a specific combo
 * of options, as given below.
 * @param {*} [resetValues]
 * @param {Dispatch} dispatch
 * @param {ComponentProps} props
 * @param {string} props.form
 * @param {Object} [options={}]
 * @param {boolean} [options.keepDirty=false]
 * @param {boolean} [options.keepSubmitSucceeded=true]
 * @param {boolean} [options.keepValues=true]
 */
export const cleanFieldsOnSubmitSuccess = (resetValues, dispatch, props, options = {}) => {
  const { keepDirty = false, keepSubmitSucceeded = true, keepValues = false } = options;

  const scrubFields = initialize(props.form, resetValues, {
    keepDirty,
    keepSubmitSucceeded,
    keepValues,
  });

  dispatch(scrubFields);
};

/**
 * After selecting or deselecting a payable in the invoice list, we need to update
 * the item's accepted payment methods
 * @param {Dispatch} dispatch
 * @param {string} formName
 * @return {void} Dispatches change if form state has changed
 */
export const updateInvoicesAcceptedPaymentMethods = (dispatch, formName) => {
  const state = store.getState();
  const countryCodes = selectedInvoicesCountryCodeSelector(state);
  const companyPaymentMethods = currentCompanyPayablePaymentDeliveryMethodsSelector(state);
  const paymentDeliveryMethodsAccepted = getAcceptedPaymentMethodsByCountryCode(countryCodes, companyPaymentMethods);
  dispatch(
    change(formName, createItemFormFields.ITEM_PAYMENT_DELIVERY_METHODS_ACCEPTED, paymentDeliveryMethodsAccepted),
  );
};

/**
 * Removes form fields values from state given the formName and fieldNames
 * @param {string} formName
 * @param {Array<string>} fieldNames
 * @returns
 */
export const clearFormFields = (formName, fieldNames, resetValue) => {
  if (!isArray(fieldNames)) {
    return;
  }
  const actions = fieldNames.map((fieldName) => change(formName, fieldName, resetValue));

  store.dispatch(actions);
  store.dispatch(untouch(formName, ...fieldNames));
};
