import clsx from 'clsx';
import swal from 'sweetalert';

import { SwalButtonStyles } from 'constants/swal';

const defaultText = 'Are you sure you want to leave this page without saving?';
const defaultTitle = 'Before you continue...';
const defaultButtonsConfig = {
  showCancel: true,
  confirmButtonText: 'Confirm',
};

/**
 * Show a custom alert prompting the user to confirm or cancel
 * @param {String} text - Text for the alert body
 * @param {String} title - Optional custom alert title
 * @param {{ buttonsConfig: any, [key : string]: any}} config - Additional configuration options
 * @returns {Promise<boolean|null>}
 */
export const confirmAlert = (text = defaultText, title = defaultTitle, config = {}) => {
  const { buttonsConfig: givenButtonsConfig, ...swalConfig } = config;
  const buttonsConfig = {
    ...defaultButtonsConfig,
    ...givenButtonsConfig,
  };

  return swal({
    title,
    className: 'swal-confirmation',
    text,
    ...swalConfig,
    buttons: {
      cancel: {
        // Allow cancel text to be changed with cancelButtonText prop
        text: buttonsConfig && buttonsConfig.cancelButtonText ? buttonsConfig.cancelButtonText : 'Cancel',
        value: false,
        visible: true,
        // don't use display:none here or the last remaining button will align-left
        className: clsx(SwalButtonStyles.GHOST_CANCEL, { hidden: !buttonsConfig.showCancel }),
        closeModal: true,
      },
      confirm: {
        text: buttonsConfig.confirmButtonText,
        value: true,
        visible: true,
        className: SwalButtonStyles.PRIMARY,
        closeModal: true,
      },
    },
  });
};

export default confirmAlert;
