import { createSelector } from 'reselect';
import { LINE_ITEMS_BLOCKS, LINE_ITEMS_SECTION_NAME, LINE_ITEMS_SUBSECTIONS, } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';
import { getItemKindFromLocationSelector } from 'selectors/routerSelectors';
import { createItemsPayableTableSchemaSelector, createItemsReceivableTableSchemaSelector, } from 'selectors/tableSelectors';
export const getSchemaSection = (sectionPath, sections) => {
    if (sectionPath.length === 0) {
        return undefined;
    }
    const currentSection = sections?.find(({ id }) => id === sectionPath[0]);
    if (!currentSection) {
        return undefined;
    }
    if (sectionPath.length === 1) {
        return currentSection;
    }
    return getSchemaSection(sectionPath.slice(1), currentSection?.sections);
};
export const lineItemsSectionsSelector = createSelector([createItemsPayableTableSchemaSelector, createItemsReceivableTableSchemaSelector, getItemKindFromLocationSelector], (payablesSchema, receivableSchema, kind) => {
    const schema = kind === 'payable' ? payablesSchema : receivableSchema;
    const lineItemsItemSection = getSchemaSection([LINE_ITEMS_SECTION_NAME, LINE_ITEMS_BLOCKS.ITEM, LINE_ITEMS_SUBSECTIONS.ITEM], schema?.sections);
    const lineItemsAccountSection = getSchemaSection([LINE_ITEMS_SECTION_NAME, LINE_ITEMS_BLOCKS.ACCOUNT, LINE_ITEMS_SUBSECTIONS.ACCOUNT], schema?.sections);
    return { lineItemsItemSection, lineItemsAccountSection };
});
export const footerMemoSectionSelector = createSelector([createItemsPayableTableSchemaSelector, createItemsReceivableTableSchemaSelector, getItemKindFromLocationSelector], (payablesSchema, receivableSchema, kind) => {
    const schema = kind === 'payable' ? payablesSchema : receivableSchema;
    const footerSection = getSchemaSection(['footer', 'footer_memo'], schema?.sections);
    return footerSection;
});
