import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from 'components/checkbox';
import {
  getFlattenedOptions,
  isOptionSelectAll,
  isSelectAllOptionActive,
} from 'components/selectTypes/MultiCheckSelect/helpers';
import { SelectActions } from 'components/selectV2/utils/constants';

import { noDefaultEventWithCallback } from 'helpers/events';

/**
 * Default Option component for the checkbox multi-select.
 * Handles its selection/deselection logic when used with or
 * without a 'select-all' option.
 * @param {Object} props
 * @returns {JSX.Element}
 */
const MultiCheckOption = ({ children, className, data, isSelected, onSelect, onSetValue, options, selectValue }) => {
  const classes = clsx('multi-check-select--option', className, {
    'all-option': isOptionSelectAll(data),
    selected: isSelected,
  });

  const isSelectAllActive = isSelectAllOptionActive(selectValue);

  let handleSetValues;

  const flatOptions = getFlattenedOptions(options);

  if (isSelected || isSelectAllActive) {
    // handling de-selection
    handleSetValues = () => {
      if (isOptionSelectAll(data)) {
        // if select-all is active, and this option is select-all, we're now un-selecting all
        onSetValue([], SelectActions.SET_VALUE);
      } else {
        // here we're un-selecting only this option; filter it out
        const filterOptions = isSelectAllActive ? flatOptions : selectValue;
        const newValue = filterOptions.filter((opt) => !isOptionSelectAll(opt) && opt.value !== data.value);
        onSetValue(newValue, SelectActions.SET_VALUE);
      }
    };
  } else {
    // handling selection
    handleSetValues = () => {
      const optionsIncludeSelectAll = isSelectAllOptionActive(flatOptions); // check all available options

      // the logic for this scenario is slightly counter intuitive, but--
      // if the only values currently selectable are this and one other
      // (i.e. length - 2), the other selectable value *has* to be 'select all',
      // in which case selecting this one also makes all options actively selected
      if (isOptionSelectAll(data) || (optionsIncludeSelectAll && selectValue.length === flatOptions.length - 2)) {
        onSetValue(flatOptions, SelectActions.SET_VALUE);
      } else {
        // this is just a normal selection of this single value
        onSelect();
      }
    };
  }

  // both the containing div and the inner checkbox need this callback
  // (the checkbox already stops the event from bubbling if clicked)
  const handleSelect = (evt) => noDefaultEventWithCallback(evt, handleSetValues);

  return (
    <div className={classes} onClick={handleSelect} role="presentation">
      <div>
        <Checkbox
          content={children}
          formState={{ [data.value]: isSelected || isSelectAllActive }}
          name={data.value}
          onChange={handleSelect}
        />
      </div>
    </div>
  );
};

MultiCheckOption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSetValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectValue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

MultiCheckOption.defaultProps = {
  className: undefined,
};

export default MultiCheckOption;
