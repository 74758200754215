import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import ArrowRenderer from 'components/form/ArrowRenderer';
import { SelectFieldV2 } from 'components/selectV2';

import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import withOptionWrapper from 'hoc/withOptionWrapper';

import { MultiCheckGroupHeading, MultiCheckOption, MultiCheckValue, MultiCheckValueContainer } from './components';

const WrappedOption = withOptionWrapper(MultiCheckOption);

export const MultiCheckSelect = ({ className, components, input, label, ...rest }) => (
  <SelectFieldV2
    {...rest}
    backspaceRemovesValue={false}
    className={clsx('multi-select multi-check-select', className)}
    closeMenuOnSelect={false}
    components={{
      DropdownIndicator: ArrowRenderer,
      GroupHeading: MultiCheckGroupHeading,
      MultiValue: MultiCheckValue,
      Option: WrappedOption,
      ValueContainer: MultiCheckValueContainer,
      ...components,
    }}
    getOptionValue={(opt) => opt.value}
    hideSelectedOptions={false}
    idPrefix={input.name}
    input={input}
    isClearable
    isCreatable={false}
    isMulti
    isSearchable={false}
    label={label}
    parseValue={returnFullOptionObjectAsValueForSelects}
  />
);

MultiCheckSelect.propTypes = {
  className: PropTypes.string,
  components: PropTypes.shape({
    Group: PropTypes.func,
    GroupHeading: PropTypes.func,
    MultiValue: PropTypes.func,
    Option: PropTypes.func,
    ValueContainer: PropTypes.func,
  }),
  defaultValue: PropTypes.shape(),
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
};

MultiCheckSelect.defaultProps = {
  className: undefined,
  components: {},
  defaultValue: undefined,
};

export default MultiCheckSelect;
