import { TruncatableText } from '@routable/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table header cell component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableHeaderCell = ({ children, className, ...rest }) => (
  <div {...rest} className={clsx('table-row--column remove-padding', className)}>
    <div className="column-content">
      <TruncatableText position="top" text={children} />
    </div>
  </div>
);

InvoiceGeneratorTableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableHeaderCell.defaultProps = {
  children: undefined,
  className: 'table-cell--width-8',
};

export default InvoiceGeneratorTableHeaderCell;
