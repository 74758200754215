import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormFieldRenderInput } from 'components/input';
import { requiredValidator } from 'helpers/fieldValidation';
import { useCountryCodesContext } from 'hooks';
import { LockedCountryInputTooltipContent } from './components';
import { createLockedCountryInputFormatter } from './helpers';
import {} from './LockedCountryInput.types';
const LockedCountryInput = ({ label, reason, dataTestId, ...rest }) => {
    const countryCodeMap = useCountryCodesContext();
    const formatter = createLockedCountryInputFormatter(countryCodeMap);
    return (React.createElement(Field, { ...rest, component: ReduxFormFieldRenderInput, dataTestId: dataTestId, format: formatter, inputClassName: "padding-right--m-large", isDisabled: true, isLocked: true, label: label || 'Country', tooltipContent: () => React.createElement(LockedCountryInputTooltipContent, { content: reason }), validate: [requiredValidator] }));
};
export default LockedCountryInput;
