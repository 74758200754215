import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { IconNames } from 'components/icon';
import FlexRow from 'components/layout/FlexRow';
import Text from 'components/text/Text';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { TooltipIcon } from 'components/tooltip/TooltipIcon';

import { colors, typography } from 'constants/styles';

import { anyValues } from 'helpers/utility';

import { useIsTitleOverflowing } from './TitleWithDetails.hook';

const commonTextProps = {
  ...typography.TitleWithSubtitle.textProps,
  color: typography.TextColor.STEEL,
};

/**
 * Helper component to compose a TitleWithSubtitle. This component has two different text props and the option to add
 * a tooltip.
 *
 * @see Storybook > TitleWithSubtitle > TitleWithDetails
 * @param {ComponentProps} props
 * @param {StringMaybe} [props.detail]
 * @param {String} props.main
 * @param {Object} [props.mainProps]
 * @param {ObjectMaybe} [props.tooltipProps]
 * @returns {StatelessComponent}
 */
const TitleWithDetails = ({ dataFullStory, detail, main, mainProps, tooltipProps }) => {
  const ref = useRef();
  const isOverflowing = useIsTitleOverflowing(ref);

  const shouldTruncateMainText = !!main && isOverflowing;

  return (
    <FlexRow className="flex-parent-truncate" ref={ref} stackOnMobile={false}>
      <Text.Semibold
        className={clsx({
          'flex-shrink--zero': !shouldTruncateMainText,
          truncate: shouldTruncateMainText,
          'margin-right--xm': anyValues(tooltipProps),
        })}
        dataFullStory={dataFullStory}
        {...commonTextProps}
        {...mainProps}
      >
        {main}
      </Text.Semibold>

      {detail && !shouldTruncateMainText && (
        <Text.Regular
          {...typography.TitleWithSubtitle.textProps}
          className={clsx('truncate', { 'margin-left--sm-alt': !!main })}
          color={main ? typography.TextColor.STEEL : typography.TextColor.BLUE_DARK}
          dataFullStory={dataFullStory}
        >
          {detail}
        </Text.Regular>
      )}

      {anyValues(tooltipProps) && (
        <TooltipIcon
          icon={IconNames.INFO_SIGN}
          iconColor={colors.colorMainJordan}
          iconProps={typography.TitleWithSubtitle.iconProps}
          {...tooltipProps}
        />
      )}
    </FlexRow>
  );
};

TitleWithDetails.propTypes = {
  dataFullStory: PropTypes.bool,
  detail: PropTypes.node,
  main: PropTypes.node,
  mainProps: PropTypes.shape(),
  tooltipProps: PropTypes.shape(),
};

TitleWithDetails.defaultProps = {
  detail: undefined,
  main: undefined,
  mainProps: undefined,
  tooltipProps: undefined,
};

export default TitleWithDetails;
