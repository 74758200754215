import { apiCall } from '@routable/framework';
import { fundRoutesApiResponseModel } from '../models';
export const fundRoutesService = {
    getFundRoutes: (field, receiverCountry) => apiCall({
        method: 'get',
        url: 'routes/',
        camelCaseResponse: true,
        queryParams: {
            field,
            ...(receiverCountry ? { receiver_country: receiverCountry } : {}),
        },
        responseModel: fundRoutesApiResponseModel,
    }),
};
