/* eslint-disable react/forbid-prop-types */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders text for a table cell.
 * @see {TableCellTextWithSubtext}
 *
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {String} [props.className]
 * @param {Object} [props.style]
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const TableCellText = ({ children, className, dataFullStory, dataTestId, style }) => (
  <div
    className={clsx(
      'text-overflow--ellipsis overflow--hidden align-items--center font-color--darkBlue font-size--xs line-height--15 max-width--full',
      className,
    )}
    data-fullstory={dataFullStory}
    data-testid={dataTestId}
    style={style}
  >
    {children}
  </div>
);

TableCellText.propTypes = {
  dataFullStory: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

TableCellText.defaultProps = {
  dataFullStory: undefined,
  children: undefined,
  className: undefined,
  style: undefined,
};

export default TableCellText;
