import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import SettingsDivider from '../SettingsDivider';

const SettingsPaddedDivider = ({ className }) => <SettingsDivider className={clsx(className)} />;

SettingsPaddedDivider.propTypes = {
  className: PropTypes.string,
};

SettingsPaddedDivider.defaultProps = {
  className: undefined,
};

export default SettingsPaddedDivider;
