import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid';

import { FormFieldErrors } from 'components/error';

import { getFieldErrors } from 'helpers/errors';
import { callOnEnterKeyEvent, createPersistedEventCallback } from 'helpers/events';
import { inputNameOrNameProp } from 'helpers/propTypes';
import { callWithArgsIfIsFn, hasLength } from 'helpers/utility';

import CheckboxTitle from './CheckboxTitle';

import './Checkbox.scss';

/**
 * Component for input of type 'checkbox'.
 * Can show text as well as input errors.
 * @param {ComponentProps} props
 * @return {JSX.Element}
 */
const Checkbox = ({
  className,
  ConditionalTooltip,
  content,
  dataTestId,
  errors,
  formState,
  hasError,
  id: propsId,
  input,
  isChecked,
  isSemiChecked,
  isDisabled,
  isDisabledDueToPermissions,
  isRequired,
  meta,
  name,
  onBlur,
  onChange,
  shouldStopPropagation,
  tooltip,
}) => {
  let checked;
  let fieldErrors;
  let hasErrors;
  let id;
  let inputName;
  let handleOnBlur;
  let handleOnChange;
  const TooltipComponent = ConditionalTooltip ?? React.Fragment;

  if (input) {
    // This is a redux-form input
    checked = input.checked || input.value;
    fieldErrors = (meta.touched || meta.submitFailed) && meta.error;
    hasErrors = !!fieldErrors;
    id = input.name;
    inputName = input.name;
    handleOnBlur = input.onBlur;
    handleOnChange = input.onChange;
  } else {
    // This is not a redux-form input
    checked = isChecked ?? !!formState[name];
    fieldErrors = errors;
    id = propsId;
    inputName = name;
    handleOnBlur = onBlur;
    handleOnChange = onChange;
    // check if hasError bool was passed as true || check if errors was passed in object format || check if errors was passed in as array or string
    hasErrors = hasError || hasLength(getFieldErrors(errors, name)) || hasLength(errors);
  }

  const fieldId = React.useMemo(() => id ?? uuid.v4(), [id]);

  const handleKeyPress = React.useCallback(
    callOnEnterKeyEvent(
      createPersistedEventCallback((event) => {
        callWithArgsIfIsFn(handleOnChange, !event.target.checked, event);
      }),
    ),
    [handleOnChange],
  );

  return (
    <div className={clsx('checkbox--container', className)}>
      <TooltipComponent>
        <input
          checked={checked}
          data-testid={dataTestId}
          disabled={isDisabled || isDisabledDueToPermissions}
          id={fieldId}
          name={inputName}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          // prevents the checkbox from staying in focused state after it was clicked
          // needed for stylings in tables
          // this does not change any behavior / styling on the already existing checkboxes throughout the app
          onClick={(e) => {
            if (shouldStopPropagation) {
              e.stopPropagation();
            }
            e.target.blur();
          }}
          onKeyPress={handleKeyPress}
          required={isRequired}
          type="checkbox"
        />
        <label
          className={clsx('checkbox--label', { 'disable-pointer-events': isDisabled || isDisabledDueToPermissions })}
          htmlFor={fieldId}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            className={clsx('checkbox--indicator', {
              'checkbox--semichecked': isSemiChecked,
              disabled: isDisabled || isDisabledDueToPermissions,
              error: !!hasErrors,
              success: !isDisabled && !isDisabledDueToPermissions,
              'has-content': !!content,
            })}
            onClick={(e) => {
              if (shouldStopPropagation) {
                e.stopPropagation();
                handleOnChange(e);
                e.target.blur();
              }
            }}
          />

          <CheckboxTitle content={content} isChecked={isChecked} isRequired={isRequired} tooltip={tooltip} />
        </label>
      </TooltipComponent>

      <FormFieldErrors errors={fieldErrors} fieldName={inputName} />
    </div>
  );
};

Checkbox.propTypes = {
  ConditionalTooltip: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  formState: PropTypes.shape(),
  hasError: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.shape(),
  isChecked: PropTypes.bool,
  isSemiChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDisabledDueToPermissions: PropTypes.bool,
  isRequired: PropTypes.bool,
  meta: PropTypes.shape(),
  name: inputNameOrNameProp,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  shouldStopPropagation: PropTypes.bool,
  tooltip: PropTypes.node,
};

Checkbox.defaultProps = {
  ConditionalTooltip: undefined,
  className: undefined,
  content: undefined,
  dataTestId: undefined,
  errors: {},
  formState: {},
  hasError: undefined,
  id: undefined,
  input: undefined,
  isChecked: undefined,
  isSemiChecked: undefined,
  isDisabled: undefined,
  isDisabledDueToPermissions: undefined,
  isRequired: undefined,
  meta: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  shouldStopPropagation: undefined,
  tooltip: undefined,
};

export default Checkbox;
