import React from 'react';
import { useSelector } from 'react-redux';
import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { authNamespaceValueSelector } from 'selectors/forms';
const ChangeWorkspace = () => {
    const namespace = useSelector(authNamespaceValueSelector);
    const brandedWorkspaceDisplay = getDomainWithOrWithoutCompanyNamespace(namespace).split('//')[1];
    const topLevelUrl = getDomainWithOrWithoutCompanyNamespace();
    const lastPathSection = window.location.pathname.split('/').pop();
    const changeWorkspaceUrl = `${topLevelUrl}/${lastPathSection}`;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "display--inline-block line-height--l" }, brandedWorkspaceDisplay),
        React.createElement("a", { className: "semibold display--block font-color--blue-bold--important line-height--m font-xs", href: changeWorkspaceUrl }, "Change workspace")));
};
export default ChangeWorkspace;
