import { createSelector } from 'reselect';

import { getQueryParam } from 'helpers/queryParams';

import { itemIdSelector, itemsSelector } from 'selectors/itemsSelectors';
import {
  idParamSelector,
  idQuerySelector,
  itemIdQuerySelector,
  secondLastSegmentInLocationSelector,
} from 'selectors/routerSelectors';

/**
 * Selects item from id query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {Item}
 */
export const itemFromParamSelector = createSelector(
  [itemsSelector, idParamSelector, idQuerySelector],
  (items, itemId, locationSearchId) => {
    // try using match itemId, match sometimes does not show params, even if they exists so try prop location search id.
    // We are using as last resort pulling it directly form the window location search for cases in which the location
    // from props exists and might not work properly as the match.
    const parsedItemId = itemId || locationSearchId || getQueryParam('id');
    return items[parsedItemId];
  },
);

/**
 * Selects item from id query param
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/item').Item>}
 */
export const itemFromQueryParamSelector = createSelector(
  [itemsSelector, idQuerySelector],
  (items, itemId) => items[itemId],
);

/**
 * Selects item from id using getQueryParamValueFromUrl
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, import('interfaces/item').Item>}
 */
export const itemFromQueryParamValueFromUrlSelector = createSelector(
  [itemsSelector, itemIdSelector],
  (itemsById, itemId) => itemsById[itemId],
);

/**
 * Selects item from id using getQueryParamValueFromUrl
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, string>}
 */
export const itemPrimaryAttachmentFromParamValueFromUrlSelector = createSelector(
  [itemFromParamSelector],
  (item) => item?.primaryAttachment,
);

/**
 * Selects item by using item_id query selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Item|undefined}
 */
export const itemFromQuerySelector = createSelector(
  [itemsSelector, itemIdQuerySelector],
  (items, itemId) => items[itemId],
);

/**
 * Selects item by item_id from props.location.pathname
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Item}
 */
export const itemFromLocationSelector = createSelector(
  [itemsSelector, secondLastSegmentInLocationSelector],
  (items, itemId) => items[itemId],
);

/**
 * Selects item kind from item selected with item query selector
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {ItemKind}
 */
export const itemKindFromQuerySelector = createSelector([itemFromQuerySelector], (item) => item?.kind);

/**
 * Get the status of the item found in state using the item_id query parameter.
 * @param {ReduxState}
 * @returns {ItemStatuses}
 */
export const itemStatusFromQuerySelector = createSelector([itemFromQuerySelector], (item) => item?.status);

/**
 * Get the currencyCodeReceiver of the item found in state using the item_id query parameter.
 * @param {ReduxState}
 * @returns {ItemStatuses}
 */
export const itemCurrencyCodeReceiverFromQuerySelector = createSelector(
  [itemFromQuerySelector],
  (item) => item?.currencyCodeReceiver,
);
