import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Text from 'components/text/Text';

import { TextColor, TextLineHeight } from 'constants/styles/typography';

/**
 * General description text component.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {StatelessComponent}
 */
const Description = ({ children, className, ...rest }) => (
  <Text.Regular {...rest} className={clsx('description display--block', className)}>
    {children}
  </Text.Regular>
);

Description.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Description.defaultProps = {
  color: TextColor.DARK_JORDAN,
  lineHeight: TextLineHeight.PARAGRAPH,
};

export default Description;
