import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { StepperButtons } from './components';

import './ProgressBarStepper.scss';

/**
 * ProgressBarStepper
 * Parses config and defines navigation logic for the stepper
 * @param {ComponentProps} props
 * @param {object} [props.backButton]
 * @param {object[]} props.steps
 * @param {Function} [props.onClickStep]
 * @param {boolean} [props.isVertical]
 * @return {StatelessComponent}
 */
const ProgressBarStepper = ({ backButton, steps, onClickStep, isVertical }) => (
  <div className="stepper--fader--wrapper">
    <div className={clsx('stepper--wrapper', { 'stepper--wrapper__vertical': !!isVertical })}>
      <div className="stepper--side">
        {backButton && (
          <ButtonV2
            className="stepper--button display--inline-flex margin-right--large"
            intent={Intent.GHOST}
            leftIconColor={colors.colorGreyDarkHex}
            leftIconName={IconNames.ARROW_LEFT}
            leftIconSize={sizes.iconSizes.XX_LARGE}
            size={ButtonSize.MEDIUM}
            {...backButton}
          />
        )}
      </div>

      <div className="stepper--main">
        <StepperButtons isVertical={isVertical} onClickStep={onClickStep} steps={steps} />
      </div>

      {/* this empty right-side stepper--side helps keep stepper-main centered */}
      <div className="stepper--side" />
      {/* stepper--fader fades overflowing text into white */}
      {!isVertical && <div className="stepper--fader" />}
    </div>
  </div>
);

ProgressBarStepper.propTypes = {
  backButton: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }),
  isVertical: PropTypes.bool,
  onClickStep: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ProgressBarStepper.defaultProps = {
  backButton: undefined,
  isVertical: undefined,
  onClickStep: undefined,
};

export default ProgressBarStepper;
