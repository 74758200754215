import { commonFormFields } from '@routable/shared';
import React from 'react';
import { InputField } from 'components';
import { UserTypeLabels } from 'constants/user';
import { useBankAccountReceivingCurrency } from 'hooks';
import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';
export const AccountBeneficiaryInfo = ({ availableCurrencies, company, formName, partnership, selectedCurrencyCode, }) => {
    const currencyOptions = useBankAccountReceivingCurrency({
        formName,
        availableCurrencies,
        partnership,
        selectedCurrencyCode,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex gap-5 mt-3" },
            React.createElement("div", { className: "basis-1/2" },
                React.createElement(VendorReceivingCurrency, { fieldLabel: "Account currency", fieldName: commonFormFields.CURRENCY_CODE, isLocked: currencyOptions.isLocked, options: currencyOptions.options, tooltipText: currencyOptions.tooltipText })),
            React.createElement("div", { className: "basis-1/2" },
                React.createElement(InputField, { isDisabled: true, isRequired: false, label: "Business type", value: UserTypeLabels[company.companyType] }))),
        React.createElement(InputField, { className: "mt-5", isDisabled: true, isRequired: false, label: "Legal name", value: company.name || partnership.name })));
};
