import { MembershipInvitesStatuses, MembershipMatchWarning } from 'constants/membership';

import { isOneMembershipDisabled } from 'helpers/memberships';
import { firstKey } from 'helpers/utility';

import { parseFetchedMembershipMatch } from './membershipMatch';

/**
 * Extract the MembershipInvites and Membership from the response.
 * @param {HttpResponse} response
 * @returns {{membershipInvites: MembershipInvite[], memberships: Membership[]}}
 */
export const getMembershipEmailLookupRelationships = (response) => {
  const membershipMatch = parseFetchedMembershipMatch(response);
  const membershipMatchId = firstKey(membershipMatch);
  const { membershipInvites, memberships } = membershipMatch[membershipMatchId];

  return { membershipInvites, memberships };
};

/**
 * Looks to see if the MembershipMatch returned any outstanding invites or memberships which already exist with the
 * email search.
 * @param {HttpResponse} response
 * @returns {boolean} true if there are no warnings
 */
export const membershipEmailLookupHasNoWarnings = (response) => {
  const { membershipInvites, memberships } = getMembershipEmailLookupRelationships(response);

  return membershipInvites?.length === 0 && memberships?.length === 0;
};

/**
 * We know there are warnings, but let's determine which one to show.
 * if we have memberships, and one is disabled, we return inactive.
 * if we have memberships, and none are disabled, we return accepted/verified.
 * if we have membership invites, we return pending.
 * otherwise nada.
 * @param {Membership[]} memberships
 * @param {MembershipInvite[]} membershipInvites
 * @returns {MembershipMatchWarning|undefined}
 */
export const determineMembershipEmailWarningType = ({ memberships, membershipInvites }) => {
  // this email is already associated with an RCTM
  if (memberships?.length) {
    // if the membership is disabled
    if (isOneMembershipDisabled(memberships)) {
      // don't add the membership again, just re-enable the existing membership
      return MembershipMatchWarning.INACTIVE;
    }

    // the membership is already an active RCTM
    return MembershipMatchWarning[MembershipInvitesStatuses.ACCEPTED_AND_VERIFIED];
  }

  // this email has an invite it hasn't accepted yet
  if (membershipInvites?.length) {
    return MembershipMatchWarning[MembershipInvitesStatuses.PENDING];
  }

  return undefined;
};
