import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';

/**
 * Decorate every row for a contact with click handlers to archive, edit, and unarchive the contact.
 * @function
 * @param {Object} props
 * @param {PartnershipMember[]} props.data
 * @param {boolean} props.isPartnerSelfManaged
 * @param {function} props.onArchive
 * @param {function} props.onEdit
 * @param {function} props.onViewDetails
 * @param {function} props.onUnarchive
 * @param {string} props.partnershipId
 * @returns {PartnershipMember[]}
 */
export const addContactActionClickHandlers = ({
  company,
  data,
  isPartnerSelfManaged,
  onArchive,
  onEdit,
  onViewDetails,
  onUnarchive,
  partnershipId,
}) => {
  return data.map((row) => ({
    ...row,
    isArchiveAndUnarchiveDisabled: isCurrentCompanyTypePersonal(company),
    managed: isPartnerSelfManaged,
    onArchive: () => onArchive(row.id),
    onEdit: () => onEdit(row),
    onViewDetails: () => onViewDetails(row),
    onUnarchive: () => onUnarchive(row.id),
    partnershipId,
  }));
};
