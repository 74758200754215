import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Text from 'components/text/Text';

import { TextColor, TextSize } from 'constants/styles/typography';

/// ///////////////////////////////////////////////////////////////
// TODO: Move this component to components/link when DEV-366 is complete.
// It can't live there now because of circular import issues. :-(
// You should also be EXTREMELY weary of adding helpers/*.js to this file
// because of these imports.
/// //////////////////////////////////////////////////////////////

/**
 * A piece of text masquerading as an anchor link but routes to wherever you want it to, probably somewhere internal.
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {StringMaybe} [props.className]
 * @param {function} props.onClick
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const TextLinkNewInternalPage = ({ children, className, onClick, ...rest }) => (
  <Text.Regular
    className={clsx('cursor--pointer text-decoration--hover-underline', className)}
    color={TextColor.BLUE_BOLD}
    onClick={onClick}
    size={TextSize.LEVEL_200}
    {...rest}
  >
    {children}
  </Text.Regular>
);

TextLinkNewInternalPage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

TextLinkNewInternalPage.defaultProps = {
  className: undefined,
};

export default TextLinkNewInternalPage;
