import { PaymentDeliveryMethodType } from '@routable/shared';
import { change } from 'redux-form';

import { formActionsAfterCompanyChange } from 'actions/forms';

import defaultAlertErrors from 'components/error/components/defaultAlertErrors';
import { selectApi, selectParse } from 'components/selectV2';

import { CompanySearchPartnerTypes } from 'constants/company';
import { commonFormFields, createItemFormFields } from 'constants/formFields';
import { PartnershipSearchSourceParamValue } from 'constants/partnership';

import { buildServerErrorAlert } from 'helpers/errors';
import { isFormCreateItem, isFormCreatePartnership } from 'helpers/forms';
import { isItemKindPayable, isItemKindReceivable } from 'helpers/items';
import { isLedgerSettingsContactTypeBoth } from 'helpers/ledger';
import { isPartnershipTypeCustomer, isPartnershipTypeVendor } from 'helpers/partnerships';
import { firstValue } from 'helpers/utility';

import { hasLedgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Gets 'source' param value for PartnershipSearch api based on formName
 * @param {string} formName
 * @param {string} suffix
 * @returns {StringMaybe} sourceValue
 */

export const getPartnershipSearchSourceParamValue = (formName, suffix) => {
  if (PartnershipSearchSourceParamValue[formName] && suffix) {
    return `${PartnershipSearchSourceParamValue[formName]}_${suffix}`;
  }

  return undefined;
};

/**
 * Runs an async search for partnerships and returns the results as select options.
 * @param {StringMaybe} input - User search input
 * @param {string} formName
 * @param {string} suffix
 * @returns {Promise<*[]>}
 */
export const getPartnershipSearchOptions = async (input, formName, suffix) => {
  if (!input) {
    return [];
  }

  const source = getPartnershipSearchSourceParamValue(formName, suffix);
  const response = await selectApi.requestPartnershipsForQuery(input, {
    source,
  });

  if (!response.ok) {
    buildServerErrorAlert(defaultAlertErrors, response);
    return [];
  }

  return selectParse.parseSearchCompaniesResponse(response.originalData);
};

/**
 * Reset the form state, keeping partner.companyType as is. If we have a bill saved, carry it over.
 * @param {Object} options
 * @param {string} options.formName
 * @param {Item} options.item
 * @param {Object} options.partner
 * @param {string} options.partnershipType
 * @param {CompanySearchPartnerTypes} options.partner.companyType
 */
export const handleResetForm = (options) => {
  const {
    formName,
    item,
    partner: { companyType },
    partnershipType,
    ...rest
  } = options;

  const { bills, paymentDeliveryMethod, paymentDeliveryOption } = item;

  const reduxState = store.getState();
  const hasLedgerIntegration = hasLedgerIntegrationSelector(reduxState);
  const resetActions = formActionsAfterCompanyChange({
    formName,
    companyType,
    hasLedgerIntegration,
    partnershipType,
    ...rest,
  });

  if (bills) {
    resetActions.push(change(formName, createItemFormFields.ITEM_BILLS, bills));
  }

  if (isItemKindReceivable(item)) {
    // if this is a receivable item, keep the default values
    // for method and option after selecting company, as these
    // are not currently changeable
    resetActions.push(
      change(formName, createItemFormFields.ITEM_PAYMENT_DELIVERY_METHOD, paymentDeliveryMethod),
      change(formName, createItemFormFields.ITEM_PAYMENT_DELIVERY_OPTION, paymentDeliveryOption),
    );
  }

  store.dispatch(resetActions);
};

/**
 * @param {Object} companyObject
 * @param {Object} options
 * @param {string} options.formName
 * @param {import('interfaces/item').Item} options.item
 */
export const handleNewPartnerCompany = (companyObject, options) => {
  const { formName, item } = options;

  const displayName = companyObject.companyName ?? companyObject.name;

  const actions = [
    // 'name' is the partner's display name
    change(formName, commonFormFields.NAME, displayName),
    // 'partner.name' is the partner's legal name, defaults to display name
    change(formName, commonFormFields.PARTNER_NAME, displayName),
    // set ledger ids, contacts, etc
    change(formName, createItemFormFields.PARTNERSHIP, {
      isVendor: isItemKindPayable(item) || isPartnershipTypeVendor(options.partnershipType),
      isCustomer: isItemKindReceivable(item) || isPartnershipTypeCustomer(options.partnershipType),
      ...companyObject,
    }),
    change(formName, createItemFormFields.UI_SELECTED_COMPANY, {
      type: CompanySearchPartnerTypes.NEW,
      ...companyObject,
    }),
  ];

  const reduxState = store.getState();
  const hasLedgerIntegration = hasLedgerIntegrationSelector(reduxState);
  if (hasLedgerIntegration) {
    // 'partner.ledgerName' is the partner's ledger name, defaults to display name
    actions.push(change(formName, commonFormFields.PARTNER_LEDGER_NAME, displayName));
  }

  if (companyObject.partner?.companyType) {
    actions.push(change(formName, commonFormFields.PARTNER_COMPANY_TYPE, companyObject.partner.companyType));
  }

  // Create item paymentDeliveryMethod
  // we only want to update this value for payables-- receivables, as of now, have one method
  if (isFormCreateItem(formName) && isItemKindPayable(item)) {
    actions.push(change(formName, createItemFormFields.ITEM_PAYMENT_DELIVERY_METHOD, PaymentDeliveryMethodType.ANY));
  }

  // Create partnership paymentDeliveryMethod
  if (isFormCreatePartnership(formName)) {
    actions.push(
      change(
        formName,
        createItemFormFields.FUNDING_PAYMENT_DELIVERY_METHOD,
        isPartnershipTypeVendor(options.partnershipType) ? null : PaymentDeliveryMethodType.ANY,
      ),
    );
  }

  store.dispatch(actions);
};

/**
 * Returns the customerRef or the vendorRef to use when requesting full data.
 * @param {Object} companyValue
 * @param {Object} options
 * @param {LedgerIntegration} options.ledger
 * @param {PartnershipTypes} [options.partnershipType]
 */
export const getVendorOrCustomerRefForFullDataRequest = (companyValue, options) => {
  const { ledger } = options;

  if (isLedgerSettingsContactTypeBoth(ledger.settings) || companyValue.isVendor) {
    // we'll reach this line if EITHER of these things are the case:
    // 1. all ledger companies are both vendors and customers
    // - OR -
    // 2. ledger companies can only be either a vendor or customer, and this company is a vendor
    return companyValue.vendorRef;
  }

  if (companyValue.isCustomer) {
    // we'll reach this line if BOTH of these things are the case:
    // 1. ledger companies can only be EITHER a vendor or customer
    // - AND -
    // 2. this company is a customer
    return companyValue.customerRef;
  }

  // "if this happens, we cry. and ideally throw a SWAL."
  // https://warrenpay.atlassian.net/browse/FRON-2243
  return undefined;
};

/**
 * @param {Object} companyValue
 * @param {Object} options
 * @param {Item} [options.item]
 * @param {LedgerIntegration} options.ledger
 * @param {string} [options.partnershipType]
 */
export const getFullDataForSelectedLedgerCompany = async (companyValue, options) => {
  const { ledger } = options;

  const vendorOrCustomerRef = getVendorOrCustomerRefForFullDataRequest(companyValue, options);

  const fullDataResponse = await selectApi.requestDataWithPartnershipMembers(ledger.id, vendorOrCustomerRef);
  const fullData = firstValue(selectParse.parseSearchCompaniesResponse(fullDataResponse.originalData));

  const { name, partnershipMembers } = fullData;

  // serialize countryPaymentOptions
  if (fullData.countryPaymentOptions) {
    fullData.countryPaymentOptions = fullData.countryPaymentOptions.map((option) => option.countryCode);
  }

  return {
    ...fullData,
    companyName: name,
    contacts: partnershipMembers,
    customerLedgerId: fullData.customerLedgerId || null,
    id: fullData.id || null,
    partnershipMembers: [],
    vendorLedgerId: fullData.vendorLedgerId || null,
  };
};

/**
 * Handle selecting a new company, an existing company or clearing SearchCompanies
 * @param {Object} companyValue - Either an object depicting a partner company or null
 * @param {Object} options - Provided by the component
 * @param {Function} options.handleChangePartnerCompany
 * @param {LedgerIntegration} [options.ledger]
 * @param {string} options.partnershipType
 * @param {Object} callbacks - Provided by the component
 * @param {Function} callbacks.onReset
 * @param {Function} callbacks.onNewPartnerCompany
 */
export const handleSelectCompanyChange = async (companyValue, options, callbacks) => {
  const { handleChangePartnerCompany } = options;

  const { onReset = handleResetForm, onNewPartnerCompany = handleNewPartnerCompany } = callbacks;

  // Remove data when changing companies
  onReset(options);

  if (!companyValue) {
    // TODO: [https://warrenpay.atlassian.net/browse/BUGS-483] Check if this is why backspace/type-over clearing behaves slightly differently
    // This is a clear action -> the data is cleared
    return;
  }

  if (companyValue.id === companyValue.companyName) {
    // This is a new record
    onNewPartnerCompany(companyValue, options);
    return;
  }

  if (companyValue.isLedgerOnly) {
    const fullData = await getFullDataForSelectedLedgerCompany(companyValue, options);
    // This is a Ledger record without a Platform record - treat as new
    onNewPartnerCompany(fullData, options);
    return;
  }

  // This is an existing record on Platform
  handleChangePartnerCompany(companyValue);
};
