import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput from 'react-phone-number-input';

import * as commonProps from 'components/input/commonProps';

import { StaticCountryCode } from 'constants/countries';

const InternationalPhoneInputField = React.forwardRef(
  (
    {
      dataTestId,
      defaultCountry = StaticCountryCode.US,
      input,
      isDisabled,
      showLabel,
      inputClassName,
      onBlur,
      onChange,
      onCountryChange,
      onFocus,
      placeholder,
      value,
    },
    ref,
  ) => (
    <PhoneInput
      {...input}
      addInternationalOption={false}
      className={clsx({ showLabel })}
      countryOptionsOrder={[StaticCountryCode.US, StaticCountryCode.CA, '|']}
      data-testid={dataTestId}
      defaultCountry={defaultCountry}
      disabled={isDisabled}
      numberInputProps={{ className: clsx('intl-phone-input', inputClassName, { showLabel }) }}
      onBlur={onBlur}
      onChange={onChange}
      onCountryChange={onCountryChange}
      onFocus={onFocus}
      placeholder={placeholder}
      ref={ref}
      value={value.number}
    />
  ),
);

InternationalPhoneInputField.propTypes = {
  ...commonProps.propTypes,
  dataTestId: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      country: PropTypes.string,
      number: PropTypes.string,
    }),
  ]),
};

InternationalPhoneInputField.defaultProps = {
  ...commonProps.defaultProps,
  dataTestId: undefined,
  inputClassName: '',
  placeholder: 'Phone number',
  value: '',
};

// setting this keeps our snapshots rendering as 'InternationalPhoneInputField' and not 'ForwardRef'
InternationalPhoneInputField.displayName = 'InternationalPhoneInputField';

export default InternationalPhoneInputField;
