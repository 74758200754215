import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ListItemLabel = ({ children, className, style }) => (
  <div className={clsx('font-color--dark-jordan font-xxs list-item-label semibold', className)} style={style}>
    {children}
  </div>
);

ListItemLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape(),
};

ListItemLabel.defaultProps = {
  className: undefined,
  children: undefined,
  style: {},
};

export default ListItemLabel;
