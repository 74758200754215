import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const EntityWidgetHeaderTitle = ({ dataFullStory, children, className, style }) => (
  <div
    className={clsx('bold entity-widget--header-title font-color--greyXDark margin-bottom--xs', className)}
    data-fullstory={dataFullStory}
    style={style}
  >
    {children}
  </div>
);

EntityWidgetHeaderTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

EntityWidgetHeaderTitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default EntityWidgetHeaderTitle;
