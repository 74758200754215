import {} from '@routable/framework';
import clsx from 'clsx';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { FlexCol, FlexRow } from 'components/layout';
import {} from './MultiLineOptionForSearchCompanies.types';
const MultiLineOptionAddons = styled.div `
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const MultiLineOptionLabel = styled.div `
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const MultiLineOptionName = styled.div `
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const MultiLineOptionLegalName = styled.div `
  color: var(--neutrals-grey-60);
  font-feature-settings: 'calt' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const MultiLineOptionDetails = styled.div `
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
export const SearchCompanyOption = ({ data, className, ...rest }) => {
    const { details, leftAddons, rightAddons } = data;
    const selectProps = rest.selectProps;
    const { dataFullStory } = selectProps;
    const isCreateNewOption = Boolean(data.__isNew__);
    const name = data.name || data.companyName;
    const legalName = data.partner?.name;
    return (React.createElement(components.Option, { ...rest, className: clsx('multi-line-option', className), data: data, "data-fullstory": dataFullStory },
        React.createElement(FlexRow, { className: "align-items--center", stackOnMobile: false },
            React.createElement(MultiLineOptionAddons, { className: "padding-right--m" }, leftAddons),
            React.createElement(FlexCol, { className: "flex--1" },
                isCreateNewOption ? (React.createElement(MultiLineOptionLabel, { className: "search-company-option__label", "data-fullstory": dataFullStory }, rest.label)) : (React.createElement(MultiLineOptionName, { className: "search-company-option__label", "data-fullstory": dataFullStory }, name)),
                legalName && (React.createElement(MultiLineOptionLegalName, { className: "search-company-option__details" }, `Legal name: ${legalName}`)),
                React.createElement(MultiLineOptionDetails, { className: "search-company-option__details" }, details)),
            React.createElement(MultiLineOptionAddons, { className: "padding-left--m" }, rightAddons))));
};
