import React, { useRef } from 'react';
import { RowSelect } from '../rowselects';
import { TableCellWrapper } from '../../table.styled';
import {} from './TableSelectCell.types';
export const TableSelectCell = ({ isDisabled, onChange, value, testId = 'table-cell--select-single', }) => {
    const checkboxRef = useRef(null);
    const handleSelectCellClick = (e) => {
        e.preventDefault();
        if (checkboxRef.current && checkboxRef.current !== e.target) {
            checkboxRef.current.click();
        }
    };
    return (React.createElement(TableCellWrapper, { className: "cursor-pointer", "data-role": "checkbox", role: "checkbox", "data-testid": testId, onClick: handleSelectCellClick },
        React.createElement(RowSelect, { isDisabled: isDisabled, onChange: onChange, ref: checkboxRef, value: value })));
};
