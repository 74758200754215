import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentBlock, ContentBlockBody, ContentBlockClassNames } from 'components/contentBlock';
import { ContentSubtitle, CurrencyAmount, CurrencyAmountClassNames } from 'components/text';

import { CurrencyCodeUsd, CurrencyCodeMapUsd } from 'constants/currency';

import { isNetBalanceNegative } from 'helpers/balance';

import { textHelpers } from 'modules/dashboard/settings/account/balance/helpers';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { BalanceAmountBlockFooter, BalanceAmountLoading, BalanceAmountPendingChip } from './components';

/**
 * Displays current and pending balances, and contains balance
 * transaction action triggers.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const BalanceAmountBlock = ({
  allowDeposit,
  allowWithdraw,
  amount,
  balanceId,
  className,
  currency,
  displayActions,
  displaySettingsLink,
  isDisabled,
  isFetching,
  onDeposit,
  onFixNegative,
  onWithdraw,
  pendingAmount,
}) => {
  const formatOptions = currency || CurrencyCodeMapUsd[CurrencyCodeUsd];
  const hasFooter = displayActions || displaySettingsLink;

  return (
    <ContentBlock
      className={clsx('balance-block', ContentBlockClassNames.INVERTED, className, {
        'balance-block_has-pending': pendingAmount > 0,
        'padding-bottom--m': !hasFooter,
      })}
    >
      <ContentBlockBody colProps={{ isGrid: true }}>
        {!isFetching && (
          <>
            <CurrencyAmount className={CurrencyAmountClassNames.LARGE} formatOptions={formatOptions}>
              {amount}
            </CurrencyAmount>

            <ContentSubtitle className="line-height--standard">
              {textHelpers.getBalanceAvailableText(formatOptions)}
            </ContentSubtitle>

            {!!pendingAmount && <BalanceAmountPendingChip amount={pendingAmount} formatOptions={formatOptions} />}

            {!!balanceId && (
              <ContentSubtitle className="line-height--standard">{`Balance ID: ${balanceId}`}</ContentSubtitle>
            )}
          </>
        )}

        {isFetching && <BalanceAmountLoading />}
      </ContentBlockBody>

      {hasFooter && (
        <BalanceAmountBlockFooter
          allowDeposit={allowDeposit && !isFetching}
          allowWithdraw={allowWithdraw && !isFetching}
          displayActions={displayActions}
          displaySettingsLink={displaySettingsLink}
          isDisabled={isDisabled}
          isNegative={isNetBalanceNegative(amount, pendingAmount)}
          onDeposit={onDeposit}
          onFixNegative={onFixNegative}
          onWithdraw={onWithdraw}
        />
      )}
    </ContentBlock>
  );
};

BalanceAmountBlock.propTypes = {
  allowDeposit: PropTypes.bool,
  allowWithdraw: PropTypes.bool,
  amount: PropTypes.number,
  balanceId: PropTypes.string,
  className: PropTypes.string,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  displayActions: PropTypes.bool,
  displaySettingsLink: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  onDeposit: PropTypes.func,
  onFixNegative: PropTypes.func,
  onWithdraw: PropTypes.func,
  pendingAmount: PropTypes.number,
};

BalanceAmountBlock.defaultProps = {
  allowDeposit: true,
  allowWithdraw: true,
  amount: 0,
  balanceId: undefined,
  className: undefined,
  displayActions: true,
  displaySettingsLink: undefined,
  isDisabled: undefined,
  onDeposit: undefined,
  onFixNegative: undefined,
  onWithdraw: undefined,
  pendingAmount: 0,
};

export default BalanceAmountBlock;
