import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol, FlexRow, Icon, IconNames } from 'components';
import { iconTooltipPropTypes } from 'components/commonProps';

import { SegmentSize } from 'constants/segment';
import { colors, sizes } from 'constants/styles';

import { oneOfValuesFromObject } from 'helpers/propTypes';

import { SegmentButtons, SegmentFooter, SegmentTitleWithSubtitle, StatusLabel } from './components';

import './Segment.scss';

/**
 * Card-like component that is primarily used in the settings pages.
 * @param {ComponentProps} props
 * @param {Object[]} [props.buttons]
 * @param {*} [props.children]
 * @param {*} [props.titleAddon]
 * @param {boolean} [props.hasError]
 * @param {boolean} [props.hasFooter]
 * @param {*} [props.headerChildren]
 * @param {boolean} [props.hideBorder]
 * @param {Object} [props.iconTooltipProps={}]
 * @param {Boolean} [props.isCollapsible]
 * @param {string} [props.size]
 * @param {string} [props.subtitle]
 * @param {string|Node} [props.title]
 * @returns {StatelessComponent}
 */
const Segment = ({
  buttons,
  children,
  className,
  titleAddon,
  footerCheckBoxText,
  footerCheckBoxTooltip,
  fullBleedBody,
  hasError,
  hasFooter,
  headerChildren,
  hideHeader,
  hideBorder,
  iconTooltipProps,
  intent,
  isCollapsible,
  isSegmentOpen,
  onFooterCheckBoxChange,
  onFooterCheckBoxIsChecked,
  size,
  segmentBodyClass,
  statusLabel,
  statusText,
  subtitle,
  title,
}) => {
  const [isOpen, setIsOpen] = React.useState(isSegmentOpen);

  const handleHeaderClick = () => {
    if (isCollapsible) {
      setIsOpen(!isOpen);
    }
  };

  const isBodyVisible = (isCollapsible && isOpen) || !isCollapsible;

  return (
    <FlexCol className={clsx('segment', className, size, { 'segment--error': hasError })}>
      {!hideHeader && (
        <FlexRow
          className={clsx('segment--header', {
            'hide-border': !!children && hideBorder,
            'has-children': isBodyVisible && !!children,
          })}
        >
          <FlexRow
            className={clsx('justify-content--space-between', { 'cursor--pointer': isCollapsible })}
            onClick={handleHeaderClick}
            stackOnMobile={false}
          >
            <SegmentTitleWithSubtitle
              iconTooltipProps={iconTooltipProps}
              intent={intent}
              statusText={statusText}
              subtitle={subtitle}
              title={title}
            />

            <SegmentButtons buttons={buttons} size={size} />

            {statusLabel && <StatusLabel intent={intent} statusLabel={statusLabel} />}

            {titleAddon}

            {isCollapsible && (
              <Icon
                className={clsx('chevron-icon', { 'is-open': isOpen })}
                color={colors.colorGreyDarkHex}
                icon={IconNames.CHEVRON_RIGHT}
                size={sizes.iconSizes.LARGE}
              />
            )}
          </FlexRow>

          {headerChildren && <FlexRow>{headerChildren}</FlexRow>}
        </FlexRow>
      )}

      {isBodyVisible && children && (
        <div
          className={clsx('segment--body', segmentBodyClass, {
            'segment--body--no-padding': fullBleedBody,
            'margin-top--m-large': fullBleedBody,
            'has-footer': hasFooter,
          })}
        >
          {children}
        </div>
      )}

      {footerCheckBoxText && (
        <SegmentFooter
          footerCheckBoxText={footerCheckBoxText}
          footerCheckBoxTooltip={footerCheckBoxTooltip}
          hasError={hasError}
          isChecked={onFooterCheckBoxIsChecked}
          onChange={onFooterCheckBoxChange}
        />
      )}
    </FlexCol>
  );
};

Segment.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  className: PropTypes.string,
  titleAddon: PropTypes.node,
  footerCheckBoxText: PropTypes.string,
  footerCheckBoxTooltip: PropTypes.string,
  fullBleedBody: PropTypes.bool,
  hasError: PropTypes.bool,
  hasFooter: PropTypes.bool,
  hideBorder: PropTypes.bool,
  hideHeader: PropTypes.bool,
  iconTooltipProps: iconTooltipPropTypes,
  intent: PropTypes.string,
  isCollapsible: PropTypes.bool,
  onFooterCheckBoxChange: PropTypes.func,
  onFooterCheckBoxIsChecked: PropTypes.bool,
  segmentBodyClass: PropTypes.string,
  size: oneOfValuesFromObject(SegmentSize),
  statusLabel: PropTypes.string,
  statusText: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Segment.defaultProps = {
  buttons: undefined,
  children: undefined,
  className: undefined,
  footerCheckBoxText: undefined,
  footerCheckBoxTooltip: undefined,
  fullBleedBody: undefined,
  hasError: undefined,
  hasFooter: undefined,
  hideBorder: undefined,
  hideHeader: undefined,
  iconTooltipProps: {},
  intent: undefined,
  isCollapsible: undefined,
  onFooterCheckBoxChange: undefined,
  onFooterCheckBoxIsChecked: undefined,
  segmentBodyClass: undefined,
  size: SegmentSize.LARGE,
  statusLabel: undefined,
  statusText: undefined,
  subtitle: undefined,
  title: undefined,
};

export default Segment;
