import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ModalBody = ({ children, hasInlineFooter }) => (
  <div className={clsx('modal-body', { 'has-footer': !!hasInlineFooter })}>{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  hasInlineFooter: PropTypes.bool,
};

ModalBody.defaultProps = {
  hasInlineFooter: undefined,
};

export default ModalBody;
