import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import DropdownMenu from 'react-dd-menu';
import { ButtonV2, IconNames } from 'components';
import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { TooltipPlacement } from 'constants/tooltip';
import { noDefaultEventWithCallback } from 'helpers/events';
import { useControlledOrUncontrolled } from 'hooks';
import RestrictedPermissionTooltip from 'modules/auth/RestrictedPermissionTooltip';
import { DropdownMenuOption } from './components';
import './DropdownMenuButton.scss';
const DropdownMenuButton = ({ align, children, containerClassName, isOpen, menuAlign, onClose, OptionComponent, options, textAlign, upwards, ...rest }) => {
    const [isMenuOpen, setIsMenuOpen] = useControlledOrUncontrolled({
        defaultValue: false,
        value: isOpen,
    });
    const onOpenMenu = (event) => noDefaultEventWithCallback(event, () => setIsMenuOpen(true));
    const onCloseMenu = onClose || (() => setIsMenuOpen(false));
    return (React.createElement(DropdownMenu, { align: align, className: clsx('dropdown-menu-btn', containerClassName), close: onCloseMenu, closeOnInsideClick: false, isOpen: isMenuOpen, menuAlign: menuAlign, textAlign: textAlign, toggle: React.createElement(ButtonV2, { onClick: onOpenMenu, rightIconClassName: "margin-left--xm", rightIconName: IconNames.CHEVRON_DOWN, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.SMALL, ...rest }, children), upwards: upwards }, options.map(({ onCloseMenu: optionOnCloseMenu, ...option }, idx) => {
        if (option?.isDisabledDueToPermissions) {
            return (React.createElement(RestrictedPermissionTooltip, { customization: { placement: TooltipPlacement.LEFT }, key: `option-${idx}` },
                React.createElement(OptionComponent, { isDisabled: option.isDisabledDueToPermissions, key: option.title, onCloseMenu: optionOnCloseMenu || onCloseMenu, ...option })));
        }
        if (option.isDisabled && option.tooltipTitle) {
            return (React.createElement(Tooltip, { "data-testid": `${option.title}-tooltip`, key: `tooltip-${idx}`, position: "left", tooltip: option.tooltipTitle, variant: "light" },
                React.createElement(OptionComponent, { key: option.title, onCloseMenu: optionOnCloseMenu || onCloseMenu, ...option })));
        }
        return React.createElement(OptionComponent, { key: option.title, onCloseMenu: optionOnCloseMenu || onCloseMenu, ...option });
    })));
};
DropdownMenuButton.defaultProps = {
    align: 'left',
    containerClassName: undefined,
    isOpen: undefined,
    menuAlign: 'left',
    onClose: undefined,
    OptionComponent: DropdownMenuOption,
    options: [],
    textAlign: 'left',
    upwards: undefined,
};
export default DropdownMenuButton;
