import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';

import { AccountStatus, AccountStatusText, AccountTabsStatus } from 'constants/accounts';
import { CurrencyCodeUsd } from 'constants/currency';
import {
  FundingAccountFieldAcronyms,
  FundingAccountUsableStates,
  FundingRequirementStatusTypes,
  FundingSourceProviderClasses,
  FundingSourceProviderStatuses,
  FundingSourceProviderSubClasses,
} from 'constants/funding';

import { parseFundingAccounts } from 'data/parse';

import { PaymentSources } from 'enums/billing';
import { FundingAccountProcessingChannels } from 'enums/funding';

import { getBillingDataForPaymentDeliveryOption } from 'helpers/billing';
import { capitalize } from 'helpers/stringHelpers';
import {
  isBankAccountViewTypePartnerAch,
  isBankAccountViewTypePartnerAchAny,
  isBankAccountViewTypePartnerAddressAny,
  isBankAccountViewTypePartnerAny,
  isBankAccountViewTypeThreadPartnerAch,
} from 'helpers/ui';
import { doesMemberInclude, hasZeroLength, isEqual, isString } from 'helpers/utility';

const { deliveryOptionFundingAccountsAccumulator } = parseFundingAccounts.transform;

// *************************************
// Funding Account
// *************************************
export const getFundingAccountMask = (accountNumber) => `***${accountNumber.substr(accountNumber.length - 4)}`;
export const getFundingAccountMaskOrNotProvidedByLedger = (accountNumber, ledger) => {
  if (!accountNumber) {
    return `not provided by ${ledger.name}`;
  }

  return getFundingAccountMask(accountNumber);
};
export const hasFundingAccountLedgerRef = (fundingAccount) => !!fundingAccount.ledgerRef;
export const hasFundingAccountLedgerRefPayable = (fundingAccount) => !!fundingAccount.ledgerRefPayable;
export const hasFundingAccountLedgerRefReceivable = (fundingAccount) => !!fundingAccount.ledgerRefReceivable;
export const isFundingAccountDeleted = (fundingAccount) => !!fundingAccount.isDeleted;
export const isFundingAccountDisabled = (fundingAccount) => !!fundingAccount.isDisabled;
export const isFundingAccountExternal = (fundingAccount) => !!fundingAccount?.isExternal;
export const isFundingAccountLedgerOnly = (fundingAccount) => !!fundingAccount.isLedgerOnly;
export const isFundingAccountMatched = (fundingAccount) => !!fundingAccount.matched;
export const isFundingAccountNumberMasked = (accountNumber) => !!accountNumber.startsWith('***');
export const isFundingAccountUsable = (fundingAccount) => fundingAccount.usable === FundingAccountUsableStates.YES;
export const isFundingAccountValid = (fundingAccount) => !!fundingAccount?.isValid;

/**
 * Given a fundingAccount, checks if it is eligible for RTP
 * @param {FundingAccount} fundingAccount
 * @returns {Boolean}
 */
export const isFundingAccountEligibleForRTP = (fundingAccount) =>
  _includes(fundingAccount?.processingChannels, FundingAccountProcessingChannels.RTP);

/**
 * Given a fundingAccount, checks if the currencyCode is USD
 * @param {FundingAccount} fundingAccount
 * @returns {Boolean}
 */
export const isFundingAccountUSD = (fundingAccount) => isEqual(fundingAccount?.currencyCode, CurrencyCodeUsd);

/**
 * Method to check whether if the funding account is not usable because it's not matched to a ledger clearing account
 * @param {object} fundingAccount
 * @return {boolean}
 */
export const isFundingAccountNotUsableNoClearingMatch = (fundingAccount) =>
  !fundingAccount.balance && fundingAccount.usable === FundingAccountUsableStates.NO_CLEARING_MATCH;

/**
 * Method to check whether if the funding account is not usable because it's not matched to a ledger bank account
 * @param {object} fundingAccount
 * @return {boolean}
 */
export const isFundingAccountNotUsableNoLedgerMatch = (fundingAccount) =>
  !fundingAccount.balance && fundingAccount.usable === FundingAccountUsableStates.NO_LEDGER_MATCH;

/**
 * Method to check whether the funding account is not usable because
 * it's not matched to either one of a ledger account or clearing account.
 * @param {object} fundingAccount
 * @return {boolean}
 */
export const isFundingAccountNotUsableNoMatchAny = (fundingAccount) =>
  isFundingAccountNotUsableNoLedgerMatch(fundingAccount) || isFundingAccountNotUsableNoClearingMatch(fundingAccount);

/**
 * Method to get the tab status for a funding account
 * @param fundingAccount
 * @param isPartnerView
 * @param partnershipFundingAccount
 * @return {*}
 */
export const getFundingAccountTabStatus = (fundingAccount, isPartnerView = false, partnershipFundingAccount) => {
  const isDeleted = isFundingAccountDeleted(fundingAccount);
  const isDisabled = isFundingAccountDisabled(fundingAccount);
  const isMyDisabledAccount = !isPartnerView && isDisabled;
  const isPartnerWithNoPartnershipFundingAccount = isPartnerView && !partnershipFundingAccount;

  if (isPartnerWithNoPartnershipFundingAccount) {
    return null;
  }

  if (isDeleted) {
    return AccountTabsStatus.DELETED;
  }

  if (isMyDisabledAccount) {
    return AccountTabsStatus.DISABLED;
  }

  return AccountStatus.ACTIVE;
};

export const getFundingAccountDisplayStatus = (fundingAccount, options = {}) => {
  const { partnershipFundingAccount, viewType } = options;

  const isDeleted = isFundingAccountDeleted(fundingAccount);
  const isDisabled = isFundingAccountDisabled(fundingAccount);
  const isValid = isFundingAccountValid(fundingAccount);

  if (isBankAccountViewTypePartnerAny(viewType) && !partnershipFundingAccount) {
    return AccountStatus.INACTIVE_PARTNER;
  }

  if (isBankAccountViewTypePartnerAch(viewType) || isBankAccountViewTypeThreadPartnerAch(viewType)) {
    return AccountStatus.ACTIVE_PARTNER;
  }

  if (isBankAccountViewTypePartnerAddressAny(viewType)) {
    return AccountStatus.ACTIVE_PARTNER_ADDRESS;
  }

  if (isDeleted) {
    return AccountStatus.DELETED;
  }

  if (isDisabled) {
    return AccountStatus.DISABLED;
  }

  if (!isValid) {
    return AccountStatus.PENDING;
  }

  return AccountStatus.ACTIVE;
};

export const getBasePartnerFundingAccountDisplayStatusText = (viewType) => {
  if (isBankAccountViewTypePartnerAchAny(viewType)) {
    return AccountStatusText[AccountStatus.ACTIVE_PARTNER];
  }

  return AccountStatusText[AccountStatus.ACTIVE_PARTNER_ADDRESS];
};

export const getFundingAccountDisplayStatusText = (fundingAccount, options = {}) => {
  const { partnership = {}, partnershipFundingAccount, viewType } = options;

  const isDeleted = isFundingAccountDeleted(fundingAccount);
  const isDisabled = isFundingAccountDisabled(fundingAccount);
  const isValid = isFundingAccountValid(fundingAccount);

  if (isDeleted) {
    return AccountStatusText[AccountStatus.DELETED];
  }

  if (isDisabled) {
    return AccountStatusText[AccountStatus.DISABLED];
  }

  if (isBankAccountViewTypePartnerAny(viewType)) {
    if (!partnershipFundingAccount) {
      return AccountStatusText[AccountStatus.INACTIVE_PARTNER];
    }

    const { name } = partnership;
    const { isCreatedByPartner } = partnershipFundingAccount;

    const baseText = getBasePartnerFundingAccountDisplayStatusText(viewType);

    if (!isCreatedByPartner) {
      return `${baseText} by your team`;
    }

    if (name) {
      return `${baseText} by ${name}`;
    }

    return baseText;
  }

  if (!isValid) {
    return AccountStatusText[AccountStatus.PENDING];
  }

  return AccountStatusText[AccountStatus.ACTIVE];
};

/**
 * Method to get a sort order for funding account statuses.
 * Used to determine which group (active, disabled, deleted) to show first on the Bank Connections page.
 * Order: Active, Disabled, Deleted
 * @param fundingAccount
 * @returns {number}
 */
export const getFundingAccountWithDisplayStatusAndSort = (fundingAccount) => {
  const augmentedFundingAccount = { ...fundingAccount };

  if (isFundingAccountDisabled(fundingAccount)) {
    augmentedFundingAccount.displayStatus = AccountStatus.DISABLED;
    augmentedFundingAccount.sortOrder = 1;
  } else if (isFundingAccountDeleted(fundingAccount)) {
    augmentedFundingAccount.displayStatus = AccountStatus.DELETED;
    augmentedFundingAccount.sortOrder = 2;
  } else {
    augmentedFundingAccount.displayStatus = AccountStatus.ACTIVE;
    augmentedFundingAccount.sortOrder = 0;
  }

  return augmentedFundingAccount;
};

/**
 * Method to make it easy to test the selector logic for getting funding accounts by funding source provider sub class
 * @param {FundingAccount[]} fundingAccounts
 * @param {FundingSource[]} fundingSources
 * @param {string} providerSubClass
 */
export const getFundingAccountsByProviderSubClass = (fundingAccounts, fundingSources, providerSubClass) =>
  fundingAccounts.reduce((arr, account) => {
    const filteredFundingSources = fundingSources.filter(
      (fundingSource) =>
        _includes(account.fundingSources, fundingSource.id) &&
        isEqual(fundingSource.providerSubClass, providerSubClass),
    );

    if (hasZeroLength(filteredFundingSources)) {
      return arr;
    }

    return [...arr, account];
  }, []);

// *************************************
// Funding Source
// *************************************
export const isFundingSourceInvalid = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.INVALID;
export const isFundingSourceInvalidMicroDepositsFailed = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.INVALID_MICRO_DEPOSITS_FAILED;
export const isFundingSourceInvalidMicroDepositsFailedVerification = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.INVALID_MICRO_DEPOSITS_FAILED_VERIFICATION;
export const isFundingSourceInvalidMicroDepositsPending = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.INVALID_MICRO_DEPOSITS_PENDING;
export const isFundingSourceInvalidMicroDepositsProcessed = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.INVALID_MICRO_DEPOSITS_PROCESSED;
export const isFundingSourceValid = (fundingSource) =>
  fundingSource.providerStatus === FundingSourceProviderStatuses.VALID;
export const isFundingSourceProviderClassAddress = (fundingSource) =>
  fundingSource.providerClass === FundingSourceProviderClasses.ADDRESS;
export const isFundingSourceProviderClassBank = (fundingSource) =>
  fundingSource.providerClass === FundingSourceProviderClasses.BANK;

export const isFundingSourceProviderSubClassCheck = (fundingSource) =>
  fundingSource.providerSubClass === FundingSourceProviderSubClasses.CHECK;
export const isFundingSourceProviderSubClassBalance = (fundingSource) =>
  fundingSource.providerSubClass === FundingSourceProviderSubClasses.BALANCE;

/**
 * Predicate function that checks if fundingSource is in micro-deposit state or invalid
 * @param {FundingSource} fundingSource
 * @returns {boolean}
 */
export const isFundingSourceInvalidOrMicroDepositState = (fundingSource) =>
  isFundingSourceInvalidMicroDepositsProcessed(fundingSource) ||
  isFundingSourceInvalidMicroDepositsPending(fundingSource) ||
  isFundingSourceInvalid(fundingSource);

/**
 * Helper method to determine which FundingSource sub classes are allowed
 * @param {BillingCodeData} billingCodeData
 * @return {Array}
 */
export const getValidFundingSourceSubClasses = ({ billingCodeData }) => {
  // When balance is required only balance can be used
  if (billingCodeData.requireBalance) {
    return [FundingSourceProviderSubClasses.BALANCE];
  }

  // If balance is allowed, return both ACH and balance
  if (billingCodeData.allowBalance) {
    // Note: this is not really a realistic case as we don't have a payment method that allows balance but doesn't
    // require it, but this logic was present previously so I kept it in case we'll want to have this moving forward.
    return [FundingSourceProviderSubClasses.ACH, FundingSourceProviderSubClasses.BALANCE];
  }

  // Otherwise, only ACH is allowed
  return [FundingSourceProviderSubClasses.ACH];
};

// *************************************
// Balance
// *************************************

export const doesFundingAccountHaveBalance = (fundingAccount) => !!fundingAccount?.balance;

/**
 * Returns string 'bank' or 'balance' depending on whether the account is balance or bank
 * @param {Object} fundingAccount
 * @return {PaymentSources}
 */
export const getPaymentSourceFromFundingAccount = (fundingAccount) =>
  doesFundingAccountHaveBalance(fundingAccount) ? PaymentSources.BALANCE : PaymentSources.BANK;

// *************************************
// Funding Customer
// *************************************
export const isFundingRequirementStatusTypeKycNeeded = (status) =>
  status === FundingRequirementStatusTypes.KYC_NEEDED || status === FundingRequirementStatusTypes.KYC_BENEFIT;

// catch for old plaid records for which we don't return a routing #
export const isRoutingNumberMissing = (routingNumber) => !routingNumber || routingNumber === '000000000';

export const getFundingAccountForFundingBalanceInfo = (balanceInfoId, fundingAccounts) =>
  _find(fundingAccounts, (account) => account.balance === balanceInfoId);

export const getFundingInfoBankAccountForFundingAccountId = (
  fundingAccountId,
  fundingAccounts = {},
  fundingInfoBankAccounts = {},
) => {
  const fundingAccount = fundingAccounts[fundingAccountId];

  if (!fundingAccount) {
    return undefined;
  }

  return fundingInfoBankAccounts[fundingAccount.bank];
};

export const getFundingInfoAddressForFundingAccountId = (
  fundingAccountId,
  fundingAccounts = {},
  fundingInfoAddresses = {},
) => {
  const fundingAccount = fundingAccounts[fundingAccountId];

  if (!fundingAccount) {
    return undefined;
  }

  return fundingInfoAddresses[fundingAccount.address];
};

export const getFundingInfoAddressForPartnershipFundingAccount = (
  partnershipFundingAccount = {},
  fundingAccounts = {},
  fundingInfoAddresses = {},
) =>
  getFundingInfoAddressForFundingAccountId(
    partnershipFundingAccount.fundingAccount,
    fundingAccounts,
    fundingInfoAddresses,
  );

/**
 * Returns only the funding accounts that have a funding source allowed
 * by the given payment delivery option.
 * @param {StringMaybe} paymentDeliveryOption
 * @param {Object} billingDataByCode
 * @param {ByIdCollection|Object[]} fundingAccounts
 * @param {ByIdCollection|Object[]} fundingSources
 * @param {NumberMaybe} balanceAmount
 * @param {ItemKind} kind
 * @return {ByIdCollection}
 */
export const getValidFundingAccountsWithSourceAllowedByPaymentDeliveryOption = (
  paymentDeliveryOption,
  billingDataByCode,
  fundingAccounts,
  fundingSources,
  balanceAmount = 0,
  kind,
) => {
  const billingData = getBillingDataForPaymentDeliveryOption({
    billingDataByCode,
    paymentDeliveryOption,
    kind,
  });

  const validFundingAccounts = _filter(
    fundingAccounts,
    (account) =>
      // allow funding accounts marked 'usable', or those marked as unmatched
      // to either a ledger or clearing account
      isFundingAccountUsable(account) || isFundingAccountNotUsableNoMatchAny(account),
  );

  if (!paymentDeliveryOption || !billingData) {
    return validFundingAccounts.filter((fundingAccount) => !doesFundingAccountHaveBalance(fundingAccount));
  }

  const allowedSubClasses = getValidFundingSourceSubClasses({
    billingCodeData: billingData,
  });

  return _reduce(
    validFundingAccounts,
    (arr, fundingAccount) =>
      deliveryOptionFundingAccountsAccumulator(arr, fundingAccount, {
        allowedSubClasses,
        balanceAmount,
        fundingSources,
        isFundingSourceProviderSubClassBalance,
      }),
    [],
  );
};

/**
 * Returns whether the given funding account, or funding account id,
 * is in the given collection.
 * @param {Object} params
 * @property {ObjectMaybe} params.fundingAccount
 * @property {StringMaybe} params.fundingAccountId
 * @property {ByIdCollection} params.fundingAccounts
 * @return {boolean}
 */
export const isFundingAccountInCollection = (params) => {
  const { fundingAccount = undefined, fundingAccountId = undefined, fundingAccounts = [] } = params;

  let searchId;

  // if fundingAccount object was given
  if (fundingAccount && fundingAccount.id) {
    searchId = fundingAccount.id;
  }

  // if fundingAccountId string was given
  if (fundingAccountId) {
    searchId = fundingAccountId;
  }

  return doesMemberInclude(fundingAccounts, searchId, { searchKey: 'id' });
};

/**
 * Returns funding account status label based on whether the account
 * is active or external.
 * @param {boolean} isActiveOrExternal
 * @return {string}
 */
export const getFundingAccountStatusText = (isActiveOrExternal) =>
  isActiveOrExternal ? 'Active' : 'Pending account verification';

/**
 * Checks if fundingAccount's funding sources are pending micro-deposits
 * @param {FundingAccount} fundingAccount
 * @param {Object.<string, FundingSource[]>} fundingSourcesById
 * @returns {boolean}
 */
export const isFundingAccountPendingMicroDeposits = (fundingAccount, fundingSourcesById) => {
  const fundingSources = fundingAccount.fundingSources.map((fundingSourceId) => fundingSourcesById[fundingSourceId]);

  return fundingSources.some(
    (fundingSource) =>
      isFundingSourceInvalidMicroDepositsPending(fundingSource) ||
      isFundingSourceInvalidMicroDepositsProcessed(fundingSource),
  );
};

/**
 * Returns true if funding account is a bank account
 * @param {FundingAccount} fundingAccount
 * @return {boolean}
 */
export const isFundingAccountBankAccount = (fundingAccount) =>
  Boolean(fundingAccount.bank) || Boolean(fundingAccount.international);

/**
 * Returns true if funding account is Routable balance
 * @param {FundingAccount} fundingAccount
 * @returns {Boolean}
 */
export const isFundingAccountBalance = (fundingAccount) => Boolean(fundingAccount?.balance);

/**
 * Returns funding info address printName if available or printCompany
 * otherwise
 * @param {FundingInfoAddress} fundingInfoAddress
 * @return {string}
 */
export const getFundingInfoAddressDisplayName = ({ printCompany, printName }) => {
  if (printCompany && printName) {
    return `${printCompany} | ${printName}`;
  }

  return printName || printCompany;
};

/**
 * Returns formatted string with city, state, postal code and country of passed
 * funding info address
 * @param {FundingInfoAddress} fundingInfoAddress
 * @return {string}
 */
export const getFormattedDetailsOfFundingInfoAddress = ({ city, state, postalcode, country }) =>
  `${city}, ${state} ${postalcode}, ${country}`;

/**
 * Returns formatted string with address, city, state, postal code and country of passed
 * funding info address
 * @param {FundingInfoAddress} fundingInfoAddress
 * @param {string} fundingInfoAddress.streetAddress
 * @return {string}
 */
export const getFormattedDetailsOfFundingInfoAddressWithAddress = ({ streetAddress, ...fundingInfoAddress }) =>
  `${streetAddress}, ${getFormattedDetailsOfFundingInfoAddress(fundingInfoAddress)}`;

/**
 * Returns filtered list of funding accounts which belong to the passed company id
 * @param {FundingAccount[]} fundingAccounts
 * @param {Company.id} companyId
 * @returns {FundingAccount[]}
 */
export const getFilteredFundingAccountsForCompanyId = (fundingAccounts = [], companyId) =>
  fundingAccounts.filter((fundingAccount) => isEqual(fundingAccount.company, companyId));

/**
 * Returns funding sources that belongs to a given funding account
 * @param {FundingAccount} fundingAccount
 * @param {FundingSource[]} fundingSources
 * @returns {FundingSource[]}
 */
export const getFundingSourcesForFundingAccount = (fundingAccount, fundingSources = []) => {
  if (!fundingAccount) {
    return [];
  }

  return fundingSources.filter((fundingSource) => fundingAccount.fundingSources?.includes(fundingSource.id));
};

/**
 * UPPERCASE banking acronyms from snake_case while only capitalizing the first letter of common words.
 *
 * @example
 * snakeCaseFundingFieldsToExternalUserCase('bic_swift');
 * // 'BIC SWIFT', all acronym, all caps
 *
 * @example
 * snakeCaseFundingFieldsToExternalUserCase('account_number');
 * // 'Account Number', only common words, no acronyms
 *
 * @example
 * snakeCaseFundingFieldsToExternalUserCase('bsb_code');
 * // 'BSB Code', mix of acronym and common word
 *
 * @param {StringMaybe} str
 * @returns {string}
 */
export const snakeCaseFundingFieldsToExternalUserCase = (str) => {
  if (!isString(str)) {
    return '';
  }

  // separate on the underscores
  return (
    str
      .split('_')
      .map((piece) => {
        // this piece is a banking acronym
        if (FundingAccountFieldAcronyms.has(piece.toUpperCase())) {
          // bic => BIC
          return piece.toUpperCase();
        }

        // capitalize the first letter
        // account => Account
        return capitalize(piece);
      })
      // replace underscores with spaces
      .join(' ')
  );
};
