import clsx from 'clsx';
import React from 'react';
import { formatCurrency } from 'helpers/currencyFormatter';
import { useCurrencyContext } from 'hooks';
const CurrencyAmountText = ({ amount, amountClassName, className, currencyCode, parseCurrencyOptions, }) => {
    const currencyCodeMap = useCurrencyContext();
    const formattedAmount = formatCurrency(amount, currencyCode, currencyCodeMap, parseCurrencyOptions);
    return (React.createElement("span", { className: className },
        React.createElement("span", { className: clsx('text-black', amountClassName) }, formattedAmount),
        React.createElement("span", { className: "ml-1 text-grey-60" }, currencyCode?.toUpperCase())));
};
export default CurrencyAmountText;
