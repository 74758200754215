import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/icon';

import { ZIndexLayers } from 'constants/ui';

import { Position } from 'enums/ui';

import { getZIndex } from 'helpers/zIndex';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import Tooltip from '../Tooltip';
import TooltipContent from '../TooltipContent';

/**
 * An icon that triggers a tooltip.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const TooltipIcon = (props) => {
  const {
    content,
    contentMargin,
    icon,
    iconClassName,
    iconColor,
    iconProps,
    title,
    tooltipClassName,
    tooltipPosition,
    tooltipProps,
    zIndex,
  } = props;

  return (
    <Tooltip
      className={tooltipClassName}
      content={content ? <TooltipContent>{content}</TooltipContent> : null}
      contentMargin={contentMargin}
      position={tooltipPosition}
      zIndex={zIndex || getZIndex(ZIndexLayers.MODAL.TOOLTIP)}
      {...tooltipProps}
    >
      <Icon className={iconClassName} color={iconColor} icon={icon} title={title} {...iconProps} />
    </Tooltip>
  );
};

TooltipIcon.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  iconProps: PropTypes.shape({}),
  title: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(Object.values(Position)),
  tooltipProps: PropTypes.shape({}),
  zIndex: PropTypes.number,
};

TooltipIcon.defaultProps = {
  contentMargin: undefined,
  iconClassName: undefined,
  iconColor: undefined,
  iconProps: {},
  title: '',
  tooltipClassName: undefined,
  tooltipPosition: Position.TOP,
  tooltipProps: {},
  zIndex: undefined,
};

export default TooltipIcon;
