import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import DocumentContentRight from 'components/document/components/DocumentContentRight';
import FormFieldErrors from 'components/error/components/FormFieldErrors';
import { LinkNewPage } from 'components/link';

import { field } from 'constants/styles/formStyles';

import { getObjDate } from 'helpers/date';

const Document = (props) => {
  const { className, clearName, documentCreatedDate, documentName, documentURL, errors, isClearable } = props;

  return (
    <div className={className}>
      <div
        className={clsx('form-style form-control document-form remove-margin-bottom', field.xl.full, {
          error: errors.length > 0,
        })}
      >
        <div className="document-form--left">
          <span className="icon-ic-image icon-left" />
          <LinkNewPage className={clsx('dark', { 'disable-pointer-events': !documentURL })} href={documentURL}>
            <span className="truncate" data-fullstory>
              {documentName}
            </span>
          </LinkNewPage>
        </div>

        {
          /* ---- IF a document DATE EXISTS, and it's not clearable ---- */
          !isClearable && documentCreatedDate && (
            <div className="document-form--date">
              <span className="like-p dark-grey">{getObjDate(props, 'documentCreatedDate', 'll')}</span>
            </div>
          )
        }

        <div className="document-form--right">
          <DocumentContentRight {...props} />
        </div>
      </div>

      <FormFieldErrors errors={errors} fieldName={clearName} />
    </div>
  );
};

Document.propTypes = {
  className: PropTypes.string,
  clearName: PropTypes.string,
  documentCreatedDate: PropTypes.string,
  documentName: PropTypes.string,
  documentStatus: PropTypes.string,
  documentURL: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  hasStatusTag: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDownloadable: PropTypes.bool,
};

Document.defaultProps = {
  className: 'w-full document',
  clearName: '',
  documentCreatedDate: '',
  documentName: 'Uploaded file',
  documentStatus: '',
  documentURL: '',
  errors: [],
  hasStatusTag: undefined,
  isClearable: undefined,
  isDownloadable: undefined,
};

export default Document;
