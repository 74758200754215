import { Hint } from '@routable/gross-ds';
import React from 'react';
import ExternalLink from 'components/ExternalLink';
import { text } from 'components/hintTypes/constants';
import helpDocs from 'helpers/helpDocs';
const AmountTransactionMinimumHint = () => (React.createElement(Hint, { className: "w-full mt-4", title: text.amountTransactionMinimumHint.title, type: "critical", variant: "filled" },
    "Payments require a minimum transfer amount.",
    ' ',
    React.createElement(ExternalLink, { className: "primary", href: helpDocs.TRANSFER_LIMITS_BANK_TRANSFER }, text.amountTransactionMinimumHint.learnMore)));
export default AmountTransactionMinimumHint;
