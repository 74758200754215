import React from 'react';

import { EXTERNAL_ONBOARD_CUSTOMER, EXTERNAL_ONBOARD_VENDOR } from 'global/images/external';

import { isExternalPathAccept, isExternalPathPay } from 'helpers/external';
import { getQueryParam } from 'helpers/queryParams';

import ExternalReceiptSingleItem from 'modules/external/externalReceipt/presenters/ExternalReceiptSingleItem';

import '../presenters/ExternalReceipt.scss';

const itemId = getQueryParam('item_id');

class ExternalReceiptContainer extends React.Component {
  renderCustomerOnboardImage = () => {
    if (!isExternalPathPay(window.location)) {
      return null;
    }
    if (itemId) {
      return null;
    }

    return <img alt="onboardCustomer" className="external-onboarding-image" src={EXTERNAL_ONBOARD_CUSTOMER} />;
  };

  renderVendorOnboardImage = () => {
    if (!isExternalPathAccept(window.location)) {
      return null;
    }
    if (itemId) {
      return null;
    }

    return <img alt="onboardVendor" className="external-onboarding-image" src={EXTERNAL_ONBOARD_VENDOR} />;
  };

  renderExternalReceipt = () => {
    if (!itemId) {
      return null;
    }
    return <ExternalReceiptSingleItem itemId={itemId} location={window.location} />;
  };

  render() {
    return (
      <div className="external-receipt--container">
        {this.renderExternalReceipt()}

        {/* No item */}
        {this.renderCustomerOnboardImage()}
        {this.renderVendorOnboardImage()}
      </div>
    );
  }
}

export default ExternalReceiptContainer;
