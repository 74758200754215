import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table container component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableContainer = ({ children, className, ...rest }) => (
  <ul {...rest} className={clsx('platform-table--inline remove-margin-bottom', className)}>
    {children}
  </ul>
);

InvoiceGeneratorTableContainer.propTypes = {
  children: PropTypes.node,
};

InvoiceGeneratorTableContainer.defaultProps = {
  children: undefined,
};

export default InvoiceGeneratorTableContainer;
