import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { BrandWrapperV2 } from 'components/brandV2';
import { ButtonV2 } from 'components/buttonV2';
import ErrorCard from 'components/error/components/errorCard';
import { IconNames } from 'components/icon';
import { Col, Row } from 'components/layout';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

/**
 * Fallback component when attempting to access a protected route and access is denied.
 */
const ProtectedRouteWarn = ({
  actionText,
  className,
  message,
  onPrimaryAction,
  onReturnClick,
  returnClassName,
  returnLinkAs,
  returnText,
  returnUrl,
  title,
}) => {
  const isReturnLinkShown = !!returnText && (!!returnUrl || !!onReturnClick);

  return (
    <BrandWrapperV2 showFooter={false} showLogo>
      <div className={clsx('branded--contents remove-margin protected-warn', className)}>
        <ErrorCard header={title} reason={message}>
          <Row alignItems="stretch" className="protected-warn--buttons-container" cols={1} flexWrap="wrap">
            {!!onPrimaryAction && !!actionText && (
              <Col>
                <ButtonV2
                  className="protected-warn--button"
                  id="routeWarn_btn"
                  onClick={onPrimaryAction}
                  rightIconClassName="margin-left--large"
                  rightIconName={IconNames.ARROW_RIGHT}
                  rightIconSize={sizes.iconSizes.LARGE}
                  size={ButtonSize.MEDIUM}
                >
                  {actionText}
                </ButtonV2>
              </Col>
            )}

            {isReturnLinkShown && (
              <Col flexDirection="row" isGrid justifyContent="center">
                {React.createElement(
                  returnLinkAs,
                  {
                    className: `protected-warn--link ${returnClassName}`,
                    to: returnUrl,
                    onClick: onReturnClick,
                  },
                  returnText,
                )}
              </Col>
            )}
          </Row>
        </ErrorCard>
      </div>
    </BrandWrapperV2>
  );
};

ProtectedRouteWarn.propTypes = {
  actionText: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  onPrimaryAction: PropTypes.func,
  onReturnClick: PropTypes.func,
  returnClassName: PropTypes.string,
  returnLinkAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  returnText: PropTypes.node,
  returnUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ProtectedRouteWarn.defaultProps = {
  actionText: undefined,
  className: undefined,
  message: undefined,
  onPrimaryAction: undefined,
  onReturnClick: undefined,
  returnClassName: '',
  returnLinkAs: Link,
  returnText: undefined,
  returnUrl: undefined,
};

export default ProtectedRouteWarn;
