import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table body row component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableBodyRow = ({ children, className }) => (
  <li className={clsx('table-row--container disable-hover', className)}>{children}</li>
);

InvoiceGeneratorTableBodyRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableBodyRow.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableBodyRow;
