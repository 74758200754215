import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const InfoCardTop = ({ children, className }) => <div className={clsx('info-card--top', className)}>{children}</div>;

InfoCardTop.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InfoCardTop.defaultProps = {
  className: undefined,
};

export default InfoCardTop;
