import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const { GroupHeading } = components;

/**
 * Wrapper around ReactSelect's GroupHeading component.
 * Passes all props to the base GroupHeading, but applies
 * a special class to denote when it has no children
 * (i.e. no label).
 * @param {Object} props
 * @param {NodeMaybe} props.children
 * @returns {JSX.Element}
 */
const MultiCheckGroupHeading = ({ children, ...props }) => (
  <div className={clsx('multi-check-select--group-heading', { 'empty-heading': !children })}>
    <GroupHeading {...props}>{children}</GroupHeading>
  </div>
);

MultiCheckGroupHeading.propTypes = {
  children: PropTypes.node,
};

MultiCheckGroupHeading.defaultProps = {
  children: undefined,
};

export default MultiCheckGroupHeading;
