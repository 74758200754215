import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { IsLoadingComponent } from 'modules/isLoading';

import { SettingsTableHeader, SettingsTableRender } from './components';

/**
 * Table displaying Team members settings.
 * @param {ComponentProps} props
 * @param {boolean} props.isLoadingTable
 * @param {string} props.tableClassName
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const SettingsTable = ({ isLoadingTable, tableClassName, ...rest }) => (
  <div className={clsx('margin-bottom--m', tableClassName)}>
    <SettingsTableHeader {...rest} />

    {isLoadingTable && <IsLoadingComponent hasShadow text="Loading team members..." />}

    {!isLoadingTable && <SettingsTableRender {...rest} />}
  </div>
);

SettingsTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoadingTable: PropTypes.bool.isRequired,
  tableClassName: PropTypes.string,
  tableConfig: PropTypes.shape({
    // This can actually be any cell name with the required props
    // see teamMembersInvitesTableConfig.js and teamMembersListTableConfig.js for reference
    cellName: PropTypes.shape({
      baseClassName: PropTypes.string,
      displayName: PropTypes.string,
      flexGrow: PropTypes.number,
      obj: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
      width: PropTypes.number,
    }),
  }).isRequired,
  onResendInvite: PropTypes.func,
  onRevokeInvite: PropTypes.func,
};

SettingsTable.defaultProps = {
  tableClassName: undefined,
  title: '',
  onResendInvite: undefined,
  onRevokeInvite: undefined,
  data: undefined,
};

export default SettingsTable;
