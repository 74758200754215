import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table body component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableBody = ({ children, className }) => (
  <div className={clsx('table-row--scroll', className)}>{children}</div>
);

InvoiceGeneratorTableBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableBody.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableBody;
