import clsx from 'clsx';
import React from 'react';
import { breakpointNames } from 'constants/mediaQuery';
import { isEqual } from 'helpers/utility';
import DisclosureList from '../DisclosureList';
import { DisclosureListHeadingStyle } from '../enums';
import {} from './HeadingBlockDisclosureList.types';
const HeadingBlockDisclosureList = ({ bodyRowProps = {}, className = undefined, headerIconName, headingStyle = DisclosureListHeadingStyle.DEFAULT, headerText, items, ...rest }) => (React.createElement(DisclosureList, { ...rest, bodyRowProps: {
        breakpointProps: {
            [breakpointNames.tablet]: {
                cols: 1,
            },
        },
        ...bodyRowProps,
    }, className: clsx('heading-block-disclosure', className, {
        'thin-heading': isEqual(headingStyle, DisclosureListHeadingStyle.THIN),
    }), headerIconName: headerIconName, headerText: headerText, items: items }));
export default HeadingBlockDisclosureList;
