import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const PanelBodyTitle = ({ children, className }) => <p className={clsx('panel-body--title', className)}>{children}</p>;

PanelBodyTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PanelBodyTitle;
