import { TruncatableText } from '@routable/components';
import { Checkbox, Skeleton, Tag } from '@routable/gross-ds';
import clsx from 'clsx';
import _sortBy from 'lodash/sortBy';
import React from 'react';
import { LinkNewPage } from 'components';
import { URL } from 'constants/regex';
import { convertToLowerCase } from 'helpers/stringHelpers';
import { useSelectorWithProps } from 'hooks';
import { itemTagsSelector } from 'selectors/taggingSelectors';
const RenderValue = ({ fieldType, fieldPath, item, options, value }) => {
    const itemTags = useSelectorWithProps(itemTagsSelector, {
        match: { params: { id: item.id } },
    });
    if (fieldType === 'boolean') {
        return (React.createElement(Checkbox, { checked: Boolean(value), className: "flex items-end min-h-[24px] pointer-events-none", name: fieldPath, readOnly: true }));
    }
    if (fieldPath === 'ledgerStatus' && item.ledgerLink) {
        return (React.createElement(LinkNewPage, { className: "text-blue-40", href: item.ledgerLink },
            React.createElement(TruncatableText, { position: "top", text: `${value} ↗`, variant: "dark" })));
    }
    if (fieldPath === 'tagLinks') {
        const sortedTags = _sortBy(itemTags, [(tag) => convertToLowerCase(tag.name)]);
        return (React.createElement("div", { className: "flex flex-wrap gap-2 mt-1" }, sortedTags.map((tag) => (React.createElement(Tag, { "data-testid": tag.id, key: tag.id }, tag.name)))));
    }
    if (typeof value === 'string' && URL.test(value)) {
        return (React.createElement(LinkNewPage, { className: "text-blue-40", href: value },
            React.createElement(TruncatableText, { position: "top", text: value, variant: "dark" })));
    }
    if (typeof value === 'string' && value) {
        return (React.createElement(TruncatableText, { className: clsx({
                capitalize: fieldPath === 'extended.paymentpriority',
            }), position: "top", text: value, variant: "dark" }));
    }
    if (options?.length === 0) {
        return React.createElement(Skeleton, { shape: "round" });
    }
    return React.createElement(React.Fragment, null, "None");
};
export default RenderValue;
