import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { isFn } from 'helpers/utility';

class EntityWidget extends React.Component {
  state = {
    isOpen: !this.props.isCollapsible || this.props.startOpen,
  };

  handleToggleOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  getClonedHeader = () => {
    const { body, header, isCollapsible } = this.props;

    if (header) {
      const canCollapseBody = !!body && isCollapsible;
      const originalOnClick = header.props.onClick;

      return React.cloneElement(header, {
        ...header.props,
        isCollapsible: canCollapseBody,
        onClick: () => {
          this.handleToggleOpen();

          if (isFn(originalOnClick)) {
            originalOnClick();
          }
        },
      });
    }

    return null;
  };

  render() {
    const { body, className, isCollapsible, style } = this.props;

    const { isOpen } = this.state;

    const classConfig = clsx('entity-widget', className, { open: !isCollapsible || (isCollapsible && isOpen) });

    const clonedHeader = this.getClonedHeader();

    return (
      <div className={classConfig} style={style}>
        {clonedHeader}

        <div className="entity-widget--body">{body}</div>
      </div>
    );
  }
}

EntityWidget.propTypes = {
  body: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node,
  isCollapsible: PropTypes.bool,
  startOpen: PropTypes.bool,
  style: PropTypes.shape(),
};

EntityWidget.defaultProps = {
  body: undefined,
  className: undefined,
  header: undefined,
  isCollapsible: true,
  startOpen: true,
  style: {},
};

export default EntityWidget;
