/**
 * @fileOverview Defines the base selectors for feature flags state.
 * @module selectors/featureFlags
 */

import { createSelector } from 'reselect';

import * as flags from 'constants/featureFlags';

/**
 * Returns the slice of state for feature flags.
 * @function
 * @param {Object} state - Redux state
 * @return {Object}
 */
export const getFeatureFlags = (state) => state.featureFlags;

/**
 * Selects the featureFlags.activeFlags object.
 * @type {StandardSelector}
 * @return {EnabledKeys}
 */
export const featureFlagsActiveFlagsSelector = createSelector(
  [getFeatureFlags],
  (featureFlags) => featureFlags.activeFlags,
);

/**
 * Given a flag name, returns a selector for activeFlags[flagName].
 * @param {string} flagName
 * @return {StandardSelector}
 */
export const createFeatureFlagSelector = (flagName) =>
  createSelector([featureFlagsActiveFlagsSelector], (featureFlags) => featureFlags[flagName]);

/**
 * Given a series of flag names return true if each flagName is an active featureFlag
 * @param {...any} flagNames
 * @returns
 */
export const areFeatureFlagsActiveSelector = (...flagNames) =>
  createSelector([featureFlagsActiveFlagsSelector], (activeFlags) =>
    flagNames.every((flagName) => flagName in activeFlags),
  );

/**
 * Selects the featureFlags.errors object.
 * @type {StandardSelector}
 * @return {Object}
 */
export const featureFlagsErrorsSelector = createSelector([getFeatureFlags], (featureFlags) => featureFlags.errors);

/**
 * Selects the featureFlags.isFetching boolean.
 * @type {StandardSelector}
 * @return {boolean}
 */
export const featureFlagsIsFetchingSelector = createSelector(
  [getFeatureFlags],
  (featureFlags) => featureFlags.isFetching,
);

/**
 * Selects the featureFlags.activeFlags.crossborderItemApprovalEnabled boolean
 * @type {StandardSelector}
 * @returns {Boolean}
 */
export const featureFlagCrossborderItemApprovalEnabled = createFeatureFlagSelector(
  flags.FEATURE_FLAG_NAME_CROSSBORDER_ITEM_APPROVAL_ENABLED,
);

/**
 * Selects the featureFlags.activeFlags.globalOnlyExternalFlowBackButton boolean
 * @type {FeatureFlagSelector}
 */
export const featureFlagExternalFlowBackButtonEnabled = createFeatureFlagSelector(
  flags.FEATURE_FLAG_NAME_GLOBAL_ONLY_EXTERNAL_FLOW_BACK_BUTTON,
);

/**
 * Selects the featureFlags.activeFlags.payablesFilterDefault boolean
 * @type {FeatureFlagSelector}
 */
export const featureFlagPayablesFilterDefault = createFeatureFlagSelector(flags.FEATURE_FLAG_PAYABLES_FILTER_DEFAULT);

/**
 * Selects the featureFlags.activeFlags.payablesStatusFilter boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagPayablesStatusFilter = createFeatureFlagSelector(flags.FEATURE_FLAG_PAYABLES_STATUS_FILTER);

/**
 * Selects the featureFlags.activeFlags.enterpriseApprovalRules boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagEnterpriseApprovalRules = createFeatureFlagSelector(
  flags.FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES,
);

/**
 * Selects the featureFlags.activeFlags.globalOnlyCrossborderSelfServe boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagCrossborderSelfServeSelector = createFeatureFlagSelector(
  flags.FEATURE_FLAG_CROSSBORDER_SELF_SERVE,
);

/**
 * Selects the featureFlags.activeFlags.customRolesEnabled boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagCustomRolesEnabled = createFeatureFlagSelector(flags.FEATURE_FLAG_CUSTOM_ROLES_ENABLED);

/**
 * Selects the featureFlags.activeFlags.crossBorderMultipleProviders boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagCrossBorderMultipleProviders = createFeatureFlagSelector(
  flags.FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS,
);

/**
 * Selects the featureFlags.activeFlags.taxExcludeVendor boolean
 * @type {FeatureFlagSelector}
 * @returns {Boolean}
 */
export const featureFlagTaxExcludeVendorEnabled = createFeatureFlagSelector(flags.FEATURE_FLAG_TAX_EXCLUDE_VENDOR);
