import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components/text';

import { sizes, typography } from 'constants/styles';

import BaseRelation from '../BaseRelation';

import { TextAndIconsRelationIcon } from './components';

const TextAndIconsRelation = ({ className, iconLeft, iconRight, style, text }) => (
  <BaseRelation className={clsx(className)} style={style}>
    {!!iconLeft && (
      <TextAndIconsRelationIcon
        className="relation--left-icon"
        icon={iconLeft}
        marginRight={sizes.spacing.EXTRA_MEDIUM}
      />
    )}

    <Text.Semibold className="display--inline" size={typography.TextSize.LEVEL_100}>
      {text}
    </Text.Semibold>

    {!!iconRight && <TextAndIconsRelationIcon className="relation--right-icon" icon={iconRight} />}
  </BaseRelation>
);

TextAndIconsRelation.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  style: PropTypes.shape({}),
  text: PropTypes.node.isRequired,
};

TextAndIconsRelation.defaultProps = {
  className: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  style: {},
};

export default TextAndIconsRelation;
