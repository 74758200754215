import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ListItemValue = ({ className, children, style }) => (
  <div
    className={clsx('font-color--greyXDark font-xs', className, {
      'remove-margin-bottom': !className || !className.includes('margin-bottom'),
    })}
    style={style}
  >
    {children}
  </div>
);

ListItemValue.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape(),
};

ListItemValue.defaultProps = {
  className: undefined,
  children: undefined,
  style: {},
};

export default ListItemValue;
