import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Description } from 'components/text';

const FormFieldDescription = ({ className, children }) => (
  <Description className={clsx('form-field--description', className)}>{children}</Description>
);

FormFieldDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FormFieldDescription.defaultProps = {
  className: undefined,
};

export default FormFieldDescription;
