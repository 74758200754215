import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'components';
import { Icon } from 'components/icon';

import { getFundingAccountStatusText, isFundingAccountExternal, isFundingAccountValid } from 'helpers/funding';

import { PaymentMethodHintBaseContent } from './components';
import { getFooterIcon, getFooterTextColor } from './helpers';

import './PaymentMethodHintBase.scss';

/**
 * Renders hint displaying information partnership payment method. Meant to be used
 * as a base for more specific payment method hints.
 * @see CurrentPaymentMethodHint
 * @see ItemPaymentMethodHint
 *
 * @param {ComponentProps} props
 * @param {FundingAccount} props.fundingAccount
 * @param {FundingInfoAddress} [props.fundingInfoAddress]
 * @param {string} props.label
 * @return {StatelessComponent}
 */
const PaymentMethodHintBase = ({ fundingAccount, fundingInfoAddress, label }) => {
  if (!fundingAccount) {
    return null;
  }

  const isActive = isFundingAccountExternal(fundingAccount) || isFundingAccountValid(fundingAccount);
  const statusText = getFundingAccountStatusText(isActive);

  return (
    <div className="payment-method-hint-base">
      <Text.Bold>{label}</Text.Bold>

      <div className="payment-method-hint-base__box">
        <div className="payment-method-hint-base__box--content">
          <PaymentMethodHintBaseContent fundingAccount={fundingAccount} fundingInfoAddress={fundingInfoAddress} />
        </div>

        <div className={clsx('payment-method-hint-base__box--footer', isActive ? 'active' : 'pending')}>
          <Icon icon={getFooterIcon(isActive)} />
          <Text.Bold color={getFooterTextColor(isActive)}>{statusText}</Text.Bold>
        </div>
      </div>
    </div>
  );
};

PaymentMethodHintBase.propTypes = {
  fundingAccount: PropTypes.shape({
    name: PropTypes.string,
  }),
  fundingInfoAddress: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
};

PaymentMethodHintBase.defaultProps = {
  fundingAccount: undefined,
  fundingInfoAddress: undefined,
};

export default PaymentMethodHintBase;
