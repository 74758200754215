import { useAuth0 } from '@auth0/auth0-react';
import * as FullStory from '@fullstory/browser';
import { setFrameworkOAuthClient } from '@routable/framework';
import SentryFullStory from '@sentry/fullstory';
import { ReportingObserver as ReportingObserverIntegration, ExtraErrorData as ExtraErrorDataIntegration, CaptureConsole as CaptureConsoleIntegration, } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import RoutableHead from 'components/head/RoutableHead';
import { ProtectedRouteWarnDisabledUser } from 'components/routing';
import Sandbox from 'components/sandbox/Sandbox';
import { BLOG_URL, HELP_DOCS_URL, LEGAL_PRIVACY_URL, LEGAL_TOS_URL, SECURITY_URL } from 'constants/platform';
import * as ROUTES from 'constants/routes';
import { hasAuthToken } from 'helpers/auth';
import { isBrowserSupported } from 'helpers/browser';
import { isNodeEnvProduction, isNodeEnvSandbox } from 'helpers/env';
import { identifyUserByMembershipId } from 'helpers/eventTracking';
import { getCurrentMembershipData, getCurrentMembershipId } from 'helpers/localStorage';
import { asPath, getJoinedPath } from 'helpers/routeHelpers';
import { processSentryEvent } from 'helpers/sentry.helper';
import { systemLogger, LogLevel } from 'helpers/systemLogger';
import { isSubdomainNamespaceExternal } from 'helpers/urls';
import { setUTMTagsToSessionStorage } from 'helpers/utmTags';
import { useHandleBrandedWorkspaceRedirect } from 'hooks/useHandleBrandedWorkspaceRedirect';
import { useRedirectFromTopLevelToBrandedWorkspace } from 'hooks/useRedirectFromTopLevelToBrandedWorkspace';
import AllowAny from 'modules/app/AllowAny';
import Authenticated from 'modules/app/AuthenticatedRoute';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import RedirectExternal from 'modules/app/RedirectExternal';
import SwitchOr404 from 'modules/app/SwitchOr404';
import { AcceptMembershipInvite, CreatePassword, Login, ResetPassword, Logout } from 'modules/auth';
import { IntercomWidget } from 'modules/customerSupport';
import { useCustomerServiceWidget } from 'modules/customerSupport/CustomerService/CustomerService';
import DashboardContainer from 'modules/dashboard/components/Dashboard';
import DeepLinks from 'modules/deepLinks';
import ExternalContainer from 'modules/external/global/containers/ExternalContainer';
import ExternalContainerV2 from 'modules/external/global/containers/ExternalContainerV2';
import { IsLoadingHasWrapper } from 'modules/isLoading';
import { MaintenanceModeFullPage, OldVersionModeFullPage } from 'modules/maintenance';
import { QuickswitchToBrandedSubdomain } from 'modules/quickswitch';
import ConvertExternal from 'modules/signup/components/ConvertExternal';
import SignupV2 from 'modules/signup/SignupV2';
import SignupAccountCreation from 'modules/signup-accountCreation/SignupV2';
import SignUpFlow from 'modules/signup-v3/SignUpFlowContainer';
import Processing from 'modules/signup-v3/views/Processing';
import UnsupportedBrowser from 'modules/unsupportedBrowser/UnsupportedBrowser';
import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { disabledUserErrorSelector } from 'selectors/errorsSelectors';
import { maintenanceModeSelector } from 'selectors/maintenanceModeSelectors';
import { oldVersionModeSelector } from 'selectors/oldVersionModeSelectors';
import { initAuth0Client } from 'services/auth0';
import { initTestableAuth0Client } from 'tests/helpers/auth0';
import { SentryErrorPage } from 'tests/pages';
import {} from './App.types';
import { ExporterComponent } from './components';
const { REACT_APP_FULLSTORY_ORG_ID } = process.env;
if (REACT_APP_FULLSTORY_ORG_ID) {
    FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG_ID });
}
if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        ignoreErrors: ['No FullStory session URL found'],
        integrations: [
            new ReportingObserverIntegration(),
            new BrowserTracing(),
            new ExtraErrorDataIntegration({
                depth: 3,
            }),
            new CaptureConsoleIntegration({
                levels: ['error'],
            }),
            new SentryFullStory('routable', { client: FullStory }),
        ],
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
        ],
        tracesSampleRate: 0.1,
        normalizeDepth: 8,
        release: process.env.REACT_APP_VERSION,
        environment: process.env.REACT_APP_SENTRY_ENV,
        beforeSend(event) {
            return processSentryEvent(event);
        },
    });
}
const App = ({ isMaintenanceModeOn, isOldVersionModeOn, isUserDisabled, currentCompany, currentUser }) => {
    const auth0 = useAuth0();
    if (!(isNodeEnvProduction() || isNodeEnvSandbox())) {
        initTestableAuth0Client(auth0);
    }
    initAuth0Client(auth0);
    setFrameworkOAuthClient(auth0);
    if (auth0.error) {
        systemLogger.log({ level: LogLevel.ERROR, error: auth0.error });
    }
    React.useEffect(() => {
        setUTMTagsToSessionStorage();
    }, []);
    React.useEffect(() => {
        if (!hasAuthToken()) {
            return;
        }
        const { companyId, userId } = getCurrentMembershipData();
        identifyUserByMembershipId(getCurrentMembershipId(), {
            companyId,
            userId,
            email: currentUser?.email,
            name: `${currentUser?.firstName} ${currentUser?.lastName}`,
            namespace: currentCompany?.namespace,
            accountName: currentCompany?.name,
        });
    }, [currentCompany, currentUser]);
    useHandleBrandedWorkspaceRedirect();
    useRedirectFromTopLevelToBrandedWorkspace();
    useCustomerServiceWidget();
    if (auth0.isLoading) {
        return React.createElement(IsLoadingHasWrapper, null);
    }
    return (React.createElement(React.Suspense, { fallback: React.createElement(IsLoadingHasWrapper, null) },
        React.createElement(RoutableHead, null),
        React.createElement(Sandbox, null),
        React.createElement(IntercomWidget, null),
        React.createElement(SwitchOr404, null,
            isMaintenanceModeOn && React.createElement(AllowAny, { component: MaintenanceModeFullPage }),
            isOldVersionModeOn && React.createElement(AllowAny, { component: OldVersionModeFullPage }),
            isUserDisabled && React.createElement(AllowAny, { component: ProtectedRouteWarnDisabledUser }),
            React.createElement(RedirectExternal, { path: asPath(ROUTES.REDIRECT_TO_BLOG), to: BLOG_URL }),
            React.createElement(RedirectExternal, { path: asPath(ROUTES.REDIRECT_TO_DOCS), to: HELP_DOCS_URL }),
            !isBrowserSupported() && React.createElement(AllowAny, { component: UnsupportedBrowser }),
            isSubdomainNamespaceExternal() && React.createElement(AllowAny, { component: ExternalContainerV2 }),
            React.createElement(AllowAny, { component: ExternalContainer, path: asPath(ROUTES.EXTERNAL) }),
            React.createElement(AllowAny, { component: ExternalContainerV2, path: asPath(ROUTES.EXTERNAL_V2) }),
            React.createElement(Authenticated, { component: DashboardContainer, path: asPath(ROUTES.DASHBOARD) }),
            React.createElement(Authenticated, { component: SentryErrorPage, path: asPath(ROUTES.SENTRYERRORPAGE) }),
            React.createElement(Authenticated, { component: SignUpFlow, path: asPath(ROUTES.GET_STARTED) }),
            React.createElement(Authenticated, { component: Processing, path: asPath(ROUTES.PROCESSING) }),
            React.createElement(Authenticated, { component: DeepLinks, path: asPath(ROUTES.DEEP_LINK) }),
            React.createElement(Authenticated, { component: ExporterComponent, path: asPath(ROUTES.EXPORT) }),
            React.createElement(RedirectExternal, { exact: true, path: getJoinedPath(ROUTES.LEGAL, ROUTES.PRIVACY), to: LEGAL_PRIVACY_URL }),
            React.createElement(RedirectExternal, { exact: true, path: getJoinedPath(ROUTES.LEGAL, ROUTES.TERMS), to: LEGAL_TOS_URL }),
            React.createElement(RedirectExternal, { exact: true, path: asPath(ROUTES.SECURITY), to: SECURITY_URL }),
            React.createElement(AllowAny, { component: Logout, exact: true, path: asPath(ROUTES.LOGOUT) }),
            React.createElement(NotAuthenticated, { component: AcceptMembershipInvite, exact: true, path: asPath(ROUTES.ACCEPT_MEMBERSHIP_INVITE) }),
            React.createElement(NotAuthenticated, { component: Login, exact: true, path: asPath(ROUTES.LOGIN) }),
            React.createElement(NotAuthenticated, { component: ResetPassword, path: asPath(ROUTES.RESET_PASSWORD) }),
            React.createElement(AllowAny, { component: CreatePassword, path: asPath(ROUTES.CREATE_PASSWORD) }),
            React.createElement(NotAuthenticated, { component: ConvertExternal, path: asPath(ROUTES.CONVERT_EXTERNAL_COMPANY_TO_REGISTERED) }),
            React.createElement(AllowAny, { component: QuickswitchToBrandedSubdomain, exact: true, path: asPath(ROUTES.QUICKSWITCH) }),
            React.createElement(AllowAny, { component: SignupV2, path: asPath(ROUTES.SIGNUP) }),
            React.createElement(AllowAny, { component: SignupAccountCreation, path: asPath(ROUTES.ACCOUNT_CREATION) }),
            React.createElement(Redirect, { exact: true, from: asPath(ROUTES.HOME), to: asPath(ROUTES.LOGIN) }))));
};
const mapStateToProps = createStructuredSelector({
    isMaintenanceModeOn: maintenanceModeSelector,
    isOldVersionModeOn: oldVersionModeSelector,
    isUserDisabled: disabledUserErrorSelector,
    currentUser: currentUserSelector,
    currentCompany: currentCompanySelector,
});
export default connect(mapStateToProps)(App);
