import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { getLoadMoreButtonText } from 'complexComponents/Table/helpers/text';

import { ButtonV2, NumberChip } from 'components';

import { isNum } from 'helpers/utility';

import TableContext from '../../TableContext';
import TableCell from '../TableCell';
import TableRow from '../TableRow';

const TableLoadMoreFooter = ({ isFetching, isShown, loadMoreNumber, onClick, shouldShowLess }) => {
  const { compact } = React.useContext(TableContext);

  if (!isShown) {
    return null;
  }

  const isValidNumber = isNum(loadMoreNumber);
  const isNumberShown = isValidNumber && !shouldShowLess;
  const buttonText = getLoadMoreButtonText({ isValidNumber, shouldShowLess });

  return (
    <tbody>
      <TableRow className="table-v2-footer--load-more">
        <TableCell colSpan={99}>
          <ButtonV2
            className={clsx('load-more--button', 'remove-margin-bottom', {
              'load-more--button--compact': compact,
            })}
            isLoading={isFetching}
            onClick={onClick}
            type="button"
          >
            {!isFetching && (
              <>
                <span>{buttonText}</span>
                {isNumberShown && (
                  <NumberChip className={clsx({ 'load-more--number--compact': compact })}>{loadMoreNumber}</NumberChip>
                )}
              </>
            )}
          </ButtonV2>
        </TableCell>
      </TableRow>
    </tbody>
  );
};

TableLoadMoreFooter.propTypes = {
  isFetching: PropTypes.bool,
  isShown: PropTypes.bool,
  loadMoreNumber: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  shouldShowLess: PropTypes.bool,
};

TableLoadMoreFooter.defaultProps = {
  isShown: true,
  isFetching: undefined,
  loadMoreNumber: undefined,
  shouldShowLess: undefined,
};

export default TableLoadMoreFooter;
