import React from 'react';
import { RoutableEventsSync } from '@routable/framework';
import { useDispatchTableEvent } from '../../hooks/useTableEvent.hook';
import {} from './tableselection.types';
import { useTableStore } from '../../table.store';
export const TableSelection = ({ numOfSelectedItems, onClearSelected, tableName }) => {
    const { setTableStateItem } = useTableStore(tableName);
    const changeAllSelection = useDispatchTableEvent({
        tableName,
        event: 'selected:update:all',
    });
    const onHandleClearSelection = () => {
        setTableStateItem('selections', []);
        RoutableEventsSync.Publish(`table:${tableName}:selected:updated`, []);
        changeAllSelection([]);
        onClearSelected?.();
    };
    return (React.createElement("div", { className: "flex gap-5", "data-testid": "tableSelectedItems" },
        React.createElement("span", { className: "text-black text-sm font-medium", "data-testid": "itemsSelected" }, `${numOfSelectedItems} selected`),
        React.createElement("button", { className: "text-blue-40 text-sm font-medium hover:underline focus-visible:underline", "data-testid": "clearSelection", onClick: onHandleClearSelection, type: "button" }, "Clear selection")));
};
