import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ModalBodyContent = ({ className, children }) => (
  <div className={clsx('modal-body--content', className)}>{children}</div>
);

ModalBodyContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ModalBodyContent.defaultProps = {
  className: undefined,
};

export default ModalBodyContent;
