import { Popover } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';
import { useOverlayTriggerState } from 'react-stately';

import AddContactButtonTarget from 'complexComponents/AddContactButton/components/AddContactButtonTarget';

import { TagMultiSelect } from 'components';
import {
  PopoverSelectActionContent,
  PopoverSelectSelectionContent,
} from 'components/selectTypes/PopoverSelect/components';

import { SelectPlaceholderText } from 'constants/ui';

import { createPossibleItemMembersForPopoverSelect } from 'helpers/itemMember';
import { getPaymentOrInvoiceText } from 'helpers/items';
import { getShouldDisableAddContactUi } from 'helpers/ui';

/**
 * The + Add Contact button for an InteractionListBar to add an ItemMember to an Item.
 * @param {ComponentProps} props
 * @param {Company} props.company
 * @param {function} [props.createContactIsDisabled]
 * @param {Item} props.item
 * @param {ItemMember[]} props.itemMembers
 * @param {function} [props.onCreateContact]
 * @param {Partnership} props.partnership
 * @param {ItemMemberAsPartnershipMember[]} props.possibleItemMembers
 * @param {ObjectMaybe} props.selectProps
 * @returns {FunctionComponent}
 */
const AddContactPopoverSelectButton = (props) => {
  const {
    company,
    createContactIsDisabled,
    isPrimaryActionDisabled,
    item,
    itemMembers,
    onCreateContact,
    onPrimaryAction,
    partnership,
    possibleItemMembers,
    selectProps,
    input,
  } = props;

  const isDisabled = getShouldDisableAddContactUi(company, itemMembers, partnership);
  // Whether or not we should show the create new contact option in the input
  const isCreatable = typeof onCreateContact === 'function' && !createContactIsDisabled;

  const decoratedContacts = createPossibleItemMembersForPopoverSelect({
    itemMembers: possibleItemMembers,
  });

  const popoverState = useOverlayTriggerState({});

  return (
    <Popover
      content={
        <AddContactButtonTarget
          isDisabled={isDisabled}
          itemMembers={itemMembers}
          partnership={partnership}
          possibleItemMembers={possibleItemMembers}
          {...props}
        />
      }
      keepOpen
      popoverClassName="min-w-[450px] max-w-[450px] p-4 !z-[145]"
      state={popoverState}
    >
      <PopoverSelectSelectionContent
        input={input}
        onClose={popoverState.close}
        options={decoratedContacts}
        SelectComponent={TagMultiSelect}
        selectProps={{
          dataFullStory: true,
          label: isCreatable
            ? SelectPlaceholderText.SEARCH_OR_ADD_NEW_CONTACT
            : SelectPlaceholderText.SEARCH_FOR_CONTACTS,
          isCreatable,
          onCreate: onCreateContact,
          ...selectProps,
        }}
        title="Add contact"
      />

      {!isPrimaryActionDisabled && (
        <PopoverSelectActionContent
          isDisabled={isDisabled}
          label={`Add contact to ${getPaymentOrInvoiceText(item)}`}
          onClick={(e) => {
            onPrimaryAction(e);
            popoverState.close();
          }}
        />
      )}
    </Popover>
  );
};

AddContactPopoverSelectButton.propTypes = {
  company: PropTypes.shape({}).isRequired,
  createContactIsDisabled: PropTypes.bool,
  itemMembers: PropTypes.arrayOf(PropTypes.shape({})),
  item: PropTypes.shape({}).isRequired,
  onCreateContact: PropTypes.func,
  partnership: PropTypes.shape({}).isRequired,
  possibleItemMembers: PropTypes.arrayOf(PropTypes.shape({})),
  selectProps: PropTypes.shape({}),
};

AddContactPopoverSelectButton.defaultProps = {
  createContactIsDisabled: undefined,
  itemMembers: [],
  onCreateContact: undefined,
  possibleItemMembers: [],
  selectProps: {},
};

export default AddContactPopoverSelectButton;
