import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Block from './Block';

/**
 * Component rendering column grid entity
 *
 * @param {ComponentProps} props
 * @param {Boolean} [props.bigger]
 * @param {ChildNode} [props.children]
 * @param {string} [props.className]
 * @param {Boolean} [props.isGrid]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const Col = ({ bigger, children, className, isGrid, ...rest }) => (
  <Block {...rest} className={clsx('column', className, { bigger })} isColumn isGrid={isGrid}>
    {children}
  </Block>
);

Col.propTypes = {
  bigger: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isGrid: PropTypes.bool,
  justifyContent: PropTypes.string,
  style: PropTypes.shape({}),
};

Col.defaultProps = {
  bigger: undefined,
  children: undefined,
  className: undefined,
  isGrid: false,
  justifyContent: undefined,
  style: {},
};

export default Col;
