import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Divider } from './Divider';

const SettingsDivider = ({ className }) => (
  <Divider className={clsx('margin-bottom--x-large margin-top--large', className)} />
);

SettingsDivider.propTypes = {
  className: PropTypes.string,
};

SettingsDivider.defaultProps = {
  className: undefined,
};

export default SettingsDivider;
