import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { AccountBeneficiaryInfo } from 'modules/beneficiary';
import { AccountTypeField } from 'modules/fundingAccount/components/AccountTypeField';
import { itemCurrencyCodeReceiverFromQuerySelector } from 'queries/itemRouterSelectors';
import ManuallyAddBankAccountField from '../ManuallyAddBankAccountField';
const ManuallyAddBankAccountBankDetails = ({ availableCurrencies, bankExtraFields, className, fields, formName, isInternational, company, partnership, }) => {
    const itemCurrencyCodeReceiver = useSelector(itemCurrencyCodeReceiverFromQuerySelector);
    return (React.createElement("div", { className: clsx('manually-add-bank-account--bank-details', className) },
        isInternational && (React.createElement(React.Fragment, null,
            React.createElement(Text.Bold, { color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_475 }, "Account holder details"),
            React.createElement(AccountBeneficiaryInfo, { availableCurrencies: availableCurrencies, company: company, formName: formName, partnership: partnership, selectedCurrencyCode: itemCurrencyCodeReceiver }),
            React.createElement("div", { className: "mt-5" },
                React.createElement(Text.Bold, { color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_475 }, "Bank account details")),
            bankExtraFields?.account_type && (React.createElement(AccountTypeField, { className: "mt-5", fieldName: bankExtraFields.account_type.field, form: formName })))),
        fields?.map((fieldInfo) => (React.createElement(ManuallyAddBankAccountField, { fieldInfo: fieldInfo, formName: formName, isInternational: isInternational, key: fieldInfo.field })))));
};
export default ManuallyAddBankAccountBankDetails;
