import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Row from 'components/layout/Row';
import { LedgerStatusRelation } from 'components/relationTypes';

import ItemBlockHeader from './ItemBlockHeader';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import ItemBlockInvoiceNumber from './ItemBlockInvoiceNumber';
import ItemBlockMemo from './ItemBlockMemo';
import ItemBlockPartnerCompany from './ItemBlockPartnerCompany';
import ItemBlockReference from './ItemBlockReference';

import './ItemBlock.scss';

const ItemBlock = ({ actions, className, children, item, onClose, shouldShowLedgerStatus }) => (
  <div className={clsx('item-block', className)}>
    <ItemBlockHeader actions={actions} item={item} />
    <Row className="item-block--body" flexWrap="wrap">
      <ItemBlockPartnerCompany item={item} onClose={onClose} />
      <ItemBlockInvoiceNumber item={item} />
      <ItemBlockReference item={item} />
      <ItemBlockMemo item={item} />
      {shouldShowLedgerStatus && <LedgerStatusRelation item={item} />}
      {children}
    </Row>
  </div>
);

ItemBlock.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  onClose: PropTypes.func,
  shouldShowLedgerStatus: PropTypes.bool,
};

ItemBlock.defaultProps = {
  actions: undefined,
  children: undefined,
  className: undefined,
  shouldShowLedgerStatus: true,
};

export default ItemBlock;
