import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import Avatar from 'components/avatar/Avatar';
import { FlexRow } from 'components/layout';

import { NewOptionIcon, OptionLabel } from './components';

/**
 * Option component for react-select.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Object} props.data
 * @param {string} props.data.verboseLabel
 * @param {string} props.label
 * @return {StatelessComponent}
 */
const BaseOption = (props) => {
  const {
    children,
    className,
    data,
    dataFullStory,
    isDisabled,
    label,
    stackContentOnMobile,
    selectProps: { hasAvatar },
  } = props;

  const { __isNew__: isNewOption, data: member } = data;

  return (
    <components.Option {...props} className={clsx('base-option', className)}>
      <FlexRow className="justify-content--space-between" stackOnMobile={false}>
        <FlexRow
          className="base-option--content align-items--center"
          dataFullStory={dataFullStory}
          stackOnMobile={stackContentOnMobile}
        >
          {isNewOption && <NewOptionIcon />}
          {!isNewOption && hasAvatar && (
            <Avatar
              avatar={member.avatar}
              className="margin-right--m"
              email={member.email}
              firstName={member.firstName}
              lastName={member.lastName}
              sizeClass="avatar--xs"
              styleClass="avatar--light"
            />
          )}

          <OptionLabel isDisabled={isDisabled}>{label}</OptionLabel>

          {children}
        </FlexRow>
      </FlexRow>
    </components.Option>
  );
};

BaseOption.propTypes = {
  children: PropTypes.node,
  // passed to react-select Option component
  className: PropTypes.string,
  data: PropTypes.shape({
    // react-select's indicator of whether this is the "create" option
    __isNew__: PropTypes.bool,
    data: PropTypes.shape(),
    verboseLabel: PropTypes.string,
  }).isRequired,
  dataFullStory: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectProps: PropTypes.shape().isRequired,
  stackContentOnMobile: PropTypes.bool,
};

BaseOption.defaultProps = {
  children: undefined,
  className: undefined,
  dataFullStory: undefined,
  isDisabled: undefined,
  stackContentOnMobile: true,
};

export default BaseOption;
