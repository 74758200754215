import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { baseDefaultProps, basePropTypes } from 'components/commonProps';

import { tagTypeProp } from 'helpers/propTypes';

import BaseOption from '../BaseOption';

import { OptionTag } from './components';

/**
 * Option component for react-select that displays a label and a tag.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Object} props.data
 * @param {string} props.data.tagType
 * @param {string} props.label
 * @return {StatelessComponent}
 */
const TaggedOption = (props) => {
  const {
    className,
    data: { __isNew__: isNewOption, optionTagText, optionTagType },
  } = props;

  return (
    <BaseOption stackContentOnMobile={false} {...props} className={clsx('tagged-option', className)}>
      {!isNewOption && <OptionTag type={optionTagType}>{optionTagText}</OptionTag>}
    </BaseOption>
  );
};

TaggedOption.propTypes = {
  ...basePropTypes,
  data: PropTypes.shape({
    // react-select's indicator of whether this is the "create" option
    __isNew__: PropTypes.bool,
    optionTagText: PropTypes.string.isRequired,
    optionTagType: tagTypeProp.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

TaggedOption.defaultProps = {
  ...baseDefaultProps,
  isDisabled: undefined,
};

export default TaggedOption;
