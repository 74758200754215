import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';

const ReloadPageButton = ({ className, fullWidth }) => (
  <ButtonV2
    className={className}
    leftIconClassName="margin-right--xm"
    leftIconName={IconNames.REPEAT}
    leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
    onClick={() => window.location.reload()}
    rightIconClassName="margin-left--x-large"
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconSize={sizes.iconSizes.EXTRA_MEDIUM}
    size={ButtonSize.MEDIUM}
    type="button"
    wrapperClassName={clsx({ 'btn-wrapper--action': !fullWidth })}
  >
    Reload page
  </ButtonV2>
);

ReloadPageButton.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ReloadPageButton.defaultProps = {
  className: undefined,
  fullWidth: undefined,
};

export default ReloadPageButton;
