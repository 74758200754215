import PropTypes from 'prop-types';
import React from 'react';
import { v4 } from 'uuid';

import { ExternalReceiptLineItem } from './components';

import './ExternalReceiptLineItems.scss';

/**
 * Component rendering External Receipt Single Item line items
 * @param {ComponentProps} props
 * @param {LineItem[]} props.lineItems
 * @returns {StatelessComponent}
 */
const ExternalReceiptLineItems = ({ lineItems }) => {
  if (!Array.isArray(lineItems) || lineItems.length === 0) {
    return null;
  }

  return (
    <div className="external-receipt-line-items">
      {lineItems.map((lineItem) => (
        <ExternalReceiptLineItem key={`line-item-${v4()}`} lineItem={lineItem} />
      ))}
    </div>
  );
};

ExternalReceiptLineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
};

ExternalReceiptLineItems.defaultProps = {
  lineItems: undefined,
};

export default ExternalReceiptLineItems;
