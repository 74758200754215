import clsx from 'clsx';
import React from 'react';
import { ButtonV2, IconNames } from 'components';
import { ButtonAppearance } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import { useClipboard } from 'hooks';
import TableCellTextWithSubtext from '../TableCellTextWithSubtext';
import {} from './SaveToClipboardCell.types';
const SaveToClipboardCell = ({ className, rowData, dataFullStory, dataKey }) => {
    const text = rowData[dataKey];
    const ref = useClipboard({ copyValue: text });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "left-indicator width--90-percent", "data-fullstory": dataFullStory },
            React.createElement(TableCellTextWithSubtext, { className: clsx(className, { empty: !text }), text: text })),
        React.createElement("div", { className: "right-indicator" },
            React.createElement(ButtonV2, { appearance: ButtonAppearance.BORDERLESS, innerRef: ref, intent: Intent.NEUTRAL, isIconButton: true, leftIconColor: colors.colorGreyDarkHex, leftIconName: IconNames.DUPLICATE, leftIconSize: sizes.iconSizes.LARGE }))));
};
export default SaveToClipboardCell;
