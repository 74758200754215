import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './ExternalBlock.scss';

/**
 * Component rendering single External Block
 * @param {ComponentProps} props
 * @param {Node} props.children
 * @param {string} [props.className]
 * @returns {StatelessComponent}
 */
const ExternalBlock = ({ children, className }) => <div className={clsx('external-block', className)}>{children}</div>;

ExternalBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ExternalBlock.defaultProps = {
  className: undefined,
};

export default ExternalBlock;
