import { statusVariants } from '@routable/gross-ds';

/**
 * Status of a transaction.
 * @enum {string}
 */
export const TransactionStatus = {
  CANCELED: 'canceled',
  FAILED: 'failed',
  INITIATED: 'initiated',
  ISSUE: 'issue',
  PENDING: 'pending',
  PROCESSED: 'processed',
};

export const transactionStatusToVariant = {
  [TransactionStatus.CANCELED]: statusVariants.default,
  [TransactionStatus.FAILED]: statusVariants.error,
  [TransactionStatus.INITIATED]: statusVariants.pending,
  [TransactionStatus.ISSUE]: statusVariants.error,
  [TransactionStatus.PENDING]: statusVariants.pending,
  [TransactionStatus.PROCESSED]: statusVariants.success,
};

/**
 * Text to use for each transaction status.
 * @type {{ [TransactionStatus]: string }}
 */
export const TransactionStatusText = {
  [TransactionStatus.CANCELED]: 'Canceled',
  [TransactionStatus.FAILED]: 'Failed',
  [TransactionStatus.INITIATED]: 'Initiated',
  [TransactionStatus.ISSUE]: 'Issue found',
  [TransactionStatus.PENDING]: 'Processing',
  [TransactionStatus.PROCESSED]: 'Paid',
};

/**
 * Current step of the transaction.
 * @enum {string}
 */
export const TransactionStep = {
  DESTINATION_BALANCE: 'destination_balance',
  DESTINATION_NEGATIVE: 'destination_negative',
  ORIGINATION_BALANCE: 'origination_balance',
  ORIGINATION_NEGATIVE: 'origination_negative',
};
