import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

import { BankAccountViewType } from 'constants/ui';

import BankAccount from 'modules/fundingAccount/bankAccount';

import { BankAccountRadioHeader } from './components';

import './BankAccountRadio.scss';

/**
 * Component rendering selectable bank account radio input
 *
 * @param {ComponentProps} props
 * @param {Boolean} [props.displayBody]
 * @param {Boolean} [props.highlightOnHover]
 * @param {string} props.id
 * @param {ReduxFormInput} props.input
 * @param {Boolean} [props.isDisabled]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const BankAccountRadio = ({ displayBody, highlightOnHover, id, input, isDisabled, ...rest }) => (
  <div
    className={clsx('bank-account-radio', {
      checked: input.checked,
      disabled: isDisabled,
      'no-highlight': !highlightOnHover,
    })}
  >
    <BankAccount
      customHeader={
        <BankAccountRadioHeader
          {...rest}
          id={id}
          input={input}
          isDisabled={isDisabled}
          onClick={() => input.onChange(input.value)}
        />
      }
      displayBody={displayBody}
      isSelectable
      {...rest}
    />
  </div>
);

BankAccountRadio.propTypes = {
  className: PropTypes.string,
  displayBody: PropTypes.bool,
  fieldName: PropTypes.string,
  fundingAccount: PropTypes.shape({
    bank: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    ledgerRefPayable: PropTypes.string,
    ledgerRefReceivable: PropTypes.string,
    name: PropTypes.string,
  }),
  fundingInfoAddress: PropTypes.shape(),
  fundingInfoBankAccount: PropTypes.shape(),
  highlightOnHover: PropTypes.bool,
  id: PropTypes.string.isRequired,
  input: reduxFormInputPropType.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFetchingFundingAccounts: PropTypes.bool,
  menu: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  partnership: PropTypes.shape(),
  partnershipFundingAccount: PropTypes.shape(),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewType: PropTypes.oneOf(Object.values(BankAccountViewType)),
};

BankAccountRadio.defaultProps = {
  className: undefined,
  displayBody: undefined,
  fieldName: undefined,
  fundingAccount: {},
  fundingInfoAddress: {},
  fundingInfoBankAccount: {},
  highlightOnHover: true,
  isChecked: undefined,
  isDisabled: undefined,
  isFetchingFundingAccounts: undefined,
  menu: undefined,
  onBlur: undefined,
  onChange: undefined,
  partnership: undefined,
  partnershipFundingAccount: undefined,
  value: undefined,
  viewType: BankAccountViewType.FULL,
};

export default BankAccountRadio;
