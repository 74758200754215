import clsx from 'clsx';
import React from 'react';

import './Spinner.scss';

const Spinner = ({ useBlueVariant = false }) => (
  <div className={clsx('lds-spinner', { 'blue-variant': useBlueVariant })}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
