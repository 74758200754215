/**
 * @module helpers/ui
 */
import { PaymentDeliveryMethodType } from '@routable/shared';
import { riskSummary } from '@routable/vendor-risk';
import clsx from 'clsx';
import _startCase from 'lodash/startCase';
import pluralize from 'pluralize';
import React from 'react';

import { IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';
import { RTBIconNames } from 'components/rtbIcon';

import * as images from 'constants/images';
import { PaymentOrInvoiceByItemKindText } from 'constants/item';
import { LedgerApplicationTypes } from 'constants/ledger';
import { PartnershipTypes } from 'constants/partnership';
import { SELECTED_COMPANY_TABS, SELECTED_COMPANY_TABS_TEXT } from 'constants/routes';
import { colors, sizes, typography } from 'constants/styles';
import {
  AsyncValidateStatus,
  BankAccountViewSubType,
  BankAccountViewType,
  ContactsInteractionListViewType,
  DeactivateTeamMemberModalType,
  ExternalProgressBarDisplayTypes,
  FlairTagType,
  Intent,
  TagShape,
  TagType,
} from 'constants/ui';

import { EXTERNAL_IMAGES_PATH_PREFIX } from 'global/images/external';

import { isCurrentCompanyTypeBusiness, isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { isLedgerSettingsContactTypeBoth } from 'helpers/ledger';
import { getMembershipNameOrEmail, isMembershipCompanyController, isMembershipDuplicated } from 'helpers/memberships';
import { isPartnershipMemberEmailStatusBounced } from 'helpers/partnershipMembers';
import {
  getPartnershipTypeLedgerLink,
  getPartnershipTypeLedgerName,
  getPartnershipTypeLedgerRef,
  getPaymentTypeForPartnershipTypeText,
  isPartnershipCustomer,
  isPartnershipCustomerMatched,
  isPartnershipLedgerOnly,
  isPartnershipRoutableToRoutable,
  isPartnershipTypeCompany,
  isPartnershipTypeVendor,
  isPartnershipVendor,
  isPartnershipVendorMatched,
} from 'helpers/partnerships';
import { joinPathsWithRouteConstants } from 'helpers/routeHelpers';
import { isSelectedPartnerTypeExisting } from 'helpers/searchCompanies';
import { capitalize } from 'helpers/stringHelpers';
import { isFilterAll, isFilterNeedsApproval, isFilterPoDiscrepancy } from 'helpers/urls';

import { toaster } from 'services/toaster';

const { TextLineHeight, TextSize, TextWeight } = typography;

/**
 * Depending on the PartnershipType, create a label for the DetailsList on the company about tab for ledger-connected
 * companies.
 * @function
 * @param {PartnershipTypes} partnershipType
 * @returns {string}
 */
export const getCompanyIdLabelFromPartnershipType = (partnershipType) => {
  const capitalPartnershipType = capitalize(partnershipType);

  if (isPartnershipTypeCompany(partnershipType)) {
    // Company ID
    return `${capitalPartnershipType} ID`;
  }

  // Vendor company ID
  return `${capitalPartnershipType} company ID`;
};

/**
 * Assemble a pair of DetailsList items for a ledger-connected partnership. The first item is the "Vendor/Customer
 * Profile", which is what the partnership is named on the ledger; it is linked to the ledger if possible. The second
 * item is the "Vendor/Customer Company ID", which is the unique ID for the partnership on the ledger.
 * @function
 * @see assembleLedgerDetailsListItems
 * @param {Partnership} partnership
 * @param {PartnershipTypes} partnershipType
 * @returns {Object[]} - Tuple containing two items for DetailsList
 */
export const createLedgerDetailItemsPair = (partnership, partnershipType) => {
  // partnershipType = 'vendor'
  // capitalPartnershipType = 'Vendor';
  const capitalPartnershipType = capitalize(partnershipType);
  const ledgerName = getPartnershipTypeLedgerName(partnership, partnershipType);
  const ledgerLink = getPartnershipTypeLedgerLink(partnership, partnershipType);
  const ref = getPartnershipTypeLedgerRef(partnership, partnershipType);

  return [
    {
      component: ledgerLink ? LinkNewPage : undefined,
      // vendorProfile
      key: `${partnershipType}Profile`,
      // Vendor Profile
      label: `${capitalPartnershipType} profile`,
      value: `${ledgerName}${ledgerLink ? ' ↗' : ''}`,
      data: {
        children: `${ledgerName}${ledgerLink ? ' ↗' : ''}`,
        className: ledgerLink ? 'font-color--primary_force' : undefined,
        href: ledgerLink,
      },
      target: '_blank',
    },
    {
      // vendorCompanyId
      key: `${partnershipType}CompanyId`,
      // Vendor Company ID
      label: getCompanyIdLabelFromPartnershipType(partnershipType),
      value: ref,
    },
  ];
};

/**
 * Display customer or vendor list items for a ledger-connected partnership when the ledger allows the partnership to be
 * BOTH a customer and a vendor. If the partnership is a customer and vendor, use generic language.
 * @function
 * @param {Partnership} partnership
 * @returns {Object[]|[]}
 */
export const getLedgerDetailsListItemsForContactTypeBoth = (partnership) => {
  const isCustomer = isPartnershipCustomer(partnership) && isPartnershipCustomerMatched(partnership);
  const isVendor = isPartnershipVendor(partnership) && isPartnershipVendorMatched(partnership);

  if (isCustomer && isVendor) {
    return createLedgerDetailItemsPair(partnership, PartnershipTypes.COMPANY);
  }

  if (isCustomer) {
    return createLedgerDetailItemsPair(partnership, PartnershipTypes.CUSTOMER);
  }

  if (isVendor) {
    return createLedgerDetailItemsPair(partnership, PartnershipTypes.VENDOR);
  }

  return [];
};

/**
 * Display customer and/or vendor list items for a ledger-connected partnership when the ledger allows the
 * partnership to be ONLY a customer OR a vendor.
 * @function
 * @param {Partnership} partnership
 * @returns {Object[]|[]}
 */
export const getLedgerDetailsListItemsForContactTypeSingular = (partnership) => {
  const items = [];

  if (isPartnershipCustomerMatched(partnership)) {
    items.push(...createLedgerDetailItemsPair(partnership, PartnershipTypes.CUSTOMER));
  }

  if (isPartnershipVendorMatched(partnership)) {
    items.push(...createLedgerDetailItemsPair(partnership, PartnershipTypes.VENDOR));
  }

  return items;
};

/**
 * Assemble DetailsList items for a ledger-connected partnership. Depending the on ledger settings, a partnership may
 * be both a customer and a vendor. Only show details for matched partnerships.
 * @function
 * @see CompanyLedgerInfoCard
 * @param {LedgerSettings} ledgerSettings
 * @param {Partnership} partnership
 * @returns {[]|Object[]}
 */
export const assembleLedgerDetailsListItems = (ledgerSettings, partnership) => {
  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    return getLedgerDetailsListItemsForContactTypeBoth(partnership);
  }

  return getLedgerDetailsListItemsForContactTypeSingular(partnership);
};

/**
 * Given component props, type settings, and additional (optional) class config,
 * gets the result of classNames() for Text components.
 * @param {ComponentProps} props
 * @param {TextSize} [size=300]
 * @param {TextWeight} [weight=300]
 * @param {Object.<string, *>} [otherClasses={}]
 * @param {TextLineHeight} [lineHeight='']
 * @return {string}
 */
export const getClassNamesForTypography = (
  className,
  size = TextSize.LEVEL_300,
  weight = TextWeight.LEVEL_300,
  otherClasses = {},
  lineHeight = TextLineHeight.UNSET,
) =>
  clsx(className, otherClasses, {
    'base-line-height': lineHeight === TextLineHeight.BASE,
    'paragraph-line-height': lineHeight === TextLineHeight.PARAGRAPH,
    'line-height--0': lineHeight === TextLineHeight.ZERO,
    'line-height--sm': lineHeight === TextLineHeight.SMALL,
    'line-height--xm': lineHeight === TextLineHeight.EXTRA_MEDIUM,
    'line-height--m': lineHeight === TextLineHeight.MEDIUM,
    'line-height--l': lineHeight === TextLineHeight.LARGE,
    'line-height--xl': lineHeight === TextLineHeight.EXTRA_LARGE,
    'line-height--xxl': lineHeight === TextLineHeight.EXTRA_EXTRA_LARGE,
    'line-height--xxxl': lineHeight === TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE,
    'line-height--28': lineHeight === TextLineHeight.TWENTY_EIGHT,
    'line-height--40': lineHeight === TextLineHeight.FORTY,

    'font-size--xs': size === TextSize.LEVEL_100,
    'font-size--sm': size === TextSize.LEVEL_200,
    'font-size--regular': size === TextSize.LEVEL_300,
    'font-size--m': size === TextSize.LEVEL_400,
    'font-size--18': size === TextSize.LEVEL_450,
    'font-size--32': size === TextSize.LEVEL_32,
    'font-size--m-l': size === TextSize.LEVEL_475,
    'font-size--l': size === TextSize.LEVEL_500,
    'font-size--larger': size === TextSize.LEVEL_600,

    thin: weight === TextWeight.LEVEL_100,
    light: weight === TextWeight.LEVEL_200,
    regular: weight === TextWeight.LEVEL_300,
    semibold: weight === TextWeight.LEVEL_400,
    bold: weight === TextWeight.LEVEL_500,
    'extra-bold': weight === TextWeight.LEVEL_600,
  });

/**
 * Curried function stores the map of paths and text metadata for the tabs.
 * Pass the path to the returned function to generate the tab metadata.
 * @function
 * @see {uiHelpers.getCompanyNavTabs|Example usage}
 * @param {Object} paths
 * @param {Object} textLookup
 * @returns {function}
 */
export const generateNavTab =
  (paths, textLookup) =>
  (path, overrides = {}) => ({
    path: paths[path],
    text: textLookup[path],
    ...overrides,
  });

export const getCompanyNavTabs = ({ currentPath, hasTaxViewPermission, pathCompany, partnership }) => {
  const isCurrentPathCompanyPath = currentPath === pathCompany;

  const paths = joinPathsWithRouteConstants(pathCompany, SELECTED_COMPANY_TABS);
  const tabGenerator = generateNavTab(paths, SELECTED_COMPANY_TABS_TEXT);
  const isVendor = isPartnershipVendor(partnership);

  const navTabs = [
    tabGenerator(SELECTED_COMPANY_TABS.ABOUT, {
      isSelected: isCurrentPathCompanyPath || currentPath.startsWith(paths[SELECTED_COMPANY_TABS.ABOUT]),
    }),
    tabGenerator(SELECTED_COMPANY_TABS.VENDOR_INFO),
    tabGenerator(SELECTED_COMPANY_TABS.CUSTOMER_INFO),
    tabGenerator(SELECTED_COMPANY_TABS.PAYMENTS, {
      text: capitalize(getPaymentTypeForPartnershipTypeText(partnership)),
    }),
  ];

  if (hasTaxViewPermission && isVendor) {
    navTabs.push(tabGenerator(SELECTED_COMPANY_TABS.TAX_DOCS));
  }

  if (isVendor) {
    navTabs.push(
      tabGenerator(SELECTED_COMPANY_TABS.VENDOR_COMPLIANCE_CHECKS, {
        hasError: partnership.riskSummary === riskSummary.Enum.cant_validate,
      }),
    );
  }

  return navTabs;
};

export const getEmptyStateImageSrc = (filter, requiresApproval, noFiltersOrSearchApplied) => {
  if (isFilterPoDiscrepancy(filter)) {
    return undefined;
  }

  if (isFilterAll(filter) && noFiltersOrSearchApplied) {
    return images.TABLE_CREATE_ITEM;
  }

  if (isFilterNeedsApproval(filter) && !requiresApproval) {
    return images.TABLE_EMPTY_APPROVALS_STATE;
  }

  return images.TABLE_EMPTY_STATE_WITHOUT_BORDERS;
};

export const getRTBIconNameForLedgerType = (ledgerAppType) => {
  switch (ledgerAppType) {
    case LedgerApplicationTypes.QBO:
      return RTBIconNames.QBO;
    case LedgerApplicationTypes.XERO:
      return RTBIconNames.XERO;
    default:
      return undefined;
  }
};

/**
 * Returns a function that can be called to show error UI in the current format.
 * @param {string} message - Message to display
 * @param {?{ id?: string, duration?: number }} options
 * @returns {function(): *}
 */
export const getShowErrorUI =
  (message, options = {}) =>
  // return a function that can be called later...
  // we can test that this returns something callable, without
  // having to inadvertently/somehow test the actual library implemented here
  () =>
    toaster.danger(
      <div className="contents" data-error-type={options?.dataErrorType}>
        {message}
      </div>,
      options,
    );

/**
 * Returns a function that can be called to show info UI in the current format.
 * @param {string} message - Message to display
 * @param {?{ id?: string, duration?: number }} options
 * @returns {function(): *}
 */
export const getShowInfoUI =
  (message, options = {}) =>
  // return a function that can be called later...
  // we can test that this returns something callable, without
  // having to inadvertently/somehow test the actual library implemented here
  () =>
    toaster.notify(message, options);

/**
 * Returns a function that can be called to show success UI in the current format.
 * @param {string} message - Message to display
 * @param {?{ id?: string, duration?: number }} options
 * @returns {function(): *}
 */
export const getShowSuccessUI =
  (message, options = {}) =>
  // return a function that can be called later...
  // we can test that this returns something callable, without
  // having to inadvertently/somehow test the actual library implemented here
  () =>
    toaster.success(
      <div data-fullstory={options?.dataFullStory} style={{ display: 'contents' }}>
        {message}
      </div>,
      options,
    );

/**
 * Returns a function that can be called to show warning UI in the current format.
 * @param {string} message - Message to display
 * @param {?{ id?: string, duration?: number }} options
 * @returns {function(): *}
 */
export const getShowWarningUI =
  (message, options = {}) =>
  // return a function that can be called later...
  // we can test that this returns something callable, without
  // having to inadvertently/somehow test the actual library implemented here
  () =>
    toaster.warning(message, options);

export const getIntentColor = (intent) => {
  switch (intent) {
    case Intent.DANGER:
      return colors.colorRedBoldHex;

    case Intent.INFO:
      return colors.colorBluePrimaryHex;

    case Intent.NEUTRAL:
      return colors.colorMainJordan;

    case Intent.SCHEDULE:
      return colors.colorMainPurpleHex;

    case Intent.SUCCESS:
      return colors.colorMainGreenHex;

    case Intent.WARNING:
      return colors.colorYellowMediumHex;

    default:
      return colors.colorDarkJordanHex;
  }
};

/**
 * Returns the background color for each Intent
 * @param {string} intent - Intent
 * @returns {string}
 */
export const getIntentBackgroundColor = (intent) => {
  switch (intent) {
    case Intent.DANGER:
      return colors.colorRedLightHex;

    case Intent.INFO:
      return colors.colorBlueLightHex;

    case Intent.WARNING:
      return colors.colorYellowLightHex;

    default:
      return null;
  }
};

/**
 * Returns the icon for each Intent
 * @param {string} intent - Intent
 * @returns {string}
 */
export const getIntentIconName = (intent) => {
  switch (intent) {
    case Intent.DANGER:
      return IconNames.ERROR;

    case Intent.INFO:
      return IconNames.INFO_SIGN;

    case Intent.SCHEDULE:
      return IconNames.CALENDAR;

    case Intent.SUCCESS:
      return IconNames.TICK_CIRCLE;

    case Intent.WARNING:
      return IconNames.WARNING_SIGN;

    case Intent.NEUTRAL:
      return IconNames.NOTIFICATIONS;

    default:
      return null;
  }
};

// =======================
// Bank account view sub types
// =======================
export const isBankAccountViewSubTypeExternal = (viewSubType) => viewSubType === BankAccountViewSubType.EXTERNAL;

// =======================
// Bank account view types
// =======================
export const isBankAccountViewTypeAddress = (viewType) => viewType === BankAccountViewType.ADDRESS;
export const isBankAccountViewTypeFull = (viewType) => viewType === BankAccountViewType.FULL;
export const isBankAccountViewTypeNewPartner = (viewType) => viewType === BankAccountViewType.NEW_PARTNER;
export const isBankAccountViewTypeSimpleVerify = (viewType) => viewType === BankAccountViewType.SIMPLE_VERIFY;
export const isBankAccountViewTypePartnerAch = (viewType) => viewType === BankAccountViewType.PARTNER_ACH;
export const isBankAccountViewTypePartnerAddress = (viewType) => viewType === BankAccountViewType.PARTNER_ADDRESS;
export const isBankAccountViewTypeThread = (viewType) => viewType === BankAccountViewType.THREAD;
export const isBankAccountViewTypeThreadPartnerAch = (viewType) => viewType === BankAccountViewType.THREAD_PARTNER_ACH;
export const isBankAccountViewTypeThreadPartnerAddress = (viewType) =>
  viewType === BankAccountViewType.THREAD_PARTNER_ADDRESS;

export const isBankAccountViewTypeThreadAny = (viewType) =>
  isBankAccountViewTypeThread(viewType) ||
  isBankAccountViewTypeThreadPartnerAch(viewType) ||
  isBankAccountViewTypeThreadPartnerAddress(viewType);

export const isBankAccountViewTypePartnerAddressAny = (viewType) =>
  isBankAccountViewTypePartnerAddress(viewType) || isBankAccountViewTypeThreadPartnerAddress(viewType);

export const isBankAccountViewTypePartnerAchAny = (viewType) =>
  isBankAccountViewTypePartnerAch(viewType) || isBankAccountViewTypeThreadPartnerAch(viewType);

export const isBankAccountViewTypePartnerAny = (viewType) =>
  isBankAccountViewTypePartnerAddressAny(viewType) || isBankAccountViewTypePartnerAchAny(viewType);

export const isBankAccountViewTypeAddressAny = (viewType) =>
  isBankAccountViewTypePartnerAddressAny(viewType) || isBankAccountViewTypeAddress(viewType);

export const getPartnerBankAccountViewType = (isACH) =>
  isACH ? BankAccountViewType.PARTNER_ACH : BankAccountViewType.PARTNER_ADDRESS;

export const areCreationDetailsDisplayedForBankAccountViewType = (viewType) =>
  isBankAccountViewTypeFull(viewType) || isBankAccountViewTypeThread(viewType);

/**
 * Utility method to help determine whether to show the VAN tooltip on the funding account component
 * @param {BankAccountViewType} viewType
 * @return {boolean}
 */
export const shouldShowVirtualAccountNumberTooltip = (viewType) =>
  isBankAccountViewTypePartnerAch(viewType) || isBankAccountViewTypeThreadPartnerAch(viewType);

export const areHintsDisplayedForBankAccountViewType = (viewType) => !isBankAccountViewTypeThreadAny(viewType);

export const getPartnerBankAccountViewTypeForPaymentMethod = (paymentDeliveryMethod, newPartnerData) => {
  if (newPartnerData) {
    return BankAccountViewType.NEW_PARTNER;
  }

  switch (paymentDeliveryMethod) {
    case PaymentDeliveryMethodType.ACH:
      return BankAccountViewType.PARTNER_ACH;

    case PaymentDeliveryMethodType.CHECK:
      return BankAccountViewType.PARTNER_ADDRESS;

    default:
      return undefined;
  }
};

// =======================
// ContactsInteractionList view types
// =======================
/**
 * The ContactsInteractionList renders differently based on the viewType passed to the component. This helper checks if
 * the ContactsInteractionListViewType is FULL.
 * @function
 * @param {ContactsInteractionListViewType} viewType
 * @returns {boolean} - true if FULL, false otherwise
 */
export const isContactsInteractionListViewTypeFull = (viewType) => ContactsInteractionListViewType.FULL === viewType;

/**
 * The ContactsInteractionList renders differently based on the viewType passed to the component. This helper checks if
 * the ContactsInteractionListViewType is THREAD.
 * @function
 * @param {string} viewType
 * @returns {boolean} - true if THREAD, false otherwise
 */
export const isContactsInteractionListViewTypeThread = (viewType) =>
  ContactsInteractionListViewType.THREAD === viewType;

// =======================
// FlairType helpers
// =======================
export const isFlairTypePrimary = (flairType) => flairType === FlairTagType.PRIMARY;
export const isFlairTypePreferred = (flairType) => flairType === FlairTagType.PREFERRED;
export const isFlairTypePrimaryAndPreferred = (flairType) => flairType === FlairTagType.PRIMARY_AND_PREFERRED;
export const isFlairTypeManageCompany = (flairType) => flairType === FlairTagType.MANAGE_COMPANY;
export const isFlairTypeManageItems = (flairType) => flairType === FlairTagType.MANAGE_ITEMS;

/**
 * Gets the icon size to use for a flair tag, based on the type
 * and whether or not it has display text.
 * (The larger size is only returned for MANAGE_x types, and only
 * when there is no display text.)
 * @param {FlairTagType} flairType
 * @param {boolean} hasText
 * @return {number}
 */
export const getIconSizeForFlair = (flairType, hasText) => {
  if (isFlairTypeManageCompany(flairType) || isFlairTypeManageItems(flairType)) {
    if (!hasText) {
      return sizes.iconSizes.LARGE;
    }
  }

  return sizes.iconSizes.LARGE;
};

/**
 * Given a partner member object, returns the tag type to use for their multi-select tag.
 * @param {PartnershipMember} member
 * @return {TagType}
 */
export const getMultiSelectTagTypeForMember = (member) =>
  isPartnershipMemberEmailStatusBounced(member) ? TagType.ERROR : TagType.SUCCESS;

// =======================
// Tag type helpers
// =======================
export const isTagTypeArchived = (tagType) => tagType === TagType.ARCHIVED;
export const isTagTypeVariable = (tagType) => tagType === TagType.VARIABLE;
export const isLabelColorDefault = (type) => [TagType.ARCHIVED, TagType.META].includes(type);

// =======================
// Tag shape helpers
// =======================
export const isTagShapeRound = (tagShape) => tagShape === TagShape.ROUND;
export const isTagShapeSquare = (shape) => shape === TagShape.SQUARE;

// =======================
// Status display
// =======================
export const isAsyncValidateStatusLoading = (status) => status === AsyncValidateStatus.LOADING;
export const isAsyncValidateStatusOK = (status) => status === AsyncValidateStatus.OK;
export const isAsyncValidateStatusWarning = (status) => status === AsyncValidateStatus.WARNING;
export const isAsyncValidateStatusError = (status) => status === AsyncValidateStatus.ERROR;

/**
 * Get the icon type (IconNames.WHATEVER) for the AsyncInputStatusIcon based on the status derived from meta.
 * @function
 * @see {getAsyncValidateStatusFromProps}
 * @param {string|null} status
 * @returns {string|null}
 */
export const getAsyncValidateStatusIcon = (status) => {
  switch (status) {
    case AsyncValidateStatus.OK:
      return IconNames.TICK_CIRCLE;

    case AsyncValidateStatus.WARNING:
      return IconNames.WARNING_SIGN;

    case AsyncValidateStatus.ERROR:
      return IconNames.ERROR;

    default:
      return null;
  }
};

/**
 * Get the icon color for the AsyncInputStatusIcon based on the status derived from meta.
 * @function
 * @see {getAsyncValidateStatusFromProps}
 * @param {string|null} status
 * @returns {string|null}
 */
export const getAsyncValidateStatusIconColor = (status) => {
  switch (status) {
    case AsyncValidateStatus.OK:
      return colors.colorAquaBoldHex;

    case AsyncValidateStatus.LOADING:
      return colors.colorDarkSilverHex;

    case AsyncValidateStatus.WARNING:
      return colors.colorDarkYellowHex;

    case AsyncValidateStatus.ERROR:
      return colors.colorRedBoldHex;

    default:
      return null;
  }
};

/**
 * Using the props passed to the input from redux-form and the local component state hasAsyncValidated, determine which
 * state (status) to show the input in.
 * @function
 * @param {Object} options
 * @param {boolean} options.hasAsyncValidated - Component useState boolean tracking if async validation has occurred
 * @param {boolean} options.isWarn
 * @param {ReduxFormMeta} options.meta
 * @returns {string|null}
 */
export const getAsyncValidateStatusFromProps = ({ hasAsyncValidated, isWarn, meta }) => {
  const { active, asyncValidating, error, touched, warning } = meta;

  // While the user is typing, remove the status icons
  // Also, error state takes precedence over warning state
  if (active || error) {
    return null;
  }

  // Show the IsLoadingInline component while async validating
  if (asyncValidating) {
    return AsyncValidateStatus.LOADING;
  }

  // If the field is invalid with a error after async validation, show the error icon
  if (touched && isMembershipDuplicated(warning?.warningType)) {
    return AsyncValidateStatus.ERROR;
  }

  // If the field is invalid with a warning after async validation, show the warning icon
  if (touched && isWarn) {
    return AsyncValidateStatus.WARNING;
  }

  // If the field has passed async validation, show the ok icon.
  if (touched && hasAsyncValidated) {
    return AsyncValidateStatus.OK;
  }

  // E.g.
  // The field has a synchronous validation error (required, specific type checker)
  // The field is being filled for the first time
  // The field is being fixed after an error
  return null;
};

/**
 * Using the status derived by getAsyncValidateStatusFromProps, create props for the AsyncInputStatusIcon.
 * @see {getAsyncValidateStatusFromProps}
 * @function
 * @param {string|null} status
 * @returns {{iconColor: string, iconType: *, showIcon: boolean}}
 */
export const getAsyncValidateIconProps = (status) => ({
  iconColor: getAsyncValidateStatusIconColor(status),
  iconType: getAsyncValidateStatusIcon(status),
  showIcon:
    isAsyncValidateStatusOK(status) || isAsyncValidateStatusWarning(status) || isAsyncValidateStatusError(status),
});

/**
 * Helper method to determine if we should show the match company hint
 * @param partnership
 * @param partnershipType
 * @param hasLedgerIntegration
 * @param ledgerSettings
 * @return {boolean}
 */
export const shouldShowMatchCompanyHint = ({ partnership, partnershipType, hasLedgerIntegration, ledgerSettings }) => {
  if (!partnership) {
    return false;
  }

  // No need to show for a new company that's not ledger only
  if (!isSelectedPartnerTypeExisting(partnership.type) && !isPartnershipLedgerOnly(partnership)) {
    return false;
  }

  // Only applicable if on ledger
  if (!hasLedgerIntegration) {
    return false;
  }

  // On ledgers that support both (Xero) we need just one to be present
  if (isLedgerSettingsContactTypeBoth(ledgerSettings)) {
    return !(partnership.customerRef || partnership.vendorRef);
  }

  if (isPartnershipTypeVendor(partnershipType)) {
    return !partnership.vendorRef;
  }

  return !partnership.customerRef;
};

// =======================
// PartnershipMember/ItemMember UI helpers
// =======================

/**
 * Tests a company to see if it is personal type and already has one contact. If this is the case, the UI should not
 * allow another contact to be added in many places in the app.
 * @param {Company} company
 * @param {PartnershipMember[]} contacts
 * @returns {boolean}
 */
export const doesPersonalCompanyAlreadyHaveOneContact = (company, contacts) => {
  if (isCurrentCompanyTypeBusiness(company)) {
    return false;
  }

  // already has one contact
  return !!contacts?.length;
};

/**
 * The UI to add contacts should be disabled if:
 * 1. partnership is self-managed
 * 2. partnership is for an individual and there's already one ItemMember
 * @function
 * @param {Company} company
 * @param {ItemMember[] | PartnershipMember[]} itemMembers
 * @param {Partnership} partnership
 * @returns {boolean} True if we should disable the button
 */
export const getShouldDisableAddContactUi = (company, itemMembers, partnership) => {
  if (isPartnershipRoutableToRoutable(partnership)) {
    return true;
  }

  if (isCurrentCompanyTypePersonal(company)) {
    /**
     * If the company type is personal, we only allow you to have one ItemMember to the contact. The UI should restrict
     * to one item member, but just in case it somehow goes above 1, isGreaterThanZero is a better safeguard than === 1.
     */
    return itemMembers?.length > 0;
  }

  return false;
};

/**
 * TagMultiSelect normally allows the display of multiple contacts. However, if we want to only allow selection of
 * on contact at a time, the easiest way is to use this function as a Redux-Form normalizer.
 * @function
 * @param {ItemMemberAsPartnershipMember[]|null} listOfContacts
 * @returns {ItemMemberAsPartnershipMember}
 */
export const keepOnlyOneContactInTagMultiSelect = (listOfContacts) => {
  if (Array.isArray(listOfContacts)) {
    if (listOfContacts.length === 0) {
      return null;
    }
    // The user has just selected a second contact, the list of values in the normalizer is
    // [{ firstContactSelection }, { secondContactSelection }]
    const replaceContactSelection = listOfContacts.length === 2;
    // We only want to keep the { secondContactSelection }
    // Otherwise this is the field's first selection and we should keep it
    return replaceContactSelection ? listOfContacts[1] : listOfContacts[0];
  }

  return listOfContacts;
};

/**
 * Convert a spacing constant string to a number.
 * @function
 * @example
 * getNumberFromSpacingConstant('4px') => 4
 * @param {spacing} spacingConstant
 * @returns {number}
 */
export const getNumberFromSpacingConstant = (spacingConstant) => Number(spacingConstant.replace('px', ''));

/**
 * The DeactivateTeamMemberModal takes a type which displays the correct modal. Determine the type here.
 * @param {OptionsArg} options
 * @param {Company.controller} options.currentCompanyController
 * @param {Membership} options.member
 * @returns {DeactivateTeamMemberModalType}
 */
export const getDeactivateTeamMemberModalType = ({ currentCompanyController, member }) => {
  if (isMembershipCompanyController(member, currentCompanyController)) {
    return DeactivateTeamMemberModalType.BUSINESS_REPRESENTATIVE;
  }

  return DeactivateTeamMemberModalType.TEAM_MEMBER;
};

/**
 * Check for equality between the passed type and the type we would expect for a business representative.
 * @param {*} type
 * @returns {boolean}
 */
export const isDeactivateTeamMemberModalTypeBusinessRepresentative = (type) =>
  type === DeactivateTeamMemberModalType.BUSINESS_REPRESENTATIVE;

/**
 * Returns subtitle string for the deactivate team member modal
 * @param {Membership} membership
 * @return {string}
 */
export const getDeactivateTeamMemberModalSubtitle = (membership) => {
  const nameOrEmail = getMembershipNameOrEmail(membership);

  return `Deactivate ${nameOrEmail}?`;
};

/**
 * Get the CTA button for the DeactivateTeamMemberModal confirm button, which differs depending on whether or not
 * we're asking the user to confirm submission.
 * @param {StringMaybe} [errorText]
 * @returns {string}
 */
export const getDeactivateTeamMemberModalConfirmButtonText = (errorText) =>
  errorText ? 'Confirm deactivation' : 'Deactivate';

/*
 * Predicate function that determines visibility of progress bar
 * @param {Object} step
 * @returns {Boolean}
 */
export const shouldShowStepperProgressBar = (step) => step.display === ExternalProgressBarDisplayTypes.SHOW;

/**
 * Returns image URL for update payment illustration based on update payment flow
 * state
 * @param {string} updatePaymentState
 * @return {string}
 */
export const getUpdatePaymentGraphicUrl = (updatePaymentState) =>
  `${EXTERNAL_IMAGES_PATH_PREFIX}/update-payment--${updatePaymentState}.svg`;

/**
 * Returns image URL for accept partnership illustration based on accept partnership flow
 * state
 * @param {string} acceptPartnershipState
 * @return {string}
 */
export const getAcceptPartnershipGraphicUrl = (acceptPartnershipState) =>
  `${EXTERNAL_IMAGES_PATH_PREFIX}/accept-partnership--${acceptPartnershipState}.svg`;

/**
 * Returns pluralized string of Payment or invoice item kind text
 * state
 * @param {string} itemKind
 * @return {string}
 */
export const getPluralPaymentOrInvoiceByItemKindText = (itemKind) => {
  const itemKindText = PaymentOrInvoiceByItemKindText[itemKind];
  const capitalizedWords = _startCase(itemKindText);
  // 2 represents how many words exist
  return pluralize(capitalizedWords, 2);
};
