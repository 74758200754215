import { useFundRoutes } from '@routable/fund-routes';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { createPartnershipFormFields } from 'constants/formFields';
import { useFieldValue } from 'hooks';
import {} from 'interfaces/company';
import {} from 'interfaces/global';
import {} from 'interfaces/integrations';
import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';
import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import {} from './PartnerCurrencyField.types';
export const PartnerCurrencyField = ({ countryCode, className, formName, ...rest }) => {
    const dispatch = useDispatch();
    const { data, isFetching, isFetched } = useFundRoutes('receiver_currency', countryCode);
    const companyIntegrationSettings = useSelector(currentCompanySettingsIntegrationSelector);
    const selectedCurrencyCode = useFieldValue(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE);
    const ledger = useSelector(ledgerIntegrationSelector);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (!data?.data) {
            setOptions([]);
            return;
        }
        const arr = [];
        data.data.forEach((currency) => {
            arr.push({ id: currency, value: currency, text: currency });
        });
        setOptions(arr);
        if (!companyIntegrationSettings.enableBaseCurrencyMode && arr.length === 1) {
            dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, arr[0].id));
        }
    }, [dispatch, formName, data?.data]);
    useEffect(() => {
        if (companyIntegrationSettings.enableBaseCurrencyMode && !selectedCurrencyCode) {
            dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, ledger.baseCurrencyCode));
        }
    }, [
        companyIntegrationSettings.enableBaseCurrencyMode,
        dispatch,
        formName,
        ledger.baseCurrencyCode,
        selectedCurrencyCode,
    ]);
    return (React.createElement("div", { className: clsx('form-control', className) },
        React.createElement(VendorReceivingCurrency, { className: "w-full", fieldLabel: `Assigned currency in ${ledger.name}`, fieldName: createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, isFetching: isFetching && !isFetched, isLocked: companyIntegrationSettings.enableBaseCurrencyMode || !countryCode || options.length <= 1, options: options, tooltipText: undefined, ...rest })));
};
