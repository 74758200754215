import { queryClient } from '@routable/shared';
import { taxTools } from '@routable/taxes';
import { all, call, put, spawn, take } from 'redux-saga/effects';

import { fetchSinglePartnershipRequest } from 'actions/partnership';
import {
  createPartnershipMemberRoutine,
  fetchPartnershipMembersRoutine,
  fetchSinglePartnershipMemberRoutine,
  updatePartnershipMemberRoutine,
} from 'actions/routines/partnershipMember';
import { contactSidePanelClose } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';

import { SuccessIndicatorMessages } from 'constants/ui';

import { partnershipMemberSubmitTransformers } from 'data/submitTransformers';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { isFormWithNestedCreatePartnershipMemberForm } from 'helpers/forms';
import { createSaga } from 'helpers/saga';

import * as fx from 'sagas/shared/sideEffects';
import { createPartnershipMember } from 'sagas/shared/tasks';

import * as api from './api';

/**
 * Fetch partnershipMembers for a single partnership.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchPartnershipMembers({ payload }) {
  const { partnershipId, options: apiOptions } = payload;

  const options = {
    apiParams: [partnershipId, apiOptions],
    onCaughtError: parseCaughtError,
    onParseError: parseErrorResponse,
  };

  yield call(createSaga, api.fetchPartnershipMembers, fetchPartnershipMembersRoutine, options);
}

/**
 * Fetch a single partnershipMember from a single partnership.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchSinglePartnershipMember({ payload }) {
  const { partnershipId, partnershipMemberId } = payload;

  const options = {
    apiParams: [partnershipId, partnershipMemberId],
    onCaughtError: parseCaughtError,
    onParseError: parseErrorResponse,
  };

  yield call(createSaga, api.fetchSinglePartnershipMember, fetchSinglePartnershipMemberRoutine, options);
}

/**
 * Update a partnershipMember.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* updatePartnershipMember({ payload }) {
  let submitErrors = {};

  try {
    const { form = {}, props = {}, values = {} } = payload;

    const { initialValues, sidePanel } = props;
    const { partnershipId, partnershipMemberId } = values.meta;
    // when this form is submitted, it could potentially be from e.g. the create partnership flow,
    // and in that case, we can't ACTUALLY submit the form, because there's nothing to submit
    // to (the partnership does not yet exist). in this case, we're essentially submitting
    // to the parent form, and exiting.
    if (isFormWithNestedCreatePartnershipMemberForm(sidePanel?.form)) {
      const nestedFormActions = fx.getActionsForNestedPartnershipMembersFormSubmission({
        contactEmail: initialValues.email,
        form,
        isUpdate: true,
        successMessage: SuccessIndicatorMessages.UPDATE_CONTACT,
        values,
      });
      yield all(nestedFormActions.map((act) => put(act)));
      return;
    }

    // otherwise, we're updating an existing partnership, and want to submit this standalone
    const submitPayload = partnershipMemberSubmitTransformers.partnershipMemberForUpdate(values, partnershipMemberId);

    const response = yield call(api.updatePartnershipMember, partnershipId, partnershipMemberId, submitPayload);

    if (response.ok) {
      yield all([
        put(updatePartnershipMemberRoutine.success(response.data)),
        put(fetchSinglePartnershipRequest(partnershipId)),
        put(contactSidePanelClose()),
        put(showSuccessUi(SuccessIndicatorMessages.UPDATE_CONTACT)),
      ]);

      // Since creating/updating partnership member can affect tax tools data
      // (e.g. contact status can become invalid or missing ), we want to
      // invalidate query cache on successful request
      queryClient.invalidateQueries({ queryKey: [taxTools] });
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(updatePartnershipMemberRoutine.failure, submitErrors));
}

/**
 * Listens for redux actions related to partnershipMembers.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      createPartnershipMemberRoutine.TRIGGER,
      fetchPartnershipMembersRoutine.TRIGGER,
      fetchSinglePartnershipMemberRoutine.TRIGGER,
      updatePartnershipMemberRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case createPartnershipMemberRoutine.TRIGGER:
        yield spawn(createPartnershipMember, action);
        break;

      case fetchPartnershipMembersRoutine.TRIGGER:
        yield spawn(fetchPartnershipMembers, action);
        break;

      case fetchSinglePartnershipMemberRoutine.TRIGGER:
        yield spawn(fetchSinglePartnershipMember, action);
        break;

      case updatePartnershipMemberRoutine.TRIGGER:
        yield spawn(updatePartnershipMember, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root partnershipMember saga.
 * @return {IterableIterator<*>}
 */
export default function* partnershipMember() {
  yield watch();
}
