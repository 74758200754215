import clsx from 'clsx';
import React from 'react';
import useEscapeKeyPressed from 'hooks/useEscapeKeyPressed';
import { SidePanelNav } from './components';
import './SidePanel.scss';
const SidePanel = ({ className, children, headerText, isOpen, onClose }) => {
    useEscapeKeyPressed(onClose);
    return (React.createElement("div", { "aria-hidden": !isOpen, className: clsx('slide-main', className) },
        React.createElement(SidePanelNav, { headerText: headerText, onClose: onClose }),
        React.createElement("div", { className: "slide-body" }, children)));
};
export default SidePanel;
