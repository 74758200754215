import { combineReducers } from 'redux';

import allIdsReducer from './allIdsReducer';
import byIdReducer from './byIdReducer';
import hasFetchedReducer from './hasFetchedReducer';

const root = combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  hasFetched: hasFetchedReducer,
});

export default root;
