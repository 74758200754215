import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Instruction } from 'components/instruction';

const ExternalPromptInstructions = ({ instructions, isAboveButton }) => (
  <div className={clsx('external-prompt--instructions', { 'is-above-button': isAboveButton })}>
    {instructions.map((i) => (
      <Instruction className="external" description={i.text} key={i.key} num={i.id} />
    ))}
  </div>
);

ExternalPromptInstructions.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isAboveButton: PropTypes.bool,
};

ExternalPromptInstructions.defaultProps = {
  isAboveButton: undefined,
};

export default ExternalPromptInstructions;
