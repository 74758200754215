import clsx from 'clsx';
import React from 'react';
import ReactModal from 'react-modal';
import { ZIndexLayers } from 'constants/ui';
import { getZIndex } from 'helpers/zIndex';
import { DashboardNotificationBar } from 'modules/dashboard/components/Dashboard/components';
import EscButton from '../escButton/EscButton';
import ModalBody from './components/body/ModalBody';
import ModalHeader from './components/header/ModalHeader';
import './Modal.scss';
ReactModal.setAppElement('body');
const FullPageModal = React.forwardRef(({ body, contentLabel = 'Modal', hasInlineFooter = false, header, modalClassname, modalState, onCloseModal, onRequestClose = onCloseModal, overlayClassName, overrideStyle = {}, showCloseButton = true, showHeader = true, testId, }, ref) => (React.createElement(ReactModal, { className: {
        afterOpen: clsx('FullPageModal__Content--after-open', modalClassname, {
            'FullPageModal__Content--no-header': !showHeader,
        }),
        base: 'FullPageModal__Content',
        beforeClose: 'FullPageModal__Content--before-close',
    }, closeTimeoutMS: 300, contentLabel: contentLabel, isOpen: modalState, onRequestClose: onRequestClose, overlayClassName: overlayClassName, ref: ref, style: {
        overlay: {
            zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY),
        },
        ...overrideStyle,
    }, ...(testId ? { data: { testid: testId } } : {}) },
    React.createElement(DashboardNotificationBar, null),
    React.createElement(ModalHeader, { show: showHeader },
        header,
        onCloseModal && showCloseButton && React.createElement(EscButton, { onClick: onCloseModal })),
    React.createElement(ModalBody, { hasInlineFooter: hasInlineFooter }, body))));
FullPageModal.displayName = 'FullPageModal';
export default FullPageModal;
