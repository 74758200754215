import clsx from 'clsx';
import React from 'react';
import { IconNames, TooltipMUIIcon } from 'components';
import { colors, sizes } from 'constants/styles';
const RequiredInputErrorTooltipIcon = ({ className }) => (React.createElement(TooltipMUIIcon, { icon: IconNames.WARNING_SIGN, iconProps: {
        className: clsx('not-modified', className),
        color: colors.colorRedBoldHex,
        size: sizes.iconSizes.XX_LARGE,
    }, title: "This is a required field." }));
export default RequiredInputErrorTooltipIcon;
