import { Button } from '@routable/gross-ds';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import React from 'react';
import {} from '@routable/framework';
const getCurrentPageValue = (pageSize, currentPage) => {
    return pageSize * currentPage;
};
const getPageRecordLabel = (total, pageSize, currentPage) => {
    const start = Math.max(getCurrentPageValue(pageSize, currentPage - 1), 0);
    const end = Math.min(getCurrentPageValue(pageSize, currentPage), total);
    return `${Math.min(end, start + 1)}-${end} of ${total.toLocaleString('en-US')}`;
};
export const TablePagination = ({ gotoPage, pagination }) => (React.createElement("div", { className: "flex" },
    React.createElement(Button, { className: "rounded-r-none", "data-testid": "table:back-button", disabled: !pagination.hasPrevPage, onPress: () => gotoPage('PREV'), size: "small", variant: "outline" },
        React.createElement(ChevronLeft, null)),
    React.createElement("div", { className: "flex items-center justify-center min-w-max text-xs text-grey-70 px-4 h-8 bg-white border-grey-20 border-t border-b" }, getPageRecordLabel(pagination.total, pagination.pageSize, pagination.page)),
    React.createElement(Button, { className: "rounded-l-none", "data-testid": "table:next-button", disabled: !pagination.hasNextPage, onPress: () => gotoPage('NEXT'), size: "small", variant: "outline" },
        React.createElement(ChevronRight, null))));
