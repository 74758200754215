import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table header row component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableHeaderRow = ({ children, className, ...rest }) => (
  <li {...rest} className={clsx('table-row--header', className)}>
    {children}
  </li>
);

InvoiceGeneratorTableHeaderRow.propTypes = {
  children: PropTypes.node,
};

InvoiceGeneratorTableHeaderRow.defaultProps = {
  children: undefined,
};

export default InvoiceGeneratorTableHeaderRow;
