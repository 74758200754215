import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';

/**
 * Renders a select field.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} props.name
 * @param {Function} props.onChange
 * @param {Function} props.onCreate
 * @param {Object[]} props.options
 * @param {Function} props.SelectComponent
 * @return {StatelessComponent}
 */
const PopoverSelectField = ({ className, input, onCreate, options, SelectComponent, ...rest }) => {
  const { name } = input;

  return (
    <SelectComponent
      className={clsx('select-alt popover-select--select', className)}
      idPrefix={name}
      input={input}
      labelClassName="select-alt--label"
      name={name}
      onCreate={onCreate}
      options={options}
      styles={{ indicatorsContainer: () => ({ display: 'none' }) }}
      {...rest}
    />
  );
};

PopoverSelectField.propTypes = {
  className: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  SelectComponent: PropTypes.elementType.isRequired,
};

PopoverSelectField.defaultProps = {
  className: undefined,
  onCreate: undefined,
  options: [],
};

export default PopoverSelectField;
