import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { EntityWidget } from 'components/entityWidget';

import { BankAccountViewSubType, BankAccountViewType } from 'constants/ui';

import { isExternalPathAny } from 'helpers/external';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import { Provider } from 'hoc/context';

import { BankAccountBody, BankAccountHeader } from 'modules/fundingAccount/bankAccount/components';
import { text } from 'modules/fundingAccount/bankAccount/constants';
import { IsLoadingComponent } from 'modules/isLoading/IsLoading';

import './BankAccount.scss';

/**
 * Component rendering Bank Account entity widget
 *
 * @param {ComponentProps} props
 * @param {Object} [props.bodyRowProps={}]
 * @param {string} [props.className]
 * @param {Node} [props.customHeader]
 * @param {Boolean} [displayBody]
 * @param {FundingAccount} [props.fundingAccount={}]
 * @param {FundingInfoAddress} [props.fundingInfoAddress={}]
 * @param {BankAccountData} [props.fundingInfoBankAccount={}]
 * @param {Boolean} [props.isCollapsible]
 * @param {Boolean} [props.isFetchingFundingAccounts]
 * @param {Boolean} [props.isSelectable]
 * @param {React.ReactNode} [props.menu]
 * @param {Function} [props.onHeaderClick]
 * @param {Partnership} [props.partnership]
 * @param {FundingAccount} [props.partnershipFundingAccount]
 * @param {Boolean} [props.startOpen]
 * @param {BankAccountViewType} [props.viewType=BankAccountViewType.FULL]
 * @returns {StatelessComponent}
 */
const BankAccount = ({
  bodyRowProps,
  className,
  customHeader,
  displayBody,
  fundingAccount,
  fundingInfoAddress,
  fundingInfoBankAccount,
  isCollapsible,
  isFetchingFundingAccounts,
  isSelectable,
  menu,
  onHeaderClick,
  partnership,
  partnershipFundingAccount,
  startOpen,
  viewType,
}) => {
  if (isFetchingFundingAccounts) {
    return <IsLoadingComponent text={text.loadingAccountText} />;
  }

  const contextValues = {
    bodyRowProps,
    fundingAccount,
    fundingInfoAddress,
    fundingInfoBankAccount,
    viewSubType: isExternalPathAny() ? BankAccountViewSubType.EXTERNAL : BankAccountViewSubType.DASHBOARD,
    viewType,
  };

  return (
    <Provider value={contextValues}>
      <EntityWidget
        body={
          displayBody && (
            <BankAccountBody
              bodyRowProps={bodyRowProps}
              fundingAccount={fundingAccount}
              fundingInfoBankAccount={fundingInfoBankAccount}
              viewType={viewType}
            />
          )
        }
        className={clsx('view-bank-account--widget', className, {
          selectable: isSelectable,
          'view-bank-account--no-body': !displayBody,
        })}
        header={
          customHeader || (
            <BankAccountHeader
              fundingAccount={fundingAccount}
              fundingInfoAddress={fundingInfoAddress}
              menu={menu}
              onClick={onHeaderClick}
              partnership={partnership}
              partnershipFundingAccount={partnershipFundingAccount}
              viewType={viewType}
            />
          )
        }
        isCollapsible={isCollapsible}
        startOpen={startOpen}
      />
    </Provider>
  );
};

BankAccount.propTypes = {
  bodyRowProps: PropTypes.shape(),
  className: PropTypes.string,
  customHeader: PropTypes.node,
  displayBody: PropTypes.bool,
  fundingAccount: PropTypes.shape({
    bank: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    ledgerRefPayable: PropTypes.string,
    ledgerRefReceivable: PropTypes.string,
    name: PropTypes.string,
  }),
  fundingInfoAddress: PropTypes.shape(),
  fundingInfoBankAccount: PropTypes.shape(),
  isCollapsible: PropTypes.bool,
  isFetchingFundingAccounts: PropTypes.bool,
  isSelectable: PropTypes.bool,
  menu: PropTypes.func,
  onHeaderClick: PropTypes.func,
  partnership: PropTypes.shape(),
  partnershipFundingAccount: PropTypes.shape(),
  startOpen: PropTypes.bool,
  viewType: oneOfValuesFromObject(BankAccountViewType),
};

BankAccount.defaultProps = {
  bodyRowProps: {},
  className: undefined,
  customHeader: undefined,
  displayBody: true,
  fundingAccount: {},
  fundingInfoAddress: {},
  fundingInfoBankAccount: {},
  isCollapsible: undefined,
  isFetchingFundingAccounts: undefined,
  isSelectable: undefined,
  menu: undefined,
  onHeaderClick: undefined,
  partnership: undefined,
  partnershipFundingAccount: undefined,
  startOpen: true,
  viewType: BankAccountViewType.FULL,
};

export default BankAccount;
