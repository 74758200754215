import clsx from 'clsx';
import React from 'react';
import withChatOnClick from 'hoc/withChatOnClick';
import {} from './ContactUs.types';
const ContactUs = ({ children = 'contact support', className, onClick, textAfter, textBefore, useChatBubble = true, }) => (React.createElement(React.Fragment, null,
    React.createElement("a", { className: clsx('primary enable-pointer-events', className), onClick: onClick, role: "presentation" },
        !!textBefore && `${textBefore} `,
        children,
        !!textAfter && ` ${textAfter}`),
    useChatBubble && (React.createElement("span", { "aria-label": "Chat", className: "margin-left--sm", role: "img" }, "\uD83D\uDCAC"))));
export default withChatOnClick(ContactUs);
