import { ItemFilterUrlParams } from 'enums/itemFilters';
import { isArray, hasLength } from 'helpers/utility';
import { FilterOperator } from 'interfaces/itemFilters';
export const getFilterParamsForBetween = (filter) => {
    const params = {};
    if (isArray(filter.value) && hasLength(filter.value)) {
        const [lowerVal, upperVal] = filter.value;
        return {
            [`${filter.urlParam}__gte`]: lowerVal,
            [`${filter.urlParam}__lte`]: upperVal,
        };
    }
    return params;
};
const commaSeparatedListCleanup = (value) => value
    .split(',')
    .map((str) => str.trim())
    .filter(Boolean)
    .join(',');
export const filterToUrlParams = (filter) => {
    let value = filter.value;
    if (filter.urlParam === ItemFilterUrlParams.FILTER_AMOUNT_DUE_URL_PARAM) {
        value = Array.isArray(value)
            ? value.map((innerValue) => innerValue.replaceAll(',', ''))
            : value.replaceAll(',', '');
    }
    switch (filter.selectedOperator) {
        case FilterOperator.GREATER_THAN_OR_EQUAL:
            return { [`${filter.urlParam}__gte`]: value };
        case FilterOperator.LESS_THAN_OR_EQUAL:
            return { [`${filter.urlParam}__lte`]: value };
        case FilterOperator.BETWEEN:
            return getFilterParamsForBetween({ ...filter, value });
        case FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST:
            return {
                [`${filter.urlParam}__iexact`]: commaSeparatedListCleanup(value),
            };
        case FilterOperator.CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST:
            return {
                [`${filter.urlParam}__icontains`]: commaSeparatedListCleanup(value),
            };
        case FilterOperator.STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST:
            return {
                [`${filter.urlParam}__istartswith`]: commaSeparatedListCleanup(value),
            };
        case FilterOperator.ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST:
            return {
                [`${filter.urlParam}__iendswith`]: commaSeparatedListCleanup(value),
            };
        default:
            return { [filter.urlParam]: value };
    }
};
