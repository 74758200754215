import clsx from 'clsx';
import React from 'react';

import { Divider } from '../Divider';

/**
 * A divider used in a side panel to divide form sections.
 * @param {Object} props
 * @returns {StatelessComponent}
 */
const SidePanelFormDivider = ({ className, ...rest }) => (
  <Divider {...rest} className={clsx('side-panel-divider', className)} />
);

export default SidePanelFormDivider;
