import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const InvoiceGeneratorTotalsPanel = ({ children, className }) => (
  <div className={clsx(className, 'panel totals-panel disable-pointer-events')}>{children}</div>
);

InvoiceGeneratorTotalsPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default InvoiceGeneratorTotalsPanel;
