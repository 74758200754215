import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { LinkNewPage } from 'components/link';
import { BrandNav } from 'components/nav';

const BrandWrapper = ({ children, isLinkNewPage, linkUrl, linkText, text }) => (
  <div className="branded--container">
    <BrandNav>
      <ul className="nav--links">
        <li>
          <span
            className={clsx('font-color--white font-regular margin-right--sm nav--links-description', {
              bold: !linkUrl,
            })}
          >
            {text}
          </span>

          {linkUrl && isLinkNewPage && (
            <LinkNewPage className="bold font-color--white font-regular" href={linkUrl}>
              {linkText}
            </LinkNewPage>
          )}

          {linkUrl && !isLinkNewPage && (
            <Link className="bold font-color--white font-regular" to={linkUrl}>
              {linkText}
            </Link>
          )}
        </li>
      </ul>
    </BrandNav>

    {children}
  </div>
);

BrandWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  linkUrl: PropTypes.string,
  isLinkNewPage: PropTypes.bool,
  linkText: PropTypes.string,
  text: PropTypes.string.isRequired,
};

BrandWrapper.defaultProps = {
  isLinkNewPage: true,
  linkUrl: undefined,
  linkText: undefined,
};

export default BrandWrapper;
