import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Container, HandleActionsContainer, ComboBoxInput, ComboBoxLabel, ComboxDisplayContainer, ComboxDisplayAction, ComboBoxUpIcon, ComboBoxDownIcon, ClearInputValueButton, OuterCont, } from './combobox.component.styled';
import { RoutableEvents, useEvent } from '@routable/framework';
import { Close } from '@carbon/icons-react';
import { COMBOBOX_CLOSE_ACTION, COMBOBOX_VALUE_SELECTED, } from './combobox.component.types';
import { ComboBoxPopupContainer } from './combobox.popup.component';
import { createPortal } from 'react-dom';
import { getSelectPlaceholderValue } from './combobox.component.helper';
import { TruncatableText } from '../TruncatableText';
import clsx from 'clsx';
const searchInputRegexOpen = /^(\d|\w)$/;
let activeComboBoxPointer = null;
const HandleOutsideActions = ({ children, className, onPress }) => {
    const domRef = useRef(null);
    const handleOutsideClick = () => {
        activeComboBoxPointer = null;
        setTimeout(() => {
            onPress?.();
            RoutableEvents.Publish(COMBOBOX_CLOSE_ACTION, {
                close: true,
                force: true,
            });
        }, 100);
    };
    return createPortal(React.createElement(HandleActionsContainer, { onClick: handleOutsideClick, className: className, ref: domRef }, children), document.body);
};
export const ComboBox = forwardRef(({ disabled, yOffset = 10, type = 'select', width, height, hidePlaceholder, getOptionLabel, getOptionValue, closeOnSelect, placeholder, predictive, input, label, isRequired, setWrapperRef, ...rest }, ref) => {
    const placeholderValue = getSelectPlaceholderValue({
        hideLabel: false,
        hidePlaceholder,
        label,
        placeholder,
    });
    const domRef = useRef(null);
    const outerRef = useRef(null);
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    const domId = useRef(crypto.randomUUID().replaceAll('-', '').substring(0, 10));
    const [showComboBoxContainer, setShowComboBoxContainer] = useState(false);
    const [inputValue, setInputValue] = useState(input.value);
    const [inputLabel, setInputLabel] = useState(type === 'modal' ? 'ComboBox' : placeholderValue);
    const [startingFetchString, setStartingFetchString] = useState('');
    const hideComboBoxContainer = () => {
        setStartingFetchString('');
        setShowComboBoxContainer((prev) => {
            if (prev) {
                input.onBlur?.();
                setTimeout(() => {
                    inputRef.current?.focus({ preventScroll: true });
                    buttonRef.current?.focus({ preventScroll: true });
                }, 100);
            }
            return false;
        });
    };
    useEvent(`${COMBOBOX_VALUE_SELECTED}:${domId.current}`, ({ valueOption, labelOption }) => {
        setInputLabel(labelOption);
        if (closeOnSelect) {
            hideComboBoxContainer();
        }
        if (input) {
            input.onChange(valueOption);
        }
    });
    const [domPosition, setDomPosition] = useState({
        x: -2,
        y: -2,
        width: 0,
        height: 0,
    });
    useEvent(COMBOBOX_CLOSE_ACTION, ({ close, force }) => {
        if (force || (close && activeComboBoxPointer !== domRef.current)) {
            hideComboBoxContainer();
        }
    });
    const checkInputValue = (hasValue) => {
        if (hasValue)
            return Boolean(input.value);
        try {
            return !input.value || input.value?.toString?.().trim() === '';
        }
        catch (error) {
            console.warn('Error processing checkInputValue', { input, error });
            return !!input.value;
        }
    };
    const comboboxContainerDisplay = (e) => {
        if (e && 'key' in e && e.key === 'Tab') {
            return;
        }
        if (e && 'key' in e && RegExp(searchInputRegexOpen, 'g').test(e.key)) {
            setStartingFetchString(e.key);
        }
        if (e && e.preventDefault && e.stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (domRef.current) {
            if (inputRef.current) {
                inputRef.current.focus({ preventScroll: true });
            }
            const domRefPosition = domRef.current?.getBoundingClientRect();
            setDomPosition({
                x: domRefPosition.left | 0,
                y: domRefPosition.bottom | 0,
                width: domRefPosition.width | 0,
                height: domRefPosition.height | 0,
            });
        }
    };
    useEffect(() => {
        setInputValue(getOptionValue(input.value));
        if (input?.value) {
            const optionList = rest.options || [];
            let optionLabel = getOptionLabel(input.value) || '';
            if (optionLabel === '') {
                const optionFound = optionList.find((option) => option.id === input.value);
                optionLabel = optionFound?.label ?? '';
            }
            setInputLabel(optionLabel);
        }
        else {
            setInputLabel(type === 'modal' ? 'ComboBox' : placeholderValue);
        }
    }, [input?.value, type, placeholderValue, getOptionLabel, getOptionValue]);
    const handleClearValue = () => {
        input.onChange('');
        setInputLabel(placeholderValue);
    };
    useImperativeHandle(ref, () => inputRef.current);
    useEffect(() => {
        const handleEscKey = (e) => {
            if (e.key === 'Escape' || e.code === 'Escape') {
                e.preventDefault();
                RoutableEvents.Publish(COMBOBOX_CLOSE_ACTION, {
                    close: true,
                    force: true,
                });
            }
        };
        window.addEventListener('keyup', handleEscKey, true);
        return () => {
            window.removeEventListener('keyup', handleEscKey, true);
        };
    }, []);
    useEffect(() => {
        if (domPosition.x > -2) {
            activeComboBoxPointer = domRef.current;
            RoutableEvents.Publish(COMBOBOX_CLOSE_ACTION, { close: true });
            setShowComboBoxContainer(true);
        }
    }, [domPosition]);
    return (React.createElement(OuterCont, { ref: outerRef },
        React.createElement(Container, { ref: domRef, className: clsx('focus-within:ring hover:ring ring-blue-40/20 ring-inset', {
                'pointer-events-none bg-grey-5': disabled,
            }), onClick: comboboxContainerDisplay },
            React.createElement(ComboBoxInput, { autoComplete: "off", name: rest.name, onKeyDown: (e) => {
                    const reg = RegExp(searchInputRegexOpen, 'g').test(e.key);
                    if (e.key === 'Enter' || e.key === ' ' || e.key.startsWith('Arrow') || reg) {
                        comboboxContainerDisplay(e);
                    }
                }, ref: inputRef, onFocus: () => {
                    outerRef.current?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'nearest' });
                } }),
            React.createElement(ComboxDisplayContainer, { ref: setWrapperRef },
                React.createElement(ComboBoxLabel, { className: "flex gap-2 items-center justify-start", style: { width: 'calc(100% - 40px)' }, noValueSelected: !input?.value },
                    React.createElement(TruncatableText, { position: "top", className: isRequired ? 'hide-required-when-required-externally' : undefined, text: inputLabel?.replace?.('*', '') || inputLabel }),
                    !hidePlaceholder && (isRequired || inputLabel?.includes?.('*')) && React.createElement("span", { className: "asterisk" }, "*")),
                React.createElement(ComboxDisplayAction, { hasClear: !showComboBoxContainer && checkInputValue(true) },
                    showComboBoxContainer && React.createElement(ComboBoxUpIcon, null),
                    !showComboBoxContainer && checkInputValue(false) && React.createElement(ComboBoxDownIcon, null),
                    !showComboBoxContainer && checkInputValue(true) && (React.createElement(ClearInputValueButton, { variant: "floating", size: "small", "data-testid": "combobox-clear-input", intent: null, type: "button", ref: buttonRef, onPress: handleClearValue, onKeyDown: comboboxContainerDisplay },
                        React.createElement(Close, null))))),
            domPosition.x >= -2 && showComboBoxContainer && (React.createElement(HandleOutsideActions, { type: type, className: type === 'modal' ? 'is-combobox-modal' : '', activeComboBoxPointer: activeComboBoxPointer, onPress: hideComboBoxContainer, comboboxRef: domRef },
                React.createElement(ComboBoxPopupContainer, { predictive: predictive, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, options: rest.options, ...rest, domId: domId.current, type: type, height: height, width: width, yOffset: yOffset, position: domPosition, domRef: domRef.current, onHide: hideComboBoxContainer, value: input.value, selectId: input.value ? input.value : inputValue, startingFetchString: startingFetchString }))))));
});
