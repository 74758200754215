import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { iconSizes } from 'constants/styles/sizes';

/**
 * Returns an icon-style image of a ledger's logo.
 * Accepts the `size`, `marginLeft`, and `marginRight` properties for consistency with existing icon components.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {Object} props.ledger
 * @param {Object} props.marginLeft
 * @param {Object} props.marginRight
 * @param {NumberMaybe} props.size
 * @param {Object} props.style
 * @return {StatelessComponent}
 */
const LedgerIcon = ({ className, ledger, marginLeft, marginRight, size, style }) => {
  const sizeStr = `${size}px`;

  return (
    <img
      alt={`${ledger.fullName} logo`}
      className={clsx('ledger-icon', className)}
      src={ledger.logo}
      style={{
        height: sizeStr,
        marginLeft,
        marginRight,
        width: sizeStr,
        ...style,
      }}
    />
  );
};

LedgerIcon.propTypes = {
  className: PropTypes.string,
  ledger: PropTypes.shape({
    application: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.shape({}),
};

LedgerIcon.defaultProps = {
  className: undefined,
  marginLeft: '0',
  marginRight: '0',
  size: iconSizes.LARGE,
  style: {},
};

export default LedgerIcon;
