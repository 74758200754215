import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { IFrame } from 'components/iframe';

/**
 * Renders an iframe element for document display.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.iFrameClassName
 * @param {string} props.objectUrl
 * @param {StringMaybe} props.title
 * @return {StatelessComponent}
 */
const DocumentViewer = ({ iFrameClassName, objectUrl, title, ...rest }) => (
  <IFrame {...rest} className={clsx('viewer', iFrameClassName)} src={objectUrl} title={title} />
);

DocumentViewer.propTypes = {
  iFrameClassName: PropTypes.string,
  objectUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DocumentViewer.defaultProps = {
  iFrameClassName: undefined,
  title: undefined,
};

export default DocumentViewer;
