import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, LedgerIcon } from 'components/icon';
import FlexCol from 'components/layout/FlexCol';
import FlexRow from 'components/layout/FlexRow';

import { typography } from 'constants/styles';

import { anyValues, hasLength } from 'helpers/utility';

import { SubtitleBlock, TitleBlock } from './components';

/**
 * Flexible component to provide common styling for a title with a subtitle. The title can be custom or you could use
 * the common TitleWithDetails component. There can be more than one title, for example, to display an address.
 * You can also pass a custom subtitle(s) or just use the common SubtitleWithIcon component.
 *
 * @see Storybook > TitleWithSubtitle
 * @param {Object} props
 * @param {string} [props.className]
 * @param {ObjectMaybe} [props.iconProps]
 * @param {ObjectMaybe} [props.ledgerIconProps]
 * @param {JSX.Element[]|string[]} [props.subtitles]
 * @param {ObjectMaybe} [props.subtitleProps]
 * @param {JSX.Element[]} props.titles
 * @param {Object} [props.titleProps={}]
 * @returns {StatelessComponent}
 */
const TitleWithSubtitle = ({
  className,
  dataFullStory,
  iconProps,
  ledgerIconProps,
  subtitles,
  subtitleProps,
  titles,
  titleProps,
}) => (
  <FlexRow className={clsx('flex-parent-truncate', className)} stackOnMobile={false}>
    {anyValues(iconProps) && <Icon {...typography.TitleWithSubtitle.iconProps} {...iconProps} />}

    {anyValues(ledgerIconProps) && <LedgerIcon {...typography.TitleWithSubtitle.iconProps} {...ledgerIconProps} />}

    <FlexCol className="flex-parent-truncate">
      <TitleBlock {...titleProps} dataFullStory={dataFullStory} titles={titles} />

      {hasLength(subtitles) && <SubtitleBlock {...subtitleProps} subtitles={subtitles} />}
    </FlexCol>
  </FlexRow>
);

TitleWithSubtitle.propTypes = {
  dataFullStory: PropTypes.bool,
  iconProps: PropTypes.shape(),
  ledgerIconProps: PropTypes.shape(),
  subtitles: PropTypes.arrayOf(PropTypes.node),
  subtitleProps: PropTypes.shape(),
  titles: PropTypes.arrayOf(PropTypes.node).isRequired,
  titleProps: PropTypes.shape(),
};

TitleWithSubtitle.defaultProps = {
  iconProps: undefined,
  ledgerIconProps: undefined,
  subtitles: undefined,
  subtitleProps: undefined,
  titleProps: {},
};

export default TitleWithSubtitle;
