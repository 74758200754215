import { testUUID1, testUUID2, testUUID3 } from '@routable/shared';
import {} from '../models';
export const testListVendorPartnershipBase = {
    id: testUUID1,
    type: 'Partnership',
    attributes: {
        collectVendorTaxInfo: true,
        countryCode: 'US',
        countryCodeCompany: 'US',
        countryCodePartner: 'US',
        created: '2024-09-04T09:01:30.912996Z',
        currencyCodeCompany: 'USD',
        currencyCodeCustomer: 'USD',
        currencyCodePartner: 'USD',
        currencyCodeVendor: 'USD',
        hasCompanyManagementContact: true,
        hasContact: true,
        hasEmail: true,
        hasValidEmail: true,
        isCustomer: false,
        isLedgerOnly: false,
        isPartnerSelfManaged: false,
        isVendor: true,
        name: 'Acme Inc.',
        partnerStatus: 'accepted',
        riskSummary: 'not_evaluated',
        supportsDomestic: true,
        taxInfoStatus: 'no_info',
    },
    relationships: {
        partner: {
            data: {
                id: testUUID2,
                type: 'Company',
            },
        },
    },
};
export const testListVendorPartnership = {
    ...testListVendorPartnershipBase,
    attributes: {
        ...testListVendorPartnershipBase.attributes,
        payablesOutstandingCount: 0,
        payablesOutstandingSum: '0.00',
        payablesProcessedCount: 2,
        payablesProcessedSum: '120.50',
        receivablesOutstandingCount: 0,
        receivablesOutstandingSum: '0.00',
        receivablesProcessedCount: 2,
        receivablesProcessedSum: '55.75',
    },
};
export const generateTestListVendorPartnership = ({ attributes = {}, id = testUUID1, relationships = {}, }) => ({
    ...testListVendorPartnership,
    id,
    attributes: {
        ...testListVendorPartnership.attributes,
        ...attributes,
    },
    relationships: {
        ...testListVendorPartnership.relationships,
        ...relationships,
    },
});
export const testListCustomerPartnershipBase = {
    id: testUUID2,
    type: 'Partnership',
    attributes: {
        collectVendorTaxInfo: true,
        countryCode: 'US',
        countryCodeCompany: 'US',
        countryCodePartner: 'US',
        created: '2024-09-04T09:01:30.912996Z',
        currencyCodeCompany: 'USD',
        currencyCodeCustomer: 'USD',
        currencyCodePartner: 'USD',
        currencyCodeVendor: 'USD',
        hasCompanyManagementContact: true,
        hasContact: true,
        hasEmail: true,
        hasValidEmail: true,
        isCustomer: true,
        isLedgerOnly: false,
        isPartnerSelfManaged: false,
        isVendor: false,
        name: 'Acme Inc.',
        partnerStatus: 'accepted',
        riskSummary: 'not_evaluated',
        supportsDomestic: true,
        taxInfoStatus: 'no_info',
    },
    relationships: {
        partner: {
            data: {
                id: testUUID3,
                type: 'Company',
            },
        },
    },
};
export const testListCustomerPartnership = {
    ...testListCustomerPartnershipBase,
    attributes: {
        ...testListCustomerPartnershipBase.attributes,
        payablesOutstandingCount: 0,
        payablesOutstandingSum: '0.00',
        payablesProcessedCount: 2,
        payablesProcessedSum: '120.50',
        receivablesOutstandingCount: 0,
        receivablesOutstandingSum: '0.00',
        receivablesProcessedCount: 2,
        receivablesProcessedSum: '55.75',
    },
};
export const generateTestListCustomerPartnership = ({ attributes = {}, id = testUUID1, partnerId = testUUID2, }) => ({
    ...testListCustomerPartnership,
    id,
    attributes: {
        ...testListCustomerPartnership.attributes,
        ...attributes,
    },
    relationships: {
        partner: {
            data: {
                id: partnerId,
                type: 'Company',
            },
        },
    },
});
export const testDetailVendorPartnership = {
    ...testListVendorPartnershipBase,
    relationships: {
        partner: {
            data: {
                id: testUUID2,
                type: 'Company',
            },
        },
        defaultPayableFundingAccount: {
            data: null,
        },
        defaultReceivableFundingAccount: {
            data: null,
        },
        lastNameChange: {
            data: null,
        },
        partnershipMembers: {
            data: [{ id: testUUID3, type: 'PartnershipMember' }],
            meta: { count: 1 },
        },
    },
};
export const generateTestDetailVendorPartnership = ({ attributes, relationships, id = testUUID1, }) => ({
    ...testDetailVendorPartnership,
    id,
    attributes: {
        ...testDetailVendorPartnership.attributes,
        ...attributes,
    },
    relationships: {
        ...testDetailVendorPartnership.relationships,
        ...relationships,
    },
});
export const testDetailCustomerPartnership = {
    ...testListCustomerPartnershipBase,
    relationships: {
        partner: {
            data: {
                id: testUUID2,
                type: 'Company',
            },
        },
        defaultPayableFundingAccount: {
            data: null,
        },
        defaultReceivableFundingAccount: {
            data: null,
        },
        lastNameChange: {
            data: null,
        },
        partnershipMembers: {
            data: [{ id: testUUID3, type: 'PartnershipMember' }],
            meta: { count: 1 },
        },
    },
};
export const generateTestDetailCustomerPartnership = ({ attributes, relationships, id = testUUID1, }) => ({
    ...testDetailCustomerPartnership,
    id,
    attributes: {
        ...testDetailCustomerPartnership.attributes,
        ...attributes,
    },
    relationships: {
        ...testDetailCustomerPartnership.relationships,
        ...relationships,
    },
});
