import { businessType } from '@routable/companies-management';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { w9FormBusinessTypeLabels } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.constants';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import { w8ChapterFourStatus, w8ChapterThreeStatus, taxFormEntryType, taxFormType, } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';
import { isBusinessTypeSoleProprietor, isCompanyTypeBusiness } from 'helpers/currentCompany';
import { formatAsEIN, formatAsGIIN, formatAsSSN } from 'helpers/numbers';
import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import { RenderMaskedTextInput } from '../RenderMaskedTextInput';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';
const FieldGroupW8EntityInformation = ({ businessLegalNamePlaceholder, isBusiness, isExternalEntry, }) => {
    const beneficialOwnerNamePlaceholder = isBusiness
        ? `${businessLegalNamePlaceholder} (as shown on ${isExternalEntry ? 'your ' : ''}income tax return)`
        : 'Legal name';
    return (React.createElement(Group, null,
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderTextInput, { isRequired: true, name: "beneficialOwnerName", placeholder: beneficialOwnerNamePlaceholder })),
        isBusiness && (React.createElement(React.Fragment, null,
            React.createElement(GroupSlot, { size: 3 },
                React.createElement(RenderSelect, { isRequired: false, label: "Entity type (Chapter 3 status)", name: "chapterThreeStatus", options: getLabelValuePairsFromEnum(w8ChapterThreeStatus), placeholder: "Select entity type" })),
            React.createElement(GroupSlot, { size: 3 },
                React.createElement(RenderSelect, { isRequired: false, label: "FATCA status (Chapter 4 status)", name: "chapterFourStatus", options: getLabelValuePairsFromEnum(w8ChapterFourStatus), placeholder: "Select FATCA status" })),
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderMaskedTextInput, { formatter: formatAsGIIN, name: "giin", placeholder: "GIIN" }))))));
};
const FieldGroupW9EntityInformation = ({ businessLegalNamePlaceholder, isBusiness, isExternalEntry, }) => {
    const { watch, setValue, unregister } = useFormContext();
    const selectedBusinessType = watch('businessType');
    const isBusinessTinTypeSSN = watch('isBusinessTinTypeSSN');
    const isSoleProprietor = isBusinessTypeSoleProprietor(selectedBusinessType);
    const isBusinessTinTypeSSNLabel = `${isExternalEntry ? "I don't" : "Vendor doesn't"} have a Business EIN`;
    const tinPlaceholder = isBusiness && !isBusinessTinTypeSSN ? 'Business EIN' : 'Social Security Number';
    const tinFormatter = isBusiness && !isBusinessTinTypeSSN ? formatAsEIN : formatAsSSN;
    useEffect(() => {
        const subscription = watch((_, { name, type }) => {
            if (name === 'companyType' && type === 'change') {
                unregister('legalName');
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [watch, setValue, unregister]);
    return (React.createElement(Group, null,
        isBusiness ? (React.createElement(React.Fragment, null,
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderTextInput, { isRequired: true, key: "businessLegalName", name: "legalName.0", placeholder: businessLegalNamePlaceholder })),
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderTextInput, { key: "businessName", name: "businessName", placeholder: "Business name/diregarded entity name (if different from above)" })),
            React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderSelect, { isRequired: true, key: "businessType", label: "Business type", name: "businessType", options: getLabelValuePairsFromEnum(businessType, w9FormBusinessTypeLabels), placeholder: "Select business type", shouldUnregister: true })),
            selectedBusinessType === businessType.Enum.other && (React.createElement(GroupSlot, { size: 6 },
                React.createElement(RenderTextInput, { isRequired: true, key: "businessTypeOtherDescription", name: "businessTypeOtherDescription", placeholder: "Other business type", shouldUnregister: true }))))) : (React.createElement(React.Fragment, null,
            React.createElement(GroupSlot, { size: 3 },
                React.createElement(RenderTextInput, { isRequired: true, key: "legalFirstName", name: "legalName.0", placeholder: "Legal first name" })),
            React.createElement(GroupSlot, { size: 3 },
                React.createElement(RenderTextInput, { isRequired: true, key: "legalLastName", name: "legalName.1", placeholder: "Legal last name", shouldUnregister: true })))),
        React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderMaskedTextInput, { formatter: tinFormatter, isRequired: true, key: isBusiness ? 'businessTin' : 'individualTin', name: "tin", placeholder: tinPlaceholder })),
        isSoleProprietor && (React.createElement(GroupSlot, { size: 6 },
            React.createElement(RenderCheckbox, { label: isBusinessTinTypeSSNLabel, name: "isBusinessTinTypeSSN", shouldUnregister: true })))));
};
const FieldGroupEntityInformation = () => {
    const { watch } = useFormContext();
    const { entryType, formType } = useTaxFormOptions();
    const companyType = watch('companyType');
    const isBusiness = isCompanyTypeBusiness(companyType);
    const isExternalEntry = entryType === taxFormEntryType.Enum.external;
    const businessLegalNamePlaceholder = isExternalEntry ? 'Business legal name' : "Vendor's business legal name";
    const EntityInformationGroup = formType === taxFormType.Enum.W8 ? FieldGroupW8EntityInformation : FieldGroupW9EntityInformation;
    return (React.createElement(EntityInformationGroup, { businessLegalNamePlaceholder: businessLegalNamePlaceholder, isBusiness: isBusiness, isExternalEntry: isExternalEntry }));
};
export default FieldGroupEntityInformation;
