import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const LabelLogo = ({ children, className }) => <div className={clsx('logo', className)}>{children}</div>;

LabelLogo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default LabelLogo;
