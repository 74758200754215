import PropTypes from 'prop-types';
import React from 'react';

import { ContactsInteractionListView } from 'context';

import { InteractionList } from 'components';

import { ContactsInteractionListViewType } from 'constants/ui';

import { getMembershipNameOrEmailOrPhoneNumber } from 'helpers/memberships';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import { ContactsInteractionBar, ContactListItem } from './components';

/**
 * A list of contacts. Could be used to show PartnershipMembers when creating a new partnership or to show the
 * ItemMembers on an item in the existing item thread view.
 * @param {Object} props
 * @param {String} [props.className]
 * @param {PartnershipMember[]|ItemMember[]} props.contacts
 * @param {number} props.displayMax
 * @param {ContactsInteractionListViewType} [props.viewType=ContactsInteractionListViewType.FULL]
 * @param {Boolean} props.hasPastItemMembers
 * @param {RestOfProps} props.rest
 * @return {StatelessComponent}
 */
const ContactsInteractionList = ({
  className,
  contacts,
  displayMax,
  hasPastItemMembers,
  importedContact,
  ledger,
  viewType,
  ...rest
}) => (
  <ContactsInteractionListView.Provider value={viewType}>
    <InteractionList
      className={className}
      displayMax={displayMax}
      interactionBar={
        <ContactsInteractionBar
          className={className}
          contacts={contacts}
          hasPastItemMembers={hasPastItemMembers}
          importedContact={importedContact}
          ledger={ledger}
          {...rest}
        />
      }
      // since each list item (below) is a react symbol, the key extractor
      // here just needs to target the key assigned to ContactListItem below;
      // since we may not have an id yet, keying by the contact email address
      keyExtractor={(obj) => obj.key}
      listItems={contacts.map((contact) => (
        <ContactListItem {...rest} contact={contact} key={getMembershipNameOrEmailOrPhoneNumber(contact)} />
      ))}
    />
  </ContactsInteractionListView.Provider>
);

ContactsInteractionList.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape()),
  displayMax: PropTypes.number,
  hasPastItemMembers: PropTypes.bool,
  importedContact: PropTypes.shape({}),
  ledger: PropTypes.shape({}),
  viewType: oneOfValuesFromObject(ContactsInteractionListViewType),
};

ContactsInteractionList.defaultProps = {
  contacts: [],
  displayMax: 4,
  hasPastItemMembers: undefined,
  importedContact: undefined,
  ledger: undefined,
  viewType: ContactsInteractionListViewType.FULL,
};

export default ContactsInteractionList;
