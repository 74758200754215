import { Tooltip } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import RestrictedPermissionContent from 'components/RestrictedPermissionContent';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { text } from 'modules/dashboard/settings/account/balance/constants';

/**
 * Buttons to show when the balance amount is positive, or at least NOT negative (zero or greater).
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.allowDeposit=true]
 * @param {boolean} [props.allowWithdraw=true]
 * @param {boolean} [props.isDisabled]
 * @param {function} [props.onDeposit]
 * @param {function} [props.onWithdraw]
 * @returns {StatelessComponent}
 */
const PositiveBalanceAmountButtons = (props) => {
  const { allowDeposit, allowWithdraw, isDisabled, onDeposit, onWithdraw } = props;

  return (
    <>
      <Tooltip data-testid="deposit-balance-button-tooltip" tooltip={isDisabled && <RestrictedPermissionContent />}>
        <ButtonV2
          className="margin-right--m"
          id="depositBalanceBtn"
          isDisabled={!allowDeposit || isDisabled}
          leftIconClassName="margin-right--m"
          leftIconName={IconNames.PLUS}
          leftIconSize={sizes.iconSizes.MEDIUM}
          onClick={onDeposit}
          size={ButtonSize.MEDIUM}
        >
          {text.balanceAmountBlock.depositButton}
        </ButtonV2>
      </Tooltip>

      <Tooltip data-testid="withdraw-balance-button-tooltip" tooltip={isDisabled && <RestrictedPermissionContent />}>
        <ButtonV2
          id="withdrawBalanceBtn"
          intent={Intent.NEUTRAL}
          isDisabled={!allowWithdraw || isDisabled}
          leftIconClassName="margin-right--m"
          leftIconName={IconNames.MINUS}
          leftIconSize={sizes.iconSizes.MEDIUM}
          onClick={onWithdraw}
          size={ButtonSize.MEDIUM}
        >
          {text.balanceAmountBlock.withdrawButton}
        </ButtonV2>
      </Tooltip>
    </>
  );
};

PositiveBalanceAmountButtons.propTypes = {
  allowDeposit: PropTypes.bool,
  allowWithdraw: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onDeposit: PropTypes.func,
  onWithdraw: PropTypes.func,
};

PositiveBalanceAmountButtons.defaultProps = {
  allowDeposit: true,
  allowWithdraw: true,
  isDisabled: undefined,
  onDeposit: undefined,
  onWithdraw: undefined,
};

export default PositiveBalanceAmountButtons;
