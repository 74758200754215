import { UserFollow } from '@carbon/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormFieldDescription, LinkNewPage } from 'components';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { UserType } from 'enums/user';
import { filterActiveItemMembersWithEmail } from 'helpers/itemMember';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
import { hasLength } from 'helpers/utility';
import { useMountedValue } from 'hooks';
import {} from './GroupedContactDynamicFormFieldDescription.types';
const GroupedContactDynamicFormFieldDescription = ({ isEditItem, onCreateContact, partnershipMembers, partnershipType, selectedCompany, selectedContacts, }) => {
    const hasContactsOnInitialRender = useMountedValue(hasLength(selectedContacts));
    const hasReachableContacts = filterActiveItemMembersWithEmail(partnershipMembers);
    if (!hasLength(hasReachableContacts)) {
        return selectedCompany?.partner?.companyType === UserType.PERSONAL ? (React.createElement(FormFieldDescription, { className: "mb-3 enable-pointer-events pl-2" },
            `This individual doesn't have a reachable email. You can update their `,
            React.createElement(Link, { className: "primary no-underline", to: getCompanyRouteByPartnershipId(selectedCompany.id) }, "company page"),
            ".")) : (React.createElement("div", { className: "flex gap-2 pl-2" },
            React.createElement(UserFollow, { className: "fill-grey-70" }),
            React.createElement(FormFieldDescription, { className: "mb-3 enable-pointer-events" },
                `You have no reachable contacts, but you can `,
                React.createElement(LabelLink, { onClick: onCreateContact }, "add a new contact"),
                React.createElement("span", null, "."))));
    }
    if (isEditItem && !hasContactsOnInitialRender) {
        return (React.createElement("div", { className: "flex gap-2 pl-2" },
            React.createElement(UserFollow, { className: "fill-grey-70" }),
            React.createElement(FormFieldDescription, { className: "mb-3 enable-pointer-events" },
                "You have no selected contacts, but you can ",
                React.createElement(LabelLink, { onClick: onCreateContact }, "add a contact"),
                ".")));
    }
    if (hasContactsOnInitialRender) {
        return (React.createElement(FormFieldDescription, { className: "!mb-3 enable-pointer-events pl-2" },
            `The contacts below were autofilled based on the `,
            React.createElement(LinkNewPage, { className: "primary", href: getCompanyRouteByPartnershipId(selectedCompany.id) }, `${partnershipType}'s contact settings ↗`),
            "."));
    }
    return null;
};
export default GroupedContactDynamicFormFieldDescription;
