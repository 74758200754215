import queryString from 'query-string';
import { call, put, spawn, take, takeLatest } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/integrations';
import { pushHistory } from 'actions/navigation';
import {
  fetchSingleIntegrationLedgerPartnershipsRoutine,
  integrationConnectAuthRoutine,
} from 'actions/routines/integrations';

import { SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE } from 'constants/routes';

import { confirmAlert } from 'helpers/confirmAlert';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getQueryParam } from 'helpers/queryParams';
import { isEmptyObject } from 'helpers/utility';

import { updatePartnershipsPaginationOffset } from 'modules/dashboard/settings/account/integrations/ledger/matching/helpers/partnerships';

import * as types from 'types/integrations';

import * as api from './api';
import * as strings from './strings';

/**
 * Handle fetching active integrations.
 * @return {IterableIterator<*>}
 */
export function* fetchIntegrations() {
  let errorData = {};

  try {
    const response = yield call(api.fetchIntegrations);

    if (response.ok) {
      yield put(actions.fetchIntegrationsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchIntegrationsFailure, errorData));
}

/**
 * Handle fetching active integration configs.
 * @return {IterableIterator<*>}
 */
export function* fetchIntegrationConfigs() {
  let errorData = {};

  try {
    const response = yield call(api.fetchIntegrationConfigs);

    if (response.ok) {
      yield put(actions.fetchIntegrationConfigsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchIntegrationConfigsFailure, errorData));
}

/**
 * Handle fetching active integration configs.
 * @return {IterableIterator<*>}
 */
export function* fetchSingleIntegrationConfig(action) {
  const {
    payload: { integrationId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchSingleIntegrationConfig, integrationId);

    if (response.ok) {
      yield put(actions.fetchSingleIntegrationConfigSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchSingleIntegrationConfigFailure, errorData));
}

/**
 * Handle fetching platform-side partnerships.
 * @return {IterableIterator<*>}
 */
export function* fetchIntegrationPlatformPartnerships(action) {
  const {
    payload: { filter, integrationConfigId, isSearch },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchIntegrationPlatformPartnerships, integrationConfigId, filter);

    if (response.ok) {
      if (isSearch) {
        yield put(actions.fetchIntegrationPlatformPartnershipsSearchSuccess(response.data));
      } else {
        yield put(actions.fetchIntegrationPlatformPartnershipsSuccess(response.data));
      }

      const parsedNextLink = queryString.parse(response.data.links.next);
      if (parsedNextLink && parsedNextLink['page[offset]']) {
        updatePartnershipsPaginationOffset(parsedNextLink['page[offset]']);
      }

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchIntegrationPlatformPartnershipsFailure, errorData));
}

/**
 * Handle fetching ledger-side partnerships.
 * @return {IterableIterator<*>}
 */
export function* fetchIntegrationLedgerPartnerships(action) {
  const {
    payload: { filter, integrationConfigId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchIntegrationLedgerPartnerships, integrationConfigId, filter);

    if (response.ok) {
      yield put(actions.fetchIntegrationLedgerPartnershipsSuccess(response.data));

      const parsedNextLink = queryString.parse(response.data.links.next);
      if (parsedNextLink && parsedNextLink['page[offset]']) {
        updatePartnershipsPaginationOffset(parsedNextLink['page[offset]']);
      }

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchIntegrationLedgerPartnershipsFailure, errorData));
}

/**
 * Handle fetching single ledger-side partnership.
 * @return {IterableIterator<*>}
 */
export function* fetchSingleIntegrationLedgerPartnerships(action) {
  yield put(fetchSingleIntegrationLedgerPartnershipsRoutine.request());

  let errorData = {};

  try {
    const { integrationConfigId, ledgerRef } = action.payload;
    const response = yield call(api.fetchIntegrationLedgerPartnerships, integrationConfigId, { ledger_ref: ledgerRef });

    if (response.ok) {
      yield put(fetchSingleIntegrationLedgerPartnershipsRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(fetchSingleIntegrationLedgerPartnershipsRoutine.failure, errorData));
}

/**
 * Handle fetching ledger-side unmatched funding accounts.
 * @return {IterableIterator<*>}
 */
export function* fetchLedgerUnmatchedLedgerFundingAccounts(action) {
  const {
    payload: { filter, integrationConfigId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchLedgerUnmatchedLedgerFundingAccounts, integrationConfigId, filter);

    if (response.ok) {
      yield put(actions.fetchLedgerUnmatchedLedgerFundingAccountsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchLedgerUnmatchedLedgerFundingAccountsFailure, errorData));
}

/**
 * Handle fetching platform-side unmatched funding accounts.
 * @return {IterableIterator<*>}
 */
export function* fetchLedgerUnmatchedPlatformFundingAccounts(action) {
  const {
    payload: { filter, integrationConfigId },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.fetchLedgerUnmatchedPlatformFundingAccounts, integrationConfigId, filter);

    if (response.ok) {
      yield put(actions.fetchLedgerUnmatchedPlatformFundingAccountsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchLedgerUnmatchedPlatformFundingAccountsFailure, errorData));
}

/**
 * Handle disconnecting an integration.
 * @return {IterableIterator<*>}
 */
export function* disconnectIntegrationConfig(action) {
  let errorData = {};

  try {
    const {
      payload: { id, integrationName },
    } = action;

    const isAgreed = yield call(confirmAlert, strings.getDisconnectConfirmAlertMessage(integrationName));

    if (!isAgreed) {
      return;
    }

    const response = yield call(api.disconnectIntegrationConfig, id);

    if (response.ok) {
      yield put(actions.disconnectIntegrationConfigSuccess(id));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.disconnectIntegrationConfigFailure, errorData));
}

/**
 * Handle connecting an OAuth integration.
 * @return {IterableIterator<*>}
 */
export function* connectIntegrationOAuth(action) {
  let errorData = {};

  try {
    const {
      payload: { integration },
    } = action;

    const response = yield call(api.connectIntegrationOAuth, integration);

    if (response.ok) {
      const { redirect } = response.data.meta;

      if (integration.presentation?.connectionData) {
        // in this case, user is going through a lookalike oAuth flow
        // hosted on Routable instead of on a NetSuite which doesn't support oAuth
        // As a result, we can push the user directly to that page
        // history.push avoids full page refresh (less friction for user)
        const { pathname, search } = new URL(redirect);
        yield put(pushHistory(`${pathname}${search}`));
        return;
      }

      // In this case, user is going through a real oAuth flow hosted on the ledger
      // As a result, we send the user to the ledger's auth page
      window.location.assign(redirect);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.connectIntegrationOAuthFailure, errorData));
}

/**
 * Handle refreshing data for an integration.
 * @return {IterableIterator<*>}
 */
export function* refreshIntegrationConfig(action) {
  let errorData = {};

  try {
    const {
      payload: { id },
    } = action;

    const response = yield call(api.refreshIntegrationConfig, id);

    if (response.ok) {
      yield put(actions.refreshIntegrationConfigSuccess(id));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.refreshIntegrationConfigFailure, errorData));
}

/**
 * Handle searching for funding accounts
 * @return {IterableIterator<*>}
 */
export function* searchFundingAccounts(action) {
  const {
    payload: { integrationConfigId, searchTerm },
  } = action;

  let errorData = {};

  try {
    const response = yield call(api.searchFundingAccounts, integrationConfigId, searchTerm);

    if (response.ok) {
      yield put(actions.searchFundingAccountsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.searchFundingAccountsFailure, errorData));
}

/**
 * This function simulates how an auth submission would be done in an oauth flow:
 * It POSTs ledger auth credentials to our API which then returns a 302 to redirect either:
 * - to the integrations page where the syncing modal is shown (valid credentials)
 * - or to the error page (invalid credentials or empty credentials when 'Cancel' is clicked on IntegrationConnectForm)
 * @return {IterableIterator<*>}
 */
export function* submitIntegrationConnectAuth(action) {
  yield put(integrationConnectAuthRoutine.request());

  // values.form can be undefined when the user clicks on "Cancel" from the IntegrationConnectForm
  // in this case, the API will simply redirect to the integration error page
  const {
    payload: { values: { form = {} } = {} },
  } = action;

  const apiURL = getQueryParam('redirect');
  const jwt = getQueryParam('state');

  // Check if form fields are empty; this means that the user has clicked on the cancel
  // button and needs to be redirected back to the integrations page without any errors
  if (isEmptyObject(form)) {
    // We need to redirect user back to the integrations page. We want to redirect the user
    // prior to making an API request, since the UX feels un-responsive upon hitting the
    // cancel button otherwise
    yield put(pushHistory(SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE));

    // We still need to make a call to the API even though we know it will fail in order
    // to reset the loading state of the ledger integration.
    yield call(api.connectIntegrationApi, apiURL, { error: 'cancel' });

    // Finally, we need to re-fetch integrations in order to set integration
    // loading state back to false (since we hit the BE endpoint, even though it has failed,
    // loading will be set to false).
    yield put(actions.fetchIntegrationsRequest());

    // Since we've exited connect flow early, no redirect happens, but success action still needs
    // to be dispatched since technically, nothing "wrong" has happened, user just canceled
    // the integration flow altogether
    yield put(integrationConnectAuthRoutine.success());

    // And we exit the saga before window.location.assign happens
    return;
  }

  let errorData = {};
  // wrap up by doing a POST sending the form data. The returned response should
  // tell us to redirect to successfully integrated page
  try {
    const response = yield call(api.connectIntegrationApi, apiURL, {
      state: jwt,
      ...form,
    });

    // in ok and some bad cases, if the redirect_url is provided, we redirect to the returned url
    const redirectUrl = response?.data?.redirect_url || response?.originalData?.data?.redirect_url;
    if (redirectUrl) {
      // in a standard oAuth flow, this call is made by the ledger's auth page and, on success,
      // the user would be redirected to the endpoint provided when the oAuth started (in connectIntegrationOAuth).
      // here, the user is going through a lookalike custom ledger oAuth flow on Routable
      // to mimic oAuth, we redirect the user to the API endpoint and let the API redirect back
      yield put(integrationConnectAuthRoutine.success());

      window.location.assign(redirectUrl);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchIntegrationsFailure, errorData));
}

/**
 * Listens for redux actions related to integrations.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  yield takeLatest(types.FETCH_INTEGRATION_CONFIG_REQUEST, fetchSingleIntegrationConfig);

  while (true) {
    const action = yield take([
      fetchSingleIntegrationLedgerPartnershipsRoutine.TRIGGER,
      integrationConnectAuthRoutine.TRIGGER,
      types.CONNECT_INTEGRATION_REQUEST,
      types.DISCONNECT_INTEGRATION_CONFIG_REQUEST,
      types.REFRESH_INTEGRATION_CONFIG_REQUEST,
      types.FETCH_INTEGRATION_CONFIGS_REQUEST,
      types.FETCH_INTEGRATIONS_REQUEST,
      types.FETCH_LEDGER_PARTNERSHIPS_REQUEST,
      types.FETCH_PLATFORM_PARTNERSHIPS_REQUEST,
      types.FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST,
      types.FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST,
      types.SEARCH_FUNDING_ACCOUNTS_REQUEST,
    ]);

    switch (action.type) {
      case types.CONNECT_INTEGRATION_REQUEST:
        yield spawn(connectIntegrationOAuth, action);
        break;

      case types.DISCONNECT_INTEGRATION_CONFIG_REQUEST:
        yield spawn(disconnectIntegrationConfig, action);
        break;

      case types.REFRESH_INTEGRATION_CONFIG_REQUEST:
        yield spawn(refreshIntegrationConfig, action);
        break;

      case types.FETCH_INTEGRATION_CONFIGS_REQUEST:
        yield spawn(fetchIntegrationConfigs);
        break;

      case types.FETCH_INTEGRATIONS_REQUEST:
        yield spawn(fetchIntegrations);
        break;

      case types.FETCH_PLATFORM_PARTNERSHIPS_REQUEST:
        yield spawn(fetchIntegrationPlatformPartnerships, action);
        break;

      case types.FETCH_LEDGER_PARTNERSHIPS_REQUEST:
        yield spawn(fetchIntegrationLedgerPartnerships, action);
        break;

      case fetchSingleIntegrationLedgerPartnershipsRoutine.TRIGGER:
        yield spawn(fetchSingleIntegrationLedgerPartnerships, action);
        break;

      case types.FETCH_UNMATCHED_LEDGER_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(fetchLedgerUnmatchedLedgerFundingAccounts, action);
        break;

      case types.FETCH_UNMATCHED_PLATFORM_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(fetchLedgerUnmatchedPlatformFundingAccounts, action);
        break;

      case types.SEARCH_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(searchFundingAccounts, action);
        break;

      case integrationConnectAuthRoutine.TRIGGER:
        yield spawn(submitIntegrationConnectAuth, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root integrations saga.
 * @return {IterableIterator<*>}
 */
export default function* integrations() {
  yield watch();
}
