import clsx from 'clsx';
import React from 'react';
import { ButtonV2, FormControl, IconNames, Image } from 'components';
import { ButtonSize } from 'constants/button';
import { formNamesAuth } from 'constants/forms';
import { googleLetterLogoMap } from 'constants/images';
import { sizes } from 'constants/styles';
import { isValueEmpty } from 'helpers/utility';
import { getSubmitButtonText } from 'modules/auth/AcceptMembershipInvite/helpers/ui';
const AcceptMembershipInviteFormActionButtons = ({ canManageSsoSettings, companySSOSettings, isSubmitting, loginWithPassword, setLoginWithPassword, }) => {
    const isSSOTurnedOn = !isValueEmpty(companySSOSettings);
    const isSSOTurnedOnAndOptional = isSSOTurnedOn && !companySSOSettings.ssoRequired;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormControl, { className: clsx({
                'remove-margin-bottom': canManageSsoSettings || loginWithPassword || !isSSOTurnedOnAndOptional,
            }) },
            React.createElement(ButtonV2, { htmlFor: formNamesAuth.ACCEPT_MEMBERSHIP_INVITE, id: "auth_acceptMembership_btn", isLoading: isSubmitting, rightIconName: IconNames.ARROW_RIGHT, rightIconProps: { style: { marginLeft: 'auto' } }, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, type: "submit" },
                !canManageSsoSettings && !loginWithPassword && isSSOTurnedOn && (React.createElement(Image, { alt: "Google logo", className: "google-btn--logo margin-right--m", src: googleLetterLogoMap.dark })),
                getSubmitButtonText(companySSOSettings, loginWithPassword, canManageSsoSettings))),
        !canManageSsoSettings && !loginWithPassword && isSSOTurnedOnAndOptional && (React.createElement(FormControl, { className: "remove-margin-bottom" },
            React.createElement("a", { className: "font-color--dark-jordan font-size--sm font-weight--bold", onClick: setLoginWithPassword, role: "presentation" }, "Log in with password")))));
};
export default AcceptMembershipInviteFormActionButtons;
