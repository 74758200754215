import clsx from 'clsx';
import React from 'react';
import { Icon, IconNames } from 'components';
import { margin, sizes } from 'constants/styles';
const SkipButton = ({ className, isDisabled, onClick }) => (React.createElement("a", { className: clsx('align-items--center dark-grey display--flex force-underline hover-primary', className, {
        disabled: isDisabled,
    }), onClick: onClick, role: "presentation" },
    React.createElement(Icon, { icon: IconNames.STEP_FORWARD, marginRight: margin.small, size: sizes.iconSizes.LARGE }),
    React.createElement("p", { className: "font-xs remove-margin-bottom" }, "Skip")));
export default SkipButton;
