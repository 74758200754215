import clsx from 'clsx';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import * as inputHelpers from 'components/form/helpers/inputs';
import { InputLabel, InputPlaceholder } from 'components/input';

import { getFieldErrors } from 'helpers/errors';

import { useDebouncedField } from 'hooks';

import DebouncedTextarea from '../DebouncedTextarea';

import '../Textarea.scss';

/**
 * Autosize textarea component used for block fields in create item.
 * @see {TextBlockField}
 *
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const AutosizeTextareaBlockField = (props) => {
  const {
    cols,
    debounceDelay,
    errors,
    input,
    isDebounced,
    isDisabled,
    isRequired,
    maxLength,
    meta,
    name,
    onBlur,
    onInputChange,
    placeholder,
    rows,
    style,
    value: valueProp,
  } = props;

  let currentValue;
  let fieldErrors;
  let hasErrors;
  let inputName;
  let handleOnBlur;

  if (input) {
    // This is a redux-form input
    inputName = input.name;
    currentValue = input.value;
    fieldErrors = (meta.touched || meta.submitFailed) && meta.error;
    hasErrors = !!fieldErrors;
    handleOnBlur = input.onBlur;
  } else {
    // This is not a redux-form input
    inputName = name;
    currentValue = valueProp;
    fieldErrors = errors;
    hasErrors = getFieldErrors(errors, inputName);
    handleOnBlur = onBlur;
  }

  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, {
    hasErrors,
  });

  const inputInterface = input || {
    onChange: onInputChange,
    value: valueProp,
  };

  const { onChange, value } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input: inputInterface,
  });

  const handleOnChangeInput = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.replace(/\n+/gm, '');
    onChange(event);
  };

  return (
    <div className={clsx(widthClasses)}>
      <div className={clsx('input-container autosized-container', otherClasses)}>
        <InputLabel name={name} placeholder={placeholder} value={currentValue} />

        <TextareaAutosize
          cols={cols}
          disabled={isDisabled}
          maxLength={maxLength}
          name={name}
          onBlur={handleOnBlur}
          onChange={handleOnChangeInput}
          placeholder={placeholder}
          required={isRequired}
          rows={rows}
          style={style}
          value={value}
        />
        <InputPlaceholder isRequired={isRequired} placeholder={placeholder} value={currentValue} />
      </div>

      <FormFieldErrors errors={fieldErrors} fieldName={inputName} />
    </div>
  );
};

AutosizeTextareaBlockField.propTypes = DebouncedTextarea.propTypes;

AutosizeTextareaBlockField.defaultProps = {
  ...DebouncedTextarea.defaultProps,
  rows: undefined,
};

export default AutosizeTextareaBlockField;
