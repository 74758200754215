import { Tooltip } from '@routable/components';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders the tag label wrapper element.
 * Determines the wrapper type (Tooltip if has
 * a tooltip) and renders it with children.
 * @param {Object} props
 * @return {StatelessComponent}
 */
const TagTooltip = ({ children, tooltipClassName, tooltipContent, tooltipProps }) => {
  const wrapper = tooltipContent ? Tooltip : 'span';

  const tooltipPaneProps = {
    tooltip: tooltipContent,
    className: tooltipClassName,
    ...tooltipProps,
  };

  const wrapperProps = tooltipContent ? tooltipPaneProps : { className: tooltipClassName };

  return React.createElement(wrapper, wrapperProps, children);
};

TagTooltip.propTypes = {
  children: PropTypes.node,
  tooltipClassName: PropTypes.string,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.shape(),
};

TagTooltip.defaultProps = {
  children: undefined,
  tooltipClassName: undefined,
  tooltipContent: undefined,
  tooltipProps: undefined,
};

export default TagTooltip;
