import { dateModel } from '@routable/shared';
import { z } from 'zod';
import { taxBusinessType, taxEmailContactStatus, taxInfoStatus, taxRequestStatus, taxRiskSummary } from '../status';
export const taxRequestActionMemberDataModel = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
});
export const taxRequestDataModel = z.object({
    id: z.string(),
    status: taxRequestStatus,
    expiresAt: z.string().nullable(),
    createdAt: z.string().nullable(),
    actionMember: taxRequestActionMemberDataModel,
});
export const baseTaxVendorDataModel = z.object({
    businessType: taxBusinessType.nullable(),
    businessTypeOtherDescription: z.string().nullable(),
    countryCode: z.string(),
    createdAt: dateModel,
    entityType: z.string(),
    legalName: z.string(),
    name: z.string(),
    requestStatus: taxRequestStatus,
    riskSummary: taxRiskSummary.nullish().default('not_evaluated'),
    taxEmailContactStatus: taxEmailContactStatus,
    taxInfoStatus: taxInfoStatus,
    taxRequest: taxRequestDataModel.nullish(),
    totalPaidInYear: z.string(),
});
