import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './Instruction.scss';

const Instruction = ({ className, description, link, num }) => (
  <div className={clsx('instruction--container', className)}>
    {num && num !== '0' && (
      <div className="content--left">
        <div className="instruction--circle">
          <div className="aligner">
            <span>{num}</span>
          </div>
        </div>
      </div>
    )}
    <div className="content--right">
      <div className="instruction--text">
        {link ? (
          <a className="primary" href={link} rel="noopener noreferrer" target="_blank">
            {description}
          </a>
        ) : (
          <span>{description}</span>
        )}
      </div>
    </div>
  </div>
);

Instruction.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node.isRequired,
  link: PropTypes.string,
  num: PropTypes.node,
};

Instruction.defaultProps = {
  className: undefined,
  link: undefined,
  num: undefined,
};

export default Instruction;
