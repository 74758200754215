import PropTypes from 'prop-types';
import React from 'react';

import DetailsListCard from '../DetailsListCard';

import { getCompanyGeneralInfoCards } from './helpers/logic';

/**
 * A SimpleCard showing general information about a partner company.
 * @param {Object} props
 * @param {Partnership} props.partnership
 * @param {Company} props.partnershipCompany - company matching the partnership.partner
 * @param {String} [props.className]
 * @returns {StatelessComponent}
 */
const CompanyGeneralInfoCard = ({ className, companies, partnership, partnershipCompany, info }) => {
  const cards = getCompanyGeneralInfoCards({
    partnership,
    partnershipCompany,
    companies,
    info,
  });

  return <DetailsListCard className={className} dataFullStory detailsListItems={cards} title="General" />;
};

CompanyGeneralInfoCard.propTypes = {
  partnership: PropTypes.shape({
    created: PropTypes.string.isRequired, // datestring
    name: PropTypes.string.isRequired,
  }).isRequired,
  partnershipCompany: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompanyGeneralInfoCard;
