import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { LoadMoreButton } from 'components';

import { IsLoadingComponent } from 'modules/isLoading';

import { EmptyStateContainer } from './CompanyContactsTable.styles';
import {
  CompanyContactsTableAddContactButton,
  CompanyContactsTableSectionHeader,
  CompanyContactsTableRender,
  CompanyContactsTableEmptyState,
} from './components';

import './CompanyContactsTable.scss';

/**
 * Table displaying PartnershipMembers for a company.
 * @param {Object} props
 * @param {boolean} props.isLoadingMore - for isFetching in LoadMoreButton
 * @param {boolean} props.isLoadingTable - initialization
 * @param {function} props.onLoadMore
 * @param {boolean} props.hasContacts
 * @param {object} props.pagination - Pagination object from backend to get more contacts
 * @param {string} props.partnershipId
 * @param {RestOfProps} props.rest
 * @returns {JSX.Element}
 */
const CompanyContactsTable = ({
  hasContacts,
  isLoadingMore,
  isLoadingTable,
  onLoadMore,
  pagination,
  onAddNewContact,
  partnershipId,
  ...rest
}) => (
  <>
    <CompanyContactsTableSectionHeader {...rest} hasContacts={hasContacts} />

    {isLoadingTable && <IsLoadingComponent hasShadow text="Looking up contacts" />}

    {!isLoadingTable && hasContacts ? (
      <CompanyContactsTableRender partnershipId={partnershipId} {...rest} />
    ) : (
      <EmptyStateContainer>
        <CompanyContactsTableEmptyState content={<CompanyContactsTableAddContactButton onClick={onAddNewContact} />} />
      </EmptyStateContainer>
    )}

    {typeof onLoadMore === 'function' && (
      <LoadMoreButton
        className="margin-top--m"
        isFetching={isLoadingMore}
        onClick={onLoadMore}
        pagination={pagination}
      />
    )}
  </>
);

CompanyContactsTable.propTypes = {
  isLoadingMore: PropTypes.bool,
  isLoadingTable: PropTypes.bool,
  pagination: PropTypes.shape({}),
  partnershipId: PropTypes.string.isRequired,
  onAddNewContact: PropTypes.func,
  onLoadMore: PropTypes.func,
  hasContacts: PropTypes.bool.isRequired,
};

CompanyContactsTable.defaultProps = {
  isLoadingMore: undefined,
  isLoadingTable: undefined,
  onAddNewContact: undefined,
  onLoadMore: undefined,
  pagination: { next: undefined },
};

export default CompanyContactsTable;
