import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders an iframe.
 * The `permissions` prop is a convenience to pass down permissions/restrictions in array format,
 * whose elements will be joined into a space-separated string, and used as the value for the
 * iframe's `sandbox` attribute.
 * [iFrame sandbox]{@link https://www.w3schools.com/tags/att_iframe_sandbox.asp}
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {StringMaybe} props.name
 * @param {Array<string>|undefined} props.permissions
 * @param {string} props.src
 * @param {string} props.title
 * @return {StatelessComponent}
 */
const IFrame = ({ className, name, permissions, src, title, ...rest }) => (
  <iframe
    {...rest}
    className={clsx('full-screen iframe', className)}
    name={name}
    sandbox={permissions ? permissions.join(' ') : undefined}
    src={src}
    title={title}
  />
);

IFrame.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

IFrame.defaultProps = {
  className: undefined,
  name: undefined,
  permissions: undefined,
  title: undefined,
};

export default IFrame;
