import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import FlexCol from 'components/layout/FlexCol';
import FlexRow from 'components/layout/FlexRow';

/**
 * A grouping of titles used within the TitleWithSubtitle. The titles are presented as a flex-column, with each
 * title rendered as a flex-row.
 * @param {Object} props
 * @param {string} [props.rowClassName]
 * @param {[]|Node[]} props.titles
 * @returns {StatelessComponent}
 */
const TitleBlock = ({ dataFullStory, titles, rowClassName, ...rest }) => (
  <FlexCol {...rest}>
    {titles.map((title, i) => (
      <FlexRow
        className={clsx('align-items--center', rowClassName, {
          // If this is not the first row, add some margin on top
          'margin-top--xs': i > 0,
        })}
        dataFullStory={dataFullStory}
        /* eslint-disable-next-line react/no-array-index-key */
        key={`titleBlock-${i}`}
        stackOnMobile={false}
      >
        {title}
      </FlexRow>
    ))}
  </FlexCol>
);

TitleBlock.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.node),
};

TitleBlock.defaultProps = {
  titles: [],
};

export default TitleBlock;
