import clsx from 'clsx';
import React from 'react';
import { ButtonV2 } from 'components';
import {} from './TwoLineButton.types';
import './TwoLineButton.scss';
const TwoLineButton = ({ className, title, description, ...rest }) => (React.createElement(ButtonV2, { className: clsx('two-line-button', className), ...rest },
    React.createElement("div", { className: "two-line-button__content" },
        React.createElement("span", { className: "two-line-button__title" }, title),
        React.createElement("span", { className: "two-line-button__description" }, description))));
export default TwoLineButton;
