import { notExists } from 'helpers/utility';

/**
 * If there are no async errors or warnings, there is no reason to show the hint.
 * @param {Object} options
 * @param {StringMaybe[]} [options.asyncErrors]
 * @param {Object} [options.warningEmail]
 * @returns {boolean}
 */
export const shouldHideHints = ({ asyncErrors, warningEmail }) => {
  const noAsyncErrors = !Array.isArray(asyncErrors) || asyncErrors.length === 0;
  const noWarnings = notExists(warningEmail);

  return noAsyncErrors && noWarnings;
};
