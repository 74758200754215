import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import ShadedBlock from 'components/shadedBlock';

import { Intent } from 'constants/ui';

/**
 * Confirm alert SWAL content component. Must be rendered HTML for Sweetalert to accept it as content.
 * Note: this also has to be a single element, hence the <div> and not <React.Fragment>, for SWAL to render it properly
 * @param {Object} props
 * @param {*} props.blockContent
 * @param {*} props.bottomContent
 * @param {*} props.topContent
 * @param {String} [props.className]
 * @return {JSX.Element}
 */
const ConfirmAlertContent = ({ blockContent, bottomContent, className, topContent }) => (
  <div className={clsx('confirm-alert-content', className)}>
    {topContent && <p className="alert-error-text top-content">{topContent}</p>}

    {blockContent && (
      <ShadedBlock
        className="alert-error-text margin-bottom--large margin-top--m block-content"
        intent={Intent.NEUTRAL}
      >
        {blockContent}
      </ShadedBlock>
    )}

    {bottomContent && <span className="alert-error-text bottom-content">{bottomContent}</span>}
  </div>
);

ConfirmAlertContent.propTypes = {
  className: PropTypes.string,
  blockContent: PropTypes.node,
  bottomContent: PropTypes.node,
  topContent: PropTypes.node,
};

ConfirmAlertContent.defaultProps = {
  className: undefined,
  blockContent: undefined,
  bottomContent: undefined,
  topContent: undefined,
};

export default ConfirmAlertContent;
