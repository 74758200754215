import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { approverRequiredValidator } from 'helpers/fieldValidation';

import { SingleSelect } from './components';

import './MutuallyExclusiveSelects.scss';

/**
 * Renders multiple selects which values are mutually exclusive.
 * To use in redux-form's Field.props.component,
 *
 * Accepts a selectConfigs prop which contains configuration about every select specifically.
 * Each config should at least consist of a fieldName, label, members, options, and text prop.
 *
 * @param {Object} props
 * @param {Array} props.allApprovals
 * @param {string} [props.className]
 * @param {boolean} props.isItemEdit
 * @param {ApprovalSelectConfig[]} props.selectConfigs
 * @return {StatelessComponent}
 */
const MutuallyExclusiveSelects = ({ allApprovals, className, isItemEdit, selectConfigs, ...rest }) => (
  <div className={clsx('mutually-exclusive-selects', className)}>
    {selectConfigs.map((config) => (
      <Field
        {...rest}
        allApprovals={allApprovals}
        className={className}
        component={SingleSelect}
        config={config}
        isItemEdit={isItemEdit}
        key={config.fieldName}
        name={config.fieldName}
        selectConfigs={selectConfigs}
        validate={approverRequiredValidator}
      />
    ))}
  </div>
);

MutuallyExclusiveSelects.propTypes = {
  allApprovals: PropTypes.arrayOf(PropTypes.shape()),
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.shape(),
  isItemEdit: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onShowToast: PropTypes.func.isRequired,
  selectConfigs: PropTypes.arrayOf(PropTypes.shape()),
};

MutuallyExclusiveSelects.defaultProps = {
  allApprovals: [],
  formValues: undefined,
  isItemEdit: undefined,
  selectConfigs: [],
};

export default MutuallyExclusiveSelects;
