import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table body cell component for the invoice generator ledger field tables.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
const InvoiceGeneratorTableBodyCell = ({ children, className, ...rest }) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  return (
    <div
      {...rest}
      className={clsx('table-row--column ledger-form-field create-item-field remove-padding', className, {
        disabled: isDisabled,
      })}
    >
      <div className="column-content">
        {React.cloneElement(children, {
          ...children.props,
          setIsDisabled,
        })}
      </div>
    </div>
  );
};

InvoiceGeneratorTableBodyCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableBodyCell.defaultProps = {
  children: undefined,
  className: undefined,
};

export default InvoiceGeneratorTableBodyCell;
