import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from 'components/layout';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { BlockListItem } from 'components/listItems';

import { breakpointNames } from 'constants/mediaQuery';

import './DetailsList.scss';

/**
 * A simple, table-like way to display related name/value data
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {Object[]} props.items
 * @param {Function} props.keyExtractor
 * @param {ObjectMaybe} props.rowProps
 * @param {Object} props.style
 * @return {StatelessComponent}
 */
const DetailsList = ({ dataFullStory, className, items, keyExtractor, rowProps, style, footer }) => {
  const listItems = items.map((item, idx) => {
    const {
      component,
      data,
      icon,
      label,
      labelProps,
      target,
      tooltipContent,
      url,
      value,
      valueIcon,
      wrapWithCol = true,
    } = item;

    const key = keyExtractor(item, idx);

    const Component = component || BlockListItem;

    let itemComponent = React.createElement(Component, {
      icon,
      dataFullStory,
      key: wrapWithCol ? undefined : key,
      label,
      labelProps,
      target,
      tooltipContent,
      url,
      value,
      valueIcon,
      ...data,
    });

    // if passed both a custom component and a label, wrap the custom
    // component in a block list item, and attach the label, using
    // the given rendered component as the value.
    // this is purely a convenience to avoid making N additional wrappers
    // solely for this purpose.
    if (component && label) {
      itemComponent = (
        <BlockListItem
          className={clsx({ 'last-item': idx === items.length - 1 })}
          dataFullStory={dataFullStory}
          label={label}
          labelProps={labelProps}
          tooltipContent={tooltipContent}
          value={itemComponent}
          valueIcon={valueIcon}
        />
      );
    }

    if (wrapWithCol) {
      return <Col key={key}>{itemComponent}</Col>;
    }

    return itemComponent;
  });

  if (footer) {
    listItems.push(<div className="margin--m disclosure-list-footer">{footer}</div>);
  }

  return (
    <Row
      breakpointProps={{
        [breakpointNames.tablet]: {
          cols: 2,
        },
      }}
      className={clsx('details-list', className, { 'column-left-padding--remove': !!style?.columnLeftPaddingNone })}
      cols={1}
      flexWrap="wrap"
      style={style}
      {...rowProps}
    >
      {listItems}
    </Row>
  );
};

DetailsList.propTypes = {
  dataFullStory: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  keyExtractor: PropTypes.func,
  rowProps: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

DetailsList.defaultProps = {
  className: undefined,
  items: [],
  footer: undefined,
  keyExtractor: undefined,
  rowProps: {},
  style: {},
};

export default DetailsList;
