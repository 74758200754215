import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Adds the relation icon class name (e.g. `relation--right-icon`) to any
 * existing class names on the icon.
 * Normally, we would want to pass name/className props for icon, and render
 * the icon locally. However, these are sometimes our custom `LedgerIcon`
 * components, which can't be rendered the same way as our normal named icons.
 * @param {ComponentProps} props
 * @param {StringMaybe} props.className
 * @param {*} props.icon
 * @return {StatelessComponent}
 */
const TextAndIconsRelationIcon = (props) => {
  const { className, icon, ...rest } = props;

  return React.cloneElement(icon, {
    ...icon.props,
    ...rest,
    className: clsx(className, icon.props.className),
  });
};

TextAndIconsRelationIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

TextAndIconsRelationIcon.defaultProps = {
  className: undefined,
};

export default TextAndIconsRelationIcon;
