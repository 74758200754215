import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { DetailsList, FlexCol, FlexRow, HeadingTitle, SimpleCard } from 'components';

/**
 * DetailsList wrapped with a SimpleCard. Title optional.
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Object[]} props.detailsListItems
 * @param {String} [props.title]
 * @returns {StatelessComponent}
 */
const DetailsListCard = ({ className, controls, dataFullStory, detailsListItems, title }) => (
  <FlexCol className={className}>
    {title && <HeadingTitle>{`${title} info`}</HeadingTitle>}

    <SimpleCard className={clsx(className, { 'margin-top--m': !!title })}>
      <FlexRow>
        <DetailsList dataFullStory={dataFullStory} items={detailsListItems} keyExtractor={(item) => item.label} />

        {controls}
      </FlexRow>
    </SimpleCard>
  </FlexCol>
);

DetailsListCard.propTypes = {
  controls: PropTypes.node,
  detailsListItems: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.node]).isRequired,
  title: PropTypes.string,
};

DetailsListCard.defaultProps = {
  controls: undefined,
  title: undefined,
};

export default DetailsListCard;
