export const LOCAL_STORAGE_KEY_FEATURE_FLAGS = 'featureFlags';
export const FEATURE_FLAG_APPROVE_AND_NEXT = 'approveAndNext';
export const FEATURE_FLAG_SIDE_NAVIGATION_V2 = 'sideNavigationV2';
export const FEATURE_FLAG_INBOX_SIDE_BY_SIDE = 'inboxSideBySide';
export const FEATURE_FLAG_NAME_CROSSBORDER_ITEM_APPROVAL_ENABLED = 'crossborderItemApprovalEnabled';
export const FEATURE_FLAG_NAME_GLOBAL_ONLY_EXTERNAL_FLOW_BACK_BUTTON = 'globalOnlyExternalFlowBackButton';
export const FEATURE_FLAG_PAYABLES_FILTER_DEFAULT = 'payablesFilterDefault';
export const FEATURE_FLAG_PAYABLES_STATUS_FILTER = 'payablesStatusFilter';
export const FEATURE_FLAG_NAME_PREFIX = 'FEATURE_FLAG_';
export const DEV_ONLY_FEATURE_FLAG_NAME_PREFIX = 'DEV_ONLY_';
export const FEATURE_FLAG_CROSSBORDER_SELF_SERVE = 'crossborderSelfServe';
export const FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS = 'crossborderMultipleProvidersEnabled';
export const FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES = 'enterpriseApprovalRules';
export const FEATURE_FLAG_CUSTOM_ROLES_ENABLED = 'customRolesEnabled';
export const FEATURE_FLAG_INTERNATIONAL_BANK_ACCOUNT_VALIDATION = 'globalOnlyInternationalBankAccountValidation';
export const FEATURE_FLAG_TAX_EXCLUDE_VENDOR = 'globalOnlyTaxExcludeVendor';
