import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Tag, WhiteSpace } from 'components';

import { GuideStatuses } from 'constants/guide';
import { TagType } from 'constants/ui';

import {
  isGuideStatusComplete,
  isGuideStatusFailed,
  isGuideStatusPending,
} from 'modules/dashboard/guide/global/helpers/statuses';

import './GuideSectionStatus.scss';

class GuideSectionStatus extends React.Component {
  renderCallToAction = () => {
    const { callToAction, isRenderedOutsideGuideList, status, showCtaWhenComplete = false } = this.props;

    if (!callToAction || isRenderedOutsideGuideList) {
      return null;
    }

    const invalidStatuses = [
      GuideStatuses.FAILED,
      GuideStatuses.PENDING,
      // when this component is shown on the ReviewStep of SignUpFlow we want to show completed statuses
      showCtaWhenComplete ? undefined : GuideStatuses.COMPLETE,
    ];
    if (invalidStatuses.includes(status)) {
      return null;
    }

    return (
      <div className="guide-section--text right">
        <p className="font-xs bold font-color--primary remove-margin-bottom">
          <span className="margin-right--m">{callToAction}</span>
        </p>
      </div>
    );
  };

  renderFailedBadge = () => {
    const { status } = this.props;

    if (!isGuideStatusFailed(status)) {
      return null;
    }

    return (
      <div className="guide-section--text right">
        <Tag hasIcon={false} isClosable={false} readOnly type={TagType.ERROR}>
          Failed
        </Tag>
      </div>
    );
  };

  renderPendingBadge = () => {
    const { status } = this.props;

    if (!isGuideStatusPending(status)) {
      return null;
    }

    return (
      <div className="guide-section--text right">
        <Tag hasIcon={false} isClosable={false} readOnly type={TagType.WARNING}>
          Pending
        </Tag>
      </div>
    );
  };

  renderGuideSectionDescription = () => {
    const { description, status } = this.props;

    if (isGuideStatusComplete(status)) {
      return null;
    }

    return <p className="font-xs semibold font-color--dark-jordan remove-margin-bottom">{description}</p>;
  };

  renderGuideSectionError = () => {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return (
      <div className="guide-section--row section-failed">
        <span className="icon-ic-alert font-color--negative margin-right--xm" />

        <p className="font-xs font-color--greyXDark remove-margin-bottom">
          <span className="bold">{error.title}</span>
          <WhiteSpace />
          {error.description}
        </p>
      </div>
    );
  };

  renderGuideSection = () => {
    const { status, title, error } = this.props;
    const isComplete = isGuideStatusComplete(status) && !error;

    return (
      <React.Fragment>
        <div className={clsx('guide-section--row', { 'section-complete': isComplete })}>
          <span className={clsx('icon-ic-checkmark-circle guide-section--icon', { 'section-complete': isComplete })} />

          <div className="guide-section--description">
            <div className="guide-section--text">
              <p className="font-regular bold font-color--greyXDark remove-margin-bottom">{title}</p>

              {this.renderGuideSectionDescription()}
            </div>
            {this.renderPendingBadge()}
            {this.renderFailedBadge()}
            {this.renderCallToAction()}
          </div>
        </div>

        {this.renderGuideSectionError()}
      </React.Fragment>
    );
  };

  render() {
    const { error, isDisabled, isRenderedOutsideGuideList, onClick, sectionURL, status } = this.props;

    return (
      <div
        className={clsx('guide-section', {
          'outside-guide': isRenderedOutsideGuideList,
          'section-complete': isGuideStatusComplete(status) && !error,
          'section-disabled': isDisabled,
        })}
      >
        <Link className="remove-hover-underline" onClick={onClick} to={sectionURL}>
          {this.renderGuideSection()}
        </Link>
      </div>
    );
  }
}

GuideSectionStatus.propTypes = {
  callToAction: PropTypes.string,
  description: PropTypes.string.isRequired,
  error: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  isRenderedOutsideGuideList: PropTypes.bool,
  onClick: PropTypes.func,
  sectionURL: PropTypes.string,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

GuideSectionStatus.defaultProps = {
  callToAction: undefined,
  error: undefined,
  isDisabled: undefined,
  isRenderedOutsideGuideList: undefined,
  onClick: () => {},
  sectionURL: undefined,
};

export default GuideSectionStatus;
