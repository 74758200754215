import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './HeadingTitle.scss';

const HeadingTitle = ({ children, className, style }) => (
  <p className={clsx('heading-title', className)} style={style}>
    {children}
  </p>
);

HeadingTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

HeadingTitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default HeadingTitle;
