import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { isFn } from 'helpers/utility';

/**
 * Table row <tr> component for new tables used with react-table
 * @param {Node} children
 * @param {String} [className]
 * @param {Boolean} [isDisabled]
 * @param {Boolean} [isHighlighted]
 * @param {Boolean} [isWarn]
 * @param {Function} [onClick]
 * @return {StatelessComponent}
 */
const TableRow = ({ children, className, isDisabled, isHighlighted, isWarn, onClick }) => (
  <tr
    className={clsx(className, {
      'cursor--pointer': isFn(onClick),
      disabled: !!isDisabled,
      highlighted: !!isHighlighted,
      warning: !!isWarn,
    })}
    onClick={onClick}
  >
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isWarn: PropTypes.bool,
  onClick: PropTypes.func,
};

TableRow.defaultProps = {
  className: undefined,
  isDisabled: undefined,
  isHighlighted: undefined,
  isWarn: undefined,
  onClick: undefined,
};

export default TableRow;
