import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'helpers/axiosBaseQuery';
export var RoutableTags;
(function (RoutableTags) {
    RoutableTags["Company"] = "Company";
    RoutableTags["CompanySettings"] = "CompanySettings";
    RoutableTags["CustomRoles"] = "CustomRoles";
    RoutableTags["Exporter"] = "Exporter";
    RoutableTags["Funding"] = "Funding";
    RoutableTags["Items"] = "Items";
    RoutableTags["LedgerObjects"] = "LedgerObjects";
    RoutableTags["Memberships"] = "Memberships";
    RoutableTags["Partnership"] = "Partnership";
    RoutableTags["Permissions"] = "Permissions";
    RoutableTags["Transactions"] = "Transactions";
})(RoutableTags || (RoutableTags = {}));
export var RoutableTagIds;
(function (RoutableTagIds) {
    RoutableTagIds["W8TaxInfo"] = "W8TaxInfo";
    RoutableTagIds["W9TaxInfo"] = "W9TaxInfo";
})(RoutableTagIds || (RoutableTagIds = {}));
export const routableApi = createApi({
    reducerPath: 'routableApi',
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5,
    tagTypes: Object.values(RoutableTags),
    refetchOnFocus: true,
    endpoints: () => ({}),
});
