import { DateFormats } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { IconNames, Text, TooltipMUIConditionalWrapper } from 'components';
import { typography } from 'constants/styles';
import { getCountryNameFromCode } from 'helpers/countries';
import { useClipboard, useCountryCodesContext, useToggle } from 'hooks';
import { formatDateString } from '../../helpers/date';
import { CompanyAddressDataCardAction, CompanyAddressDataCardActionButton, CompanyAddressDataCardHeader, CompanyAddressDataCardItem, CompanyRegisteredAddressDisplayValue, } from './components';
import './CompanyAddressDataCard.scss';
const CompanyAddressDataCard = ({ address, className, disabledTooltipProps, disableManageActions, hasError, isEdittingDisabled, isSubmitting, onEdit, onDelete, }) => {
    const [isExpanded, toggleExpanded] = useToggle();
    const countryCodesMap = useCountryCodesContext();
    const countryName = getCountryNameFromCode(countryCodesMap, address.country);
    const fullAddress = `${address.streetAddress}, ${address.postalcode} ${address.city}, ${countryName}`;
    const ref = useClipboard({ copyValue: fullAddress });
    let createdDateString = null;
    let updatedDateString = null;
    if (address?.createdByUserFullName) {
        createdDateString = `Added on ${formatDateString(address.created, DateFormats.LOCAL_DATE_SHORT)}`;
    }
    if (address?.lastUpdatedByUserFullName) {
        updatedDateString = `Updated on ${formatDateString(address.modified, DateFormats.LOCAL_DATE_SHORT)}`;
    }
    return (React.createElement("div", { className: clsx('company-address-data-card', className) },
        React.createElement(CompanyAddressDataCardHeader, { hasError: hasError, isExpanded: isExpanded, onToggleExpanded: toggleExpanded, title: fullAddress }),
        React.createElement("div", { className: clsx('company-address-data-card__expandable', {
                'company-address-data-card__expandable--expanded': isExpanded,
            }) },
            React.createElement(CompanyAddressDataCardItem, { actions: [
                    React.createElement(CompanyAddressDataCardAction, { icon: IconNames.DUPLICATE, key: "Copy Registered Address", ref: ref, tooltipTitle: "Copy registered address" }),
                ], label: "Registered Address", value: React.createElement(CompanyRegisteredAddressDisplayValue, { address: address, countryName: countryName }) }),
            createdDateString && (React.createElement(CompanyAddressDataCardItem, { actions: [
                    React.createElement(CompanyAddressDataCardAction, { icon: IconNames.EYE_OPEN, key: createdDateString, tooltipTitle: React.createElement(Text.Regular, null, createdDateString) }),
                ], label: "Added by", value: React.createElement(Text.Regular, { color: typography.TextColor.BLACK }, address.createdByUserFullName) })),
            updatedDateString && (React.createElement(CompanyAddressDataCardItem, { actions: [
                    React.createElement(CompanyAddressDataCardAction, { icon: IconNames.EYE_OPEN, key: updatedDateString, tooltipTitle: React.createElement(Text.Regular, null, updatedDateString) }),
                ], label: "Updated by", value: React.createElement(Text.Regular, { color: typography.TextColor.BLACK }, address.lastUpdatedByUserFullName) })),
            !disableManageActions && (React.createElement("div", { className: "company-address-data-card__expandable__actions" },
                React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: disabledTooltipProps },
                    React.createElement(CompanyAddressDataCardActionButton, { dataTestId: "companyAddressEdit", icon: IconNames.EDIT, isDisabled: isSubmitting || hasError || isEdittingDisabled, onClick: onEdit })),
                React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: disabledTooltipProps },
                    React.createElement(CompanyAddressDataCardActionButton, { dataTestId: "companyAddressDelete", icon: IconNames.TRASH, isDisabled: isSubmitting || isEdittingDisabled, onClick: onDelete })))))));
};
export default CompanyAddressDataCard;
