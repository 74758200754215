import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const EntityWidgetSection = ({ children, className, style }) => (
  <div className={clsx('entity-widget--section', className)} style={style}>
    {children}
  </div>
);

EntityWidgetSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

EntityWidgetSection.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default EntityWidgetSection;
