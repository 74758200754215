import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from 'components/layout';

/**
 * Generic card component with flex layout.
 *
 * @param {*} children
 * @param {string} className
 * @param {Object} rowClassName Class name to forward to Row component.
 * @param {Object} rest Props to forward to Row component.
 * @returns {StatelessComponent}
 */
const Card = ({ children, className, rowClassName, ...rest }) => (
  <Row className={rowClassName} {...rest}>
    <Col className={clsx('generic-card with-shadow', className)}>{children}</Col>
  </Row>
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  rowClassName: PropTypes.string,
};

Card.defaultProps = {
  children: undefined,
  className: undefined,
  rowClassName: undefined,
};

export default Card;
