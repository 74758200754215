import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';

import { ButtonV2 } from 'components/buttonV2';
import {
  getBouncedSwalShadedBlockContent,
  getBouncedSwalDescription,
  getBouncedSwalTitle,
  getBouncedSwalInstruction,
} from 'components/error/helpers/text';
import { Icon, IconNames } from 'components/icon';
import ShadedBlock from 'components/shadedBlock';
import { Text } from 'components/text';

import { ButtonSize } from 'constants/button';
import { BOUNCED_EMAIL_IMAGE } from 'constants/images';
import { colors, sizes, typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import './BouncedEmailError.scss';

/**
 * SWAL inner component displayed when
 * - an item is submitted with bounced contacts OR
 * - a user clicks 'View details' in the companies page on a contact with a bounced email
 * Note: this has to be a single element, hence the <div> and not <React.Fragment>, for SWAL to render it properly
 * @param {Object} props
 * @param {Boolean} props.isInCreateItemForm
 * @param {Object} rest
 * @return {StatelessComponent}
 */
const BouncedEmailError = ({ isInCreateItemForm, ...rest }) => {
  const onClose = () => {
    swal.close();
  };

  const onEditContact = () => {
    const { onEdit, partnershipMember } = rest;
    onEdit(partnershipMember);
    onClose();
  };

  return (
    <div>
      <img alt="Bounced email" className="bounced-email--image" src={BOUNCED_EMAIL_IMAGE} />

      <Text.Bold
        className="bounced-email--title margin-bottom--sm"
        color={colors.colorSteelHex}
        size={typography.TextSize.LEVEL_400}
      >
        {getBouncedSwalTitle(isInCreateItemForm, rest)}
      </Text.Bold>

      <p className="bounced-email--text">{getBouncedSwalDescription(isInCreateItemForm, rest)}</p>

      <ShadedBlock className="margin-bottom--m-large margin-top--m" intent={Intent.NEUTRAL}>
        <Text className="bounced-email--shaded-text" color={colors.colorSteelHex}>
          {getBouncedSwalShadedBlockContent(isInCreateItemForm, rest)}
        </Text>
      </ShadedBlock>

      <Icon
        className="margin-right--xm"
        color={colors.colorMainJordanHex}
        icon={IconNames.HELP}
        size={sizes.iconSizes.EXTRA_MEDIUM}
      />
      <Text.Bold size={typography.TextSize.LEVEL_200}>How can I fix it?</Text.Bold>

      <p className="bounced-email--text">{getBouncedSwalInstruction(isInCreateItemForm, rest)}</p>

      <div className="bounced-email--footer">
        <ButtonV2
          className={clsx({
            // hide it but maintain the same button positions
            // (Close button stays on the right side)
            'visibility--hidden': !!isInCreateItemForm,
          })}
          intent={Intent.NEUTRAL}
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.REPEAT}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          onClick={onEditContact}
          size={ButtonSize.MEDIUM}
        >
          Reactivate email address
        </ButtonV2>
        <ButtonV2 intent={Intent.DANGER} onClick={onClose} size={ButtonSize.MEDIUM}>
          Close
        </ButtonV2>
      </div>
    </div>
  );
};

BouncedEmailError.propTypes = {
  partnerCompanyName: PropTypes.string,
  bouncedContacts: PropTypes.arrayOf(PropTypes.shape({})),
  currentCompanyName: PropTypes.string,
  isInCreateItemForm: PropTypes.bool,
  itemKind: PropTypes.string,
  onEdit: PropTypes.func,
  partnershipMember: PropTypes.shape(),
};

BouncedEmailError.defaultProps = {
  partnerCompanyName: undefined,
  bouncedContacts: undefined,
  currentCompanyName: undefined,
  isInCreateItemForm: undefined,
  itemKind: undefined,
  onEdit: undefined,
  partnershipMember: undefined,
};

export default BouncedEmailError;
