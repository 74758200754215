import PropTypes from 'prop-types';

import { ContactSidePanelType } from 'constants/sidePanels';
import { FlairTagType, TagType } from 'constants/ui';

import { isArray, isFn, isBool, isUndef } from 'helpers/utility';

/**
 * Checks for input.name or name in props.
 * @param props {Object}
 * @param propName {string}
 * @param componentName {string}
 * @return {Error|null}
 */
export const inputNameOrNameProp = (props, propName, componentName) => {
  // Don't show an error when disabled
  if (props.isDisabled) {
    return null;
  }

  // Has name prop
  if (props.name) {
    return null;
  }

  // Has input.name prop
  if (props.input && props.input.name) {
    return null;
  }

  // Has custom onChange logic - we might be rendering Checkbox outside of a form
  // & we are okay with name or input.name not being passed
  if (props.onChange) {
    return null;
  }

  return new Error(`A non-disabled ${componentName} must have either 'name' or 'input.name'`);
};

/**
 * Checks for label or placeholder in props.
 * @param {Object} props
 * @param {string} propName
 * @param {string} componentName
 * @return {Error|null}
 */
export const labelOrPlaceholderProp = (props, propName, componentName) => {
  const labelType = typeof props.label;
  const labelLen = props.label ? props.label.length : 0;
  const placeholderType = typeof props.placeholder;
  const placeholderLen = props.placeholder ? props.placeholder.length : 0;

  // Has label string prop
  if (labelType === 'string' && labelLen > 0) {
    return null;
  }

  // Has placeholder string prop
  if (placeholderType === 'string' && placeholderLen > 0) {
    return null;
  }

  return new Error(
    `${componentName} expected either label or placeholder prop to be a string with length, but label was '${labelType}' (${labelLen}) and placeholder was '${placeholderType}' (${placeholderLen})`,
  );
};

/**
 * Checks for JS date.
 * @param props {Object}
 * @param propName {string}
 * @param componentName {string}
 * @return {Error|null}
 */
export const dateProp = (props, propName, componentName) => {
  // more info on this check in this thread:
  // https://stackoverflow.com/a/10589791/7111667
  if (props[propName] instanceof Date && !Number.isNaN(props[propName].valueOf())) {
    return null;
  }

  return new Error(`${componentName} expected type 'date' for prop ${propName}`);
};

/**
 * Checks for an array of requirements tuples.
 * @param props {Object}
 * @param propName {string}
 * @param componentName {string}
 * @return {Error|*}
 */
export const accessRequirementsProp = (props, propName, componentName) => {
  const propVal = props[propName];

  if (propVal.some((element) => !Array.isArray(element))) {
    return new Error(`${componentName} expected a list with items of type 'array' for prop '${propName}'`);
  }

  return propVal.reduce((err, requirementPair) => {
    if (err) {
      return err;
    }

    if (requirementPair.length !== 2) {
      return new Error(`${componentName} expected each requirement array to contain 2 items for prop '${propName}'`);
    }

    if (typeof requirementPair[0] !== 'function') {
      return new Error(
        `${componentName} expected the first item in each requirement array to be a selector function for prop '${propName}'`,
      );
    }

    return null;
  }, null);
};

/**
 * Checks for children or render function in props.
 * @param {Object} props
 * @param {string} propName
 * @param {string} componentName
 * @return {Error|null}
 */
export const childrenOrRenderProp = (props, propName, componentName) => {
  const { children, render } = props;

  // Has children prop
  if (children !== undefined) {
    return null;
  }

  // Has render function prop
  if (isFn(render)) {
    return null;
  }

  const renderType = typeof render;

  return new Error(
    `${componentName} expected either 'children' or 'render' function in props, but children was 'undefined' and render was '${renderType}'`,
  );
};

/**
 * Creates a prop-types checker function that looks for a valid select field `value` property.
 * Valid values for select fields are strings, numbers, objects, and arrays, or undefined/null.
 * Basically, anything that isn't a boolean or function type.
 * @param {string} [valuePropName='value']
 * @return {function(...[*]=)}
 */
export const makeSelectFieldValuePropCheck =
  (valuePropName = 'value') =>
  (props, propName, componentName) => {
    const prop = props[valuePropName];

    if (!isFn(prop) && !isBool(prop)) {
      return undefined;
    }

    const type = typeof prop;

    return new Error(
      `${componentName} expected ${valuePropName} to be a string, number, object, or array, but received '${type}'`,
    );
  };

/**
 * Creates a prop-types checker function that uses the string value of props[propName]
 * to check for the prop it points to, and ensure that prop is a function.
 *
 * @example
 * // assuming pointerProp is defined as associatedCallbackProp,
 * // will get 'onBlur' from pointerProp, and check that is a function
 * { pointerProp: 'onBlur', onBlur: someFunction }
 *
 * @param {ComponentProps} props
 * @param {string} propName
 * @param {string} componentName
 * @return {undefined|Error}
 */
export const associatedCallbackProp = (props, propName, componentName) => {
  const eventName = props[propName];
  const callbackProp = props[eventName];

  if (isFn(callbackProp)) {
    return undefined;
  }

  const type = typeof callbackProp;

  return new Error(`${componentName} expected ${eventName} to be a callback function, but received '${type}'`);
};

/**
 * Creates a prop-types checker function that asserts the prop is a TagType value.
 * @param {boolean} isRequired
 * @param {ComponentProps} props
 * @param {string} propName
 * @param {string} componentName
 * @return {undefined|Error}
 */
export const checkTagTypeProp = (isRequired, props, propName, componentName) => {
  const prop = props[propName];

  if (!isUndef(TagType[prop]) || (isUndef(prop) && !isRequired)) {
    return undefined;
  }

  const type = typeof prop;

  return new Error(`${componentName} expected ${propName} to be a TagType, but received '${type}'`);
};

export const tagTypeProp = checkTagTypeProp.bind(null, false);
tagTypeProp.isRequired = checkTagTypeProp.bind(null, true);

/**
 * Creates a prop-types checker function that asserts the prop is a FlairTagType value.
 * @param {ComponentProps} props
 * @param {string} propName
 * @param {string} componentName
 * @return {undefined|Error}
 */
export const flairTagTypeProp = (props, propName, componentName) => {
  const prop = props[propName];

  if (isUndef(prop) || !isUndef(FlairTagType[prop])) {
    return undefined;
  }

  const type = typeof prop;

  return new Error(`${componentName} expected ${propName} to be a FlairTagType, but received '${type}'`);
};

/**
 * PropType definition for things that are renderable.
 * Most will be functions, but things like React.memo, which
 * we'll treat the same as other components, are actually objects.
 * @type {PropType}
 */
export const renderableProp = PropTypes.oneOfType([PropTypes.func, PropTypes.object]);

/**
 * There are many cases where a prop should be one of the constants we've defined
 *
 * This helper creates a propType from the values of a constant object (or any object). It can be chained with
 * .isRequired() in the component.
 * @param {Object} constantObject
 * @returns {PropTypes.Requireable<any>}
 */
export const oneOfValuesFromObject = (constantObject) => PropTypes.oneOf(Object.values(constantObject));

/**
 * Validates that a prop's value is found in ContactSidePanelType.
 * @type {function}
 */
export const contactSidePanelPropType = oneOfValuesFromObject(ContactSidePanelType);

/**
 * Returns the propTypes for an integration
 * @param {object} overrides
 * @return {LedgerIntegration} propTypes
 */
export const getIntegrationPropTypes = (overrides = {}) => ({
  ...PropTypes.shape({
    accountName: PropTypes.string,
    application: PropTypes.string,
    id: PropTypes.string,
    isConnecting: PropTypes.bool,
    isDisconnecting: PropTypes.bool,
    dateConnected: PropTypes.string,
    dateLastConnection: PropTypes.string,
    dateLastSync: PropTypes.string,
    dateLastToken: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    ...overrides,
  }),
});

/**
 * Creates a prop-types checker function that asserts the prop can be used as a field validator, AKA that it is
 * either a function, or an array of functions.
 * @param {Boolean} isRequired
 * @param {ComponentProps} props
 * @param {string} propName
 * @param {string} componentName
 * @return {Error|undefined}
 */
export const checkFieldValidatorPropType = (isRequired, props, propName, componentName) => {
  const prop = props[propName];

  if (isUndef(prop) && !isRequired) {
    return undefined;
  }

  if (isFn(prop)) {
    return undefined;
  }

  if (isArray(prop) && prop.every(isFn)) {
    return undefined;
  }

  return new Error(
    `${componentName} expected ${propName} to be a field validator, which must be a function or an array of functions`,
  );
};

/**
 * Checks the a prop type can be used as a field validator. Does not enforce isRequired.
 * @type {Function}
 */
export const fieldValidatorPropType = checkFieldValidatorPropType.bind(null, false);

/**
 * Checks the a prop type can be used as a field validator. Enforces isRequired.
 * @type {Function}
 */
fieldValidatorPropType.isRequired = checkFieldValidatorPropType.bind(null, true);

/**
 * Checks that a prop (probably usually props.children) is either a node or a function (render prop).
 * @type {Function}
 */
export const universalRenderPropType = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);

/**
 * Checks that a prop is Truthy when isCrossBorder is true.
 *
 * @param {string} targetProp - the prop you're checking for
 * @returns {(function(ComponentProps, string, string): (Error|null))|*}
 */
export const internationalFormPropTypeChecker = (targetProp) => (props, propName, componentName) => {
  const { isCrossBorder } = props;

  if (isCrossBorder) {
    const { [targetProp]: targetPropValue } = props;

    // for example, check if props.partnership is defined, which is required for cross-border addresses
    if (isUndef(targetPropValue)) {
      return new Error(`${targetProp} in ${componentName} is required when isCrossBorder is true`);
    }
  }

  return null;
};
