import { SideSheet } from '@routable/components';
import React from 'react';
export const WatchlistHitDetailsSideSheet = ({ state }) => {
    return (React.createElement(SideSheet, { onClose: state.onClose, state: state.state, title: React.createElement(SideSheet.TwoLineTitle, { label: "Watchlist" }, state.hitDetails?.source.name) },
        React.createElement("div", { className: "flex flex-col gap-7" },
            state.hitDetails?.url && (React.createElement("div", { className: "flex flex-col border-b border-b-grey-10 pb-4" },
                React.createElement("span", { className: "text-xs text-grey-60" }, "Source URL"),
                React.createElement("a", { className: "text-blue-40 text-sm hover:underline focus-visible:underline truncate", href: state.hitDetails?.url, target: "_blank", rel: "noreferrer" }, state.hitDetails?.url))),
            state.hitDetails?.fields.map(({ label, value }) => (React.createElement("div", { className: "flex flex-col border-b border-b-grey-10 pb-4", key: label },
                React.createElement("span", { className: "text-xs text-grey-60 first-letter:capitalize" }, label),
                React.createElement("span", { className: "text-black text-sm" }, value)))))));
};
