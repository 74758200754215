import clsx from 'clsx';
import React from 'react';
import { getProgressBarStepClass } from './helpers/ui';
import './ProgressBar.scss';
const ProgressBar = ({ currentStepPosition, isExternal, showNum = true, steps }) => (React.createElement("div", { className: clsx('progress-bar', { external: isExternal }) },
    React.createElement("div", { className: "progress-bar--steps" }, steps.map((step, idx) => {
        const currentStepClass = getProgressBarStepClass(idx, currentStepPosition);
        const stepText = `${showNum ? `${step.num} ` : ' '}${step.description}`;
        return (React.createElement("div", { className: `progress-bar--step-container ${currentStepClass}`, key: step.num },
            React.createElement("div", { className: "progress-bar--step" },
                React.createElement("div", { className: "content--left" },
                    React.createElement("div", { className: "circle" },
                        React.createElement("div", { className: "aligner" }))),
                React.createElement("div", { className: "content--right" },
                    React.createElement("span", { className: "text" }, stepText)))));
    }))));
export default ProgressBar;
