import clsx from 'clsx';
import React from 'react';
import { FlexCol } from 'components';
import {} from './ExternalBlocksSection.types';
import './ExternalBlocksSection.scss';
const ExternalBlocksSection = ({ children, className }) => (React.createElement(FlexCol, { className: clsx('external-blocks-section', className) }, children));
ExternalBlocksSection.defaultProps = {
    className: undefined,
};
export default ExternalBlocksSection;
