import React from 'react';
import ReactSelect from 'react-select';
import { sizes } from 'constants/styles';
import color from 'global/css/base/color.scss';
import spacing from 'global/css/base/spacing.scss';
import {} from './Select.types';
const customStyles = ({ fontSize = sizes.spacing.MEDIUM } = {}) => ({
    option: (provided) => ({
        ...provided,
        fontSize,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize,
        color: color.greyXDark,
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0 6px 0 0',
        '> svg': {
            width: spacing.space12,
        },
        color: color.greyDark,
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: '132',
    }),
    control: (provided) => ({
        ...provided,
        border: `${spacing.space1} solid ${color.blueLight};`,
        borderRadius: '8px',
    }),
});
export const Select = ({ value: currentValue, options, onChange, ...props }) => (React.createElement(ReactSelect, { menuPlacement: "auto", menuPortalTarget: document.body, onChange: ({ value }) => onChange(value), options: options, styles: customStyles(), value: options.find(({ value }) => value === currentValue), ...props }));
