import { Environment } from 'constants/env';
import { NETSUITE, QBO, SAGE_INTACCT, XERO } from 'constants/integration';
import { ItemKinds } from 'constants/item';
import { LedgerApplicationTypes } from 'constants/ledger';
export const HOME = '/';
export const DASHBOARD = 'dashboard';
export const EXPORT = 'exporter/:id';
export const ITEMS = 'items';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const ONBOARDING = 'onboard';
export const SIGNUP = 'signup-deprecated';
export const ACCOUNT_CREATION = 'signup';
export const GET_STARTED = 'get-started';
export const PROCESSING = 'processing';
export const CREATE_PASSWORD = 'create-password';
export const RESET_PASSWORD = 'reset-password';
export const RESET_PASSWORD_COMPLETE = 'reset-password/complete';
export const ACCEPT_MEMBERSHIP_INVITE = 'accept-invite';
export const CONVERT_EXTERNAL_COMPANY_TO_REGISTERED = 'convert-company';
export const EXTERNAL = 'external';
export const EXTERNAL_V2 = 'ext';
export const SECURITY = 'security';
export const LEGAL = 'legal';
export const SITE_OFFLINE = 'maintenance';
export const NOT_FOUND = 'not-found';
export const REDIRECT_TO_BLOG = 'blog';
export const REDIRECT_TO_DOCS = 'docs';
export const QUICKSWITCH = 'quickswitch';
export const SENTRYERRORPAGE = 'sentryerror/:type?';
export const DEEP_LINK = 'deep_link';
export const ERROR = 'error';
export const SUCCESS = 'success';
export const PRIVACY = 'privacy';
export const TERMS = 'terms';
export const SETTINGS = 'settings';
export const PAYMENTS = 'payments';
export const CONTACTS = 'contacts';
export const GUIDE = 'guide';
export const ITEM = 'item';
export const PAYMENTS_LIST_TABS = {
    INBOX: 'inbox',
    PAYABLES: 'payables',
    RECEIVABLES: 'receivables',
    RECENT: 'recent',
};
export const PAYMENTS_LIST_FILTERS = {
    ALL: 'all',
    COMPLETED: 'completed',
    COMPLETED_AND_EXTERNALLY_PAID: 'completed_and_externally_paid',
    COMPLIANCE_HOLD: 'compliance_hold',
    CREATED: 'created',
    FAILED_AND_ISSUE: 'failed_and_issue',
    INITIATED: 'initiated',
    MY_APPROVAL: 'needs_my_approval',
    NEEDS_APPROVAL: 'needs_approval',
    NEEDS_REVIEW: 'needs_review',
    PAID: 'paid',
    PO_DISCREPANCY: 'po_discrepancy_hold',
    PO_DISCREPANCY_2WAY_ONLY: 'po_discrepancy_hold?po_match_type=2-way',
    PO_DISCREPANCY_3WAY_ONLY: 'po_discrepancy_hold?po_match_type=3-way',
    MISSING_RECEIPT_ONLY: 'po_discrepancy_hold?po_discrepancy_type=missing_receipt_only',
    TEAM_APPROVAL: 'needs_team_approval',
    PENDING_VENDOR_ACTION: 'pending_vendor_action',
    READY_TO_SEND: 'ready_to_send',
    SCHEDULED: 'scheduled',
    UNPAID_BILLS: 'unpaid_bills',
    UNPAID_INVOICES: 'unpaid_invoices',
    WAITING_FOR_ME: 'waiting_for_me',
    WAITING_FOR_OTHERS: 'waiting_for_others',
};
export const ITEM_DETAILS_FILTERS = {
    ITEM: 'item',
    PARTNER: 'partner',
    MY_DETAILS: 'my_details',
};
export const CONTACTS_TABS = { COMPANIES: 'companies' };
export const COMPANIES_LIST_FILTERS = {
    ALL_TAX_ELIGIBLE_VENDORS: 'all_tax_eligible_vendors',
    ALL: 'all',
    CUSTOMERS: 'customers',
    INVITES: 'invites',
    MISSING_TAX_INFO: 'missing_tax_info',
    VENDORS: 'vendors',
};
export const SELECTED_COMPANY_TABS = {
    ABOUT: 'about',
    CUSTOMER_INFO: 'customer_info',
    PAYMENTS: 'payments',
    TAX_DOCS: 'tax_docs',
    VENDOR_INFO: 'vendor_info',
    VENDOR_COMPLIANCE_CHECKS: 'compliance',
};
export const SELECTED_COMPANY_TABS_TEXT = {
    [SELECTED_COMPANY_TABS.ABOUT]: 'About',
    [SELECTED_COMPANY_TABS.CUSTOMER_INFO]: 'Customer info',
    [SELECTED_COMPANY_TABS.PAYMENTS]: 'Payment history',
    [SELECTED_COMPANY_TABS.VENDOR_INFO]: 'Vendor info',
    [SELECTED_COMPANY_TABS.TAX_DOCS]: 'Tax and Docs',
    [SELECTED_COMPANY_TABS.VENDOR_COMPLIANCE_CHECKS]: 'Compliance',
};
export const GUIDE_TABS = {
    BANK_CONNECTIONS: 'connect_bank',
    COMPANY_BRANDING: 'company_branding',
    COMPANY_INFO: 'company_info',
    COMPANY_LEGAL: 'company_legal',
    COMPANY_REPS: 'company_representatives',
    CONNECT_LEDGER: 'connect_ledger',
    ENABLE_PAYMENTS: 'enable_payments',
    INVITE_TEAM: 'invite_team',
    USER_AVATAR: 'user_avatar',
    VERIFY_ACCOUNT: 'verify_account',
};
export const INTEGRATIONS_TABS = {
    NETSUITE: LedgerApplicationTypes.NETSUITE,
    QBO: LedgerApplicationTypes.QBO,
    SAGE_INTACCT: LedgerApplicationTypes.SAGE_INTACCT,
    XERO: LedgerApplicationTypes.XERO,
};
export const SETTINGS_TABS = {
    ACCOUNT: 'account',
    USER: 'user',
    BILLING: 'billing',
};
export const SETTINGS_ACCOUNT_FILTERS = {
    API_ACCESS: 'api_access',
    BALANCE: 'balance',
    BANK_CONNECTIONS: 'company_bank_connections',
    BULK_UPLOAD_HISTORY: 'bulk_upload_history',
    BULK_UPLOADS: 'bulk_upload_history',
    COMPANY_BRANDING: 'company_branding',
    COMPANY_INFO: 'company_info',
    COMPANY_LEGAL: 'company_legal',
    COMPANY_REP_INFO: 'company_auth_rep_info',
    CREATE_ROLE: 'create_role',
    INACTIVE_TEAM_MEMBER: 'inactive_team_member',
    INACTIVE: 'inactive',
    INTEGRATIONS: 'integrations',
    INVITE_TEAM_MEMBER: 'invite_team_member',
    INVITED: 'invited',
    LEDGER_FIELD_MAPPING: 'ledger_field_mapping',
    PAYABLES_AND_VENDORS: 'payable_configurations',
    PAYMENT_METHODS: 'payment_methods',
    REACTIVATE_TEAM_MEMBER: 'reactivate',
    REP_INFO: 'rep_info',
    ROLES: 'roles',
    SINGLE_SIGN_ON: 'single_sign_on',
    SYNC_ERRORS: 'sync_errors',
    TAX: 'tax',
    TEAM_MEMBERS: 'team_members',
    WEBHOOKS_SETTINGS: 'webhooks_settings',
};
export const SETTINGS_PAYABLE_APPROVALS_FILTERS = {
    APPROVAL_RULES: 'payable_approval_rules',
    APPROVAL_SETTINGS: 'payable_approval_settings',
};
export const SETTINGS_USER_FILTERS = {
    PROFILE: 'profile',
    REPRESENTATIVE: 'company_representative',
    SECURITY: 'security',
    NOTIFICATIONS: 'notifications',
};
export const PAY = 'pay';
export const ACCEPT = 'accept';
export const TAX = 'tax';
export const UPDATE_PAYMENT_METHOD = 'update_payment_method';
export const EXT_CUSTOMER_SUFFIX = 'make_payments';
export const EXT_VENDOR_SUFFIX = 'get_paid';
export const EXT_UPDATE_PREFIX = 'update';
export const EXT_AGREE_PREFIX = 'agree_to';
export const EXT_SIGN_PREFIX = 'sign';
export const EXT_TAXES_PREFIX = 'taxes';
export const EXT_UPDATE_CUSTOMER = `${EXT_UPDATE_PREFIX}/${EXT_CUSTOMER_SUFFIX}`;
export const EXT_UPDATE_VENDOR = `${EXT_UPDATE_PREFIX}/${EXT_VENDOR_SUFFIX}`;
export const EXT_AGREE_CUSTOMER = `${EXT_AGREE_PREFIX}/${EXT_CUSTOMER_SUFFIX}`;
export const EXT_AGREE_VENDOR = `${EXT_AGREE_PREFIX}/${EXT_VENDOR_SUFFIX}`;
export const EXT_SIGN_W9 = `${EXT_SIGN_PREFIX}/w9`;
export const EXT_SIGN_AUTO_DEBIT = `${EXT_SIGN_PREFIX}/auto_debit_agreement`;
export const EXT_GET_PAID = 'get_paid';
export const EXT_PAY = 'pay';
export const EXT_TAXES_SUBMIT_W9 = `${EXT_TAXES_PREFIX}/submit/w9`;
export const EXT_TAXES_SUBMIT_W8 = `${EXT_TAXES_PREFIX}/submit/w8`;
export const EXT_GET_PAID_WITH_BASE = `${EXTERNAL_V2}/${EXT_GET_PAID}`;
export const EXT_PAY_WITH_BASE = `${EXTERNAL_V2}/${EXT_PAY}`;
export const EXT_AGREE_VENDOR_WITH_BASE = `${EXTERNAL_V2}/${EXT_AGREE_VENDOR}`;
export const EXT_AGREE_CUSTOMER_WITH_BASE = `${EXTERNAL_V2}/${EXT_AGREE_CUSTOMER}`;
export const BULK_ACTIONS_ROUTE = `/${DASHBOARD}/batch`;
export const BULK_ACTIONS_IMPORT_ROUTE = `${BULK_ACTIONS_ROUTE}/import`;
export const BULK_ACTIONS_IMPORT_ITEMS_ROUTE = `${BULK_ACTIONS_IMPORT_ROUTE}/items`;
export const PARTNERSHIP_REQUESTS = 'partnership_requests';
export const FUNDING_ACCOUNT_REQUEST_STATES = 'funding_account_request_states';
export const CONTACTS_ROUTE = `/${DASHBOARD}/${CONTACTS}`;
export const COMPANIES_ROUTE = `/${DASHBOARD}/${CONTACTS}/${CONTACTS_TABS.COMPANIES}`;
export const PAYMENTS_ROUTE = `/${DASHBOARD}/${PAYMENTS}`;
export const INBOX_ROUTE = `/${DASHBOARD}/${PAYMENTS}/${PAYMENTS_LIST_TABS.INBOX}`;
export const RECEIVABLES_ROUTE = `/${DASHBOARD}/${PAYMENTS}/${PAYMENTS_LIST_TABS.RECEIVABLES}`;
export const PAYABLES_ROUTE = `/${DASHBOARD}/${PAYMENTS}/${PAYMENTS_LIST_TABS.PAYABLES}`;
export const GUIDE_ROUTE = `/${DASHBOARD}/${GUIDE}`;
export const SETTINGS_ROUTE = `/${DASHBOARD}/${SETTINGS}`;
export const SETTINGS_ACCOUNT_ROUTE = `/${DASHBOARD}/${SETTINGS}/${SETTINGS_TABS.ACCOUNT}`;
export const SETTINGS_ACCOUNT_BALANCE_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.BALANCE}`;
export const SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.INTEGRATIONS}`;
export const SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.TEAM_MEMBERS}`;
export const SETTINGS_ACCOUNT_INVITED_TEAM_MEMBERS_ROUTE = `${SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.INVITED}`;
export const SETTINGS_ACCOUNT_INACTIVE_TEAM_MEMBERS_ROUTE = `${SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.INACTIVE}`;
export const SETTINGS_ACCOUNT_ROLES_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.ROLES}`;
export const SETTINGS_ACCOUNT_BULK_UPLOAD_HISTORY_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.BULK_UPLOAD_HISTORY}`;
export const SettingsAccountBulkUploadHistoryTabs = {
    PAYABLES: SETTINGS_ACCOUNT_BULK_UPLOAD_HISTORY_ROUTE,
};
export const SETTINGS_ACCOUNT_INVITE_TEAM_MEMBERS_ROUTE = `${SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.INVITE_TEAM_MEMBER}`;
export const SETTINGS_ACCOUNT_CONNECT_INTEGRATIONS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.INTEGRATIONS}/auth`;
export const CUSTOM_LEDGER_AUTH_FLOW_ROUTE = `${SETTINGS_ACCOUNT_CONNECT_INTEGRATIONS_ROUTE}/:application`;
export const SETTINGS_ACCOUNT_PAYMENT_METHODS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.PAYMENT_METHODS}`;
export const SETTINGS_ACCOUNT_BANK_CONNECTIONS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.BANK_CONNECTIONS}`;
export const SETTINGS_PAYABLE_APPROVAL_RULES_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_PAYABLE_APPROVALS_FILTERS.APPROVAL_RULES}`;
export const SETTINGS_PAYABLE_APPROVAL_SETTINGS_ROUTE = `${SETTINGS_ACCOUNT_ROUTE}/${SETTINGS_PAYABLE_APPROVALS_FILTERS.APPROVAL_SETTINGS}`;
export const SETTINGS_USER_ROUTE = `/${DASHBOARD}/${SETTINGS}/${SETTINGS_TABS.USER}`;
export const SIGNUP_CREATE_ACCOUNT = `/${SIGNUP}`;
export const SIGNUP_VERIFY_EMAIL = `/${SIGNUP}/verify`;
export const SIGNUP_CREATE_NAMESPACE = `/${SIGNUP}/namespace`;
export const SIGNUP_CONNECT_BANK = `/${SIGNUP}/connect-bank`;
export const ACCOUNT_CREATION_CREATE_ACCOUNT = `/${ACCOUNT_CREATION}`;
export const ACCOUNT_CREATION_VERIFY_EMAIL = `/${ACCOUNT_CREATION}/verify`;
export const ACCOUNT_CREATION_CREATE_NAMESPACE = `/${ACCOUNT_CREATION}/namespace`;
export const PARAMS = {
    PAGE: ':page?',
    TAB: ':tab?',
    FILTER: ':filter?',
    ID: ':id?',
    ITEM_FILTER: ':itemFilter?',
    KIND: ':kind?',
    METHOD: ':method?',
};
export const SETTINGS_ACCOUNT_TEAM_MEMBER_REACTIVATE_ROUTE = `${SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE}/${SETTINGS_ACCOUNT_FILTERS.REACTIVATE_TEAM_MEMBER}`;
export const MODALS = {
    CREATE_PARTNERSHIP: 'create_partnership',
    REP_INFO: 'rep_info',
};
export const CREATE_ITEM_TABS = {
    CREATE: 'create',
    CREATE_BILL: 'create_bill',
    CREATE_PAYABLE: 'create_payable',
    CREATE_RECEIVABLE: 'create_receivable',
    BATCH: 'batch/import/items',
};
export const CREATE_ITEM_STATES = {
    AWAITING_PAYMENT: 'awaiting_payment',
    BULK_UPLOAD: 'batch',
    EDIT: 'edit',
    NEW: 'new',
};
export const CREATE_PAYABLE_ROUTE = `/${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_PAYABLE}`;
export const CREATE_RECEIVABLE_ROUTE = `/${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_RECEIVABLE}`;
export const CREATE_BILL_ROUTE = `/${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_BILL}`;
export const CREATE_NEW_BILL_ROUTE = `/${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_BILL}/${CREATE_ITEM_STATES.NEW}`;
export const CREATE_NEW_PAYABLE_ROUTE = `${CREATE_PAYABLE_ROUTE}/${CREATE_ITEM_STATES.NEW}`;
export const CREATE_NEW_RECEIVABLE_ROUTE = `${CREATE_RECEIVABLE_ROUTE}/${CREATE_ITEM_STATES.NEW}`;
export const itemKindToTabMap = {
    [ItemKinds.RECEIVABLE]: PAYMENTS_LIST_TABS.RECEIVABLES,
    [ItemKinds.PAYABLE]: PAYMENTS_LIST_TABS.PAYABLES,
};
export const EXCLUDE_LIVE_CHAT_ROUTES = [
    ACCOUNT_CREATION_CREATE_ACCOUNT.slice(1),
    ACCOUNT_CREATION_CREATE_NAMESPACE.slice(1),
    ACCOUNT_CREATION_VERIFY_EMAIL.slice(1),
    CREATE_PASSWORD,
    LOGOUT,
    RESET_PASSWORD_COMPLETE,
    QUICKSWITCH,
];
export const EXTERNAL_ZENDESK_ROUTES = [CONVERT_EXTERNAL_COMPANY_TO_REGISTERED, EXTERNAL, EXTERNAL_V2];
export const ACTIVE_EXTERNAL_ROUTES = [
    EXT_UPDATE_CUSTOMER,
    EXT_UPDATE_VENDOR,
    EXT_AGREE_CUSTOMER,
    EXT_AGREE_VENDOR,
    EXT_SIGN_W9,
    EXT_SIGN_AUTO_DEBIT,
    EXT_PAY,
    EXT_GET_PAID,
    EXT_TAXES_SUBMIT_W9,
    EXT_TAXES_SUBMIT_W8,
];
export const APP_ROUTES = [
    ACCEPT_MEMBERSHIP_INVITE,
    CONVERT_EXTERNAL_COMPANY_TO_REGISTERED,
    CREATE_PASSWORD,
    DASHBOARD,
    EXTERNAL,
    LOGIN,
    LOGOUT,
    NOT_FOUND,
    ONBOARDING,
    RESET_PASSWORD,
    SIGNUP,
    SITE_OFFLINE,
];
export const ALLOWED_FORWARDING_ROUTES = [DASHBOARD];
export const FetchExportType = {
    CSV: 'text/csv',
};
export const FetchExportOption = {
    ITEM: 'item',
    LINE_ITEM: 'line_item',
};
export const FILTER_LABELS = {
    [COMPANIES_LIST_FILTERS.VENDORS]: 'Vendors',
    [COMPANIES_LIST_FILTERS.CUSTOMERS]: 'Customers',
    [COMPANIES_LIST_FILTERS.INVITES]: 'Invites',
    [GUIDE_TABS.BANK_CONNECTIONS]: 'Bank connections',
    [GUIDE_TABS.COMPANY_BRANDING]: 'Business branding',
    [GUIDE_TABS.COMPANY_INFO]: 'Business verification',
    [GUIDE_TABS.COMPANY_REPS]: 'Business representatives',
    [GUIDE_TABS.CONNECT_LEDGER]: 'Connect accounting software',
    [GUIDE_TABS.ENABLE_PAYMENTS]: 'Bank connections',
    [GUIDE_TABS.INVITE_TEAM]: 'Invite a new team member',
    [GUIDE_TABS.USER_AVATAR]: 'User profile',
    [GUIDE_TABS.VERIFY_ACCOUNT]: 'Verify your account',
    [PAYMENTS_LIST_FILTERS.COMPLETED_AND_EXTERNALLY_PAID]: 'Completed',
    [PAYMENTS_LIST_FILTERS.FAILED_AND_ISSUE]: 'Failed and Issue',
    [PAYMENTS_LIST_FILTERS.INITIATED]: 'In-transit',
    [PAYMENTS_LIST_FILTERS.PO_DISCREPANCY]: 'PO discrepancy',
    [PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL]: 'Needs approval',
    [PAYMENTS_LIST_FILTERS.MY_APPROVAL]: 'Needs my approval',
    [PAYMENTS_LIST_FILTERS.PAID]: 'Paid',
    [PAYMENTS_LIST_FILTERS.PENDING_VENDOR_ACTION]: 'Pending vendor action',
    [PAYMENTS_LIST_FILTERS.NEEDS_REVIEW]: 'Needs review',
    [PAYMENTS_LIST_FILTERS.SCHEDULED]: 'Scheduled',
    [PAYMENTS_LIST_FILTERS.TEAM_APPROVAL]: 'Needs team approval',
    [PAYMENTS_LIST_FILTERS.READY_TO_SEND]: 'Ready to send',
    [PAYMENTS_LIST_FILTERS.SCHEDULED]: 'Scheduled',
    UNPAID_BILLS_PAYABLES: 'Unpaid bills',
    UNPAID_INVOICES_RECEIVABLES: 'Unpaid invoices',
    [PAYMENTS_LIST_FILTERS.WAITING_FOR_ME]: 'Received payments',
    [PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS]: 'Pending vendor action',
    [PAYMENTS_LIST_FILTERS.COMPLIANCE_HOLD]: 'Compliance hold',
    [SETTINGS_ACCOUNT_FILTERS.BALANCE]: 'Balance',
    [SETTINGS_ACCOUNT_FILTERS.BANK_CONNECTIONS]: 'Bank connections',
    [SETTINGS_ACCOUNT_FILTERS.COMPANY_BRANDING]: 'Business branding',
    [SETTINGS_ACCOUNT_FILTERS.COMPANY_INFO]: 'Account information',
    [SETTINGS_ACCOUNT_FILTERS.COMPANY_LEGAL]: 'Business verification',
    [SETTINGS_ACCOUNT_FILTERS.COMPANY_REP_INFO]: 'Business representatives',
    [SETTINGS_ACCOUNT_FILTERS.INTEGRATIONS]: 'Integrations',
    [SETTINGS_ACCOUNT_FILTERS.TEAM_MEMBERS]: 'Team members',
    [SETTINGS_ACCOUNT_FILTERS.ROLES]: 'Roles',
    [SETTINGS_ACCOUNT_FILTERS.SYNC_ERRORS]: 'Sync errors',
    [SETTINGS_ACCOUNT_FILTERS.PAYABLES_AND_VENDORS]: 'Payables and vendors',
    [SETTINGS_ACCOUNT_FILTERS.PAYMENT_METHODS]: 'Payment methods',
    [SETTINGS_ACCOUNT_FILTERS.API_ACCESS]: 'API access',
    [SETTINGS_ACCOUNT_FILTERS.WEBHOOKS_SETTINGS]: 'Webhooks',
    [SETTINGS_ACCOUNT_FILTERS.SINGLE_SIGN_ON]: 'Single sign-on (SSO)',
    [SETTINGS_ACCOUNT_FILTERS.LEDGER_FIELD_MAPPING]: 'Object Field Mapping',
    [SETTINGS_ACCOUNT_FILTERS.TAX]: 'Tax management',
    [SETTINGS_USER_FILTERS.PROFILE]: 'My profile',
    [SETTINGS_USER_FILTERS.REPRESENTATIVE]: 'Representative info',
    [SETTINGS_USER_FILTERS.SECURITY]: 'Security',
    [SETTINGS_USER_FILTERS.NOTIFICATIONS]: 'Notifications',
    [SETTINGS_PAYABLE_APPROVALS_FILTERS.APPROVAL_RULES]: 'Approval rules',
    [SETTINGS_PAYABLE_APPROVALS_FILTERS.APPROVAL_SETTINGS]: 'Approval settings',
};
export const SETTINGS_ADDITIONAL_FILTER_LABELS = {
    [INTEGRATIONS_TABS.NETSUITE]: `Manage ${NETSUITE}`,
    [INTEGRATIONS_TABS.QBO]: `Manage ${QBO}`,
    [INTEGRATIONS_TABS.SAGE_INTACCT]: `Manage ${SAGE_INTACCT}`,
    [INTEGRATIONS_TABS.XERO]: `Manage ${XERO}`,
    [ERROR]: 'Connection error',
    [SETTINGS_ACCOUNT_FILTERS.INACTIVE_TEAM_MEMBER]: 'Inactive',
    [SETTINGS_ACCOUNT_FILTERS.INVITE_TEAM_MEMBER]: 'Invite a new team member',
    [SETTINGS_ACCOUNT_FILTERS.CREATE_ROLE]: 'Create',
};
export const ADMISSION = 'admission';
export const AdmissionRoutes = {
    PERMISSIONS: 'permissions',
    ROLES: 'roles',
    CUSTOM_ROLES: 'custom_roles',
};
export const COUNTRIES = 'countries';
export const CURRENCIES = 'currencies';
export const PURPOSE_CODE = 'purpose_code';
export const MEGABOX_DOMAIN = 'megabox.dev';
export const ENVIRONMENT_DOMAINS = {
    [Environment.TEST]: {
        topLevel: 'app.routable.com',
        brandedWorkspaces: 'routablehq.com',
    },
    [Environment.DEVELOPMENT]: {
        topLevel: 'app.routable.cloud',
        brandedWorkspaces: 'routable.cloud',
    },
    [Environment.STAGING]: {
        topLevel: 'app.staging.routable.com',
        brandedWorkspaces: 'routable-staging.com',
    },
    [Environment.SANDBOX]: {
        topLevel: 'app.sandbox.routable.com',
        brandedWorkspaces: 'routable-sandbox.com',
    },
    [Environment.PRODUCTION]: {
        topLevel: 'app.routable.com',
        brandedWorkspaces: 'routablehq.com',
    },
};
export const TEAM_MEGABOX_URLS = [
    'pod-business-automation',
    'pod-ledger-integrations',
    'pod-payments-risk',
    'pod-core-functionality',
    'product-tour-builder',
    'support-team',
];
