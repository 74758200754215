import { TransactionStatus, transactionStatusToVariant } from 'constants/transactions';
export const BalanceTransactionStatusText = {
    [TransactionStatus.CANCELED]: 'Canceled',
    [TransactionStatus.FAILED]: 'Failed',
    [TransactionStatus.INITIATED]: 'Initiated',
    [TransactionStatus.ISSUE]: 'Issue found',
    [TransactionStatus.PENDING]: 'Processing',
    [TransactionStatus.PROCESSED]: 'Completed',
};
export const getBalanceTransactionStatus = (transactionStatus) => {
    return {
        text: BalanceTransactionStatusText[transactionStatus],
        variant: transactionStatusToVariant[transactionStatus],
    };
};
