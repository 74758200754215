import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import { change, Field, untouch } from 'redux-form';
import { Radio } from 'components';
export const AccountTypeField = ({ className, fieldName, form }) => {
    const dispatch = useDispatch();
    useMount(() => {
        dispatch(change(form, fieldName, 'checking'));
    });
    useUnmount(() => {
        dispatch(change(form, fieldName, undefined));
        dispatch(untouch(form, fieldName));
    });
    return (React.createElement("div", { className: clsx('flex gap-4', className) },
        React.createElement(Field, { component: Radio, id: "accountTypeChecking", name: fieldName, optionText: "Checking", type: "radio", value: "checking" }),
        React.createElement(Field, { component: Radio, id: "accountTypeSavings", name: fieldName, optionText: "Savings", type: "radio", value: "savings" })));
};
