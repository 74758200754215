import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const InternationalPhoneInputLabel = ({ isFocused, label, placeholder, name, showLabel }) => (
  <label className={clsx('intl-phone-input-label', { isFocused, showLabel })} htmlFor={name}>
    {label || placeholder}
  </label>
);

InternationalPhoneInputLabel.propTypes = {
  isFocused: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
};

InternationalPhoneInputLabel.defaultProps = {
  isFocused: undefined,
  label: undefined,
  placeholder: 'Phone number',
  showLabel: undefined,
};

export default InternationalPhoneInputLabel;
