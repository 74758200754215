import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const HeadingSubtitle = ({ children, className, style }) => (
  <p className={clsx('font-color--greyXDark font-xs remove-margin-bottom', className)} style={style}>
    {children}
  </p>
);

HeadingSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

HeadingSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default HeadingSubtitle;
