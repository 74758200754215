import PropTypes from 'prop-types';
import React from 'react';

import { TextColor, TextLineHeight, TextSize, TextWeight } from 'constants/styles/typography';

import { getClassNamesForTypography } from 'helpers/ui';
import { allValues } from 'helpers/utility';

/**
 * Base text component. Intended for internal use only, and is not re-exported
 * as part of the /text directory.
 * @deprecated Use native HTML tag with Tailwind classes instead. If you were planning
 * to use this component, you're probably after the `span` tag but others can be used
 * as well.
 * @param {Object} props
 * @param {*} props.children
 * @param {string} [props.className]
 * @param {string} props.color
 * @param {TextLineHeight} props.lineHeight
 * @param {TextWeight} props.weight
 * @param {TextSize} props.size
 * @param {Object} props.style
 * @param {Object} props.rest
 * @return {StatelessComponent}
 */
const BaseText = ({ dataFullStory, className, children, color, lineHeight, weight, size, style, ...rest }) => (
  <span
    {...rest}
    className={getClassNamesForTypography(className, size, weight, { 'base-text': true }, lineHeight)}
    data-fullstory={dataFullStory}
    style={{ ...style, color }}
  >
    {children}
  </span>
);

const TextColorValueMap = Object.fromEntries(allValues(TextColor).map((c) => [c, true]));

BaseText.propTypes = {
  dataFullStory: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color(props, propName, componentName) {
    if (
      props &&
      props.color &&
      (props.color.indexOf('#') > -1 || props.color.indexOf('var') > -1 || props.color.indexOf('rgb') > -1)
    ) {
      return null;
    }

    const colorValue = props[propName];
    const isValidColor = TextColorValueMap[colorValue];
    const isCssColorVariable = colorValue.toString().startsWith('var(--color-');

    if (isValidColor || isCssColorVariable) {
      return null;
    }

    return new Error(
      `Invalid prop \`${propName}\` supplied to` +
        ` \`${componentName}\`. Validation failed. Value ${colorValue} is not a valid TextColor or CSS variable.`,
    );
  },
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(allValues(TextLineHeight))]),

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(allValues(TextSize))]),
  style: PropTypes.shape({}),

  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(allValues(TextWeight))]),
};

BaseText.defaultProps = {
  dataFullStory: undefined,
  children: undefined,
  className: undefined,
  color: TextColor.DEFAULT,
  lineHeight: undefined,
  size: TextSize.LEVEL_300,
  style: {},
  weight: TextWeight.LEVEL_300,
};

export default BaseText;
