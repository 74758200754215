import {
  PartnershipRequestNames,
  PartnershipRequestTypes,
  PartnershipRequestUserFriendlyNames,
} from 'constants/partnershipRequest';

/**
 * Getter function that gets partnership request requestType
 * @param {PartnershipRequest} partnershipRequest
 * @returns {PartnershipRequestTypes} requestType
 */
export const getPartnershipRequestType = (partnershipRequest) => partnershipRequest?.requestType;

/**
 * Predicate function that determines if partnership request type is accept-partnership
 * @param {PartnershipRequest} partnershipRequest
 * @returns {Boolean}
 */
export const isPartnershipRequestTypeAcceptPartnership = (partnershipRequest) =>
  getPartnershipRequestType(partnershipRequest) === PartnershipRequestTypes.ACCEPT_PARTNERSHIP;

/**
 * Predicate function that determines if partnership request type is update-payment
 * @param {PartnershipRequest} partnershipRequest
 * @returns {Boolean}
 */
export const isPartnershipRequestTypeUpdatePaymentMethod = (partnershipRequest) =>
  getPartnershipRequestType(partnershipRequest) === PartnershipRequestTypes.UPDATE_PAYMENT_METHOD;

/**
 * Getter function that gets partnership request name from the requestType
 * @param {PartnershipRequest} partnershipRequest
 * @returns {PartnershipRequestNames} name
 */
export const getPartnershipRequestName = (partnershipRequest) =>
  PartnershipRequestNames[getPartnershipRequestType(partnershipRequest)];

/**
 * Getter function that returns partnership request client facing term
 * @param {PartnershipRequest} partnershipRequest
 * @returns {PartnershipRequestUserFriendlyNames} clientTerm
 */
export const getPartnershipRequestUserFriendlyName = (partnershipRequest) =>
  PartnershipRequestUserFriendlyNames[getPartnershipRequestType(partnershipRequest)];

/**
 * Getter function that returns partnership request client facing term or partnership request name as a fallback
 * @param {PartnershipRequest} partnershipRequest
 * @returns {PartnershipRequestUserFriendlyNames|PartnershipRequestNames} clientTermOrName
 */
export const getPartnershipRequestUserFriendlyOrInternalName = (partnershipRequest) =>
  getPartnershipRequestUserFriendlyName(partnershipRequest) || getPartnershipRequestName(partnershipRequest);

/**
 * Getter function that returns whether we should hide/show the collection of vendor tax info
 * @param partnershipRequest
 * @returns {boolean}
 */
export const shouldPartnershipRequestCollectVendorTaxInfo = (partnershipRequest) =>
  Boolean(partnershipRequest?.collectVendorTaxInfo);
