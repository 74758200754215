import React from 'react';

import { IsLoading } from 'modules/isLoading';

/**
 * Loading indicator for tables in the dashboard
 * @return {StatelessComponent}
 */
const TableLoading = () => (
  <div className="align-items--center display--flex item-list-wrapper item-list-wrapper--empty justify-content--center">
    <IsLoading />
  </div>
);

export default TableLoading;
