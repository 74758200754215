export const AmountTextProps = {
    CancelledItem: {
        amountClassName: 'text-decoration--line-through',
    },
    Default: {},
    OutstandingAmount: {
        amountClassName: 'text-red-60',
    },
    ZeroAmount: {
        amountClassName: 'text-grey-60',
    },
};
export default AmountTextProps;
