import { ItemFilterLabels } from 'enums/itemFilters';
import { FilterOperator } from 'interfaces/itemFilters';
import { createAmountDueFilter, createBankAccountFilter, createBulkActionFilter, createBulkActionStatusFilter, createCreatedDateFilter, createCurrentApproverFilter, createCurrentApproverStatusFilter, createDateDueFilter, createInvoiceNumbersFilter, createItemStatusFilter, createPoDiscrepancyTypeFilter, createPoMatchTypeFilter, } from 'modules/itemFilters/filterFactories';
import { PAYMENTS_LIST_FILTERS } from './routes';
export const unpaidBillsFilters = [
    PAYMENTS_LIST_FILTERS.NEEDS_REVIEW,
    PAYMENTS_LIST_FILTERS.PENDING_VENDOR_ACTION,
    PAYMENTS_LIST_FILTERS.READY_TO_SEND,
    PAYMENTS_LIST_FILTERS.SCHEDULED,
    PAYMENTS_LIST_FILTERS.UNPAID_BILLS,
];
export const amountDueFieldOperatorOptions = [
    {
        label: 'is greater or equal to',
        operator: FilterOperator.GREATER_THAN_OR_EQUAL,
    },
    { label: 'is equal to', operator: FilterOperator.EQUALS },
    { label: 'is less or equal to', operator: FilterOperator.LESS_THAN_OR_EQUAL },
    { label: 'is between', operator: FilterOperator.BETWEEN },
];
export const bankAccountFieldOperatorOptions = [{ label: 'is any', operator: FilterOperator.IS_IN_SET }];
export const dateFieldOperatorsOptions = [
    { label: 'is on or after', operator: FilterOperator.GREATER_THAN_OR_EQUAL },
    { label: 'is on', operator: FilterOperator.EQUALS },
    { label: 'is on or before', operator: FilterOperator.LESS_THAN_OR_EQUAL },
    { label: 'is between', operator: FilterOperator.BETWEEN },
];
export const invoiceNumbersFieldOperatorOptions = [
    {
        label: 'exactly matches',
        operator: FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST,
    },
    {
        label: 'contains',
        operator: FilterOperator.CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST,
    },
    {
        label: 'starts with',
        operator: FilterOperator.STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST,
    },
    {
        label: 'ends with',
        operator: FilterOperator.ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST,
    },
];
export const ItemFiltersByLabel = {
    [ItemFilterLabels.AMOUNT_DUE]: createAmountDueFilter(),
    [ItemFilterLabels.BANK_ACCOUNT]: createBankAccountFilter(),
    [ItemFilterLabels.BULK_ACTION]: createBulkActionFilter(),
    [ItemFilterLabels.BULK_ACTION_STATUS]: createBulkActionStatusFilter(),
    [ItemFilterLabels.CREATED_DATE]: createCreatedDateFilter(),
    [ItemFilterLabels.CURRENT_APPROVER]: createCurrentApproverFilter(),
    [ItemFilterLabels.CURRENT_APPROVER_STATUS]: createCurrentApproverStatusFilter(),
    [ItemFilterLabels.DUE_DATE]: createDateDueFilter(),
    [ItemFilterLabels.INVOICE_NUMBERS]: createInvoiceNumbersFilter(),
    [ItemFilterLabels.ITEM_STATUS]: createItemStatusFilter(),
    [ItemFilterLabels.PO_MATCH_TYPE]: createPoMatchTypeFilter(),
    [ItemFilterLabels.PO_DISCREPANCY_TYPE]: createPoDiscrepancyTypeFilter(),
};
export const AllFilterOptions = [
    ItemFilterLabels.AMOUNT_DUE,
    ItemFilterLabels.BANK_ACCOUNT,
    ItemFilterLabels.BULK_ACTION,
    ItemFilterLabels.BULK_ACTION_STATUS,
    ItemFilterLabels.CREATED_DATE,
    ItemFilterLabels.CURRENT_APPROVER,
    ItemFilterLabels.DUE_DATE,
    ItemFilterLabels.INVOICE_NUMBERS,
];
export const ItemFilterOptions = [
    ItemFilterLabels.AMOUNT_DUE,
    ItemFilterLabels.BANK_ACCOUNT,
    ItemFilterLabels.CREATED_DATE,
    ItemFilterLabels.DUE_DATE,
    ItemFilterLabels.INVOICE_NUMBERS,
];
