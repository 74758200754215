import { apiLinksModel, paginationModel, uuidModel, logEntryModel } from '@routable/shared';
import { taxInfoStatus, taxRiskSummary } from '@routable/taxes';
import { z } from 'zod';
import { companyModel } from './company.model';
import { companyAddressModel } from './companyAddress.model';
import { partnershipMemberModel } from './partnershipMember.model';
export const partnershipType = z.enum(['vendor', 'customer']);
export const partnershipTypePlural = z.enum(['vendors', 'customers', 'invites']);
export const partnerStatus = z.enum(['accepted', 'added', 'invited']);
export const partnershipAttributesModel = z.object({
    collectVendorTaxInfo: z.boolean(),
    countryCode: z.string(),
    countryCodeCompany: z.string(),
    countryCodePartner: z.string(),
    created: z.string().datetime(),
    currencyCodeCompany: z.string(),
    currencyCodeCustomer: z.string(),
    currencyCodePartner: z.string(),
    currencyCodeVendor: z.string(),
    customerLedgerId: uuidModel.nullish(),
    customerRef: z.string().nullish(),
    externalRef: z.string().nullish(),
    hasCompanyManagementContact: z.boolean(),
    hasContact: z.boolean(),
    hasEmail: z.boolean(),
    hasValidEmail: z.boolean(),
    isCustomer: z.boolean(),
    isLedgerOnly: z.boolean(),
    isPartnerSelfManaged: z.boolean(),
    isVendor: z.boolean(),
    lastItemCreatedDate: z.string().datetime().nullish(),
    ledgerLinkCustomer: z.string().nullish(),
    ledgerLinkVendor: z.string().nullish(),
    ledgerNameCustomer: z.string().nullish(),
    ledgerNameVendor: z.string().nullish(),
    name: z.string(),
    partnerStatus: partnerStatus,
    riskSummary: taxRiskSummary,
    supportsDomestic: z.boolean(),
    taxInfoStatus: taxInfoStatus,
    vendorLedgerId: uuidModel.nullish(),
    vendorRef: z.string().nullish(),
});
export const partnershipRelationshipsModel = z.object({
    partner: z.object({
        data: z
            .object({
            id: uuidModel,
            type: z.literal('Company'),
        })
            .nullish(),
    }),
});
export const partnershipDetailRelationshipsModel = partnershipRelationshipsModel.extend({
    defaultPayableFundingAccount: z.object({
        data: z
            .object({
            type: z.literal('FundingAccount'),
            id: uuidModel,
        })
            .nullish(),
    }),
    defaultReceivableFundingAccount: z.object({
        data: z
            .object({
            type: z.literal('FundingAccount'),
            id: uuidModel,
        })
            .nullish(),
    }),
    lastNameChange: z.object({
        data: z
            .object({
            type: z.literal('LogEntry'),
            id: z.string(),
        })
            .nullish(),
    }),
    partnershipMembers: z.object({
        data: z.array(z.object({ type: z.literal('PartnershipMember'), id: uuidModel })).nullish(),
        meta: z.object({
            count: z.number().gte(0),
        }),
    }),
});
export const partnershipAttributesWithCountsModel = partnershipAttributesModel.extend({
    payablesOutstandingCount: z.number().gte(0),
    payablesOutstandingSum: z.string(),
    payablesProcessedCount: z.number().gte(0),
    payablesProcessedSum: z.string(),
    receivablesOutstandingCount: z.number().gte(0),
    receivablesOutstandingSum: z.string(),
    receivablesProcessedCount: z.number().gte(0),
    receivablesProcessedSum: z.string(),
});
export const partnershipModel = z.object({
    type: z.literal('Partnership'),
    id: uuidModel,
    attributes: partnershipAttributesModel,
    relationships: partnershipRelationshipsModel,
});
export const partnershipWithCountsModel = partnershipModel.extend({
    attributes: partnershipAttributesWithCountsModel,
});
export const partnershipDetailModel = partnershipModel.extend({
    relationships: partnershipDetailRelationshipsModel,
});
export const partnershipListApiResponseModel = z.object({
    data: z.array(partnershipWithCountsModel),
    included: z.array(companyModel.or(companyAddressModel)),
    links: apiLinksModel,
    meta: z.object({
        pagination: paginationModel,
    }),
});
export const partnershipDetailApiResponseModel = z.object({
    data: partnershipDetailModel,
    included: z.array(companyModel.or(companyAddressModel).or(logEntryModel).or(partnershipMemberModel)),
});
export const partnershipListExportApiResponseModel = z.unknown();
export const partnershipPatchApiResponseModel = z.object({
    partnership: z.record(uuidModel, partnershipModel),
    company: z.record(uuidModel, companyModel),
});
