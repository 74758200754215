import PropTypes from 'prop-types';
import React from 'react';

import { ContactListItemMenuPopover } from './components';

/**
 * Menu for a ContactListItem.
 * @param {Object} props
 * @param {Function} props.onRemoveContact
 * @param {Function} props.onSetActionableContact
 * @param {Function} props.onSetReadOnlyContact
 * @param {Function} props.onUpdateContact
 * @param {PartnershipMember} props.contact
 * @return {StatelessComponent}
 */
const ContactListItemMenu = ({
  onRemoveContact,
  onSetActionableContact,
  onSetReadOnlyContact,
  onUpdateContact,
  contact,
}) => (
  <ContactListItemMenuPopover
    onRemove={() => {
      onRemoveContact(contact);
    }}
    onSetActionable={() => {
      onSetActionableContact(contact);
    }}
    onSetReadOnly={() => {
      onSetReadOnlyContact(contact);
    }}
    onUpdate={() => {
      onUpdateContact(contact);
    }}
    showRemove={typeof onRemoveContact === 'function'}
    showSetAccess={typeof onSetActionableContact === 'function' && typeof onSetReadOnlyContact === 'function'}
    {...contact}
  />
);

ContactListItemMenu.propTypes = {
  onRemoveContact: PropTypes.func,
  onSetActionableContact: PropTypes.func,
  onSetReadOnlyContact: PropTypes.func,
  onUpdateContact: PropTypes.func,
};

ContactListItemMenu.defaultProps = {
  onRemoveContact: undefined,
  onSetActionableContact: undefined,
  onSetReadOnlyContact: undefined,
  onUpdateContact: undefined,
};

export default ContactListItemMenu;
