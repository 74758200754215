import {} from '@routable/shared';
import { apiCall, apiJsonApiCall, convertToSnakeCase } from '@routable/framework';
import { partnershipDetailApiResponseModel, partnershipListApiResponseModel, partnershipListExportApiResponseModel, partnershipPatchApiResponseModel, } from '../models';
export const partnershipsService = {
    list: async ({ pagination = { page: 1, pageSize: 25 }, partnershipType, search, }) => apiCall({
        method: 'get',
        camelCaseResponse: true,
        url: 'partnerships/',
        queryParams: {
            partnershipType,
            style: 'counts',
            'page[number]': pagination.page,
            'page[size]': pagination.pageSize,
            ...(search ? { search } : {}),
        },
        responseModel: partnershipListApiResponseModel,
    }),
    export: async ({ partnershipType, search }) => apiCall({
        method: 'get',
        url: 'partnerships/',
        headers: { Accept: 'text/csv' },
        responseType: 'arraybuffer',
        queryParams: {
            partnershipType,
            style: 'counts',
            ...(search ? { search } : {}),
        },
        responseModel: partnershipListExportApiResponseModel,
    }),
    read: async (partnershipId) => apiCall({
        method: 'get',
        camelCaseResponse: true,
        url: `partnerships/${partnershipId}/`,
        responseModel: partnershipDetailApiResponseModel,
    }),
    updatePartnershipVendorTaxCollection: async (partnershipId, collectVendorTaxInfo) => apiJsonApiCall({
        url: `partnerships/${partnershipId}/`,
        method: 'patch',
        body: convertToSnakeCase({
            data: {
                type: 'Partnership',
                id: partnershipId,
                attributes: { collectVendorTaxInfo },
            },
        }),
        responseModel: partnershipPatchApiResponseModel,
    }),
};
