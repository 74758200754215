import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { HeadingSubtitle } from 'components/text';

const EntityWidgetHeaderSubtitle = ({ children, className, style }) => (
  <HeadingSubtitle className={clsx('entity-widget--header-subtitle', className)} style={style}>
    {children}
  </HeadingSubtitle>
);

EntityWidgetHeaderSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

EntityWidgetHeaderSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default EntityWidgetHeaderSubtitle;
