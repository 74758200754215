import clsx from 'clsx';
import React from 'react';
import { FormFieldErrors } from 'components/error/components';
import { FormControl } from 'components/form';
import { hasLength } from 'helpers/utility';
const HintFormFieldWrapper = ({ children, className, fieldName, hideErrors, meta }) => {
    const hasErrors = meta && hasLength(meta.error) && !hideErrors;
    return (React.createElement(FormControl, null,
        React.createElement("div", { className: clsx('hint-field', className, { 'has-error': hasErrors }) },
            children,
            hasErrors && React.createElement(FormFieldErrors, { errors: meta.error, fieldName: fieldName }))));
};
export default HintFormFieldWrapper;
