import { queryContext } from '@routable/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { partnershipsBaseQueryKey } from '../../helpers';
import { partnershipsService } from '../../services';
export const useUpdatePartnershipVendorTaxCollection = () => {
    const queryClient = useQueryClient({ context: queryContext });
    return useMutation({
        context: queryContext,
        mutationFn: async ({ id, collectVendorTaxInfo }) => {
            return partnershipsService.updatePartnershipVendorTaxCollection(id, collectVendorTaxInfo);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [partnershipsBaseQueryKey] });
        },
    });
};
