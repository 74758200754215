import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders an image with some sane, though very minimal, default styles.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} props.alt
 * @param {string} props.src
 * @return {StatelessComponent}
 */
const Image = ({ alt, className, src, style }) => (
  <img alt={alt} className={clsx('display--block max-width--full', className)} src={src} style={style} />
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

Image.defaultProps = {
  className: undefined,
  style: {},
};

export default Image;
