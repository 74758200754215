import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import FlexCol from 'components/layout/FlexCol';
import FlexRow from 'components/layout/FlexRow';

/**
 * A grouping of subtitles used within the TitleWithSubtitle. The subtitles are presented as a flex-column, with each
 * subtitle rendered as a flex-row.
 *
 * @param {[]|Node[]} [subtitles]
 * @param {RestOfProps} rest
 * @returns {StatelessComponent}
 */
const SubtitleBlock = ({ subtitles, rowClassName, ...rest }) => (
  <FlexCol {...rest}>
    {subtitles.map((subtitle, i) => (
      <FlexRow
        className={clsx('align-items--center margin-top--sm-alt', rowClassName)}
        /* eslint-disable-next-line react/no-array-index-key */
        key={`subtitle-${i}`}
        stackOnMobile={false}
      >
        {subtitle}
      </FlexRow>
    ))}
  </FlexCol>
);

SubtitleBlock.propTypes = {
  subtitles: PropTypes.arrayOf(PropTypes.node),
};

SubtitleBlock.defaultProps = {
  subtitles: [],
};

export default SubtitleBlock;
