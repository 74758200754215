import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Renders subtext for a table cell.
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 */
const TableCellSubtext = ({ children, className, style }) => (
  <div className={clsx('font-xs font-color--dark-jordan truncate', className)} style={style}>
    {children}
  </div>
);

TableCellSubtext.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

TableCellSubtext.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
};

export default TableCellSubtext;
