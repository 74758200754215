import { Tooltip } from '@routable/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListClassNames } from 'components/disclosureList/constants';
import { Icon } from 'components/icon';
import { HeadingTitle } from 'components/text';

import { margin, sizes } from 'constants/styles';

import { DisclosureControl } from './components';

const DisclosureListHeader = (props) => {
  const { className, headerComponent, icon, iconProps, isOpen, onToggleOpen, style, text, tooltipText } = props;

  let heading = <HeadingTitle className="font-size--xs">{text}</HeadingTitle>;

  if (tooltipText) {
    heading = (
      <Tooltip className="display--flex" tooltip={tooltipText}>
        {heading}
      </Tooltip>
    );
  }

  if (headerComponent) {
    heading = headerComponent;
  }

  return (
    <div className={DisclosureListClassNames.HEADER} style={style}>
      <button
        className={clsx('align-items--center display--flex text-align--left', className)}
        onClick={onToggleOpen}
        type="button"
      >
        {!!icon && (
          <Icon
            className="disclosure-header--icon"
            icon={icon}
            marginRight={margin.extraMedium}
            size={sizes.iconSizes.LARGE}
            {...iconProps}
          />
        )}

        {heading}

        <DisclosureControl isOpen={isOpen} />
      </button>
    </div>
  );
};

DisclosureListHeader.propTypes = {
  className: PropTypes.string,
  headerComponent: PropTypes.node,
  icon: PropTypes.string,
  iconProps: PropTypes.shape(),
  isOpen: PropTypes.bool,
  onToggleOpen: PropTypes.func,
  style: PropTypes.shape({}),
  text: PropTypes.string,
  tooltipContentMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipText: PropTypes.string,
};

DisclosureListHeader.defaultProps = {
  className: undefined,
  headerComponent: undefined,
  icon: undefined,
  iconProps: {},
  isOpen: undefined,
  onToggleOpen: undefined,
  style: {},
  text: undefined,
  tooltipContentMargin: undefined,
  tooltipText: undefined,
};

export default DisclosureListHeader;
