import clsx from 'clsx';
import React from 'react';
import { ButtonV2, IconNames } from 'components';
import {} from 'components/buttonV2/ButtonV2.types';
import { ButtonSize } from 'constants/button';
import { SSO_LOGIN } from 'constants/images';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';
import './LoginWithSSOButton.scss';
const LoginWithSSOButton = ({ className, ...btnProps }) => (React.createElement(ButtonV2, { className: clsx('login-with-sso-btn', className), intent: Intent.NEUTRAL, rightIconClassName: "sso-btn--right-icon", rightIconName: IconNames.ARROW_RIGHT, rightIconSize: sizes.iconSizes.LARGE, size: ButtonSize.LARGE, ...btnProps },
    React.createElement("img", { alt: "SSO 2fa logo", className: "sso-btn--logo", src: SSO_LOGIN }),
    "Login with SSO"));
export default LoginWithSSOButton;
