import { Menu } from '@carbon/icons-react';
import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { callOnEnterKeyEvent } from 'helpers/events';

import { useSideNavigationV2 } from 'hooks/useSideNavigationV2';

/**
 * Hamburger nav menu Icon and controlls for ActionNav.
 * @param {Object} props
 * @param {Function} props.onToggleSidebar
 * @param {Boolean} [props.shouldHideMenu=false] - Hide the menu icon completely
 * @param {Boolean} [props.shouldHideMobileMenuToggle] - Hide the menu for certain screen sizes
 * @returns {JSX.Element}
 */
const ActionNavMobileMenuToggle = ({ onToggleSidebar, shouldHideMenu, shouldHideMobileMenuToggle }) => {
  const hasSideNavigationV2 = useSideNavigationV2();
  if (hasSideNavigationV2) {
    return null;
  }

  return (
    <button
      aria-label="Dashboard sidebar toggle"
      className={clsx('dashboard-toggle', {
        'hide-menu': shouldHideMenu,
        'hide-mobile-menu': shouldHideMobileMenuToggle,
      })}
      onClick={onToggleSidebar}
      onKeyDown={callOnEnterKeyEvent(onToggleSidebar)}
      tabIndex="0"
      type="button"
    >
      <Tooltip data-testid="expand-dashboard-menu-old" position="bottom" tooltip="Open sidebar">
        <Menu size={20} />
      </Tooltip>
    </button>
  );
};

ActionNavMobileMenuToggle.propTypes = {
  onToggleSidebar: PropTypes.func.isRequired,
  shouldHideMenu: PropTypes.bool,
  shouldHideMobileMenuToggle: PropTypes.bool,
};

ActionNavMobileMenuToggle.defaultProps = {
  shouldHideMenu: undefined,
  shouldHideMobileMenuToggle: undefined,
};

export default ActionNavMobileMenuToggle;
