import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const HintText = ({ children, className, dataFullStory, marginBottom }) => (
  <p
    className={clsx('hint--text', className, { 'remove-margin-bottom': !marginBottom })}
    data-fullstory={dataFullStory}
  >
    {children}
  </p>
);

HintText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataFullStory: PropTypes.bool,
  marginBottom: PropTypes.bool,
};

HintText.defaultProps = {
  className: undefined,
  dataFullStory: undefined,
  marginBottom: undefined,
};

export default HintText;
