import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { APPROVAL_QUEUED_TEXT, RESCHEDULING_QUEUED_TEXT, SCHEDULING_QUEUED_TEXT, SEND_QUEUED_TEXT, } from 'constants/status';
import { bulkImportHasIssues, getBulkActionFromItemLockState, isBulkActionApprove, isBulkActionApproveAndSend, isBulkActionSend, } from 'helpers/bulkActions';
import { isDateToday } from 'helpers/date';
import { getItemDateExpected, isItemDueSoon, isItemKindPayable, isItemKindReceivable, isItemOverdue, isItemPaymentDeliveryMethodAch, isItemPaymentDeliveryMethodInternational, itemDueDateDescription, } from 'helpers/items';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';
import {} from 'interfaces/item';
export const getBulkUploadText = () => 'Loading bill from a file...';
export const getCompletedWithFailuresText = ({ summary }) => {
    const { totals: { count, errors }, } = summary;
    const difference = count - errors;
    return `${difference.toLocaleString()} / ${count.toLocaleString()} uploaded successfully`;
};
export const getCreatedText = (dateStr) => (dateStr ? `Created on ${dateStr}` : '');
export const getDraftText = (dateStr) => (dateStr ? `Draft created ${dateStr}` : '');
export const getExternallyPaidText = (dateStr) => dateStr ? `Paid outside ${PLATFORM_DISPLAY_SHORT_NAME} ${dateStr}` : `Paid outside ${PLATFORM_DISPLAY_SHORT_NAME}`;
export const getInitiatedText = (item) => {
    if (isPaymentDeliveryOptionRTP(item.paymentDeliveryOption)) {
        return 'Payment will arrive today';
    }
    const dateExpected = getItemDateExpected(item);
    return dateExpected ? `Expected ${dateExpected}` : '';
};
export const getIssueText = () => 'Please contact support';
export const getItemCompletedText = (item, dateStatusChange) => {
    if (bulkImportHasIssues(item)) {
        return getCompletedWithFailuresText(item);
    }
    if (isItemPaymentDeliveryMethodAch(item) || isItemPaymentDeliveryMethodInternational(item)) {
        return dateStatusChange ? `Transferred ${dateStatusChange}` : '';
    }
    return dateStatusChange ? `Check arrived ${dateStatusChange}` : '';
};
export const getItemNewText = (item) => {
    if (isItemKindPayable(item)) {
        if (isItemDueSoon(item) || isItemOverdue(item)) {
            return itemDueDateDescription(item);
        }
        return 'Pay now';
    }
    if (isItemKindReceivable(item)) {
        return 'Accept to receive';
    }
    return null;
};
export const getItemPendingText = (item) => {
    if (isItemKindPayable(item)) {
        return 'Awaiting vendor action';
    }
    if (isItemKindReceivable(item)) {
        if (isItemDueSoon(item) || isItemOverdue(item)) {
            return itemDueDateDescription(item);
        }
        return 'Unpaid';
    }
    return null;
};
export const getOCRText = () => 'OCR';
export const getOnDateText = (dateStr) => (dateStr ? `On ${dateStr}` : '');
export const getProcessingText = () => 'Preparing transaction...';
export const getQueuedText = (item) => {
    const bulkAction = getBulkActionFromItemLockState(item.lockState);
    if (isBulkActionApprove(bulkAction)) {
        return APPROVAL_QUEUED_TEXT;
    }
    if (isBulkActionApproveAndSend(bulkAction) || isBulkActionSend(bulkAction)) {
        if (isDateToday(item.dateScheduled)) {
            return SEND_QUEUED_TEXT;
        }
        return SCHEDULING_QUEUED_TEXT;
    }
    return RESCHEDULING_QUEUED_TEXT;
};
export const getReadyToSendText = (isExternal = false) => isExternal ? 'Schedule date pending' : 'No send date selected';
export const getScheduledText = (dateStr, isExternal) => {
    if (isExternal) {
        return 'Queued for release';
    }
    return dateStr ? `Send on ${dateStr}` : '';
};
