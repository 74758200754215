import { TruncatableText } from '@routable/components';
import { useEventSync } from '@routable/framework';
import { Checkbox, Skeleton } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExistingItem } from 'context';
import { DiscrepanciesObjectKeyMap } from 'constants/discrepancies';
import { LineItemStyles } from 'constants/lineItems';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';
import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';
import { isFetchingItemSelector } from 'selectors/itemsSelectors';
import { getFieldProps } from '../../blockComponents/PresentationalField/helpers';
export const PresentationalTableField = React.memo((props) => {
    const item = useContext(ExistingItem);
    const { minDecimalScale, section, rowIdx } = props;
    const isFetchingItem = useSelector(isFetchingItemSelector);
    const [page, setPage] = useState(1);
    useEventSync(`table:${section.id}:manual:paging`, (ev) => {
        setPage(ev.page);
    });
    const lineItemIndex = (page - 1) * TABLE_DEFAULT_PAGE_SIZE + rowIdx;
    const lineItemStyle = section.sectionPath.includes('line_items_item_block')
        ? LineItemStyles.ITEM
        : LineItemStyles.ACCOUNT;
    const rowValues = (item?.lineItems || []).filter((lineItem) => lineItem.style === lineItemStyle).at(lineItemIndex);
    const { fieldPath, value } = getFieldProps({
        item: rowValues,
        ledger: {},
        minDecimalScale,
        ...props,
    });
    const { hasDiscrepancyForColumn } = useDiscrepancyContext();
    const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();
    const hasDiscrepancy = hasDiscrepancyForColumn(rowValues?.id, DiscrepanciesObjectKeyMap[fieldPath]);
    const showDiscrepancy = !isRefreshingDiscrepancies && hasDiscrepancy;
    if (isFetchingItem) {
        return React.createElement(Skeleton, { className: "w-full", shape: "round" });
    }
    if (value === undefined) {
        return null;
    }
    if (typeof value === 'boolean') {
        return React.createElement(Checkbox, { checked: value, disabled: true, name: fieldPath });
    }
    if (minDecimalScale > 0) {
        return (React.createElement("div", { className: "flex justify-end w-full" },
            React.createElement("div", { className: "min-w-0 relative" },
                showDiscrepancy && (React.createElement("div", { className: "absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]", "data-testid": "discrepancy-border" })),
                React.createElement("span", { className: "text-xs text-black w-full text-right" },
                    `${value} `,
                    React.createElement("span", { className: "!text-grey-60" }, item.currencyCode)))));
    }
    return (React.createElement("div", { className: clsx({
            'flex w-full justify-center': fieldPath === 'quantity',
        }) },
        React.createElement("div", { className: clsx('relative', {
                'min-w-[16px] text-center': fieldPath === 'quantity',
            }) },
            showDiscrepancy && (React.createElement("div", { className: "absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]", "data-testid": "discrepancy-border" })),
            React.createElement(TruncatableText, { className: "text-xs text-black", position: "top", text: value }))));
});
