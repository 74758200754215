import React from 'react';
import clsx from 'clsx';
import { DataGridItem } from './DataGridItem';
export const DataGrid = ({ children, className, gridTemplateColumns }) => {
    const gridProps = {
        className: clsx('!grid', 'gap-y-7', 'gap-x-5', className),
        style: {
            gridTemplateColumns: gridTemplateColumns || 'repeat(auto-fill, minmax(max(250px, calc((100% - 48px) / 4)), 1fr))',
        },
    };
    return React.createElement("div", { ...gridProps }, children);
};
DataGrid.Item = DataGridItem;
