import { Locked } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import { components as selectComponents } from 'react-select';

import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { mergeLockedIndicatorTooltipProps } from './helpers';

const { LoadingIndicator } = selectComponents;

/**
 * A 🔒 icon to use in place of the normal dropdown ⌄ (down chevron) when the select is locked.
 *
 * @param {Object} props
 * @param {function} props.getStyles
 * @param {Object} [props.innerProps={}]
 * @param {Object} [props.tooltipProps]
 * @param {Object} props.selectProps
 * @param {boolean} props.selectProps.isLoading
 * @returns {JSX.Element}
 */
const LockedIndicator = (props) => {
  const { getStyles, innerProps, tooltipProps, selectProps } = props;
  return (
    <div {...innerProps} style={getStyles('clearIndicator', props)}>
      <TooltipMUIConditionalWrapper tooltipProps={mergeLockedIndicatorTooltipProps(tooltipProps)}>
        {selectProps.isLoading ? (
          <LoadingIndicator {...props} innerProps={{ role: 'progressbar', 'aria-label': 'Locked select loading' }} />
        ) : (
          <Locked aria-label="Locked select icon" data-icon="lock" data-testid="icon-lock" role="img" size={17} />
        )}
      </TooltipMUIConditionalWrapper>
    </div>
  );
};

LockedIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: PropTypes.shape(),
  }),
  tooltipProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({ isLoading: PropTypes.bool }),
};

LockedIndicator.defaultProps = {
  innerProps: {},
  tooltipProps: undefined,
};

export default LockedIndicator;
