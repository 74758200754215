import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Hint, HintText, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

const EntityWidgetHintSection = ({ actions, className, dataFullStoryText, intent, style, text, title }) => (
  <div className={clsx('entity-widget--hint-section entity-widget--section', intent, className)} style={style}>
    <Hint intent={intent}>
      <HintTitle>{title}</HintTitle>

      <HintText dataFullStory={dataFullStoryText}>{text}</HintText>
    </Hint>

    {!!actions && <div className="entity-widget--hint-section--actions">{actions}</div>}
  </div>
);

EntityWidgetHintSection.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  dataFullStoryText: PropTypes.bool,
  intent: PropTypes.oneOf(Object.values(Intent)),
  style: PropTypes.shape(),
  text: PropTypes.string,
  title: PropTypes.string,
};

EntityWidgetHintSection.defaultProps = {
  actions: undefined,
  className: undefined,
  dataFullStoryText: undefined,
  intent: Intent.INFO,
  style: {},
  text: undefined,
  title: undefined,
};

export default EntityWidgetHintSection;
