import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { ButtonV2, Hint, HintContent, HintText, HintTitle, IconNames, LinkNewPage, WhiteSpace } from 'components';

import { ButtonSize } from 'constants/button';
import { CUSTOMER_DILIGENCE_REQUIREMENTS_URL } from 'constants/legal';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import { getQueryParam } from 'helpers/queryParams';
import { getConvertCompanyURL } from 'helpers/routeHelpers';

import { partnershipSelector } from 'selectors/partnershipsSelectors';

const PartnerCompanyVerifyIdentity = ({ history, partnership }) => {
  // Get query params to generate convert URL
  const companyId = getQueryParam('company_id');
  const membershipId = getQueryParam('membership_id');
  const token = getQueryParam('token');

  const createAccountAndVerify = () => {
    history.push(getConvertCompanyURL(token, membershipId, companyId));
  };

  return (
    <Hint className="margin-bottom--large margin-top--large" intent={Intent.WARNING} multiline>
      <HintTitle>
        <span>One-time customer verification required.</span>
        <WhiteSpace />
        <LinkNewPage
          className="enable-pointer-events font-color--primary regular semibold"
          href={helpDocs.DASHBOARD_ONE_TIME_VERIFICATION_FOR_NEW_CUSTOMER}
        >
          Learn more
        </LinkNewPage>
      </HintTitle>
      <HintContent className="margin-top--sm">
        <HintText marginBottom>
          <LinkNewPage
            className="enable-pointer-events font-color--primary semibold"
            href={CUSTOMER_DILIGENCE_REQUIREMENTS_URL}
          >
            U.S. Federal customer due diligence rules
          </LinkNewPage>
          <WhiteSpace />
          require verification of a company and its representative. To help the government fight financial crime, and to
          stay compliant, please verify your entity information.
        </HintText>
        <HintTitle>How to verify:</HintTitle>
        <HintText>
          A one-time verification helps to prevent fraud and assures a secure experience for you and your payee
          <WhiteSpace />
          {partnership.name}.
        </HintText>
        <HintText className="margin-top--m" marginBottom>
          A
          <WhiteSpace />
          {PLATFORM_DISPLAY_SHORT_NAME}
          <WhiteSpace />
          representative will be in touch to assist you.
        </HintText>

        <ButtonV2
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.LARGE}
          onClick={createAccountAndVerify}
          rightIconClassName="margin-left--x-large"
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.MEDIUM}
        >
          Create account and verify
        </ButtonV2>
      </HintContent>
    </Hint>
  );
};

PartnerCompanyVerifyIdentity.propTypes = {
  partnership: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const partnershipId = getQueryParam('partnership_id');

  return {
    partnership: partnershipSelector(state, partnershipId),
  };
};

const enhance = compose(withRouter, connect(mapStateToProps));

export { PartnerCompanyVerifyIdentity };
export default enhance(PartnerCompanyVerifyIdentity);
