import { companyBusinessTypes, CompanyPlatformTypes } from 'constants/company';
import {
  FundingCustomerFailedStatuses,
  FundingCustomerStatuses,
  FundingCustomerVerifiedStatuses,
} from 'constants/funding';
import { UserTypes } from 'constants/user';

import { getCurrentCompanyId } from 'helpers/localStorage';

// *************************************
// CurrentCompany
// *************************************

/**
 * A method to check whether a company is the current company.
 * @param {Id} companyId
 * @param {string} currentCompanyId
 * @return {boolean}
 */
export const isCompanyIdCurrentCompany = (companyId, currentCompanyId = getCurrentCompanyId()) =>
  currentCompanyId === companyId;

/**
 * A method to check whether a company is the current company.
 * @param {Company} company
 * @param {string} currentCompanyId
 * @return {boolean}
 */
export const isCompanyCurrentCompany = (company, currentCompanyId = getCurrentCompanyId()) =>
  isCompanyIdCurrentCompany(company?.id, currentCompanyId);

/**
 * A method to check whether a company is NOT the current company.
 * @param {Id} companyId
 * @param {string} currentCompanyId
 * @return {boolean}
 */
export const isCompanyIdNotCurrentCompany = (companyId, currentCompanyId = getCurrentCompanyId()) =>
  !isCompanyIdCurrentCompany(companyId, currentCompanyId);

/**
 * A method to check whether a company is NOT the current company.
 * @param {Company} company
 * @param {string} currentCompanyId
 * @return {boolean}
 */
export const isCompanyNotCurrentCompany = (company, currentCompanyId = getCurrentCompanyId()) =>
  !isCompanyCurrentCompany(company, currentCompanyId);

/**
 * Predicate function that checks if company type is personal
 * @param {UserTypes} companyType
 * @returns {boolean}
 */
export const isCompanyTypePersonal = (companyType) => companyType === UserTypes.PERSONAL;

/**
 * Predicate function that checks if company type is business
 * @param {UserTypes} companyType
 * @returns {boolean}
 */
export const isCompanyTypeBusiness = (companyType) => companyType === UserTypes.BUSINESS;

/**
 * Predicate function that checks if given company is personal type
 * @param {Company} currentCompany
 * @returns {boolean}
 */
export const isCurrentCompanyTypePersonal = (currentCompany) => isCompanyTypePersonal(currentCompany?.companyType);

/**
 * Predicate function that checks if given company is business type
 * @param {Company} currentCompany
 * @returns {boolean}
 */
export const isCurrentCompanyTypeBusiness = (currentCompany) => isCompanyTypeBusiness(currentCompany?.companyType);

export const isCompanyPlatformTypeAdded = (platformType) => platformType === CompanyPlatformTypes.ADDED;
export const isCompanyPlatformTypeExternal = (platformType) => platformType === CompanyPlatformTypes.EXTERNAL;
export const isCompanyPlatformTypeInvited = (platformType) => platformType === CompanyPlatformTypes.INVITED;
export const isCompanyPlatformTypeRegistered = (platformType) => platformType === CompanyPlatformTypes.REGISTERED;

/**
 * A helper which evaluates if the CompanyPlatformType is invited or added.
 * @function
 * @param {CompanyPlatformTypes} platformType
 * @returns {boolean}
 */
export const isCompanyPlatformTypeInInviteStage = (platformType) =>
  isCompanyPlatformTypeInvited(platformType) || isCompanyPlatformTypeAdded(platformType);

/**
 * The inverse of isCompanyPlatformTypeInInvitedStage. Returns true if CompanyPlatformType is external or registered.
 * @param {CompanyPlatformTypes} platformType
 * @returns {boolean}
 */
export const isCompanyPlatformTypePastInviteStage = (platformType) => !isCompanyPlatformTypeInInviteStage(platformType);

// *************************************
// CurrentCompany info
// *************************************

/**
 * Returns whether or not the company business type is sole proprietor
 * @param {string} businessType
 * @return {Boolean}
 */
export const isBusinessTypeSoleProprietor = (businessType) => businessType === companyBusinessTypes.SOLE_PROPRIETOR;

// *************************************
// CurrentCompany settings
// *************************************

/**
 * Getter function that returns the value of collectVendorTaxInfo from CompanySettings
 * @param {import('interfaces/partnerships').CompanySettings} companySettings
 * @returns {boolean}
 */
export const getCompanySettingsCollectVendorTaxInfo = (companySettings) => companySettings.collectVendorTaxInfo;

/**
 * Predicate that returns a boolean casted value of enableApi from CompanySettings
 * @param {Object} companySettings
 * @returns {Boolean}
 */
export const isCurrentCompanySettingsApiEnabled = (companySettings) => Boolean(companySettings.enableApi);

/**
 * Predicate that returns a boolean casted value of enableInternationalPayments from CompanySettings
 * @param {Object} companySettings
 * @returns {Boolean}
 */
export const isCompanySettingsInternationalPaymentsEnabled = (companySettings) =>
  Boolean(companySettings.enableInternationalPayments);

/**
 * Getter function that returns the value of maxBankAccounts from CompanySettings
 * @param {Object} companySettings
 * @returns {Number}
 */
export const getCompanySettingsMaxBankAccounts = (companySettings) => companySettings.maxBankAccounts;

/**
 * Getter function that returns the value of apiVersio from CompanySettings'
 * @param {Object} companySettings
 * @returns {String}
 */
export const getCompanySettingsApiVersion = (companySettings) => companySettings.apiVersion;

/**
 * Getter function that returns the value of payablePaymentDeliveryMethod from CompanySettings'
 * @param {Object} companySettings
 * @returns {PaymentDeliveryMethodType[]}
 */
export const getCompanySettingsPayablePaymentDeliveryMethods = (companySettings) =>
  companySettings.payablePaymentDeliveryMethods;

/**
 * Getter function that returns the value of receivablePaymentDeliveryMethod from CompanySettings'
 * @param {Object} companySettings
 * @returns {String[]}
 */
export const getCompanySettingsReceivablePaymentDeliveryMethods = (companySettings) =>
  companySettings.receivablePaymentDeliveryMethods;

// Company Settings Integration
/**
 * Predicate that returns a boolean casted value of enableClearingAccounts from CompanySettingsIntegration
 * @param {Object} companySettingsIntegration
 * @returns {Boolean}
 */
export const isCompanySettingsIntegrationClearingAccountsEnabled = (companySettingsIntegration) =>
  Boolean(companySettingsIntegration.enableClearingAccounts);

/**
 * Predicate that returns a boolean casted value of syncLegalNameChanges from CompanySettingsIntegration
 * @param {Object} companySettingsIntegration
 * @returns {Boolean}
 */
export const isCompanySettingsIntegrationSyncLegalNameChangesEnabled = (companySettingsIntegration) =>
  Boolean(companySettingsIntegration.syncLegalNameChanges);

/**
 * Predicate that returns a boolean casted value of ProfileNameChanges from CompanySettingsIntegration
 * @param {Object} companySettingsIntegration
 * @returns {Boolean}
 */
export const isCompanySettingsIntegrationProfileNameChangesEnabled = (companySettingsIntegration) =>
  Boolean(companySettingsIntegration.ledgerProfileNameChanges);

/**
 * Predicate that returns a boolean casted value of includePaymentReference from CompanySettingsIntegration
 * @param {Object} companySettingsIntegration
 * @returns {Boolean}
 */
export const isCompanySettingsIntegrationIncludePaymentReferenceEnabled = (companySettingsIntegration) =>
  Boolean(companySettingsIntegration.includePaymentReference);

// *************************************
// CurrentCompany
// *************************************

export const isCurrentCompanyKycStatusPending = (currentCompany) =>
  currentCompany.kycStatus === FundingCustomerStatuses.PENDING;

export const isCurrentCompanyKycStatusDocument = (currentCompany) =>
  // Dwolla is manually verifying a document for this customer
  currentCompany.kycStatus === FundingCustomerStatuses.DOCUMENT;

export const isCurrentCompanyKycStatusRetry = (currentCompany) =>
  currentCompany.kycStatus === FundingCustomerStatuses.RETRY;

export const isCurrentCompanyKycStatusVerified = (currentCompany) =>
  FundingCustomerVerifiedStatuses.includes(currentCompany.kycStatus);

export const isCurrentCompanyKycStatusUnverified = (currentCompany) =>
  currentCompany.kycStatus === FundingCustomerStatuses.UNVERIFIED;

export const isCurrentCompanyKycStatusFailed = (currentCompany) =>
  FundingCustomerFailedStatuses.includes(currentCompany.kycStatus);

export const isCurrentCompanyAchCustomerApproved = (currentCompany) => Boolean(currentCompany?.approvedAchCustomer);

export const isCurrentCompanyInInviteStage = (currentCompany) =>
  isCompanyPlatformTypeInInviteStage(currentCompany.platformType);

// check to see that all company info is submitted (does not include representative info)
export const isCurrentCompanyVerificationInfoSubmitted = (currentCompany) => currentCompany.hasAllCompanyInfo;

export const isCurrentCompanyAccountVerified = (currentCompany) =>
  isCurrentCompanyKycStatusVerified(currentCompany) && isCurrentCompanyAchCustomerApproved(currentCompany);

/**
 * As a current company goes through the external onboarding flow, see if the platformType we started with is what
 * we currently have.
 * @param {CompanyPlatformTypes} startingPlatformType
 * @returns {boolean}
 */
export const isCurrentCompanyPlatformTypeAlreadyAccepted = (startingPlatformType) =>
  // if the currentCompany hasn't loaded yet, we can't do the comparison
  Boolean(startingPlatformType) &&
  // currentCompany is loaded and we started past the invite stage
  isCompanyPlatformTypePastInviteStage(startingPlatformType);

/**
 * Unifying helper to determine if CornerDialogPartnerAlreadyAccepted should show.
 * @param {boolean} hasNeverShownCornerDialog
 * @param {boolean} isCornerDialogCurrentlyHidden
 * @param {boolean} partnershipAcceptFlow
 * @param {CompanyPlatformTypes} startingPlatformType
 * @returns {boolean}
 */
export const shouldShowPartnerAlreadyAcceptedCornerDialog = ({
  hasNeverShownCornerDialog,
  isCornerDialogCurrentlyHidden,
  partnershipAcceptFlow,
  startingPlatformType,
}) =>
  // if we're in the partnership accept flow with no item
  partnershipAcceptFlow &&
  // and we've never shown the corner dialog before
  hasNeverShownCornerDialog &&
  // and the corner dialog isn't showing right now
  isCornerDialogCurrentlyHidden &&
  // and the user is opening the link after the partnership has already been accepted
  isCurrentCompanyPlatformTypeAlreadyAccepted(startingPlatformType);

/**
 * Check if this is a cross border company and the company type is personal.
 *
 * @param {OptionsArg} options
 * @param {UserTypes} options.companyType
 * @param {boolean} options.isInternational
 * @returns {boolean}
 */
export const isPersonalInternationalCompany = ({ companyType, isInternational }) =>
  isInternational && isCompanyTypePersonal(companyType);

/**
 * shouldRedirectToSignUpFlow
 * @param {Company} currentCompany
 * @returns {boolean}
 */
export const shouldRedirectToSignUpFlow = ({ currentCompany }) => {
  if (!currentCompany.id) {
    return false;
  }

  const previouslyVerified = currentCompany.kycPreviouslyVerified;
  if (isCurrentCompanyKycStatusVerified(currentCompany) || previouslyVerified) {
    // if client was verified in the past, let RCTMs access /dashboard
    return false;
  }

  if (
    isCurrentCompanyKycStatusUnverified(currentCompany) ||
    isCurrentCompanyKycStatusPending(currentCompany) ||
    isCurrentCompanyKycStatusFailed(currentCompany) ||
    isCurrentCompanyKycStatusDocument(currentCompany)
  ) {
    // if client is in a pending/error/retry state and was not verified in the past,
    // ensure they can only see SUF at /get-started
    // this can happen if a client goes back in a verification flow after having been verified before
    // to update some business data (like a company name changing)
    return true;
  }

  return false;
};
