import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { ToggleSwitch } from 'components/form';

const ActiveToggle = ({ isActive, isDisabled, name, onChange }) => (
  <ToggleSwitch
    htmlFor={name}
    icons={false}
    isChecked={isActive}
    isDisabled={isDisabled}
    name={name}
    onChange={onChange}
  >
    <span
      className={clsx('bold font-xs margin-left--xm', isActive ? 'font-color--greyXDark' : 'font-color--dark-jordan')}
    >
      {isActive ? 'Active' : 'Inactive'}
    </span>
  </ToggleSwitch>
);

ActiveToggle.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

ActiveToggle.defaultProps = {
  isActive: undefined,
  isDisabled: undefined,
  onChange: undefined,
};

export default ActiveToggle;
