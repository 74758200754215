import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { InfoCardAmount } from 'components/infoCard';

import { createAmountPaidTooltipText } from 'modules/external/ExternalCollectTaxInfo/helpers/text';

import { TenNinetyNineFaqInfoCard } from './components';

/**
 * Right-hand sidebar showing amount vendor was paid in the last tax year and FAQs.
 * @param {(string|number)} [amount]
 * @param {string} [className]
 * @param {Object} partnership
 * @returns {StatelessComponent}
 */
const CollectTaxSidebar = ({ amount, className, partnership }) => (
  <div className={className}>
    <InfoCardAmount
      amount={amount}
      amountPaidLabel="Amount you've been paid to date"
      className="constrain-width margin-bottom--large"
      tooltipText={createAmountPaidTooltipText(partnership.name)}
    />
    <TenNinetyNineFaqInfoCard className={clsx({ 'margin-top--large': !!amount })} />
  </div>
);

CollectTaxSidebar.propTypes = {
  amount: PropTypes.number,
  className: PropTypes.string,
  partnership: PropTypes.shape().isRequired,
};

CollectTaxSidebar.defaultProps = {
  amount: undefined,
  className: undefined,
};

export default CollectTaxSidebar;
