import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const InfoCardBottom = ({ children, className }) => (
  <div className={clsx('info-card--bottom', className)}>{children}</div>
);

InfoCardBottom.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InfoCardBottom.defaultProps = {
  className: undefined,
};

export default InfoCardBottom;
