import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';
import { isUUIDv4 } from '@routable/tablematic';

import { createItemFormFields } from 'constants/formFields';

import { ItemKinds } from 'enums/items';

import { getBillingDataForBillingCode, getDeliveryOptionBillingCodesForAR } from 'helpers/billing';
import {
  isPaymentDeliveryOptionAvailable,
  isPaymentMethodAvailableACH,
  isPaymentMethodAvailableCheck,
  isPaymentMethodAvailableInternational,
  isPaymentDeliveryMethodInternational,
} from 'helpers/paymentMethods';
import { isEqual } from 'helpers/utility';

import { isItemStatusReadyToSend } from './items';

const ARBillingCodes = getDeliveryOptionBillingCodesForAR();

/**
 * Helper for initializing form values where payment delivery method and option
 * can be selected.
 *
 * Parses available payment methods and their associated available delivery options,
 * and returns the best fit default values for payment method details.
 *
 * @param {Object} params
 * @param {BillingCodeData} params.billingDataByCode
 * @param {boolean} params.isPayable
 * @param {Object} params.item
 * @param {PaymentDeliveryMethod[]} params.payablePaymentMethodsAccepted
 * @param {PaymentDeliveryMethod[]} params.receivablePaymentMethodsAccepted
 * @return {Object}
 */
export const getItemPaymentMethodValues = (params) => {
  const { billingDataByCode, isPayable, item, payablePaymentMethodsAccepted, receivablePaymentMethodsAccepted } =
    params;

  const filteredPaymentMethods = item?.isInternational
    ? [PaymentDeliveryMethodType.ANY, PaymentDeliveryMethodType.INTERNATIONAL]
    : [
        PaymentDeliveryMethodType.ANY,
        ...payablePaymentMethodsAccepted.filter((method) => method !== PaymentDeliveryMethodType.INTERNATIONAL),
      ];

  const paymentMethods = isPayable ? filteredPaymentMethods : receivablePaymentMethodsAccepted;

  const initialPaymentDeliveryMethod = isPayable
    ? PaymentDeliveryMethodType.ANY
    : // this is ACH on-load for receivables and unchangeable,
      // but when we add credit card, etc methods, this logic will
      // probably need to be updated
      receivablePaymentMethodsAccepted[0];

  const initialPaymentDeliveryOptionData = isPayable
    ? undefined
    : // this defaults to the first method on-load, before
      // this select becomes interactive
      getBillingDataForBillingCode(billingDataByCode, ARBillingCodes[PaymentDeliveryOptionType.ACH_STANDARD]);

  const initialPaymentDeliveryOption = initialPaymentDeliveryOptionData?.paymentDeliveryOption;

  const isOptionAvailable = isPaymentDeliveryOptionAvailable(
    [initialPaymentDeliveryMethod],
    initialPaymentDeliveryOption,
    billingDataByCode,
    isPayable ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE,
  );

  let methodsAccepted = paymentMethods;

  const isRTSInternational =
    isItemStatusReadyToSend(item) && isPaymentDeliveryMethodInternational(item?.paymentDeliveryMethod);

  const hasOnlyPaymentDeliveryMethodsAccepted =
    item?.paymentDeliveryMethodsAccepted && !item?.paymentDeliveryOption && !item?.paymentDeliveryMethod;

  if (isRTSInternational || hasOnlyPaymentDeliveryMethodsAccepted) {
    methodsAccepted = item.paymentDeliveryMethodsAccepted;
  }

  const paymentDeliveryMethodsAccepted = {
    [PaymentDeliveryMethodType.ACH]: isPaymentMethodAvailableACH(methodsAccepted),
    [PaymentDeliveryMethodType.CHECK]: isPaymentMethodAvailableCheck(methodsAccepted),
    [PaymentDeliveryMethodType.INTERNATIONAL]: isPaymentMethodAvailableInternational(methodsAccepted),
  };

  return {
    paymentDeliveryMethod: initialPaymentDeliveryMethod,
    paymentDeliveryMethodsAccepted,
    paymentDeliveryOption: isOptionAvailable ? initialPaymentDeliveryOption : undefined,
    paymentMethods,
  };
};

/**
 * When creating an item, check if the form "Mark as paid" is clicked.
 * @param {FormUI} formUI
 * @returns {boolean} - returns true if item is marked as paid
 */
export const isCreateItemFormUiShowMarkAsPaid = (formUI) =>
  isEqual(formUI?.[createItemFormFields.SHOW_MARK_AS_PAID], true);

export const isBillViewAndHasInvalidPartnership = (formUI, partnership) =>
  Boolean(formUI.isBillView && !isUUIDv4(partnership.id || ''));
