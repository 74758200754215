import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table cell <td> component for new tables used with react-table
 * @param {ComponentProps} props
 * @param {Node} [props.children]
 * @param {String} [props.className]
 * @param {Number} [props.colSpan]
 * @param {Boolean} [props.hasError]
 * @param {Boolean} [props.hasWarning]
 * @param {Boolean} [props.isDisabled]
 * @param {Boolean} [props.isHighlighted]
 * @param {Object} [props.style]
 * @return {StatelessComponent}
 */
const TableCell = ({
  children,
  className,
  colSpan,
  hasError,
  hasWarning,
  isDisabled,
  isHighlighted,
  onClick,
  style,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <td
    className={clsx(className, {
      disabled: !!isDisabled,
      error: !!hasError,
      highlighted: !!isHighlighted,
      warning: !!hasWarning,
    })}
    colSpan={colSpan}
    onClick={onClick}
    style={style}
  >
    <div>{children}</div>
  </td>
);

TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

TableCell.defaultProps = {
  children: undefined,
  className: undefined,
  colSpan: 1,
  hasError: undefined,
  hasWarning: undefined,
  isDisabled: undefined,
  isHighlighted: undefined,
  onClick: undefined,
  style: undefined,
};

export default TableCell;
