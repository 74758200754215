import PropTypes from 'prop-types';
import React from 'react';

import { Hint, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

import { isExternalPathAccept } from 'helpers/external';

import ExternalItemsListTable from '../ExternalItemsListTable';

const PartnershipPaymentItems = ({ partnershipItems, generateSimilarItemURL }) => {
  if (!isExternalPathAccept(window.location)) {
    return null;
  }

  return (
    <>
      <Hint className="margin-bottom--large margin-top--large" intent={Intent.WARNING}>
        <HintTitle className="margin-bottom--m font-color--greyXDark semibold">
          Below are payments you may take action on:
        </HintTitle>
      </Hint>

      <div className="external-items-list">
        <ExternalItemsListTable generateSimilarItemURL={generateSimilarItemURL} items={partnershipItems} />
      </div>
    </>
  );
};

PartnershipPaymentItems.propTypes = {
  partnershipItems: PropTypes.arrayOf(PropTypes.shape()),
  generateSimilarItemURL: PropTypes.func.isRequired,
};
PartnershipPaymentItems.defaultProps = {
  partnershipItems: undefined,
};

export default PartnershipPaymentItems;
