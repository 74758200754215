import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const LabelBody = ({ children, className }) => <div className={clsx('body', className)}>{children}</div>;

LabelBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LabelBody.defaultProps = {
  className: '',
};

export default LabelBody;
