import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import * as RTBIconNames from './RTBIconNames';

import './RTBIcon.scss';

class RTBIcon extends React.PureComponent {
  getBaseProps = () => {
    const { className, id, icon, onBlur, onFocus, style } = this.props;

    const baseProps = {
      className: clsx('rtb-icon', className, icon),
      id,
      onBlur,
      onFocus,
      style,
    };

    // grab any prefixed props that should pass forward
    Object.keys(this.props).forEach((key) => {
      if (key.startsWith('aria-') || key.startsWith('data-')) {
        baseProps[key] = this.props[key];
      }
    });

    return baseProps;
  };

  getInteractiveProps = () => ({
    onClick: this.props.onClick,
    onMouseLeave: this.props.onMouseLeave,
    onMouseOver: this.props.onMouseOver,
  });

  getIsInteractive = () => {
    const { onClick, onMouseLeave, onMouseOver } = this.props;

    // we don't want to return a function here, just a simple true/false
    return Boolean(onClick || onMouseLeave || onMouseOver);
  };

  render() {
    const isInteractive = this.getIsInteractive();
    const baseProps = this.getBaseProps();

    if (!isInteractive) {
      return <i {...baseProps} />;
    }

    const interactiveProps = this.getInteractiveProps();

    // we likely want the flexibility to use icons as buttons, but to stay accessible
    // (since this is a base-level component), if we have any available interactions
    // that screen-readers, etc should be aware of, we should render this as
    // a natively interactive element
    return <button {...baseProps} {...interactiveProps} type="button" />;
  }
}

const iconNameValues = Object.values(RTBIconNames);

RTBIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(iconNameValues).isRequired,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  style: PropTypes.string,
};

RTBIcon.defaultProps = {
  className: undefined,
  isDisabled: false,
  id: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  onMouseOver: undefined,
  onMouseLeave: undefined,
  style: undefined,
};

export default RTBIcon;
