import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexCol } from 'components/layout';

/**
 * Displays addons (node) if passed to the MultiLineOption
 * component used in react-select or null
 * @see MultiLineOption
 * @param {Object} props
 * @param {NodeMaybe} props.addons
 * @param {string} [props.className]
 * @return {StatelessComponent}
 */
const MultiLineOptionAddons = ({ addons, className }) => {
  if (!addons) {
    return null;
  }

  return <FlexCol className={clsx('multi-line-option--addons', className)}>{addons}</FlexCol>;
};

MultiLineOptionAddons.propTypes = {
  addons: PropTypes.node,
};

MultiLineOptionAddons.defaultProps = {
  addons: undefined,
};

export default MultiLineOptionAddons;
