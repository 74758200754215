import { ItemFiltersByLabel } from 'constants/itemFilters';
import { getQueryParamsFromUrl, getQueryString } from 'helpers/queryParams';
import {} from 'interfaces/itemFilters/filter';
import { filterToUrlParams } from 'modules/itemFilters/filterToUrlParams';
export const getQueryStringFromFilterList = (urlQuery, filterList) => {
    const queryPart = urlQuery.replace(/^\?+(.+)/, '$1');
    const currentParams = getQueryParamsFromUrl(queryPart);
    const itemFilterUrlParams = Object.values(ItemFiltersByLabel).map((filter) => filter.urlParam);
    const nonFilterParams = Object.entries(currentParams || {})
        .filter(([key]) => !itemFilterUrlParams.some((param) => key.indexOf(param) === 0))
        .map(([key, obj]) => ({ [key]: obj }))
        .reduce((agg, add) => ({ ...agg, ...add }), {});
    const newParams = filterList
        .reduce((cur, filter) => ({
        ...cur,
        ...filterToUrlParams(filter),
    }), nonFilterParams);
    return getQueryString(newParams);
};
export const persistFilterListToURL = (filterList, window = global.window) => {
    const lastPathSection = window.location.pathname.split('/').pop();
    const hashPart = window.location.hash;
    const newQuery = getQueryStringFromFilterList(window.location.search, filterList);
    const urlUpdate = `${lastPathSection}?${newQuery}${hashPart}`;
    window.history.replaceState(null, '', urlUpdate);
};
