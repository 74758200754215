import { SUPPORTED_MIME_TYPES, SupportedMimeTypesExtensionsMap } from 'constants/mimeTypes';
export const convertSupportedMimeTypesToExtension = (string) => {
    let parsedString = string;
    SUPPORTED_MIME_TYPES.forEach((mimeType) => {
        if (parsedString.includes(mimeType)) {
            parsedString = parsedString.replace(mimeType, `.${SupportedMimeTypesExtensionsMap.get(mimeType)}`);
        }
    });
    return parsedString;
};
