import { PaymentDeliveryMethodType } from '@routable/shared';
import dayjs from 'dayjs';
import indefinite from 'indefinite';
import _get from 'lodash/get';
import _negate from 'lodash/negate';
import pluralize from 'pluralize';

import { formNamesExistingItem } from 'constants/forms';
import {
  ExistingItemActions,
  INFORMAL,
  ItemKinds,
  ItemKindsText,
  ItemPartnerLabel,
  ItemPaymentDeliveryOptionType,
  ItemPaymentMethodsText,
  ItemPaymentStatusText,
  ItemSourceTypes,
  PaymentOrInvoiceByItemKindText,
} from 'constants/item';
import { LedgerApplicationTypes } from 'constants/ledger';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import {
  CREATE_ITEM_STATES,
  CREATE_ITEM_TABS,
  DASHBOARD,
  ITEM_DETAILS_FILTERS,
  PAYMENTS,
  PAYMENTS_LIST_FILTERS,
} from 'constants/routes';

import { ItemAmountKey, ItemCurrencyCodeKey, ItemLedgerStatuses, ItemPaymentTerms, ItemStatuses } from 'enums/items';
import { PartnershipCountryCodeKey } from 'enums/partnerships';
import { ItemDateScheduledTypes } from 'enums/temporal';

import { getItemSideApprovalsForItemApprovals, isCurrentMembershipApproverOnItem } from 'helpers/approvals';
import { formatting, getBillingDataForPaymentDeliveryOption } from 'helpers/billing';
import { isCurrencyCodeNonUSD, isCurrencyCodeUSD } from 'helpers/currency';
import { formatCurrency, formatCurrencyExplicit, formatCurrencyUSDExplicit } from 'helpers/currencyFormatter';
import { getObjDate, isAfterNow } from 'helpers/date';
import { isPathExternalV2AcceptPartnershipOrItem } from 'helpers/external';
import { isApplicationTypeQBO } from 'helpers/ledger';
import { getCurrentMembershipId } from 'helpers/localStorage';
import { isCanadianPartnership } from 'helpers/partnerships';
import { asPath, getJoinedPath, isSearchRoute } from 'helpers/routeHelpers';
import { capitalize } from 'helpers/stringHelpers';
import { isFilterOneOfNeedsApprovalFilters, isFilterPoDiscrepancy } from 'helpers/urls';
import { hasLength, isEqual, isNotEqual } from 'helpers/utility';

/**
 * Function which returns item details url for given itemId
 * @param {String} itemId
 * @param {Object} params
 * @return {String}
 */
export const buildItemUrl = (itemId, params) => {
  const { filter, tab } = params;

  return getJoinedPath(DASHBOARD, PAYMENTS, tab, filter, itemId, ITEM_DETAILS_FILTERS.ITEM);
};

// *************************************
// Item kind
// *************************************
export const isKindPayable = (kind) => kind === ItemKinds.PAYABLE;
export const isKindReceivable = (kind) => kind === ItemKinds.RECEIVABLE;

export const isItemKindPayable = (item) => isKindPayable(item?.kind);
export const isItemKindReceivable = (item) => isKindReceivable(item?.kind);

// *************************************
// Item creator
// *************************************
export const isItemCreatorCurrentCompany = (item) => item?.isCreator;
export const isPayableCreatorCurrentCompany = (item) => isItemKindPayable(item) && isItemCreatorCurrentCompany(item);
export const isReceivableCreatorCurrentCompany = (item) =>
  isItemKindReceivable(item) && isItemCreatorCurrentCompany(item);
export const isPayableCreatorPartnerCompany = (item) => isItemKindPayable(item) && !isItemCreatorCurrentCompany(item);
export const isReceivableCreatorPartnerCompany = (item) =>
  isItemKindReceivable(item) && !isItemCreatorCurrentCompany(item);

// *************************************
// Item ledger
// *************************************

/**
 * Returns ledger application type attached to the item object
 * @param {Item} item
 * @return {?LedgerApplicationType}
 */
export const getItemLedgerApplicationType = (item) => item?.ledgerOn;

export const isItemLedgerApplicationTypeQBO = (item) =>
  getItemLedgerApplicationType(item) === LedgerApplicationTypes.QBO;

// *************************************
// Item payment or request
// *************************************
export const isItemPayment = (item) => isPayableCreatorCurrentCompany(item) || isReceivableCreatorPartnerCompany(item);
export const isItemRequest = (item) => isPayableCreatorPartnerCompany(item) || isReceivableCreatorCurrentCompany(item);

// *************************************
// Item reference/invoice number
// *************************************
export const doesItemHaveReferenceOrInvoiceNo = (item) => !!item.reference || !!item.invoiceNumber;

export const getItemTransactionLabel = (item) => {
  if (isReceivableCreatorCurrentCompany(item)) {
    return 'Invoice';
  }

  if (isReceivableCreatorPartnerCompany(item)) {
    return 'Received payment';
  }

  return 'Bill';
};

// *************************************
// Statuses
// *************************************
export const isStatusBulkUploadActionRequired = (status) => status === ItemStatuses.BULK_UPLOAD_ACTION_REQUIRED;

// *************************************
// Item statuses
// *************************************
export const isItemStatusBulkUploadComplete = (item) => item?.status === ItemStatuses.BULK_UPLOAD_COMPLETE;
export const isItemStatusCanceled = (item) => item?.status === ItemStatuses.CANCELED;
export const isItemStatusCompleted = (item) => item?.status === ItemStatuses.COMPLETED;
export const isItemStatusComplianceHold = (item) => item?.status === ItemStatuses.COMPLIANCE_HOLD;
export const isItemStatusCreated = (item) => item?.status === ItemStatuses.CREATED;
export const isItemStatusExternallyPaid = (item) => item?.status === ItemStatuses.EXTERNALLY_PAID;
export const isItemStatusFailed = (item) => item?.status === ItemStatuses.FAILED;
export const isItemStatusInitiated = (item) => item?.status === ItemStatuses.INITIATED;
export const isItemStatusIssue = (item) => item?.status === ItemStatuses.ISSUE;
export const isItemStatusNeedsApproval = (item) => item?.status === ItemStatuses.NEEDS_APPROVAL;
export const isItemStatusNew = (item) => item?.status === ItemStatuses.NEW;
export const isItemStatusOCR = (item) => item?.status === ItemStatuses.OCR;
export const isItemStatusPending = (item) => item?.status === ItemStatuses.PENDING;
export const isItemStatusProcessing = (item) => item?.status === ItemStatuses.PROCESSING;
export const isItemStatusReadyToSend = (item) => item?.status === ItemStatuses.READY_TO_SEND;
export const isItemStatusScheduled = (item) => item?.status === ItemStatuses.SCHEDULED;
export const isItemStatusPoDiscrepancy = (item) => item?.status === ItemStatuses.PO_DISCREPANCY;

export const isItemStatusNextReadyToSend = (item) => item?.statusNext === ItemStatuses.READY_TO_SEND;
export const isItemStatusNextScheduled = (item) => item?.statusNext === ItemStatuses.SCHEDULED;

export const isItemStatusFailedOrIssue = (item) => isItemStatusFailed(item) || isItemStatusIssue(item);

export const isItemLocked = (item) => Boolean(item?.lockState);

// *************************************
// Item NOT statuses
// *************************************
export const isItemStatusNotCreated = (item) => item.status !== ItemStatuses.CREATED;

export const getItemStatusByFilter = (filter, isPayablesStatusFilterEnabled) => {
  if (isPayablesStatusFilterEnabled) {
    if (filter === PAYMENTS_LIST_FILTERS.UNPAID_INVOICES) {
      return [
        ItemStatuses.NEEDS_APPROVAL,
        ItemStatuses.NEW,
        ItemStatuses.PENDING,
        ItemStatuses.READY_TO_SEND,
        ItemStatuses.SCHEDULED,
      ];
    }

    if (isFilterOneOfNeedsApprovalFilters(filter)) {
      return ItemStatuses.NEEDS_APPROVAL;
    }

    if (isFilterPoDiscrepancy(filter)) {
      return ItemStatuses.PO_DISCREPANCY;
    }

    return undefined;
  }

  if (filter === PAYMENTS_LIST_FILTERS.WAITING_FOR_ME || filter === PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS) {
    return ItemStatuses.PENDING;
  }

  if (filter === PAYMENTS_LIST_FILTERS.UNPAID_BILLS) {
    return [
      ItemStatuses.COMPLIANCE_HOLD,
      ItemStatuses.NEEDS_APPROVAL,
      ItemStatuses.NEW,
      ItemStatuses.PENDING,
      ItemStatuses.PO_DISCREPANCY,
      ItemStatuses.READY_TO_SEND,
      ItemStatuses.SCHEDULED,
    ];
  }

  if (filter === PAYMENTS_LIST_FILTERS.UNPAID_INVOICES) {
    return [
      ItemStatuses.NEEDS_APPROVAL,
      ItemStatuses.NEW,
      ItemStatuses.PENDING,
      ItemStatuses.READY_TO_SEND,
      ItemStatuses.SCHEDULED,
    ];
  }

  if (filter === PAYMENTS_LIST_FILTERS.FAILED_AND_ISSUE) {
    return [ItemStatuses.FAILED, ItemStatuses.ISSUE];
  }

  if (filter === PAYMENTS_LIST_FILTERS.COMPLETED_AND_EXTERNALLY_PAID) {
    return [ItemStatuses.COMPLETED, ItemStatuses.EXTERNALLY_PAID];
  }

  if (filter === PAYMENTS_LIST_FILTERS.MY_APPROVAL || filter === PAYMENTS_LIST_FILTERS.TEAM_APPROVAL) {
    return PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL;
  }

  return filter;
};

// *************************************
// Item status groups
// *************************************
export const hasItemProcessed = (item) => {
  const processedStatuses = [ItemStatuses.INITIATED, ItemStatuses.COMPLETED, ItemStatuses.EXTERNALLY_PAID];

  return processedStatuses.includes(item.status);
};

export const isExternalItemComplete = (item) => {
  const completeStatuses = [
    ItemStatuses.INITIATED,
    ItemStatuses.COMPLETED,
    ItemStatuses.EXTERNALLY_PAID,
    ItemStatuses.PROCESSING,
  ];

  return completeStatuses.includes(item.status);
};

export const isExternalItemActionable = (item) => {
  const actionableStatuses = [ItemStatuses.NEW];

  return actionableStatuses.includes(item.status);
};

export const isExternalItemClickable = (item) => {
  const nonclickableStatuses = [ItemStatuses.READY_TO_SEND, ItemStatuses.SCHEDULED];

  return !nonclickableStatuses.includes(item.status);
};

export const isAllowedEditPayloadStatuses = (status) => {
  const allowedStatus = [ItemStatuses.EXTERNALLY_PAID, ItemStatuses.READY_TO_SEND, ItemStatuses.PENDING];

  return allowedStatus.includes(status);
};

// *************************************
// Item attachments
// *************************************
export const doesItemHaveAttachments = (item) => Boolean(item.hasAttachments);

/**
 * Compare the time now against the latest time we can cancel an item without a refund.
 * @param {Item} item
 * @returns {boolean}
 */
export const isInitiatedItemCancelableNormal = (item) => {
  const { cancelableUntil } = item;

  if (isItemStatusInitiated(item) && cancelableUntil?.cancelNormal) {
    return isAfterNow(cancelableUntil.cancelNormal);
  }

  return false;
};

/**
 * Utility method to determine when an item can or cannot be cancelled.
 * @param {Item} item
 * @return {boolean}
 */
export const isItemCancelable = (item) => {
  if (isItemStatusInitiated(item)) {
    return isInitiatedItemCancelableNormal(item);
  }

  const cancelableStatuses = [
    ItemStatuses.COMPLIANCE_HOLD,
    ItemStatuses.NEEDS_APPROVAL,
    ItemStatuses.PENDING,
    ItemStatuses.PO_DISCREPANCY,
    ItemStatuses.PROCESSING,
    ItemStatuses.READY_TO_SEND,
    ItemStatuses.SCHEDULED,
  ];

  return cancelableStatuses.includes(item.status);
};

/**
 * During a cancellation confirmation dialog, determine if we should warn our users
 * that a subsequent cancellation email will be sent to their partnership.
 * @param {Object} item
 * @returns {boolean}
 */
export const isItemCancelableWithoutNotification = (item) => {
  // These items always need a notification when you cancel
  const statusNeedsNotification = [ItemStatuses.PENDING, ItemStatuses.PROCESSING, ItemStatuses.INITIATED].includes(
    item.status,
  );

  if (statusNeedsNotification) {
    return false;
  }

  return isItemCancelable(item);
};

export const isItemSettleable = (item) => {
  const settleableStatuses = [
    ItemStatuses.NEEDS_APPROVAL,
    ItemStatuses.PENDING,
    ItemStatuses.PO_DISCREPANCY,
    ItemStatuses.READY_TO_SEND,
    ItemStatuses.SCHEDULED,
  ];

  return settleableStatuses.includes(item.status);
};

export const isItemEmailSentOnSubmit = (item) => {
  const emailSentOnSubmitStatuses = [
    ItemStatuses.PENDING,
    ItemStatuses.PROCESSING,
    ItemStatuses.INITIATED,
    ItemStatuses.COMPLETED,
  ];

  return emailSentOnSubmitStatuses.includes(item.status);
};

// *************************************
// Item status Logic
// *************************************

/**
 * Helper method to check whether an item is awaiting current membership's approval.
 * @param {object} props
 * @param {Item} props.item
 * @param {ItemApprovalLevels[]} props.allApprovals
 * @returns {boolean}
 */
export const isItemNeedingMyApproval = ({ item, allApprovals }) => {
  const currentMembershipId = getCurrentMembershipId();

  if (!isItemStatusNeedsApproval(item)) {
    return false;
  }

  const itemApprovals = getItemSideApprovalsForItemApprovals({
    item,
    allApprovals,
  });
  const currentMembershipItemSideApproval = isCurrentMembershipApproverOnItem(currentMembershipId, itemApprovals);

  return Boolean(currentMembershipItemSideApproval?.canApprove);
};

/**
 * Helper method to check whether an item is awaiting at least one team member's approval.
 * @param {object} props
 * @param {Item} props.item
 * @param {ItemApprovalLevels[]} props.allApprovals
 * @returns {boolean}
 */
export const isItemNeedingOthersApproval = ({ item, allApprovals }) => {
  const currentMembershipId = getCurrentMembershipId();

  if (!isItemStatusNeedsApproval(item)) {
    return false;
  }

  const itemApprovals = getItemSideApprovalsForItemApprovals({
    item,
    allApprovals,
  });

  const teamMembersWhoStillNeedToApprove = itemApprovals
    .flat()
    // keep only the approvals which still need to occur
    .filter((approval) => approval.canApprove)
    // remove any from the currentMembership
    .filter((approval) => isNotEqual(approval.membership, currentMembershipId));

  return hasLength(teamMembersWhoStillNeedToApprove);
};

// *************************************
// Item ledger statuses
// *************************************
export const isItemLedgerStatusError = (invoice) => invoice.ledgerStatus === ItemLedgerStatuses.ERROR;

// *************************************
// Item payment methods
// *************************************
export const isItemPaymentDeliveryMethodInternational = (item) =>
  item.paymentDeliveryMethod === PaymentDeliveryMethodType.INTERNATIONAL;
export const isItemPaymentDeliveryMethodAch = (item) => item.paymentDeliveryMethod === PaymentDeliveryMethodType.ACH;

export const isItemPaymentDeliveryMethodAchOrInternational = (item) =>
  isItemPaymentDeliveryMethodInternational(item) || isItemPaymentDeliveryMethodAch(item);

export const isItemPaymentDeliveryMethodAny = (item) => item.paymentDeliveryMethod === PaymentDeliveryMethodType.ANY;
export const isItemPaymentDeliveryMethodCheck = (item) =>
  item.paymentDeliveryMethod === PaymentDeliveryMethodType.CHECK;
export const isItemPaymentDeliveryMethodUnset = (item) => item.paymentDeliveryMethod === null;

// *************************************
// Item days until due
// *************************************
export const isItemDueSoon = (item) => item.daysUntilDue <= 7 && item.daysUntilDue >= 1;
export const isItemDueToday = (item) => item.daysUntilDue === 0;
export const isItemOverdue = (item) => item.daysUntilDue < 0;

// Use Math.abs to ensure a positive value for display
export const getItemDaysUntilDueLabel = (item) => Math.abs(item.daysUntilDue);

export const itemDaysUntilDueTimePeriod = (item) => pluralize('day', getItemDaysUntilDueLabel(item));
export const itemDueDateDescription = (item) => {
  // Due today
  if (isItemDueToday(item)) {
    return 'Due today';
  }

  // Overdue by x day(s)
  if (isItemOverdue(item)) {
    return `${getItemDaysUntilDueLabel(item)} ${itemDaysUntilDueTimePeriod(item)} late`;
  }

  return `Due in ${getItemDaysUntilDueLabel(item)} ${itemDaysUntilDueTimePeriod(item)}`;
};

// *************************************
// Item description
// *************************************
export const getItemFundsFlowText = (item) => {
  if (isItemKindPayable(item)) {
    if (isItemCreatorCurrentCompany(item)) {
      return 'Pay to';
    }

    return 'Bill from';
  }

  if (isItemKindReceivable(item)) {
    if (isItemCreatorCurrentCompany(item)) {
      return 'Bill to';
    }

    return 'Payment from';
  }

  return 'Error';
};

/**
 * Method to get the item to/from depending on the item kind and direction
 * @param {Pick<Item, 'kind' | 'isCreator'>} item
 * @return {string}
 */
export const getItemToOrFromPlaceholderText = (item) => {
  if (isReceivableCreatorPartnerCompany(item)) {
    return 'from';
  }

  return 'to';
};

// *************************************
// Item dates
// *************************************
// Invoice or created date
export const getItemDateIssued = (item) => getObjDate(item, 'dateIssued', 'll');
// Due date
export const getItemDateDue = (item) => getObjDate(item, 'dateDue', 'll');
// Date item will be sent on
export const getItemDateScheduled = (item) => getObjDate(item, 'dateScheduled', 'll');
// Last status change date
export const getItemDateStatusChange = (item) => getObjDate(item, 'dateStatusChange', 'll');
// Invoice or created date
export const getItemDateSent = (item) => getObjDate(item, 'dateSent', 'll');
// Date transfer is expected to transfer
export const getItemDateExpected = (item) => getObjDate(item, 'dateExpected', 'll');
// Date user received payment outside of platform
export const getItemDateExternallyPaid = (item) => getObjDate(item, 'dateExternallyPaid', 'll');

// *************************************
// Item payment terms
// *************************************
export const getItemPaymentTerms = (paymentTerms) => {
  switch (paymentTerms) {
    case ItemPaymentTerms.NET30:
      return 'NET 30';

    case ItemPaymentTerms.NET60:
      return 'NET 60';

    case ItemPaymentTerms.UPON_RECEIPT:
      return 'Due upon receipt';

    case ItemPaymentTerms.SEE_INVOICE:
      return 'See Invoice';

    default:
      return null;
  }
};

export const isItemPaymentTermsSeeInvoice = ({ paymentTerms }) => paymentTerms === ItemPaymentTerms.SEE_INVOICE;

// *************************************
// Item payment methods
// *************************************

export const hasItemExternallyPaidMethod = (item) => !!(isItemStatusExternallyPaid(item) && item.externallyPaidMethod);

export const getItemPaymentMethod = (item) => {
  if (!item) {
    return 'Error: could not find payment method';
  }

  if (hasItemExternallyPaidMethod(item)) {
    const paidMethod = ItemPaymentMethodsText[item.externallyPaidMethod];

    return `Paid outside of ${PLATFORM_DISPLAY_SHORT_NAME} via ${paidMethod}`;
  }

  if (item.paymentDeliveryMethod) {
    return capitalize(ItemPaymentMethodsText[item.paymentDeliveryMethod]);
  }

  return null;
};

/**
 * Returns a string describing the given item's payment delivery option (applying our standard
 * display formatting).
 * @param {Object} item
 * @param {Object.<string, BillingCodeData>} billingDataByCode
 * @param {string} paymentSource
 * @return {StringMaybe}
 */
export const getItemPaymentDeliveryOption = (item, billingDataByCode, paymentSource) => {
  if (!item) {
    return 'Error: could not find delivery option';
  }

  const billingData = getBillingDataForPaymentDeliveryOption({
    billingDataByCode,
    kind: item.kind,
    paymentDeliveryOption: item.paymentDeliveryOption,
    paymentSource,
    swiftChargeOption: item?.swiftChargeOption,
  });

  if (!billingData) {
    return null;
  }

  return formatting.getFormattedBillingCodeDisplayTitle(billingData, item.paymentDeliveryOption);
};

export const isItemPartnerReceivableAccountSelected = (item) => !!item.partnerReceivableAccount;

// *************************************
// Item payment methods + statuses
// *************************************
export const isItemCompletedACHPayment = (item) => isItemPaymentDeliveryMethodAch(item) && isItemStatusCompleted(item);

export const isItemCompletedCheckPayment = (item) =>
  isItemPaymentDeliveryMethodCheck(item) &&
  [ItemStatuses.PROCESSING, ItemStatuses.INITIATED, ItemStatuses.COMPLETED].includes(item.status);

export const isItemInitiatedACHPayment = (item) =>
  isItemPaymentDeliveryMethodAch(item) && [ItemStatuses.PROCESSING, ItemStatuses.INITIATED].includes(item.status);

// *************************************
// Existing item
// *************************************
/**
 * Get the existing item form name from the action
 * @param action
 * @return {*}
 */
export const getExistingItemFormNameFromAction = (action) => {
  switch (action) {
    case ExistingItemActions.APPROVE:
      return formNamesExistingItem.APPROVE;

    case ExistingItemActions.CANCEL:
      return formNamesExistingItem.CANCEL;

    case ExistingItemActions.PAY:
    case ExistingItemActions.RECEIVE:
      return formNamesExistingItem.SELECT_FUNDING_ACCOUNT;

    case ExistingItemActions.MARK_AS_PAID:
      return formNamesExistingItem.MARK_AS_PAID;

    default:
      return null;
  }
};

// *************************************
// Item texts
// *************************************
/**
 * Get the invoice or bill text per item
 * @param {object} item
 * @return {string}
 */
export const getBillOrInvoiceText = (item) => (isItemKindPayable(item) ? 'bill' : 'invoice');

/**
 * Get the bill or pay text per item
 * @param {object} item
 * @return {string}
 */
export const getBillOrPayText = (item) => (isItemKindPayable(item) ? 'bill' : 'pay');

/**
 * Get the item kind text
 * @param {object} item
 * @param {string} [casing]
 * @return {string}
 */
export const getItemKindText = (item, casing) => {
  const itemKindText = ItemKindsText[item.kind];
  if (casing) {
    return itemKindText[casing]();
  }
  return itemKindText;
};

/**
 * Get payable or receivable text per item
 * @param item
 * @return {string}
 */
export const getPayableOrReceivableText = (item) =>
  isItemKindPayable(item) ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE;

/**
 * Get payables or receivables text per item
 * @function
 * @param {ItemKinds} itemKind
 * @param {String|Number} itemCount
 * @return {string}
 */
export const getPayablesOrReceivablesTextForItemCount = (itemKind, itemCount) =>
  pluralize(getPayableOrReceivableText({ kind: itemKind }), parseInt(itemCount, 10));

/**
 * Get the invoice or payment text per item
 * @param {Object} item
 * @param {ItemKinds} item.kind
 * @return {string}
 */
export const getPaymentOrInvoiceText = (item) => PaymentOrInvoiceByItemKindText[item?.kind];

/**
 * Get payments or invoices text per item
 * @function
 * @param {ItemKinds} itemKind
 * @param {String|Number} itemCount
 * @return {string}
 */
export const getPaymentsOrInvoicesText = (itemKind, itemCount) => {
  const count = Number.isNaN(parseInt(itemCount, 10)) ? 1 : parseInt(itemCount, 10);
  const word = getPaymentOrInvoiceText({ kind: itemKind }) || '';
  return pluralize(word, count);
};

/**
 * Get payments or invoices text per item.
 * @param {ItemKinds} itemKind
 * @param {String|Number} itemCount
 * @return {string}
 */
export const getPaymentsOrInvoicesTextForExternalFlow = (itemKind, itemCount) => {
  const count = Number.isNaN(parseInt(itemCount, 10)) ? 1 : parseInt(itemCount, 10);
  // itemKind needs to be flipped as it's reversed on the external flow
  const flipItemKind = itemKind === ItemKinds.RECEIVABLE ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE;
  const word = getPaymentOrInvoiceText({ kind: flipItemKind }) || '';
  return pluralize(word, count);
};

/**
 * Get the invoice or payment or informal text per item
 * @param {ObjectMaybe} [item]
 * @return {string}
 */
export const getPaymentOrInvoiceOrInformalText = (item) => getPaymentOrInvoiceText(item) || INFORMAL;

/**
 * Get payable or invoice per item
 * @function
 * @param {Item} item
 * @return {string}
 */
export const getPayableOrInvoiceText = (item) => (isItemKindPayable(item) ? ItemKinds.PAYABLE : 'invoice');

/**
 * Get the invoice or payment text per item, with leading 'a' or 'an'
 * @param {object} item
 * @return {string}
 */
export const getPaymentOrInvoiceTextWithArticle = (item) => {
  const baseText = getPaymentOrInvoiceText(item);
  return indefinite(baseText);
};

/**
 * When the next step in accepting an item is an approval, we say something like, "After this item is approved, it will
 * be accepted/initiated."
 * @param {Item} item
 * @returns {string}
 */
export const getNextStepApprovalVerbFromItem = (item) => (isItemKindPayable(item) ? 'initiated' : 'accepted');

/**
 * Returns sent if receivable or paid if payable
 * @param item
 * @return {string}
 */
export const getItemSentOrPaidText = (item) => {
  if (isItemKindReceivable(item)) {
    return ItemPaymentStatusText.SENT;
  }

  return ItemPaymentStatusText.PAID;
};

/**
 * Returns due if receivable or paid if payable
 * @param {Item} item
 * @returns {string}
 */
export const getItemDueOrPaidText = (item) => {
  if (isItemKindReceivable(item)) {
    return ItemPaymentStatusText.DUE;
  }

  return ItemPaymentStatusText.PAID;
};

export const getItemTransactionBaseText = (item) => {
  if (item.reference && item.invoiceNumber) {
    return `${item.invoiceNumber}: ${item.reference}`;
  }

  if (item.reference) {
    return item.reference;
  }

  return item.invoiceNumber;
};

export const getItemTransactionText = (item) =>
  getItemTransactionBaseText(item) || `No ${getBillOrInvoiceText(item)} number provided`;

// *************************************
// Create item
// *************************************

export const isCurrentPathCreateItemBillView = (location = window.location) =>
  asPath(location.pathname).startsWith(getJoinedPath(DASHBOARD, CREATE_ITEM_TABS.CREATE_BILL));

export const isCurrentPathCreateItemReceivable = (location = window.location) =>
  asPath(location.pathname).startsWith(getJoinedPath(DASHBOARD, CREATE_ITEM_TABS.CREATE_RECEIVABLE));

export const isCurrentPathCreateItemStateAwaitingPayment = (location = window.location) =>
  asPath(location.pathname).endsWith(CREATE_ITEM_STATES.AWAITING_PAYMENT);

export const isCurrentPathCreateItemStateNew = (location = window.location) =>
  asPath(location.pathname).endsWith(CREATE_ITEM_STATES.NEW);

/**
 * Are we in the route to edit an item?
 * @param {Object} [location=window.location]
 * @returns {boolean}
 */
export const isCurrentPathItemEdit = (location = window.location) =>
  asPath(location.pathname).endsWith(CREATE_ITEM_STATES.EDIT);

export const getItemKindFromCreateItemPath = (location = window.location) => {
  if (isCurrentPathCreateItemReceivable(location)) {
    return ItemKinds.RECEIVABLE;
  }
  return ItemKinds.PAYABLE;
};

/**
 * Checks if item source is platform
 *
 * @param {ItemSource} source
 * @returns {boolean}
 */
export const isItemSourcePlatform = (source) => source === ItemSourceTypes.PLATFORM;

/**
 * Returns item kind from based on current External V2 location
 *
 * @param {Location} [location=window.location]
 * @returns {ItemKinds|string}
 */
export const getItemKindFromExternalV2Path = (location = window.location) =>
  isPathExternalV2AcceptPartnershipOrItem(location) ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE;

/**
 * Check if we're currently on the route to edit a payable.
 *
 * @param {Location} [location=window.location]
 * @returns {boolean} true if editing payable
 */
export const isCurrentPathItemEditPayable = (location = window.location) =>
  asPath(location.pathname).startsWith(getJoinedPath(DASHBOARD, ItemKinds.PAYABLE));

/**
 * Determine the item kind based on the edit path.
 *
 * @param {Location} [location=window.location]
 * @returns {ItemKinds}
 */
export const getItemKindFromEditItemPath = (location = window.location) =>
  isCurrentPathItemEditPayable(location) ? ItemKinds.PAYABLE : ItemKinds.RECEIVABLE;

// *************************************
// Misc
// *************************************
export const getFirstAndLastValuesFromPagination = (pagination) => {
  const { page, pages, pageSize } = pagination;

  if (isSearchRoute()) {
    // running a search keeps only the current visible item ids in search state,
    // so we'll always slice the first 0 -> pageSize
    return { firstValue: 0, lastValue: pageSize };
  }

  // regular tab/filter slicing doesn't have a results list; thus, we do math
  const totalResults = pages * pageSize;
  const previousPage = page - 1;
  const isLastPage = page * pageSize >= totalResults;

  const firstValue = totalResults > 0 ? pageSize * previousPage : 1;
  const lastValue = isLastPage ? totalResults : page * pageSize;

  return { firstValue, lastValue };
};

export const getPaginationCountFromMeta = (meta = {}) => {
  const { routable } = meta;

  if (routable && routable.pagination) {
    const { count } = routable.pagination;

    if (count) {
      return count;
    }
  }

  return undefined;
};

/**
 * Returns boolean representing whether the item has partner contacts whose access is not `none`.
 * @param {Object} item
 * @param {boolean} item.hasPartnerContacts
 * @return {boolean}
 */
export const hasPartnerContacts = (item) => !!item.hasPartnerContacts;

/**
 * Returns boolean representing whether the item amount is above the unverified transaction limit
 * @param {Item} item - In e.g. ExistingItem, the item total will be the item.amount property.
 * @param {number|undefined} [itemAmount] - In CreateItem, the item total will be provided as a standalone value by the
 *   dynamic framework.
 * @return {boolean}
 */
export const isItemAmountOverACHLimit = (item, itemAmount) =>
  // use itemAmount if provided (required if called when creating an item), otherwise fallback
  // to the item.amount property (can do this on existing items)
  (itemAmount ?? item.amount) > parseFloat(process.env.REACT_APP_ACH_UNVERIFIED_TRANSACTION_LIMIT);

/**
 * Determine if the item needs the partnership to be matched before proceeding.
 * @param {Item | import('interfaces/item').LedgerItem} item
 * @param {boolean} item.isPartnershipMatched
 * @returns {boolean}
 */
export const isPartnershipMatched = (item) => !!item.isPartnershipMatched;

export const isPartnerNotified = (item) => !!item.partnerNotified;

/**
 * Handler to update the form per a change to 'partner funding account' checkbox (redux-form)
 * @param {OptionsArg} options
 * @param {object} options.values
 * @param {object} options.previousValues
 * @param {object} options.props
 * @param {object} options.formPartnerAccountPaths
 * @return {void}
 */
export const onFormPartnerFundingAccountChange = ({ values, previousValues, props, formPartnerAccountPaths }) => {
  // If submit has not failed, paymentDeliveryOption field does not have
  // an error and we don't need to do any other check
  if (!props.submitFailed) {
    return;
  }

  const { receivable, payable } = formPartnerAccountPaths;

  const prevReceivable = _get(previousValues, receivable);
  const nextReceivable = _get(values, receivable);

  const prevPayable = _get(previousValues, payable);
  const nextPayable = _get(values, payable);

  // If either payable or receivable partner funding account has changed,
  // we want to clear any async errors on ItemPaymentDeliveryOption field
  if (isNotEqual(prevReceivable, nextReceivable) || isNotEqual(prevPayable, nextPayable)) {
    props.clearSubmitErrors();
  }
};

/**
 * When importing items from the ledger, the invoice doesn't always have the item kind. The item may also have approvers
 * information which the invoice needs for submission. Combine the two, but use invoice's amountDue.
 * @param {Item} item
 * @param {LedgerInvoice} invoice
 * @returns {Item|LedgerInvoice}
 */
export const mergeItemWithInvoice = (item, invoice) => ({
  ...item,
  ...invoice,
});

/**
 * Determines if the provided string is a mark as paid item action string
 * @param {string} value
 * @returns {boolean}
 */
export const isExistingItemActionMarkAsPaid = (value) => isEqual(ExistingItemActions.MARK_AS_PAID, value);

/**
 * Returns correct item partner label based on the given item kind
 * @param {Item} item
 * @returns {ItemPartnerLabel}
 */
export const getItemPartnerLabelFromItemKind = (item) =>
  isItemKindPayable(item) ? ItemPartnerLabel.PAYEE : ItemPartnerLabel.PAYOR;

/**
 * Returns true if item amount for given amount key is zero
 * @param {import('interfaces/item').Item} item
 * @param {ItemAmountKey} [amountKey=ItemAmountKey.TOTAL]
 * @returns {Boolean}
 */
export const isItemAmountZero = (item, amountKey = ItemAmountKey.TOTAL) => !parseFloat(item[amountKey]);

/**
 * Looks at amountPaid and amountPartner to determine if item has been paid
 * @param {Item} item
 * @returns {boolean}
 */
export const isItemPaid = (item) => {
  const amountPaid = item[ItemAmountKey.PAID];
  const amountPartner = item[ItemAmountKey.PARTNER];
  const amountCompany = item[ItemAmountKey.COMPANY];

  if (item.currencyCode === item.currencyCodePartner) {
    return amountPaid === amountPartner;
  }

  return amountPaid === amountCompany;
};

/**
 * Gets the right property key to select the amount due in USD currency
 * @param {import('interfaces/item').Item} item
 * @returns {ItemAmountKey} amountDueKey
 */
export const getAmountDueKey = (item) => {
  // When an item is paid,
  // then amountDue is '0.00', so we should display it
  const isPaid = isItemPaid(item);

  return isPaid ? ItemAmountKey.DUE : ItemAmountKey.COMPANY;
};

/**
 * Gets the right property key to select the amount paid in USD currency
 * @param {import('interfaces/item').Item} item
 * @returns {ItemAmountKey} amountPaidKey
 */
export const getAmountPaidKey = (item) => {
  // When and item has not been paid,
  // then amountPaid is '0.00', so we should display it
  const isDue = !isItemPaid(item);

  return isDue ? ItemAmountKey.PAID : ItemAmountKey.COMPANY;
};

/**
 * Given an item, returns formatted string of the item's amount selected by the passed
 * amountKey param
 * @param {OptionsArg} allArgs
 * @param {ItemAmountKey} [allArgs.amountKey=ItemAmountKey.TOTAL]
 * @param {CurrencyCodeMap} allArgs.currencyCodeMap
 * @param {ItemCurrencyCodeKey} [allArgs.currencyCodeKey=ItemCurrencyCodeKey.GENERAL]
 * @param {Item} allArgs.item
 * @param {Object} [allArgs.options]
 * @returns {String}
 */
export const formatItemAmountForAmountKey = ({
  amountKey = ItemAmountKey.TOTAL,
  currencyCodeMap,
  currencyCodeKey = ItemCurrencyCodeKey.GENERAL,
  item,
  options,
}) => {
  const amount = item[amountKey];
  const currencyCode = item[currencyCodeKey];

  return formatCurrency(amount, currencyCode, currencyCodeMap, options);
};

/**
 * Given an item, returns an explict formatted string of the item's amount selected by the passed
 * amountKey param ex. "€1,234.56 EUR"
 * @param {OptionsArg} allArgs
 * @param {ItemAmountKey} [allArgs.amountKey=ItemAmountKey.TOTAL]
 * @param {CurrencyCodeMap} allArgs.currencyCodeMap
 * @param {ItemCurrencyCodeKey} [allArgs.currencyCodeKey=ItemCurrencyCodeKey.GENERAL]
 * @param {Item} allArgs.item
 * @param {Object} [allArgs.options]
 * @returns {String}
 */
export const formatItemAmountForAmountKeyExplicit = ({
  amountKey = ItemAmountKey.TOTAL,
  currencyCodeMap,
  currencyCodeKey = ItemCurrencyCodeKey.GENERAL,
  item,
  options,
}) => {
  const amount = item[amountKey];
  const currencyCode = item[currencyCodeKey];

  return formatCurrencyExplicit(amount, currencyCode, currencyCodeMap, options);
};

/**
 * Determines if an item is a payment in USD to a Canadian company and should therefore be
 * treated similarly to a domestic payment.
 * @param {Item} item
 * @param {Partnership} partnership
 * @param {PartnershipCountryCodeKey} [countryCodeKey=PartnershipCountryCodeKey.PARTNER]
 * @returns {Boolean}
 */
export const isCanadianUsdPayment = (item, partnership, countryCodeKey = PartnershipCountryCodeKey.PARTNER) => {
  const currencyCode = item[ItemCurrencyCodeKey.GENERAL];

  return isItemPayment(item) && isCurrencyCodeUSD(currencyCode) && isCanadianPartnership(partnership, countryCodeKey);
};

/**
 * Given an item, returns formatted string of amount
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {Item} item
 * @param {Object} [options]
 * @returns {string}
 */
export const formatItemAmount = (currencyCodeMap, item, options) =>
  formatItemAmountForAmountKey({ currencyCodeMap, item, options });

/**
 * Given an item, returns formatted string of amountPaid
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {Item} item
 * @param {Object} [options]
 * @returns {string}
 */
export const formatItemAmountPaid = (currencyCodeMap, item, options) =>
  formatItemAmountForAmountKey({
    currencyCodeMap,
    amountKey: ItemAmountKey.PAID,
    item,
    options,
  });

/**
 * Given an item, returns formatted string of amountDue
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {Item} item
 * @param {Object} [options]
 * @returns {string}
 */
export const formatItemAmountDue = (currencyCodeMap, item, options) =>
  formatItemAmountForAmountKey({
    currencyCodeMap,
    amountKey: ItemAmountKey.DUE,
    item,
    options,
  });

/**
 * Returns null or a dayjs object to prefill the item.dateScheduled in the createItem form when used in the item edit flow
 * @param {Item} item
 * @returns {null|object}
 */
export const getItemDateScheduledInitialValueForItemEdit = (item) =>
  isItemStatusReadyToSend(item) || isItemStatusNextReadyToSend(item) ? null : dayjs(item.dateScheduled);

/**
 * Returns the expected ItemDateScheduledTypes if the dateScheduled is in the future or not
 * @param item
 * @returns {ItemDateScheduledTypes}
 */
export const getDateScheduledTypeInitialValueForItemEdit = (item, hasPermissionToSendItem) => {
  if (isItemStatusNeedsApproval(item) || isItemStatusPoDiscrepancy(item)) {
    if (isItemStatusNextReadyToSend(item)) {
      return ItemDateScheduledTypes.SKIP;
    }

    if (isItemStatusNextScheduled(item)) {
      return ItemDateScheduledTypes.FUTURE;
    }
  }

  if (isItemStatusReadyToSend(item) || !hasPermissionToSendItem) {
    return ItemDateScheduledTypes.SKIP;
  }

  if (item.dateScheduled && dayjs(item.dateScheduled).isAfter()) {
    return ItemDateScheduledTypes.FUTURE;
  }

  return ItemDateScheduledTypes.TODAY;
};

/**
 * Get the past tense action from the action
 * @param {ExistingItemActions} action
 * @return {string}
 */
export const getExistingItemActionPastTenseFromAction = (action) => {
  switch (action) {
    case ExistingItemActions.APPROVE:
      return 'approved';

    case ExistingItemActions.CANCEL:
      return 'canceled';

    case ExistingItemActions.EDIT:
      return 'edited';

    case ExistingItemActions.PAY:
      return 'paid';

    case ExistingItemActions.RECEIVE:
      return 'received';

    case ExistingItemActions.MARK_AS_PAID:
      return 'marked as paid';

    case ExistingItemActions.SEND:
      return 'sent';

    default:
      return null;
  }
};

/**
 * Returns true|false if item is a QBO-related receivable
 * @param {Item} item
 * @param {LedgerApplicationType} ledgerApplication Because the RCTM could have created this receivable on another ledger but now
 * be connected to QBO, a ledger application type can be passed.
 * @returns {boolean}
 */
export const isItemQBOReceivable = (item, ledgerApplication) =>
  isItemKindReceivable(item) && (isItemLedgerApplicationTypeQBO(item) || isApplicationTypeQBO(ledgerApplication));

/**
 * Returns true|false if item is processed or cancelled
 * @param {Item} item
 * @returns {boolean}
 */
export const isItemProcessedOrCancelled = (item) => hasItemProcessed(item) || isItemStatusCanceled(item);

/**
 * Returns true|false if item status allows editing
 * @param {Item} item
 * @returns {boolean}
 */
export const isItemStatusAllowsEdit = (item) => {
  const editableStatuses = [
    ItemStatuses.NEEDS_APPROVAL,
    ItemStatuses.PO_DISCREPANCY,
    ItemStatuses.READY_TO_SEND,
    ItemStatuses.SCHEDULED,
  ];
  return editableStatuses.includes(item?.status);
};

/**
 * Returns true|false if item is in a state that allows editing
 * @param {import('interfaces/item').Item} item
 * @param {LedgerApplicationType} ledger
 * @returns {boolean}
 */
export const isItemInEditableState = (item, ledger) =>
  !isItemQBOReceivable(item, ledger) && isItemStatusAllowsEdit(item) && !isItemLocked(item);

/**
 * Returns formatted conversion rate for given item amountCompany and amountPartner
 * @param {CurrencyCodeMap} currencyCodeMap
 * @param {import('interfaces/item').Item} item
 * @returns {String}
 */
export const getFormattedItemConversionRate = (currencyCodeMap, item) => {
  const { amountCompany, amountPartner, currencyCodePartner, exchangeRate } = item;

  // We recive amountCompany and amountPartner as strings from the BE -> we need
  // to convert them into floats
  const parsedAmountCompany = parseFloat(amountCompany);
  const parsedAmountPartner = parseFloat(amountPartner);

  // Safe-checking so that we don't do math with NaN values and/or
  // division by zero
  if (!parsedAmountCompany || !parsedAmountPartner) {
    return 'Conversion rate not available';
  }

  // We always want to display the company's conversion value as $1 USD
  const formattedCompanyAmount = `${formatCurrencyUSDExplicit(1)}`;
  // We display the partner's conversion value by formatting the dynamic values
  const formattedPartnerAmount = `${formatCurrencyExplicit(exchangeRate, currencyCodePartner, currencyCodeMap, {
    precision: 4,
  })}`;

  // We return the string in expected format, that is:
  // $1 USD = <currency_symbol>X <currency_code>
  return `${formattedCompanyAmount} = ${formattedPartnerAmount}`;
};

/**
 * Returns the value of item.isInternational if set or performs a fallback
 * check for item.currencyCode if isInternational flag is not set
 * @param {import('interfaces/item').Item} item
 * @returns {Boolean}
 */
export const isInternationalItem = (item) => item?.isInternational ?? isCurrencyCodeNonUSD(item?.currencyCode);

/**
 * Checks if item by is domestic by returning the opposite value from isInternationalItem
 * @param {Item} item
 * @returns {Boolean}
 */
export const isDomesticItem = _negate(isInternationalItem);

/**
 * This will determine whether to use the amountDueCompany prop for international items
 * or amountDue prop for domestic. Not all items have the amountDueCompany property,
 * so this is only meant to run for CSV upload items.
 * @param {Item} item
 * @returns {String} amountDueCompany
 */
export const getAmountDueForBulkUploadItem = (item) =>
  isInternationalItem(item) ? item[ItemAmountKey.DUE_COMPANY] : item[ItemAmountKey.DUE];

/**
 * Returns true if item.paymentDeliveryOption is "vendors_choice"
 * @param {import('interfaces/item').Item | import('@routable/bulk-importer').BulkImporterItemGroup} item
 * @returns {Boolean}
 */
export const isItemPaymentDeliveryOptionVendorsChoice = (item) =>
  item?.paymentDeliveryOption === ItemPaymentDeliveryOptionType.VENDORS_CHOICE;

/**
 * Returns true if supports multiple currency
 * @param {Item} item
 * @returns {boolean}
 */
export const hasMultipleCurrency = (item) =>
  item[ItemCurrencyCodeKey.GENERAL] !== item[ItemCurrencyCodeKey.COMPANY] ||
  item[ItemCurrencyCodeKey.GENERAL] !== item[ItemCurrencyCodeKey.PARTNER];
