import { Clickable } from '@routable/gross-ds';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import StatusBadge from 'components/statusBadge';

import { AccountTabsStatus } from 'constants/accounts';

import './AccountStatus.scss';

const AccountStatusTab = ({ accountsForStatus, onClick, isSelected, status }) => (
  <Clickable
    className={clsx('account-status--tab hover:no-underline text-grey-60', { selected: isSelected })}
    onClick={(e) => {
      e.preventDefault();
      onClick(status);
    }}
  >
    <StatusBadge
      displayStatus={status}
      displayText={AccountTabsStatus[status.toUpperCase()]}
      iconClassName="account-status--tab-icon"
      status={status}
      textClassName="account-status--tab-text"
    />
    <span className="font-regular">{` bank accounts (${accountsForStatus ? accountsForStatus.length : 0})`}</span>
  </Clickable>
);

AccountStatusTab.propTypes = {
  accountsForStatus: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default AccountStatusTab;
