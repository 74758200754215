import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const CheckListInnerList = ({ children, className }) => (
  <ul className={clsx('check-list--list', className)}>{children}</ul>
);

CheckListInnerList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CheckListInnerList.defaultProps = {
  children: undefined,
  className: undefined,
};

export default CheckListInnerList;
