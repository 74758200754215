import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureListClassNames } from 'components/disclosureList/constants';
import { DetailsList } from 'components/listItems';

import { DisclosureListHeader } from './components';

/**
 * Disclosure List- a list that, by default, has a header with a detail disclosure icon
 * and a body that is expandable/collapsible.
 *
 * Optionally, we can pass in a customHeader (prop) or customBody (prop) to override
 * the default components used for these.
 */
const DisclosureList = ({
  dataFullStory,
  bodyRowProps,
  bodyClassName,
  className,
  footer,
  headerClassName,
  headerComponent,
  headerIconProps,
  headerIconName,
  headerText,
  headerTooltipText,
  headerTooltipContentMargin,
  isBodyInset,
  items,
  keyExtractor,
  onToggleOpen,
  isOpenOverride,
  startOpen,
  style,
  useHeader,
  useTransitions,
}) => {
  const useOpenOverride = isOpenOverride !== undefined;

  const [isOpen, toggleIsOpen] = React.useState(!useHeader || startOpen);
  const openState = useOpenOverride ? isOpenOverride : isOpen;

  return (
    <div
      className={clsx(className, DisclosureListClassNames.LIST, {
        [DisclosureListClassNames.LIST_TRANSITIONS]: useTransitions,
        'inset-body': isBodyInset,
        'no-header': !useHeader,
        open: openState,
      })}
      style={style}
    >
      {useHeader && (
        <DisclosureListHeader
          className={headerClassName}
          headerComponent={headerComponent}
          icon={headerIconName}
          iconProps={headerIconProps}
          isOpen={openState}
          onToggleOpen={() => {
            if (useOpenOverride) {
              onToggleOpen();
            } else {
              toggleIsOpen(!openState);
            }
          }}
          text={headerText}
          tooltipContentMargin={headerTooltipContentMargin}
          tooltipText={headerTooltipText}
          useHeader={useHeader}
        />
      )}
      <DetailsList
        className={clsx(bodyClassName, DisclosureListClassNames.BODY, {
          [DisclosureListClassNames.BODY_TRANSITIONS]: useTransitions,
        })}
        dataFullStory={dataFullStory}
        footer={footer}
        items={items}
        keyExtractor={keyExtractor}
        rowProps={bodyRowProps}
      />
    </div>
  );
};

DisclosureList.propTypes = {
  bodyClassName: PropTypes.string,
  bodyRowProps: PropTypes.shape({}),
  className: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dataFullStory: PropTypes.bool,
  headerClassName: PropTypes.string,
  headerComponent: PropTypes.node,
  headerIconName: PropTypes.string,
  headerIconProps: PropTypes.shape(),
  headerText: PropTypes.string,
  headerTooltipContentMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerTooltipText: PropTypes.string,
  isBodyInset: PropTypes.bool,
  isOpenOverride: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.node]),
  keyExtractor: PropTypes.func,
  onToggleOpen: PropTypes.func,
  startOpen: PropTypes.bool,
  style: PropTypes.shape({}),
  useHeader: PropTypes.bool,
  useTransitions: PropTypes.bool,
};

DisclosureList.defaultProps = {
  bodyClassName: undefined,
  bodyRowProps: {},
  className: undefined,
  footer: undefined,
  headerClassName: undefined,
  headerComponent: undefined,
  headerIconName: undefined,
  headerIconProps: undefined,
  headerText: undefined,
  headerTooltipContentMargin: undefined,
  headerTooltipText: undefined,
  // eslint-disable-next-line routable/default-props-prefer-undefined
  isBodyInset: false,
  isOpenOverride: undefined,
  items: [],
  keyExtractor: (obj) => obj.key,
  onToggleOpen: undefined,
  // eslint-disable-next-line routable/default-props-prefer-undefined
  startOpen: false,
  style: {},
  useHeader: true,
  useTransitions: true,
};

export default DisclosureList;
