import clsx from 'clsx';
import React from 'react';
import './SignUpFormFooter.module.scss';
import { LinkNewPage, SafeAndSecureBadge } from 'components';
import { HELP_DOCS_URL } from 'constants/platform';
import {} from './types/SignUpFormFooter.types';
const SignUpFormFooter = ({ extraBottomPadding = false }) => (React.createElement("div", { className: clsx('form-footer', extraBottomPadding && 'extra-bottom-padding') },
    React.createElement(SafeAndSecureBadge, { className: "font-color--greyXDark", includeLink: true }),
    React.createElement(LinkNewPage, { className: "font-color--greyXDark", href: HELP_DOCS_URL }, "Help Docs")));
export default SignUpFormFooter;
