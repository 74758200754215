import PropTypes from 'prop-types';
import React from 'react';

import BrandLogo from 'components/brand/BrandLogo';
import ErrorCard from 'components/error/components/errorCard';

import { CONTACT_SUPPORT_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';

import { LOGO } from 'global/images/platform';

import './BrandedError.scss';

const BrandedError = ({ errorText, errorTitle, isV2 }) => (
  <div className="branded--contents remove-margin branded-error--container">
    {isV2 && (
      <div className="branded-error--logo-container">
        <BrandLogo imageSrc={LOGO.NAVY} />
      </div>
    )}

    <ErrorCard header={errorTitle} reason={errorText}>
      <div className="branded-error--help-container">
        <span className="branded-error--need-help">Need help?</span>
        <a className="branded-error--contact-us" href={`mailto:${PLATFORM_EMAILS.SUPPORT}`}>
          <span>{CONTACT_SUPPORT_LABEL}</span>
        </a>
      </div>
    </ErrorCard>
  </div>
);

BrandedError.propTypes = {
  errorText: PropTypes.string,
  errorTitle: PropTypes.string,
  isV2: PropTypes.bool,
};

BrandedError.defaultProps = {
  errorText: 'Please contact support for assistance.',
  errorTitle: 'Something went wrong',
  isV2: undefined,
};

export default BrandedError;
