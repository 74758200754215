import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Chip = ({ className, text }) => <span className={clsx('chip', className)}>{text}</span>;

Chip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node.isRequired,
};

Chip.defaultProps = {
  className: undefined,
};

export default Chip;
