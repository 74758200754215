import React from 'react';

/**
 * When initializing a component, you may need to fetch some resources (members, items, etc.), but you don't want to
 * fetch again if you're already fetching them. Similarly, if you already have the resources, don't fetch them again.
 * @param {OptionsArg} options
 * @param {function} options.fetcher
 * @param {boolean} options.isFetching
 * @param {boolean} options.resourceExists
 * @returns {function}
 */
const useFetchIfNotExists = ({ fetcher, isFetching, resourceExists }) =>
  React.useEffect(() => {
    if (!resourceExists && !isFetching) {
      fetcher();
    }
  }, [isFetching, fetcher, resourceExists]);

export default useFetchIfNotExists;
