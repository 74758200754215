import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { getItemBullet } from 'components/checkList/helpers';

const CheckListItem = (props) => {
  const { children, className, isComplete, isTitle } = props;

  return (
    <li
      className={clsx(
        'check-list--item',
        className,
        isComplete ? 'check-list--item_complete' : 'check-list--item_incomplete',
        {
          'check-list--item--title': isTitle,
        },
      )}
    >
      {getItemBullet(props)}

      <span className="check-list--item--text">{children}</span>
    </li>
  );
};

CheckListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /* bullet props used in helper function */
  /* eslint-disable react/no-unused-prop-types */
  completeBullet: PropTypes.node,
  incompleteBullet: PropTypes.node,
  /* eslint-enable react/no-unused-prop-types */
  isComplete: PropTypes.bool,
  /* isTitle prop used in helper function */
  /* eslint-disable-next-line react/no-unused-prop-types */
  isTitle: PropTypes.bool,
};

CheckListItem.defaultProps = {
  children: undefined,
  className: undefined,
  completeBullet: undefined,
  incompleteBullet: undefined,
  isComplete: false,
  isTitle: false,
};

export default CheckListItem;
