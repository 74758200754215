import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from 'components/layout';

const EntityWidgetHeader = ({
  className,
  contentLeft,
  contentRight,
  contentRightProps,
  isCollapsible,
  onClick,
  style,
  subtitle,
  title,
}) => (
  <button
    className={clsx('entity-widget--header', className, { collapsible: isCollapsible })}
    onClick={onClick}
    style={style}
    type="button"
  >
    <Row>
      {!!contentLeft && <Col>{contentLeft}</Col>}

      <Col bigger>
        <Row className="entity-widget--header-titles" flexDirection="column" isGrid={false} justifyContent="center">
          {!!title && <Col>{title}</Col>}
          {!!subtitle && <Col>{subtitle}</Col>}
        </Row>
      </Col>

      {!!contentRight && <Col {...contentRightProps}>{contentRight}</Col>}
    </Row>
  </button>
);

EntityWidgetHeader.propTypes = {
  className: PropTypes.string,
  contentLeft: PropTypes.node,
  contentRight: PropTypes.node,
  contentRightProps: PropTypes.shape(),
  isCollapsible: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape(),
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

EntityWidgetHeader.defaultProps = {
  className: undefined,
  contentLeft: undefined,
  contentRight: undefined,
  contentRightProps: {},
  isCollapsible: true,
  onClick: () => {},
  style: {},
  subtitle: undefined,
  title: undefined,
};

export default EntityWidgetHeader;
