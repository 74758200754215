import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const PanelHeader = ({ children, className, hasPanelBody, title, titleIconClassName }) => (
  <div className={clsx('panel-heading', className, { 'no-panel-body': !hasPanelBody })}>
    <div className="panel-heading--row">
      <div className="panel-heading--title">
        {titleIconClassName && <span className={titleIconClassName} />}
        <p className="font-regular font-color--greyXDark">{title}</p>
      </div>

      {children}
    </div>
  </div>
);

PanelHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasPanelBody: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleIconClassName: PropTypes.string,
};

PanelHeader.defaultProps = {
  children: undefined,
  className: undefined,
  hasPanelBody: true,
  titleIconClassName: undefined,
};

export default PanelHeader;
