import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { fundRoutesService } from '../../services';
import { generateKeys } from '../../helpers';
export const useFundRoutes = (field, receiverCountry) => {
    return useQuery({
        context: queryContext,
        enabled: !!receiverCountry,
        queryKey: generateKeys.fundRoutes(field, receiverCountry),
        queryFn: async () => fundRoutesService.getFundRoutes(field, receiverCountry),
    });
};
