import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Text-only element (children can be strings or inline text-friendly elements)
 * for rendering section/content subtitles.
 * @param {Object} props
 * @return {StatelessComponent}
 */
const ContentSubtitle = ({ children, className }) => (
  <p className={clsx('font-color--dark-jordan font-regular remove-margin-bottom', className)}>{children}</p>
);

ContentSubtitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
};

export default ContentSubtitle;
